import React, { useMemo } from "react";
import {
  ComponentType,
  ComponentParamType,
  ComponentParameter,
  ComponentTypeReference,
} from "model/datatypes";
import { EditableParameterRow } from "./EditableParameter";
import getUUID from "utils/jsUtils/getUUID";
import RepositionList, { RepositionListItem } from "components/basic/RepositionList";

interface Props {
  componentParameters: ComponentType["parameters"];
  modelRef:
    | {
        id: string;
        displayName: string;
      }
    | undefined;
  onUpdateParam: (updatedParam: ComponentParamType) => void;
  onUpdateAllParms: (updatedParams: ComponentType["parameters"]) => void;
  onDelete: (param: ComponentParamType) => void;
  allowedParamRefs: ComponentTypeReference[];
}

const ParametersEditTable: React.FC<Props> = ({
  componentParameters,
  onDelete,
  onUpdateParam,
  modelRef,
  allowedParamRefs,
  onUpdateAllParms,
}) => {
  //sort the component parameters?
  const parameterList = useMemo<RepositionListItem[]>(() => {
    console.log("setParamlist..");
    return componentParameters.map((param) => ({
      id: param.uuid,
      element: (
        <EditableParameterRow
          modelRef={modelRef}
          key={param.uuid}
          param={param}
          onUpdateParam={(updatedParam) => {
            onUpdateParam(updatedParam);
          }}
          onDeleteParam={() => {
            onDelete(param);
          }}
          allowedParamRefs={allowedParamRefs}
        />
      ),
      val: param,
    }));
  }, [componentParameters, allowedParamRefs, modelRef, onDelete, onUpdateParam]);

  return (
    <div className="flex flex-col pb-4">
      <div className="flex-none">
        <div className="font-bold text-sm">Parameters</div>
        <div className="w-full flex py-2 px-2 border-t  border-gray-200">
          <div className="w-1/8 pr-4 text-xs font-medium">ID</div>
          <div className="w-1/8 pr-4 text-xs font-medium">Display name</div>
          <div className="w-1/8 pr-4 text-xs font-medium">Tooltip</div>
          <div className="w-1/8 pr-4 text-xs font-medium">Type</div>
          <div className="w-1/8 pr-4 text-xs font-medium">Tag / setup</div>
          <div className="w-1/8 pr-4 text-xs font-medium">Default value</div>
          <div className="w-1/12 pr-4 text-xs font-medium">Access</div>
          <div className="w-1/12 pr-4 text-xs font-medium">Optional</div>
          <div className=""></div>
        </div>
      </div>
      <RepositionList
        list={parameterList}
        onReposition={(updatedList) => {
          const updatedParamters = updatedList.map(
            (li) => li.val
          ) as ComponentType["parameters"];
          onUpdateAllParms(updatedParamters);
        }}
      />
      {componentParameters.length === 0 && (
        <div className="italic text-xs px-2">No parameters added</div>
      )}
      <div className="py-2 flex-none px-2">
        <button
          onClick={() => {
            const newParam: ComponentParameter = {
              uuid: getUUID(),
              id: "",
              value: "",
              type: "string",
              displayName: "",
            };
            onUpdateParam(newParam);
          }}
          className="w-16 bg-white shadow-md rounded border border-gray-200 focus:outline-none"
        >
          +
        </button>
      </div>
    </div>
  );
};

export default ParametersEditTable;
