import React, { useMemo, useState } from "react";
import MultipleTagSelecter from "components/basic/TagSelecter";
import gtw from "gtw";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Plot from "react-plotly.js";
import { Popup } from "components/basic/Popup";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { PlotData } from "plotly.js";
import { Dataframe } from "api/useWebsocketData";
// import EditIcon from "components/basic/icons/EditIcon";
import OpenCloseButton from "components/basic/icons/OpenCloseButton";
import Papa from "papaparse";

interface Props {
  lineGraph: { id: string; fields: string[] };
  allTags: { id: string; displayName: string }[];
  onUpdate: (updatedFields: { id: string; fields: string[] }) => void;
  onRemove: () => Promise<void>;
  setTitle: (newTitle: string) => void;
  data: Dataframe | null;
  t: number[] | null;
}

const RawLineGraph: React.FC<Props> = ({
  lineGraph,
  allTags,
  setTitle,
  onUpdate,
  onRemove,
  data,
  t,
}) => {
  const [loading, setLoading] = useState(false);
  const [graphTitle, setGraphTitle] = useState("");
  const [editingTitle, setEditingTitle] = useState(false);
  const [open, setOpen] = useState(true);

  const mappedData = useMemo(() => {
    const plotData: Partial<PlotData>[] = [];
    lineGraph.fields.forEach((field) => {
      const theData = data ? data[field] : null;
      if (theData && t)
        plotData.push({
          x: t,
          y: theData,
          type: "scatter",
          mode: "lines",
          name: field,
        });
    });
    return plotData;
  }, [data, t, lineGraph.fields]);

  const saveAsCSV = () => {
    if (!data) {
      return;
    }

    let tags: string[] = [];
    const dataRows: number[][] = [[]];

    //add time vector as first data:
    if (t) {
      tags.push("t");
      t.forEach((val, j) => {
        if (dataRows[j]) dataRows[j].push(val);
        else dataRows[j] = [val];
      });
    }

    //add remaining tags and their values:
    const graphTags = lineGraph.fields.filter((tag) => !!data[tag] && data[tag].length > 0); // make sure data exists for tags
    graphTags.forEach((tag) => {
      tags.push(tag);
      data[tag].forEach((p, j) => {
        if (dataRows[j]) dataRows[j].push(p);
        else dataRows[j] = [p];
      });
    });

    const csv = Papa.unparse([tags, ...dataRows], { delimiter: ";" });
    const blob = new Blob([csv]);
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "CSV Export File.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const renderOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        pos={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`${gtw.popupBtn}`}
              onClick={() => {
                setLoading(true);
                onRemove()
                  .then(() => setLoading(false))
                  .catch(() => setLoading(false));
                closeMe();
              }}
            >
              Remove
            </button>
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  return (
    <div
      className="my-2 p-4 shadow border border-gray-200 relative"
      style={{ backgroundColor: "#F7FAFC" }}
    >
      <div className="absolute flex top-0 right-0 mr-2 mt-2">
        <span className=" pt-1.5 mr-1.5">{renderOptions()} </span>
        <OpenCloseButton open={open} onClick={() => setOpen(!open)} />
      </div>
      <div className="flex mb-2">
        {editingTitle ? (
          <div className="flex ">
            <textarea
              rows={1}
              className="mr-2"
              onChange={(e) => setGraphTitle(e.target.value)}
            ></textarea>
            <button
              onClick={() => {
                setTitle(graphTitle);
                setEditingTitle(false);
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div className="text-sm font-medium">
            {graphTitle.length > 0 ? graphTitle : "Line graph"}
          </div>
        )}
      </div>
      <div className="text-xs font-medium">Data tags</div>
      <div className="w-full">
        <MultipleTagSelecter
          tags={allTags}
          selectedTagIDs={lineGraph.fields}
          selectTag={(tagID) => {
            onUpdate({
              ...lineGraph,
              fields: [...lineGraph.fields, tagID],
            });
          }}
          removeTag={(tagID) =>
            onUpdate({
              ...lineGraph,
              fields: lineGraph.fields.filter((field) => field !== tagID),
            })
          }
        />
      </div>
      {open && (
        <div className="w-full pt-2 relative">
          {loading && <LoadingOverlay />}
          {mappedData.length > 0 && (
            <Plot
              className={``}
              data={mappedData}
              useResizeHandler
              config={{ displayModeBar: false }}
              style={{ width: "100%" }}
              layout={{
                autosize: true,
                paper_bgcolor: "#F7FAFC",
                plot_bgcolor: "#F7FAFC",
                margin: {
                  t: 40,
                  b: 40,
                  l: 40,
                  r: 40,
                  pad: 0,
                },
              }}
            />
          )}
          <button
            className="button-small absolute right-0 bottom-0 mr-2 mb-2"
            onClick={() => saveAsCSV()}
          >
            Save CSV
          </button>
        </div>
      )}
    </div>
  );
};

export default RawLineGraph;
