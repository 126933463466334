import React, { useMemo } from "react";
import { DataSource } from "model/datatypes";
import { DropdownAtRoot } from "components/basic/Dropdown";
import { useDataSources } from "api/useFirebase";

export const DatasourceSelecter: React.FC<{
  selectedDataSourceID?: string;
  onSelect: (source: DataSource) => void;
}> = ({ selectedDataSourceID, onSelect }) => {
  const { dataSources } = useDataSources();
  const options = useMemo(
    () => [
      ...dataSources.map((ds) => ({
        id: ds.id,
        display: ds.dataSetName,
        val: ds,
      })),
      { id: "none", display: "No input", val: null },
    ],
    [dataSources]
  );
  return (
    <DropdownAtRoot
      className={"w-full"}
      selectedID={selectedDataSourceID}
      options={options}
      placeholder="Select datasource"
      onSelect={(option) => {
        onSelect(option.val);
      }}
    />
  );
};

export default DatasourceSelecter;
