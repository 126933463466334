import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { usePagePosition } from "utils/hooks/usePagePosition";

interface Props {
  className?: string;
  mt?: number;
  mx?: number;
  text: string;
  rightAlign?: true;
}

const HoverTooltip: React.FC<Props> = ({ children, className, text, mt, mx, rightAlign }) => {
  const [hovered, sethovered] = useState(false);
  const [clicked, setclicked] = useState(false);

  //clickhandler for closing
  const ref = useRef<HTMLDivElement>(null);
  const { pagePosition, updatePosition } = usePagePosition(ref);

  const handleClick = (e: MouseEvent) => {
    if (ref.current && e.target instanceof Node && ref.current.contains(e.target)) {
      return;
    }
    setclicked(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("scroll", updatePosition);

    return () => {
      setclicked(false);
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("scroll", updatePosition);
    };
  }, [updatePosition]);

  const marginLeft = rightAlign
    ? 0
    : mx
    ? pagePosition.marginLeft + mx
    : pagePosition.marginLeft;
  const marginRight = !rightAlign
    ? 0
    : mx
    ? pagePosition.marginRight + mx
    : pagePosition.marginRight;

  return (
    <div
      ref={ref}
      className={`relative z-10 ${className ? className : ""}`}
      onMouseEnter={() => {
        sethovered(true);
        updatePosition();
      }}
      onMouseLeave={() => {
        sethovered(false);
      }}
      onClick={() => {
        setclicked(true);
        updatePosition();
      }}
    >
      {children}
      {(hovered || clicked) && (
        <RootPortal>
          <div
            className={`absolute z-50 px-1 py-1 bg-white shadow-xl rounded border 
           top-0 ${rightAlign ? "right-0" : "left-0"} text-xs max-w-sm
          `}
            style={{
              marginTop: mt ? pagePosition.marginTop + mt : pagePosition.marginTop,
              marginLeft,
              marginRight,
              zIndex: 1000,
            }}
          >
            {text}
          </div>
        </RootPortal>
      )}
    </div>
  );
};

export default HoverTooltip;

const RootPortal: React.FC = ({ children }) => {
  const appRoot = document.getElementById("root");
  return appRoot && createPortal(children, appRoot);
};
