import { CMHarvester, CMHarvesterInstance, Scenario } from "model/datatypes";
import { fsFieldvalue } from "utils/firebase/helpers";
import { updateArrayValRawString } from "utils/jsUtils/imutableArray";

export const getCMHarvesterInstance = (harvester: CMHarvester) => {
  const { params, tagDocs, ...instanceVars } = harvester;
  const harvesterInstance: CMHarvesterInstance = {
    ...instanceVars,
    params: params || {
      groupingsType: "HOURS",
      tagList: [],
      timeFrom: 0,
      timeTo: 0,
    },
  };
  return harvesterInstance;
};

export const prepareHavesterForSave = (scenario: Scenario) => {
  let harvester = scenario.harvester;

  if (harvester?.harvesterType === "control_machines") {
    let allTagPaths: string[] = [];
    scenario.simComponents.forEach((simComp) => {
      simComp.inputVariables.forEach((inputVar) => {
        if (inputVar.CMTag)
          allTagPaths = updateArrayValRawString(allTagPaths, inputVar.CMTag.path);
      });
    });
    harvester.params.tagList = allTagPaths;
  }

  return harvester || fsFieldvalue.delete();
};
