import React, { useState } from "react";
import { useFirebase } from "api/useFirebase";
import Modal from "components/basic/Modal";
import { Group } from "model/datatypes";
import MetadataSetup from "components/simulations/newSimulation/simSetup/MetadataSetup";

const EditGroup: React.FC<{ projectID: string; onFinish: () => void; group: Group }> = ({
  onFinish,
  group,
  projectID,
}) => {
  const [editedGroup, setEditedGroup] = useState<Group>(group);
  const fb = useFirebase();
  const [loading, setLoading] = useState(false);
  const updateGroup = () => {
    if (editedGroup) {
      setLoading(true);
      const { created, id, scenarios, ...updateableFields } = editedGroup;
      fb.firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .doc(group.id)
        .update(updateableFields)
        .then(() => {
          setLoading(false);
          onFinish();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  return (
    <Modal onClose={() => onFinish()}>
      <div className={`${tw.card} z-30`}>
        <label className="font-bold text-xs">Group name</label>
        <input
          type="text"
          className={`${tw.input} mb-2`}
          value={editedGroup.groupName}
          onChange={(e) => setEditedGroup({ ...editedGroup, groupName: e.target.value })}
        />
        <label className="font-bold text-xs">Group description</label>
        <textarea
          className={`${tw.input} h-20 mb-2`}
          value={editedGroup.description}
          onChange={(e) => setEditedGroup({ ...editedGroup, description: e.target.value })}
        />
        <MetadataSetup
          meta_data={group.meta_data}
          updateMetadata={(updated) => {
            setEditedGroup({ ...editedGroup, meta_data: updated });
          }}
        />
        <div className="flex mt-4">
          <button
            className={`flex-1 ${tw.saveBtn} mr-2 ${loading ? "opacity-50" : ""}`}
            onClick={() => {
              !loading && updateGroup();
            }}
          >
            Edit Group
          </button>
          <button
            className={`flex-1 ${tw.saveBtn} ml-2 ${loading ? "opacity-50" : ""}`}
            onClick={() => !loading && onFinish()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

const tw = {
  card: "border border-gray-300 rounded p-4 bg-white shadow",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
};

export default EditGroup;
