import React, { useState } from "react";
import { useFirebase } from "api/useFirebase";
import Modal from "components/basic/Modal";
import { ComponentType } from "model/datatypes";
import * as Sentry from "@sentry/browser";
import Toast from "components/basic/Toast";

interface Props {
  compType: ComponentType;
  onFinish: () => void;
}

const SaveLibraryComponent: React.FC<Props> = ({ compType, onFinish }) => {
  const [componentDisplayName, setcomponentDisplayName] = useState(compType.displayName);
  const [componentName, setcomponentName] = useState(compType.name);
  const [componentType, setcomponentType] = useState(compType.type);

  const [loading, setLoading] = useState(false);
  const fb = useFirebase();
  const saveToLibrary = () => {
    setLoading(true);
    let newComponent: any = {
      displayName: componentDisplayName,
      name: componentName,
      type: componentType,
      item_class: compType.item_class,
      parameters: compType.parameters,
      inputVariables: compType.inputVariables,
      instantiationRules: compType.instantiationRules,
    };
    fb.firestore()
      .collection("ComponentLibrary")
      .add(newComponent)
      .then(() => {
        setLoading(false);
        onFinish();
        Toast("Succesfully saved component to library", { icon: "success" });
      })
      .catch((e) => {
        setLoading(false);
        Sentry.captureException(e);
        console.log(e);
      });
  };

  return (
    <Modal
      onClose={() => {
        onFinish();
      }}
    >
      <div className="bg-white z-50 w-64 px-4 py-4 border border-gray-300 shadow-lg rounded">
        <div className="font-bold">Save component to library</div>
        <div className="text-xs mt-3">Display Name</div>
        <input
          type="text"
          className={tw.input}
          onChange={(e) => {
            setcomponentDisplayName(e.target.value);
          }}
          value={componentDisplayName}
        />
        <div className="text-xs mt-3">Name/ID</div>
        <input
          type="text"
          className={tw.input}
          onChange={(e) => {
            setcomponentName(e.target.value);
          }}
          value={componentName}
        />
        <div className="text-xs mt-3">Type</div>
        <input
          type="text"
          className={tw.input}
          onChange={(e) => {
            setcomponentType(e.target.value);
          }}
          value={componentType}
        />
        <div className="text-xs mt-3">
          {compType.parameters ? compType.parameters.length : 0} parameters
        </div>
        <div className="text-xs">{compType.inputVariables.length} input variables</div>
        <button
          className={`mt-4 w-full py-1 border border-gray-300 rounded shadow focus:outline-none ${
            loading ? "opacity-50 hover:cursor-wait" : "hover:font-bold"
          }`}
          onClick={() => {
            !loading && saveToLibrary();
          }}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default SaveLibraryComponent;

const tw = {
  input: "px-2 py-1 focus:outline-none border rounded",
};
