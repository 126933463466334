import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  // useFirebase,
  useFirestore,
} from "api/useFirebase";
import { Scenario } from "model/datatypes";
// import { loadSimulationRawData } from "api/SimulationAPI";
import Toast from "components/basic/Toast";
import LoadingOverlay from "components/basic/LoadingOverlay";
import { fetchWebSocketData } from "api/useWebsocketData";

const IFrameExpansion: React.FC<{
  iframeURL: string;
  scenario: Scenario;
  projectID: string;
}> = ({ scenario, projectID, iframeURL }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeLoading, setIframeLoading] = useState(true);

  const extensionData = useExtensionData(projectID, scenario.id, iframeURL);

  const [iframeReady, setIframeReady] = useState(false);
  //Parse initial state to IFRAME:
  useEffect(() => {
    if (iframeReady) {
      const extensionWindow = iframeRef.current?.contentWindow;
      extensionWindow?.postMessage(
        {
          type: "SET_SCENARIO",
          payload: JSON.parse(JSON.stringify(scenario)),
        },
        "*"
      );
    }
  }, [iframeReady, scenario]);

  useEffect(() => {
    if (iframeReady && extensionData) {
      const extensionWindow = iframeRef.current?.contentWindow;
      extensionWindow?.postMessage(
        {
          type: "LOAD_EXTENSION_DATA",
          payload: extensionData,
        },
        "*"
      );
    }
  }, [extensionData, iframeReady]);

  const fs = useFirestore();
  const [updatingScenario, setUpdatingScenario] = useState(false);
  const updateScenario = useCallback(
    async (updates: Partial<Scenario>) => {
      if (scenario) {
        try {
          setUpdatingScenario(true);
          await fs
            .collection("Projects")
            .doc(projectID)
            .collection("Scenarios")
            .doc(scenario.id)
            .update(updates);
          Toast("Saved update", { icon: "success" });
          setUpdatingScenario(false);
        } catch (error) {
          setUpdatingScenario(false);
          Toast("Error saving", { icon: "error" });
        }
      }
    },
    [fs, projectID, scenario]
  );

  const saveExtensionData = useCallback(
    async (updates: any) => {
      if (scenario) {
        try {
          setUpdatingScenario(true);
          await fs
            .collection("Projects")
            .doc(projectID)
            .collection("Scenarios")
            .doc(scenario.id)
            .collection("ExtensionData")
            .doc(iframeURL)
            .set(updates, { merge: true });
          Toast("Saved update", { icon: "success" });
          setUpdatingScenario(false);
        } catch (error) {
          setUpdatingScenario(false);
          Toast("Error saving", { icon: "error" });
        }
      }
    },
    [fs, projectID, scenario, iframeURL]
  );

  const getDataForIframe = useCallback(
    async (fields: string[]) => {
      const data = await fetchWebSocketData(projectID, scenario.id, fields);
      const extensionWindow = iframeRef.current?.contentWindow;
      extensionWindow?.postMessage(
        {
          type: "SIMULATION_DATA_RESULT",
          payload: data,
        },
        "*"
      );
    },
    [projectID, scenario.id]
  );

  //recieve messages from iFrame:
  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      console.log(event.data);
      const action = event.data as { type: string; payload: any };
      switch (action.type) {
        case "EXTENSION_READY":
          setIframeReady(true);
          break;
        case "UPDATE_SCENARIO":
          updateScenario(action.payload);
          break;
        case "SAVE_EXTENSION_DATA":
          saveExtensionData(action.payload);
          break;
        case "GET_DATA":
          getDataForIframe(action.payload);
          break;
        default:
          console.log("unkown action:");
          console.log(action);
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [updateScenario, getDataForIframe, saveExtensionData]);

  return (
    <div className="w-full h-full relative">
      <iframe
        ref={iframeRef}
        className="h-full w-full"
        src={iframeURL}
        title="Custom"
        onLoad={(reportLoadedEvent) => {
          setIframeLoading(false);
        }}
      />
      {(iframeLoading || !iframeReady || updatingScenario) && <LoadingOverlay />}
    </div>
  );
};

export default IFrameExpansion;

const useExtensionData = (projectID: string, scenarioID: string, iframeURL: string) => {
  const [extensionData, setExtensionData] = useState<any>(null);
  const fs = useFirestore();

  useEffect(() => {
    return fs
      .collection("Projects")
      .doc(projectID)
      .collection("Scenarios")
      .doc(scenarioID)
      .collection("ExtensionData")
      .doc(iframeURL)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setExtensionData(doc.data());
        }
      });
  }, [projectID, scenarioID, iframeURL, fs]);
  return extensionData;
};
