import React, { useState, useContext, useEffect, useMemo } from "react";
import { useScenarios, useFirebase, useDataSources } from "api/useFirebase";
import { store } from "store";
import { Group, Scenario } from "model/datatypes";
import { Popup } from "components/basic/Popup";
import ScenarioCard from "./ScenarioCard";
import * as Sentry from "@sentry/browser";
import EditGroup from "./group/EditGroup";
import NewScenario from "./newSimulation/NewScenario";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Toast from "components/basic/Toast";
import AddGroupProcessor from "./group/AddGroupProcessor";
import GroupProcessorCard from "./group/GroupProcessorCard";
import { useHasEditAccess } from "api/useAuth";
import CommentableComponent from "components/comments/CommentableComponent";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import gtw from "gtw";
import { copyToClipboard } from "utils/jsUtils/CopyToClipBoard";
import RepositionList, { RepositionListItem } from "components/basic/RepositionList";
import OpenCloseButton from "components/basic/icons/OpenCloseButton";

const GroupCard: React.FC<{ group: Group; onStartReposition: () => void }> = ({
  group,
  onStartReposition,
}) => {
  const scenariosAmount = group.scenarios?.length;
  const { state, dispatch } = useContext(store);
  const { projectID, projectName, selectedGroups } = state;
  const hasGroupEditAccess = useHasEditAccess(group.ownerId);

  const copyGroupLinkToClipboard = () => {
    const link = encodeURI(
      `${window.location.protocol}//${window.location.host}/simulations/${projectName}/${projectID}/${group.id}`
    );
    copyToClipboard(link);
    Toast("Direct link to group copied to clipboard", { icon: "success" });
  };

  const fb = useFirebase();
  const [open, setOpen] = useState(false);
  // const { open, setOpen, style } = useMaxHeightTransition("14em", "1000vh");

  // const commentExists = useCheckForComments("groupID", group.id);

  useEffect(() => {
    if (selectedGroups.some((selected: string) => selected === group.id)) setOpen(true);
    else setOpen(false);
  }, [selectedGroups, group, setOpen]);

  const [loading, setLoading] = useState(false);
  const [editingGroup, setEditingGroup] = useState(false);

  const [addingScenario, setAddingScenario] = useState<boolean | Scenario>(false);
  const [addingGroupProcessor, setAddingGroupProcessor] = useState(false);

  const deleteGroup = () => {
    //only allow deleting empty groups..
    if (scenariosAmount === 0 && projectID) {
      setLoading(true);
      fb.firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .doc(group.id)
        .delete()
        .then(() => {
          //nothing to do here, listerners should remove group...
        })
        .catch((error) => {
          console.log(error);
          Sentry.captureException(error);
          setLoading(false);
        });
    }
  };

  const updateGroup = async (updatedFields: Partial<Group>) => {
    if (projectID && !loading) {
      setLoading(true);
      await fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .doc(group.id)
        .update(updatedFields);
      setLoading(false);
    }
  };

  const renderOpenGroup = () => {
    if (projectID)
      return (
        <div className="px-4 py-2">
          <div className="text-lg font-medium">{group.groupName}</div>
          <div className="text-xs">{group.description}</div>
          {group.groupProcessor && (
            <GroupProcessorCard group={group} groupProcessor={group.groupProcessor} />
          )}
          <OpenGroupCardContent
            totalAmountOfScenarios={scenariosAmount}
            projectID={projectID}
            group={group}
            scenarioOrder={group.scenarios}
            updateScenarioOrder={(updatedScenaris) => {
              updateGroup({ scenarios: updatedScenaris });
            }}
            onDuplicateScenario={setAddingScenario}
          />
        </div>
      );
  };

  const renderClosedGroup = () => {
    return (
      <CommentableComponent
        commentTaget={{
          projectID: projectID!,
          projectName: projectName!,
          groupName: group.groupName,
          groupID: group.id,
        }}
        className={`relative`}
      >
        <div className="px-4 py-2">
          <div className="text-lg font-medium">{group.groupName}</div>
          <div className="text-xs">{group.description}</div>
          <div className="text-sm italic">
            {scenariosAmount === 0
              ? "No scenarios yet."
              : `${scenariosAmount} scenario${scenariosAmount > 1 ? "s" : ""}`}
          </div>
        </div>
      </CommentableComponent>
    );
  };

  const renderGroupOptions = () => {
    let errorMessage: string;
    if (scenariosAmount > 0) {
      errorMessage = "Only empty groups can be deleted";
    } else if (!hasGroupEditAccess) {
      errorMessage = "You do not have permission to delete this group";
    }

    return (
      <Popup
        useHover
        mt={13}
        pos="right"
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`${tw.popupBtn}`}
              onClick={() => {
                setAddingScenario(true);
                closeMe();
              }}
            >
              New scenario
            </button>
            <button className={`${tw.popupBtn} border-t border-gray-400  opacity-50`}>
              Duplicate group
            </button>
            <button
              className={`${tw.popupBtn} border-t border-gray-400`}
              onClick={() => {
                onStartReposition();
                closeMe();
              }}
            >
              Reposition group
            </button>
            <button
              className={`${tw.popupBtn} border-t border-gray-400`}
              onClick={() => setEditingGroup(true)}
            >
              Edit group
            </button>
            <button
              className={`${tw.popupBtn} border-t border-gray-400`}
              onClick={() => {
                setAddingGroupProcessor(true);
                closeMe();
              }}
            >
              Add group processor
            </button>
            <button
              onClick={() => {
                copyGroupLinkToClipboard();
                closeMe();
              }}
              className={`${tw.popupBtn} border-t border-gray-400`}
            >
              Copy link to group
            </button>
            {scenariosAmount > 0 || !hasGroupEditAccess ? (
              <button
                className={`${tw.popupBtn} opacity-50 text-red-700 border-t border-gray-400`}
                onClick={() => {
                  Toast(`${errorMessage}`, { icon: "warning" });
                }}
              >
                Delete group
              </button>
            ) : (
              DeleteButtonPopup()
            )}
          </div>
        )}
      >
        <DotDotDotIcon />
      </Popup>
    );
  };

  const DeleteButtonPopup = () => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete group?</div>
              <div className="italic mb-2">
                This is a destructive event that can't be undone.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`${tw.smallBtn} border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={() => {
                      if (!loading) {
                        deleteGroup();
                        closeDeletePopup();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`${tw.smallBtn} w-full`}
                    onClick={() => {
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`${tw.popupBtn} text-red-500 border-t border-gray-400`}>
          Delete group
        </button>
      </Popup>
    );
  };

  return (
    <>
      <div className="relative w-full border-2 border-gray-500 border-dashed rounded flex flex-col mb-6">
        {!open && renderClosedGroup()}

        {open && renderOpenGroup()}

        <div className="absolute right-0 top-0 mt-4 mr-4 flex items-center">
          <div className="relative mr-2">{renderGroupOptions()}</div>
          {scenariosAmount > 0 && (
            <OpenCloseButton
              open={open}
              onClick={() => {
                if (open) dispatch({ type: "DESELECT_GROUP", payload: group.id });
                else dispatch({ type: "SELECT_GROUP", payload: group.id });
              }}
            />
          )}
        </div>
      </div>

      {editingGroup && projectID && (
        <EditGroup
          group={group}
          onFinish={() => setEditingGroup(false)}
          projectID={projectID}
        />
      )}
      {addingScenario && projectID && (
        <NewScenario
          projectID={projectID}
          onFinish={() => setAddingScenario(false)}
          fromScenario={typeof addingScenario === "object" ? addingScenario : undefined}
          groupID={group.id}
        />
      )}
      {addingGroupProcessor && projectID && (
        <AddGroupProcessor
          addedProcessor={group.groupProcessor}
          groupID={group.id}
          projectID={projectID}
          onClose={() => {
            setAddingGroupProcessor(false);
          }}
        />
      )}
      {/* </CommentableComponent> */}
    </>
  );
};

export default GroupCard;

const tw = {
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
  popupBtn: "py-2 w-full font-medium text-gray-700 focus:outline-none hover:bg-gray-200",
  card: "border border-gray-300 rounded py-4 px-4 bg-white w-full",
  roundBtn:
    "relative bg-white focus:outline-none shadow rounded-full h-8 w-8 flex justify-center items-center border border-gray-200",
};

const OpenGroupCardContent: React.FC<{
  projectID: string;
  group: Group;
  onDuplicateScenario: (prevScenario: Scenario) => void;
  scenarioOrder: string[];
  updateScenarioOrder: (updatedOrder: string[]) => void;
  totalAmountOfScenarios: number;
}> = ({
  projectID,
  group,
  onDuplicateScenario,
  totalAmountOfScenarios,
  scenarioOrder,
  updateScenarioOrder,
}) => {
  const { dataSources } = useDataSources();

  const [limit, setLimit] = useState(20);
  const scenarios = useScenarios(projectID, group.id, limit);
  const [repositioningID, setRepositioningID] = useState<string | null>(null);

  const scenarioList = useMemo(() => {
    const scnearioList: RepositionListItem[] = [];
    scenarioOrder.forEach((scenarioID) => {
      const scenario = scenarios?.find((s) => s.id === scenarioID);
      if (!scenario) return;
      const dataSource = dataSources.find(
        (source) =>
          source.origin?.type === "output" && source.origin.scenarioID === scenario.id
      );
      scnearioList.push({
        id: scenario.id,
        element: (
          <ScenarioCard
            key={scenario.id}
            scenario={scenario}
            groupName={group.groupName}
            onDuplicate={() => {
              onDuplicateScenario(scenario);
            }}
            outputData={dataSource}
            onStartReposition={() => {
              setRepositioningID(scenario.id);
            }}
          />
        ),
        val: scenario,
      });
    });
    return scnearioList;
  }, [scenarios, scenarioOrder, group.groupName, dataSources, onDuplicateScenario]);

  return (
    <>
      {scenarios && (
        <RepositionList
          list={scenarioList}
          onReposition={(updatedList) => {
            updateScenarioOrder(updatedList.map((li) => li.id));
          }}
          onDragEnd={() => {
            setRepositioningID(null);
          }}
          dragableID={repositioningID}
          enableDragOverlay
        />
      )}
      {totalAmountOfScenarios > limit && (
        <div>
          <div className="italic text-sm mb-2">{`Showing ${limit} of ${totalAmountOfScenarios} simulations`}</div>
          <button className={gtw.smallBtn} onClick={() => setLimit((p) => p + 20)}>
            Load more
          </button>
        </div>
      )}

      {!scenarios && (
        <div className="h-32 relative">
          <LoadingOverlay />
        </div>
      )}
    </>
  );
};
