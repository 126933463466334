import React, { useState, useEffect } from "react";
import { InputVar } from "model/datatypes";
import gtw from "gtw";
import { InputText } from "components/basic/Input";

interface Props {
  value: InputVar;
  onUpdate: (newVal: InputVar) => void;
  onDeleteInput: () => void;
}

const InputVariableEditable: React.FC<Props> = ({ value, onUpdate, onDeleteInput }) => {
  const [displayDefaultVal, setdisplayDefaultVal] = useState<string>(value.value.toString());
  const [error, seterror] = useState<boolean>(false);

  useEffect(() => {
    setdisplayDefaultVal(value.value.toString());
  }, [value]);

  const handleChangeDefaultVal = (newVal: string) => {
    setdisplayDefaultVal(newVal);
    const num = parseFloat(newVal);
    seterror(isNaN(num));
    if (!isNaN(num)) onUpdate({ ...value, value: num });
  };

  return (
    <div className="py-2 px-2 border-t  border-gray-200 hover:bg-gray-200">
      <div className="flex w-full items-center text-sm">
        <div className="pr-4 w-1/8">
          <InputText
            value={value.id}
            className={`${tw.input} w-full`}
            onChange={(val) => {
              onUpdate({ ...value, id: val });
            }}
          />
        </div>
        <div className="pr-4 w-1/5">
          <InputText
            value={value.display}
            className={`${tw.input} w-full`}
            onChange={(val) => {
              onUpdate({ ...value, display: val });
            }}
          />
        </div>
        <div className="pr-4 w-1/5">
          <InputText
            value={value.tooltip || ""}
            className={`${tw.input} w-full`}
            onChange={(val) => {
              onUpdate({ ...value, tooltip: val });
            }}
          />
        </div>
        <div className="pr-4 w-1/5">
          <InputText
            value={value.unit || ""}
            className={`${tw.input} w-full`}
            onChange={(val) => {
              onUpdate({ ...value, unit: val });
            }}
          />
        </div>
        <div className="pr-4 w-1/5">
          <input
            type="number"
            className={`${tw.input} w-full ${error ? "border-red-300" : "border-gray-300"}`}
            value={displayDefaultVal}
            onChange={(e) => handleChangeDefaultVal(e.target.value)}
          />
        </div>
        <div className="flex-grow flex justify-end">
          <button
            onClick={() => {
              onDeleteInput();
            }}
            className={gtw.smallBtn}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputVariableEditable;

const tw = {
  input: "px-2 py-1 focus:outline-none border rounded text-xs",
};
