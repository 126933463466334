import Modal from "components/basic/Modal";
import SearchDropdownMultiple from "components/basic/SearchDropdownMultiple";
import gtw from "gtw";
import { RawUser } from "model/datatypes";
import React, { useMemo, useState } from "react";

export interface AddTeamProps {}

const AddCollaborator: React.FC<{
  onFinish: () => void;
  onSave: (uppdatedTeamIds: string[]) => Promise<any>;
  addedCollaborators: string[];
  allUsers: RawUser[];
}> = ({ onFinish, onSave, addedCollaborators, allUsers }) => {
  const [loading, setLoading] = useState(false);
  const [editedCollaborators, setEditedCollaborators] = useState(addedCollaborators);

  const allTags = useMemo(
    () => allUsers.map((rawUser) => ({ id: rawUser.id, displayName: rawUser.fullName })),
    [allUsers]
  );

  const addedRawUsers = useMemo(
    () => allUsers.filter((u) => editedCollaborators.some((uid) => uid === u.id)),
    [allUsers, editedCollaborators]
  );

  return (
    <Modal onClose={() => onFinish()}>
      <div className={`${tw.card} w-1/2 z-30`}>
        <div className="font-medium mb-4">Individual collaborators</div>
        {addedRawUsers.map((collaborator) => {
          return (
            <div
              key={collaborator.id}
              className="flex text-xs items-center w-full py-1 mb-2 border-b border-gray-200 pr-4"
            >
              <span className="flex-grow">{collaborator.fullName}</span>
            </div>
          );
        })}

        <div className={`${gtw.label}`}>Search</div>
        <SearchDropdownMultiple
          tags={allTags}
          selectedTagIDs={editedCollaborators}
          selectTag={(tagID) => setEditedCollaborators([...editedCollaborators, tagID])}
        />
        <button
          className={`${tw.saveBtn} mt-4`}
          onClick={() => {
            if (!loading) {
              setLoading(true);
              onSave(editedCollaborators)
                .then(() => {
                  setLoading(false);
                  onFinish();
                })
                .catch((error) => {
                  setLoading(false);
                });
            }
          }}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default AddCollaborator;

const tw = {
  card: "border border-gray-300 rounded p-4 bg-white shadow",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
};
