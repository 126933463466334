import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import {
  useCheckForComments,
  useFirebase,
  useLocalSimulationModel,
  useSimulationModel,
} from "api/useFirebase";
import { store } from "store";
import { Scenario, DataSource } from "model/datatypes";
import { Popup } from "components/basic/Popup";
import Modal from "components/basic/Modal";
import { downloadJsonObject } from "utils/jsUtils/downloadJsonObject";
import HoverTooltip from "components/basic/HoverTooltip";
import * as Sentry from "@sentry/browser";
import Toast from "components/basic/Toast";
import ConsoleLogger from "./ConsoleLogger";
import ScenarioBuilder from "./newSimulation/ScenarioBuilder";
import GlobalUpdatedIcon from "components/basic/icons/GlobalUpdatedIcon";
import LocalUpdatedIcon from "components/basic/icons/LocalUpdatedIcon";
import PullIcon from "components/basic/icons/PullIcon";
import ScenarioStages from "./scenarioPipeline/ScenarioStages";
import { checkScenarioIsRunning } from "utils/checkScenarioStatus";
import { useHasEditAccess, useUserRole } from "api/useAuth";
import { useHistory } from "react-router-dom";
import CommentableComponent from "components/comments/CommentableComponent";
import EyeIcon from "components/basic/icons/EyeIcon";
import { mergeScenarioSystem } from "utils/simulations/mergeScenarioSystem";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import OpenCloseButton from "components/basic/icons/OpenCloseButton";
import Extension from "components/extension/Extension";
import CommentIcon from "components/basic/icons/CommentIcon";

interface ScenarioCardProps {
  scenario: Scenario;
  groupName: string;
  onDuplicate: (scenario: Scenario) => void;
  outputData?: DataSource;
  onStartReposition: () => void;
}

const ScenarioCard: React.FC<ScenarioCardProps> = ({
  scenario,
  groupName,
  onDuplicate,
  outputData,
  onStartReposition,
}) => {
  const [consoleLoggerOpen, setConsoleLoggerOpen] = useState(false);
  const [playgroundOpen, setPlaygroundOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const fb = useFirebase();
  const { state, dispatch } = useContext(store);
  const {
    projectID,
    projectName,
    selectedScenario,
    selectedScenarioChanged,
    linkedScenario,
  } = state;

  const commentExists = useCheckForComments("scenarioID", scenario.id);

  const { hasDeveloperAccess } = useUserRole();
  const [editorOpen, setEditorOpen] = useState(false);

  const history = useHistory();

  const [highlighted, setHighlighted] = useState(false);
  //open the scenario if linked to:
  useEffect(() => {
    if (linkedScenario === scenario.id) {
      dispatch({ type: "SET_LINKED_SCENARIO", payload: null });
      history.push("/simulations");
      const blinkHighlight = (bool: boolean, time: number) =>
        setTimeout(() => {
          setHighlighted(bool);
        }, time);
      // setTimeout(()=>{dispatch({type: "SELECT_SCENARIO", payload: scenario})}, 100)
      setTimeout(() => {
        if (scenarioCardRef.current) {
          const boudingRect = scenarioCardRef.current.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const halfElementHeight = (boudingRect.bottom - boudingRect.top) / 2;
          window.scrollTo({
            top: boudingRect.top + windowHeight / 2 - halfElementHeight,
            left: 0,
            behavior: "smooth",
          });
        }
      }, 500);
      setHighlighted(true);
      blinkHighlight(false, 2000);
      blinkHighlight(true, 4000);
      blinkHighlight(false, 6000);
      blinkHighlight(true, 8000);
      blinkHighlight(false, 10000);
    }
  }, [linkedScenario, scenario.id, dispatch, history]);

  const scenarioCardRef = useRef<HTMLDivElement>(null);
  const hasScenarioEditAccess = useHasEditAccess(scenario.ownerId);

  const [topOffset, setTopOffset] = useState(0);
  const handleScroll = useCallback(
    (e: Event) => {
      const scencarioCardPosition = scenarioCardRef.current?.getBoundingClientRect();
      if (scencarioCardPosition && scencarioCardPosition.top < 0) {
        //fix to top:
        setTopOffset((scencarioCardPosition.top + 1) * -1);
      } else {
        setTopOffset(0);
      }
    },
    [scenarioCardRef]
  );
  //sroll effect
  useEffect(() => {
    if (editorOpen) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [editorOpen, handleScroll]);

  const [topBarOpacity, setTopBarOpacity] = useState(1);
  useEffect(() => {
    if (topOffset > 0) {
      setTopBarOpacity(0);
      const timeout = setTimeout(() => {
        setTopBarOpacity(1);
      }, 400);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      setTopBarOpacity(1);
    }
  }, [topOffset]);

  const localModel = useLocalSimulationModel(
    scenario.model.id,
    projectID || undefined,
    scenario.id
  );

  const globalModel = useSimulationModel(scenario.model.id);
  const [localUpdated, setLocalUpdated] = useState(false);
  const [globalUpdated, setGlobalUpdated] = useState(false);

  useEffect(() => {
    setLocalUpdated(!!localModel && localModel.localVersion !== localModel.version);
    setGlobalUpdated(
      !!globalModel && !!localModel && globalModel.version !== localModel.version
    );
  }, [localModel, globalModel]);

  useEffect(() => {
    const scenarioOpen = selectedScenario?.id === scenario.id;
    setEditorOpen(scenarioOpen);
  }, [selectedScenario, scenario.id, setEditorOpen]);

  const pullSimulationModel = async () => {
    if (globalUpdated && projectID && globalModel) {
      try {
        setLoading(true);
        await mergeScenarioSystem(fb, projectID, scenario, globalModel);
        setLoading(false);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
        setLoading(false);
      }
    }
  };

  const copyRefToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = JSON.stringify({
      projectID,
      scenarioID: scenario.id || null,
      groupID: scenario.groupID,
      scenarioName: scenario.scenarioName,
      projectName,
      kubernetes_name: scenario.kubernetes_name || "",
      kubernetes_namespace: scenario.kubernetes_namespace || "",
      //@ts-ignore
      kubernetes_name_report: scenario.kubernetes_name_report || "",
      //@ts-ignore
      kubernetes_namespace_report: scenario.kubernetes_namespace_report || "",
      fs_path: `/Projects/${projectID}/Scenarios/${scenario.id}`,
    });
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Reference copied to clipboard", { icon: "success" });
  };

  const copyScenarioLinkToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = encodeURI(
      `${window.location.protocol}//${window.location.host}/simulations/${projectName}/${projectID}/${scenario.groupID}/${scenario.id}`
    );
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Direct link to scenario copied to clipboard", { icon: "success" });
  };

  const deleteScenario = async () => {
    //TODO add checks if deling should be allowed
    if (scenario.id && projectID) {
      setLoading(true);
      try {
        const deleteScenario = fb.functions().httpsCallable("deleteScenario");
        await deleteScenario({
          projectID,
          scenarioID: scenario.id,
          groupID: scenario.groupID,
        });
        //nothing to do here, listeners should remove scenario...
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const toggleReportPublished = async () => {
    if (scenario.id && projectID && !loading) {
      setLoading(true);
      try {
        const scenarioDoc = fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenario.id);
        await scenarioDoc.update({ reportPublished: !scenario.reportPublished });
        setLoading(false);
      } catch (error) {
        Toast("Error updating scenario", { icon: "warning" });
        console.log(error);
        setLoading(false);
      }
    }
  };

  const getJson = () => {
    downloadJsonObject({ ...scenario, projectID }, scenario.scenarioName);
  };

  const renderMoreOptionsBtn = () => {
    return (
      <Popup
        useHover
        mt={15}
        pos={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            {globalUpdated && PullOption()}
            <button
              onClick={() => {
                onDuplicate(scenario);
                closeMe();
              }}
              className={tw.popupBtn}
            >
              Duplicate scenario
            </button>
            <button
              onClick={() => {
                onStartReposition();
              }}
              className={`${tw.popupBtn} border-t border-gray-400`}
            >
              Reposition scenario
            </button>

            {(scenario.traceback || scenario.traceback_report || scenario.logs) && (
              <button
                onClick={() => {
                  setConsoleLoggerOpen(true);
                  closeMe();
                }}
                className={`${tw.popupBtn} border-t border-gray-400`}
              >
                Show log
              </button>
            )}
            <button
              onClick={() => {
                copyScenarioLinkToClipboard();
                closeMe();
              }}
              className={`${tw.popupBtn} border-t border-gray-400`}
            >
              Copy scenario link
            </button>

            {hasDeveloperAccess && (
              <button
                onClick={() => {
                  getJson();
                  closeMe();
                }}
                className={`${tw.popupBtn} border-t border-gray-400`}
              >
                Get json
              </button>
            )}
            <button
              onClick={() => {
                copyRefToClipboard();
                closeMe();
              }}
              className={`${tw.popupBtn} border-t border-gray-400`}
            >
              Copy reference
            </button>
            {scenario.report_download && (
              <button
                onClick={() => {
                  toggleReportPublished();
                  closeMe();
                }}
                className={`${tw.popupBtn} border-t border-gray-400`}
              >
                {scenario.reportPublished ? "Un-publish report" : "Publish report"}
              </button>
            )}

            <button
              onClick={() => {
                setPlaygroundOpen(true);
              }}
              className={`${tw.popupBtn} border-t border-gray-400`}
            >
              Open expansion
            </button>

            {!hasScenarioEditAccess ? (
              <button
                className={`${tw.popupBtn} opacity-50 text-red-700 border-t border-gray-400`}
                onClick={() => {
                  Toast("You do not have permission to delete this scenario", {
                    icon: "warning",
                  });
                }}
              >
                Delete scenario
              </button>
            ) : (
              DeleteOption()
            )}
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  const DeleteOption = () => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete scenario?</div>
              <div className="italic mb-2">
                This is a destructive event that can't be undone.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`${tw.smallBtn} border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={() => {
                      if (!loading) {
                        deleteScenario();
                        closeDeletePopup();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`${tw.smallBtn} w-full`}
                    onClick={() => {
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`${tw.popupBtn} text-red-500 border-t border-gray-400`}>
          Delete scenario
        </button>
      </Popup>
    );
  };

  const PullOption = () => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Pull new System Model?</div>
              <div className="italic mb-2">
                This will merge and overwrite local version of the system model.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`${
                      tw.smallBtn
                    } border-green-400 bg-green-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={() => {
                      if (!loading) {
                        pullSimulationModel();
                        closeDeletePopup();
                      }
                    }}
                  >
                    Pull
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`${tw.smallBtn} w-full`}
                    onClick={() => {
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button
          className={`${tw.popupBtn} border-b border-gray-400 flex items-center justify-center`}
        >
          <PullIcon className="h-4 w-4 mr-2" /> <span>Pull system model</span>
        </button>
      </Popup>
    );
  };

  const renderSimulationOptions = () => {
    return (
      <>
        {!editorOpen && (
          <>
            {commentExists && (
              <div className="mr-2">
                <CommentIcon />
              </div>
            )}
            {globalUpdated && (
              <HoverTooltip
                className="mr-2"
                rightAlign
                mt={-30}
                mx={-60}
                text="Global simulation model updated since scenario created"
              >
                <GlobalUpdatedIcon />
              </HoverTooltip>
            )}
            {localUpdated && (
              <HoverTooltip
                className="mr-2"
                rightAlign
                mt={-30}
                mx={-60}
                text="Local simulation model changed"
              >
                <LocalUpdatedIcon />
              </HoverTooltip>
            )}
            {scenario.reportPublished && (
              <HoverTooltip
                className="mr-2"
                rightAlign
                mt={-30}
                mx={-60}
                text="Report is published"
              >
                <EyeIcon />
              </HoverTooltip>
            )}
            {renderMoreOptionsBtn()}
          </>
        )}

        {!selectedScenarioChanged && (
          <OpenCloseButton
            open={editorOpen}
            onClick={() => {
              if (!editorOpen) {
                dispatch({ type: "SELECT_SCENARIO", payload: scenario });
                if (checkScenarioIsRunning(scenario.status))
                  Toast("Editing a scenario that is currently processing", {
                    icon: "warning",
                    time: 5000,
                  });
              } else if (editorOpen) {
                dispatch({ type: "SELECT_SCENARIO", payload: null });
              }
            }}
          />
        )}
      </>
    );
  };
  const hasDescription = !!scenario.description && scenario.description.length > 0;

  return (
    <CommentableComponent
      commentTaget={{
        scenarioID: scenario.id,
        scenarioName: scenario.scenarioName,
        groupID: scenario.groupID,
        groupName: groupName,
        projectID: projectID || undefined,
        projectName: projectName || undefined,
      }}
      className={`bg-white relative my-6 border rounded text-gray-700 border-gray-300
        transition-highlighted-card
        ${highlighted ? "highlighted-card-active" : "highlighted-card-inactive "}
      `}
    >
      <div
        ref={scenarioCardRef}
        className={`flex flex-col relative ${hasDescription ? "pt-16" : "pt-12"}`}
      >
        <div
          className={`absolute top-0 left-0 bg-white rounded-t w-full px-4 py-2 border-b border-gray-200`}
          style={{
            marginTop: editorOpen ? topOffset : 0,
            opacity: topBarOpacity,
            transition: "margin, opacity 200ms",
            zIndex: 15,
          }}
        >
          <div className="flex items-center">
            <div className="font-medium flex-grow">{scenario.scenarioName}</div>
            {renderSimulationOptions()}
          </div>
          {hasDescription && (
            <div className="italic text-xs leading-tight">{scenario.description}</div>
          )}
        </div>
        {loading && <LoadingOverlay className="z-20" />}

        {!editorOpen && <ScenarioStages outputData={outputData} scenario={scenario} />}
        {editorOpen && (
          <ScenarioBuilder
            scenario={scenario}
            topBarMargin={topOffset}
            projectID={projectID || undefined}
          />
        )}
        {consoleLoggerOpen && (
          <Modal zIndex={30} onClose={() => setConsoleLoggerOpen(false)}>
            <ConsoleLogger
              headline={scenario.scenarioName}
              traceback={scenario.traceback}
              traceback_report={scenario.traceback_report}
              logs={scenario.logs}
            />
          </Modal>
        )}
        {playgroundOpen && projectID && (
          <Modal className="px-16" zIndex={30} onClose={() => setPlaygroundOpen(false)}>
            <div className="bg-white z-50 shadow-lg rounded w-full h-screen">
              <Extension scenario={scenario} projectID={projectID} />
            </div>
          </Modal>
        )}
      </div>
    </CommentableComponent>
  );
};

export default ScenarioCard;

const tw = {
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
  popupBtn: "py-2 w-full font-medium text-gray-700 focus:outline-none hover:bg-gray-200",
};
