import React, { useState, useEffect, useMemo, useContext } from "react";
import EMLogo from "resources/EMlogo.svg";
import { useFirebase, useFBManager } from "api/useFirebase";
import Dropdown from "components/basic/Dropdown";
import { store, useGlobalState } from "store";

type Organisation = { id: string; name: string; google_project: string };

const Login: React.FC<{}> = () => {
  const fb = useFirebase();
  const { state, dispatch } = useContext(store);
  const { organisationID } = state;
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState<string | null>(null);
  const { organisations, loading: organisationsLoading } = useOrganisations();
  const organisationOptions = useMemo(
    () => organisations.map((org) => ({ display: org.name, id: org.id, val: org })),
    [organisations]
  );

  const authenticate = () => {
    if (!fb) {
      setLoading(false);
      seterror("Connection error");
      return;
    }

    setLoading(true);
    fb.auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        seterror(error.message);
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <img className="mx-auto h-12 w-auto" src={EMLogo} alt="Logo" />

          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
            Or{" "}
            <button className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
              create an account
            </button>
          </p>
        </div>
        <div className="flex text-sm mt-8 items-center">
          <div className="font-bold mr-2 text-gray-700">Organisation:</div>
          <Dropdown
            className="flex-grow bg-white"
            selectedID={organisationID || undefined}
            options={organisationOptions}
            placeholder="Select organisation"
            onSelect={(option) => {
              dispatch({
                type: "SET_ORGANISATION",
                payload: {
                  id: option.id,
                  name: option.display,
                  google_project: option.val.google_project,
                },
              });
              localStorage.setItem("organisationID", option.id);
              localStorage.setItem("organisationName", option.display);
              localStorage.setItem("googleProject", option.val.google_project);
            }}
            loading={organisationsLoading}
          ></Dropdown>
        </div>
        <div className="mt-2">
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <div>
              <input
                aria-label="Email address"
                type="email"
                required
                value={email}
                onChange={(e) => setemail(e.target.value)}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Email address"
              />
            </div>
            <div className="-mt-px">
              <input
                type="password"
                required
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && authenticate()}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="mt-6 flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                className="h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
              />
              <label className="ml-2 block text-sm leading-5 text-gray-900">Remember me</label>
            </div>

            {/* <div className="text-sm leading-5">
              <button className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                Forgot your password?
              </button>
            </div> */}
          </div>

          <div className="mt-6">
            <button
              onClick={() => authenticate()}
              name="loginBtn"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
            >
              <span className="absolute left-0 inset-y pl-3">
                <svg
                  className="h-5 w-5 text-blue-500 group-hover:text-blue-400 transition ease-in-out duration-150"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign in {loading && <span>...</span>}
            </button>
          </div>
          {error && <div className="text-xs italic">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default Login;

const useOrganisations = () => {
  const fbManager = useFBManager();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const { inLocalDevMode } = useGlobalState();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (fbManager) {
      setLoading(true);
      return fbManager
        .firestore()
        .collection("organisations")
        .onSnapshot((snap) => {
          const orgs: Organisation[] = [];
          snap.docs.forEach((doc) => {
            const data = doc.data();
            orgs.push({ id: doc.id, name: data.name, google_project: data.google_project });
          });
          if (inLocalDevMode)
            orgs.push({ id: "local", name: "Local emulators", google_project: "" });
          setOrganisations(orgs);
          setLoading(false);
        });
    }
  }, [inLocalDevMode, fbManager]);

  return { organisations, loading };
};
