import {  useFirebase } from "api/useFirebase";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import Modal from "components/basic/Modal";
import { Popup } from "components/basic/Popup";
import Toast from "components/basic/Toast";
import gtw from "gtw";
import { Invitation } from "model/datatypes";
import React, { useState } from "react";
import { copyToClipboard } from "utils/jsUtils/CopyToClipBoard";

const InvitationRow: React.FC<{ invitation: Invitation }> = ({ invitation }) => {
  const fb = useFirebase();
  const [deleteOption, setDeleteOption] = useState(false);

  const removeInvitation = () => invitation && fb.firestore().collection("invitations").doc(invitation.id).delete();

  const copyLinkToClipboard = () => {
    const link = `${window.location.protocol}//${window.location.host}/${invitation.invitationLink}`;
    if(link){
      copyToClipboard(link);
      Toast("Invitation link copied to clipboard", { icon: "success" })
    } else {
      Toast("There was an error while copying the link to the clipboard", {icon: "error"});
    }
  }

  return (
    <div className="flex items-center  justify-evenly p-2 text-xs text-gray-700 ">
      <div className="w-1/5">{invitation.created.format("DD/MM HH:mm")}</div>
      <div className="w-2/5">{invitation.mail}</div>
      <div className="w-1/5">{invitation.userRole.charAt(0).toUpperCase() + invitation.userRole.slice(1)}</div>
      <div className="w-1/5 flex justify-end">
        <Popup
        useHover
        mt={15}
        pos={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button className={`${gtw.popupBtn}`}
              onClick={() => {
                copyLinkToClipboard();
                closeMe()
              }}
            >
              Copy invitation link to clipboard
            </button>
            <button
              className={`${gtw.popupBtn} text-red-400`}
              onClick={() => {
                setDeleteOption(true);
                closeMe();
              }}
            >
              Delete invitation
            </button>
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
      </div>
      {deleteOption && (
        <Modal onClose={() => setDeleteOption(false)}>
          <div className={`${tw.card} z-50 p-6`}>
            <div className="text-xl text-center mb-2">
              Cancel invitation to {invitation.mail}
            </div>
            <div className="text-center">This action is permanent and cannot be undone</div>
            <div className="mt-4 flex justify-center">
              <button
                onClick={removeInvitation}
                className="bg-red-400 p-2 border border-red-400 text-white font-bold rounded px-20 mx-1"
              >
                Delete
              </button>
              <button
                onClick={() => setDeleteOption(false)}
                className="bg-transparent border border-gray-300 p-2 rounded px-20 mx-1"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default InvitationRow;

const tw = {
  headline: "font-bold text-gray-700 text-xl",
  card: "border border-gray-300 rounded bg-white shadow",
  label: "font-bold text-xs",
  dropdown: "w-40",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
};
