import {
  ComponentType,
  Component,
  ComponentParameter,
  ComponentParamType,
  Container,
  DiscoveredComponent,
  Subcomponent,
  ParameterTable,
  InputVar,
} from "model/datatypes";
import getUUID from "./jsUtils/getUUID";

//used when going over components to instantiate..
//flat list of all components and their types:
type CompListItem = {
  comp: Component;
  prevComp?: Component;
  compType: ComponentType;
  subsInstantiated: boolean;
};

export const instantiateAllComponents = (
  componentTypes: ComponentType[],
  containers: Container[]
) => {
  let mainComponents = componentTypes
    .filter((ct) => ct.instantiationRules === undefined || ct.instantiationRules.isMain)
    .map((ct) => {
      return InstantiateComponent(ct, true);
    });

  //add containers
  containers
    .filter((ct) => ct.instantiationRules.isMain)
    .forEach((container) => {
      let compType = componentTypes.find((ct) => ct.id === container.defaultComponentType);

      //if no default try to select the first one:
      if (!compType)
        compType = componentTypes.find((ct) => ct.id === container.componentTypeIDs[0]);

      if (compType) {
        mainComponents.push(InstantiateComponent(compType, true, container.id));
      }
    });

  const allComponents = InstantiateAllSubComponents(mainComponents, componentTypes);
  const componentsWithSiblingsNames = updateComponentsNames(allComponents);

  return componentsWithSiblingsNames;
};

export const InstantiateComponent = (
  compType: ComponentType,
  isMainComp: boolean,
  containerID?: string,
  defaultEnabled?: boolean
) => {
  const { parameters, subCompRules, instantiationRules, workerType, ...c } = compType;
  const disabled =
    instantiationRules?.allowDisabling &&
    !instantiationRules?.defaultEnabled &&
    !defaultEnabled;

  const component: Component = {
    ...c,
    disabled,
    parameters: parameters.map((p) => ParamTypeToInstance(p)),
    uuid: containerID ? `comp_${containerID}` : getUUID(),
    isMainComponent: isMainComp,
    ...(workerType && { worker: { type: workerType } }),
    ...(containerID && { containerID }),
  };
  return component;
};

export const ParamTypeToInstance = (paramType: ComponentParamType) => {
  const { fileQuery, ...p } = paramType;
  const param: ComponentParameter = p;
  return param;
};

export const InstantiateAllSubComponents = (
  mainInstances: Component[],
  componentTypes: ComponentType[],
  prevComps?: Component[]
) => {
  //Start intantiation for subcomponents
  let compList: CompListItem[] = mainInstances.map((comp) => {
    const prevComp = prevComps?.find(
      (pc) => pc.isMainComponent && pc.id === comp.id && pc.containerID === comp.containerID
    );
    const compType = componentTypes.find((ct) => ct.id === comp.id) as ComponentType;
    return {
      comp,
      compType,
      prevComp,
      subsInstantiated: false,
    };
  });

  //instantiate comps and subcomps:
  let level = 0; //max 3 levels of subComponents to avoid infinite loops..
  //while some component remains to have checked to subCs:
  while (compList.some((cli) => !cli.subsInstantiated) && level < 3) {
    const newItems: CompListItem[] = [];
    const instantiatedList: CompListItem[] = [];

    compList.forEach((cli) => {
      if (
        cli.subsInstantiated ||
        !cli.compType.subCompRules ||
        cli.compType.subCompRules.length === 0
      ) {
        instantiatedList.push({ ...cli, subsInstantiated: true }); //no instantiation required
        return;
      }

      const newSubComps: CompListItem[] = [];

      cli.compType.subCompRules.forEach((rule) => {
        //find sub component type and instantiate component:
        const subCType = componentTypes.find((ct) => ct.id === rule.id);

        //if previous component had subs components, instantiate from these:
        const prevSubs = cli.prevComp?.subcomponents?.filter((sc) => sc.id === rule.id);

        if (prevSubs && prevSubs?.length > 0 && subCType) {
          prevSubs.forEach((prevSub) => {
            if (
              rule.max &&
              newSubComps.filter((scli) => scli.comp.id === prevSub.id).length >= rule.max
            )
              return; //Not allowed to add more..

            const prevSubComp = prevComps?.find((pc) => pc.uuid === prevSub.uuid);

            let newSubCompInstance = InstantiateComponent(subCType, false);

            if (prevSubComp)
              newSubCompInstance = mergeComponenets(newSubCompInstance, prevSubComp);
            newSubComps.push({
              compType: subCType,
              comp: newSubCompInstance,
              subsInstantiated: false,
              prevComp: prevSubComp,
            });
          });
        } else if (subCType && rule.default > 0)
          //else default rules:
          for (let i = 0; i < rule.default; i++) {
            let newSubCompInstance = InstantiateComponent(subCType, false);

            newSubComps.push({
              compType: subCType,
              comp: newSubCompInstance,
              subsInstantiated: false,
            });
          }
      });

      //add sub compomponents to list of all components:
      newItems.push(...newSubComps);

      //add subcomponents reference to parent components.....
      cli.comp.subcomponents = newSubComps.map((sc) => compToSubComp(sc.comp));
      return instantiatedList.push({ ...cli, subsInstantiated: true });
    });

    compList = [...instantiatedList, ...newItems];
    level++;
  }

  return compList.map((listItem) => listItem.comp);
};

//////////////////////////////////////////////
////functions for instantiation with merge////
//////////////////////////////////////////////
export const instantiateAllComponentsFromPrev = (
  componentTypes: ComponentType[],
  containers: Container[],
  prevComps: Component[]
) => {
  //Create main instances for comptypes
  const mainInstances = componentTypes
    .filter((ct) => ct.instantiationRules === undefined || ct.instantiationRules.isMain)
    .map((ct) => InstantiateComponent(ct, true))
    .map((newComp) => {
      //merge components with prev:
      const existingComp = prevComps.find((c) => c.name === newComp.name);
      if (!existingComp) return newComp;
      return mergeComponenets(newComp, existingComp);
    });

  //Add main instances for containers
  containers
    .filter((ct) => ct.instantiationRules.isMain)
    .forEach((container) => {
      //check if there is a previous component for the container:
      const prevCompInstance = prevComps.find(
        (comp) => comp.isMainComponent && comp.containerID === container.id
      );

      let newCompType = componentTypes.find(
        (ct) =>
          (prevCompInstance && ct.id === prevCompInstance?.id) ||
          (!prevCompInstance && container.defaultComponentType === ct.id)
      );

      //if no component found just take the first one:
      if (!newCompType)
        newCompType = componentTypes.find((ct) => ct.id === container.componentTypeIDs[0]);

      if (newCompType) {
        let newComInstance = InstantiateComponent(newCompType, true, container.id);
        if (prevCompInstance)
          newComInstance = mergeComponenets(newComInstance, prevCompInstance);
        mainInstances.push(newComInstance);
      }
    });

  const allComponents = InstantiateAllSubComponents(mainInstances, componentTypes, prevComps);

  const componentsWithSiblingsNames: Component[] = updateComponentsNames(allComponents);
  console.log({ componentsWithSiblingsNames });
  return componentsWithSiblingsNames;
};

const mergeComponenets = (newComp: Component, oldComp: Component) => {
  let mergedComp = newComp;
  //merge parameters:
  mergedComp.parameters = mergedComp.parameters.map((param) => {
    if (param.displayMode === "hidden" || param.type === "reference") return param; //Always overwrite hidden variables

    const existingParam = oldComp.parameters.find(
      (p) => p.id === param.id && p.type === param.type
    );
    if (!existingParam) return param;

    if (param.type === "table") {
      if (!existingParam.value || !param.value) return param;
      // merge old table entries into new:
      const prevTable = existingParam.value as ParameterTable;
      const prevTableRowLength = Object.entries(prevTable)[0][1].length; //length of first entry
      if (prevTableRowLength === 0) return param;
      const newTableEntries = Object.entries(param.value as ParameterTable);
      const mergedTableEntries = newTableEntries.map((entry) => {
        const rowLength = entry[1].length;
        const prevRows = prevTable[entry[0]];

        // console.log({ prevRows, rowLength, prevTableRowLength });
        //copy the old entry if exists
        if (prevRows) entry[1] = prevRows;
        else
          entry[1] =
            rowLength >= prevTableRowLength
              ? entry[1].slice(0, prevTableRowLength)
              : [...entry[1], ...new Array(prevTableRowLength - rowLength).fill(0)]; //fit to size of prev table
        return entry;
      });
      return { ...param, value: Object.fromEntries(mergedTableEntries) };
    }

    return { ...param, value: existingParam.value };
  });

  //merge variables:
  mergedComp.inputVariables = mergedComp.inputVariables.map((variable: InputVar) => {
    const existingVar = oldComp.inputVariables.find((v) => v.id === variable.id);
    if (!existingVar) return variable;
    const updatedVar: InputVar = {
      ...variable,
      offset: existingVar.offset,
      scaling: existingVar.scaling,
      sourceType: existingVar.sourceType,
      value: existingVar.value,
    };
    if (existingVar.source) updatedVar.source = existingVar.source;
    if (existingVar.CMTag) updatedVar.CMTag = existingVar.CMTag;
    return updatedVar;
  });

  mergedComp.disabled = oldComp.disabled;
  mergedComp.uuid = oldComp.uuid;

  return mergedComp;
};

export const updateComponentsNames = (allComponents: Component[]) => {
  const componentsWithSiblingsNames: Component[] = allComponents.map((comp, i) => {
    const olderSiblings = [...allComponents.slice(0, i)].filter((c) => c.id === comp.id);
    if (olderSiblings.length > 0) {
      return { ...comp, name: `${comp.type}_${olderSiblings.length + 1}` };
    }
    return comp;
  });
  return componentsWithSiblingsNames;
};

export const compTypeFromDiscovered = (
  discoveredComp: DiscoveredComponent,
  modelID: string,
  order: number
) => {
  const newComponentType: ComponentType = {
    id: getUUID(),
    order,
    modelID,
    instantiationRules: { isMain: true, allowDisabling: false },
    ...discoveredComp,
    inputVariables: discoveredComp.inputVariables.map((iv) => {
      return { ...iv, uuid: getUUID() };
    }), //comp.input_variables,
    parameters: discoveredComp.parameters.map((p) => {
      const type = p.type === "int" || p.type === "float" ? "number" : p.type;
      const compParam: ComponentParamType = {
        ...p,
        uuid: getUUID(),
        type,
      };
      return compParam;
    }),
  };
  return newComponentType;
};

export const compToSubComp = (comp: Component) => {
  const subComponent: Subcomponent = {
    id: comp.id,
    uuid: comp.uuid,
  };
  return subComponent;
};
