import React, { useContext, useEffect, useMemo, useState } from "react";
import { useFirestore, useGroups } from "api/useFirebase";
import NewGroup from "components/simulations/group/NewGroup";
import GroupCard from "./simulations/GroupCard";
import NewScenario from "./simulations/newSimulation/NewScenario";
import gtw from "gtw";
import AddIcon from "./basic/icons/AddIcon";
import ProjectInfo from "./projects/ProjectInfo";
import { useParams } from "react-router-dom";
import { store } from "store";
import RepositionList, { RepositionListItem } from "./basic/RepositionList";
import { Group } from "model/datatypes";

interface Props {}

const SimulationPage: React.FC<Props> = () => {
  const { state } = useContext(store);
  const { projectID } = state;
  const [repositionID, setRepositionID] = useState<string | null>(null);

  const groups = useGroups(projectID || undefined);

  const groupsList = useMemo(() => {
    const sortedGroups = groups?.sort((a, b) => {
      if (!a.order && !b.order) return a.created.diff(b.created);
      else if (!a.order) return -1;
      else if (!b.order) return 1;
      return a.order - b.order;
    });
    const groupsList: RepositionListItem[] = [];
    //id of group being repositioned currently:
    sortedGroups?.forEach((group) => {
      groupsList.push({
        id: group.id,
        element: (
          <GroupCard
            key={group.id}
            onStartReposition={() => {
              setRepositionID(group.id);
            }}
            group={group}
          />
        ),
        val: group,
      });
    });
    return groupsList;
  }, [groups]);

  const [loading, setLoading] = useState(false);
  const fs = useFirestore();
  const onRepositionGroups = (groups: Group[]) => {
    if (!projectID || loading) return;
    setLoading(true);
    //check if same position as previous
    const batch = fs.batch();
    const groupsCol = fs.collection("Projects").doc(projectID).collection("Groups");
    groups.forEach((group, i) => {
      const prevGroup = groups.find((g) => g.id === group.id);
      if (!group.order || prevGroup?.order !== group.order) {
        batch.update(groupsCol.doc(group.id), { order: i });
      }
    });
    batch
      .commit()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error saving group order");
        console.log(e);
        setLoading(false);
      });
  };

  const [addingGroup, setaddingGroup] = useState(false);
  const [addingScenario, setAddingScenario] = useState(false);

  //if user is accessing page through direct link to group and / or simulation
  useSimulationLinkLoader();

  return (
    <div className={`${gtw.innerContainer} relative`}>
      <ProjectInfo />

      <RepositionList
        list={groupsList}
        onReposition={(updatedList) => {
          onRepositionGroups(updatedList.map((li) => li.val));
        }}
        onDragEnd={() => setRepositionID(null)}
        enableDragOverlay
        dragableID={repositionID}
      />

      <button
        onClick={() => setaddingGroup(true)}
        className="mb-2 w-48 flex items-center px-3 py-2 bg-white rounded shadow focus:outline-none text-gray-700 hover:text-gray-800"
      >
        <AddIcon className="h-6 w-6 mr-3" />
        <span className="text-xs font-bold">New group</span>
      </button>
      <button
        onClick={() => {
          setAddingScenario(true);
        }}
        className=" w-48 flex items-center px-3 py-2 bg-white rounded shadow focus:outline-none text-gray-700 hover:text-gray-800"
      >
        <AddIcon className="h-6 w-6 mr-3" />
        <span className="text-xs font-bold">New scenario</span>
      </button>

      {addingGroup && (
        <NewGroup
          onFinish={() => {
            setaddingGroup(false);
          }}
        />
      )}
      {addingScenario && projectID && (
        <NewScenario projectID={projectID} onFinish={() => setAddingScenario(false)} />
      )}
    </div>
  );
};

export default SimulationPage;

const useSimulationLinkLoader = () => {
  const { dispatch } = useContext(store);

  const { projectID, projectName, groupID, scenarioID } = useParams<{
    projectName: string | undefined;
    projectID: string | undefined;
    groupID: string | undefined;
    scenarioID: string | undefined;
  }>();

  useEffect(() => {
    if (projectName && projectID) {
      dispatch({ type: "SET_CURRENT_PROJECT", payload: { projectID, projectName } });
    }
    if (groupID) dispatch({ type: "SELECT_GROUP", payload: groupID });
    if (scenarioID) dispatch({ type: "SET_LINKED_SCENARIO", payload: scenarioID });
  }, [projectID, projectName, groupID, scenarioID, dispatch]);
};
