import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import OutputDataIcon from "components/basic/icons/OutputDataIcon";

interface Props {
  simulationGenerating: boolean;
  openDataOutput: () => void;
}

const DataOutputStage = ({ simulationGenerating, openDataOutput }: Props) => {
  let statusColor = simulationGenerating ? "#9AE6B4" : "#48BB78";
  return (
    <div className="relative">
      <div className="flex items-center relative">
        <div
          className="h-8 w-8 relative cursor-pointer"
          style={{ color: statusColor }}
          onClick={() => {
            openDataOutput();
          }}
        >
          <CircularProgressbarWithChildren
            value={100}
            strokeWidth={8}
            styles={{ path: { stroke: statusColor } }}
          >
            <OutputDataIcon />
          </CircularProgressbarWithChildren>
        </div>
        <hr
          className={`w-24 border-b ${simulationGenerating ? "border-dashed" : ""}`}
          style={{ borderColor: simulationGenerating ? "#CBD5E0" : statusColor }}
        />
      </div>
      <div className="text-xs font-bold">Data output</div>
      <div className="text-xs italic">
        {simulationGenerating ? "Output being generated" : "Output ready"}
      </div>
    </div>
  );
};

export default DataOutputStage;
