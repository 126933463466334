import React, { useState } from "react";
import { useFirebase, useComponentLibrary } from "api/useFirebase";
import Modal from "components/basic/Modal";
import { ComponentType, Container } from "model/datatypes";
import * as Sentry from "@sentry/browser";

interface Props {
  simModelID: string;
  order: number;
  onFinish: () => void;
  onAdd: (newComponent: ComponentType) => Promise<any>;
  startComp?: ComponentType;
}

const AddComponentType: React.FC<Props> = ({
  simModelID,
  onFinish,
  onAdd,
  startComp,
  order,
}) => {
  const [newComponent, setNewComponent] = useState<ComponentType | undefined>(
    startComp && {
      ...startComp,
      displayName: `${startComp.displayName} duplicate`,
      name: "",
      type: "",
    }
  );
  const [addType, setAddType] = useState<
    null | "duplicate" | "blank" | "library" | "container"
  >(startComp ? "duplicate" : null);
  const [libraryOpen, setLibraryOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onAddComponent = () => {
    if (newComponent) {
      setLoading(true);
      let compTypeToSave: ComponentType = {
        ...newComponent,
        order,
      };
      //@ts-ignore
      delete compTypeToSave.id;
      onAdd(compTypeToSave)
        .then(() => {
          setLoading(false);
          setNewComponent(undefined);
          onFinish();
        })
        .catch((e) => {
          setLoading(false);
          Sentry.captureException(e);
          console.log(e);
        });
    }
  };

  const renderAddNormalComponent = () => {
    if (newComponent)
      return (
        <div className="w-64 md:w-128">
          <div className="font-bold">Add new component</div>
          <div className="italic text-xs">{`From ${addType}`}</div>
          <div className="text-xs mt-4">Display Name</div>
          <input
            type="text"
            className={`input-box w-full`}
            onChange={(e) => {
              setNewComponent({ ...newComponent, displayName: e.target.value });
            }}
            value={newComponent.displayName}
          />
          <div className="text-xs mt-4">Name/ID</div>
          <input
            type="text"
            className={`input-box w-full`}
            onChange={(e) => {
              setNewComponent({ ...newComponent, name: e.target.value, type: e.target.value });
            }}
            value={newComponent.name}
          />

          <button
            className={`mt-4 w-full py-1 border border-gray-300 rounded shadow focus:outline-none text-sm font-medium ${
              loading ? "opacity-50 hover:cursor-wait" : "hover:font-bold"
            }`}
            onClick={() => {
              !loading && onAddComponent();
            }}
          >
            Add to model
          </button>
        </div>
      );
  };

  const renderSelectComponentType = () => {
    return (
      <div>
        <div className="font-bold mb-4">Add new</div>
        <div className="">
          <button
            className="w-full border border-gray-200 rounded h-16 shadow-md"
            onClick={() => {
              setNewComponent(blankComponentType);
              setAddType("blank");
            }}
          >
            Blank component
          </button>
          <button
            className="w-full border border-gray-200 rounded h-16 mt-2 shadow-md"
            onClick={() => {
              setAddType("container");
            }}
          >
            Container
          </button>
          <button
            className="w-full border border-gray-200 rounded h-16 mt-2 shadow-md"
            onClick={() => {
              setLibraryOpen(true);
            }}
          >
            Library component
          </button>
        </div>
      </div>
    );
  };

  const renderAddComponentLogic = () => {
    if (!libraryOpen && !addType) return renderSelectComponentType();
    else if (libraryOpen)
      return (
        <LibraryComponentSelecter
          onSelectComponent={(libComp) => {
            setNewComponent(libComp);
            setLibraryOpen(false);
            setAddType("library");
          }}
          onCancel={() => setLibraryOpen(false)}
        />
      );
    else if (addType === "container")
      return <AddNewContainer simModelID={simModelID} onFinish={onFinish} order={order} />;
    else return renderAddNormalComponent();
  };

  return (
    <Modal onClose={onFinish}>
      <div className="modal-content z-50 ">{renderAddComponentLogic()}</div>
    </Modal>
  );
};

const blankComponentType: ComponentType = {
  id: "",
  displayName: "",
  name: "",
  type: "",
  parameters: [],
  inputVariables: [],
  item_class: "",
  order: 0,
  instantiationRules: { allowDisabling: false, isMain: true },
};

export default AddComponentType;

interface LibraryComponentSelecterProps {
  onSelectComponent: (libComp: ComponentType) => void;
  onCancel: () => void;
}

export const LibraryComponentSelecter = ({
  onSelectComponent,
  onCancel,
}: LibraryComponentSelecterProps) => {
  const { componentLib } = useComponentLibrary();
  const [selectedComp, setSelectedComp] = useState<ComponentType | null>(null);

  return (
    <Modal onClose={onCancel}>
      <div className="bg-white z-50 w-1/2 px-4 py-4 border border-gray-300 shadow-lg rounded">
        <div>Component library</div>
        <div className="flex flex-wrap">
          {componentLib.map((libComp) => {
            const isSelected = libComp.id === selectedComp?.id;
            return (
              <div
                className={`mr-4 mb-4 shadow-md rounded cursor-pointer border hover:opacity-100
              ${isSelected ? "border-green-400" : "border-gray-300"}
              ${!isSelected && !!selectedComp ? "opacity-75" : ""}
            `}
                onClick={() => setSelectedComp(isSelected ? null : libComp)}
              >
                <div
                  className={`border-2 px-2 py-2 ${
                    isSelected ? "border-green-400" : "border-white"
                  }`}
                >
                  <div className="font-medium">{libComp.displayName}</div>
                  <div className="text-xs mt-3">
                    {libComp.parameters ? libComp.parameters.length : 0} parameters
                  </div>
                  <div className="text-xs">
                    {libComp.inputVariables.length} input variables
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <button
            className={`${tw.smallBtn} 
            ${selectedComp ? "" : "opacity-50"} mr-2`}
            onClick={() => selectedComp && onSelectComponent(selectedComp)}
          >
            Ok
          </button>
          <button className={tw.smallBtn} onClick={() => onCancel()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

const AddNewContainer: React.FC<{
  simModelID: string;
  onFinish: () => void;
  order: number;
}> = ({ simModelID, onFinish, order }) => {
  const [newContainer, setNewContainer] = useState<Container>(blankContainer);
  const [loading, setLoading] = useState(false);
  const fb = useFirebase();

  const onAddContainer = () => {
    setLoading(true);
    //add container:
    let containerForSaving: Container = {
      ...newContainer,
      order,
    };
    //@ts-ignore
    delete containerForSaving.id;
    fb.firestore()
      .collection("SimulationModels")
      .doc(simModelID)
      .collection("Containers")
      .add(containerForSaving)
      .then(() => {
        setLoading(false);
        onFinish();
      })
      .catch((e) => {
        setLoading(false);
        Sentry.captureException(e);
        console.log(e);
      });
  };
  return (
    <>
      <div className="font-bold">Add new conntainer</div>
      <div className="text-xs mt-4">Display Name</div>
      <input
        type="text"
        className={tw.input}
        onChange={(e) => {
          setNewContainer({ ...newContainer, displayName: e.target.value });
        }}
        value={newContainer.displayName}
      />
      <div className="text-xs mt-4">Name/ID</div>
      <input
        type="text"
        className={tw.input}
        onChange={(e) => {
          setNewContainer({ ...newContainer, name: e.target.value });
        }}
        value={newContainer.name}
      />
      <button
        className={`mt-4 w-full py-1 border border-gray-300 rounded shadow focus:outline-none text-sm font-medium ${
          loading ? "opacity-50 hover:cursor-wait" : "hover:font-bold"
        }`}
        onClick={() => {
          !loading && onAddContainer();
        }}
      >
        Add to model
      </button>
    </>
  );
};

const blankContainer: Container = {
  displayName: "",
  id: "",
  name: "",
  order: 0,
  componentTypeIDs: [],
  instantiationRules: { allowDisabling: false, isMain: true },
};

const tw = {
  input: "px-2 py-1 focus:outline-none border rounded",
  smallBtn:
    "bg-white py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
};
