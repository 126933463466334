import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";
import { store } from "store";
import fbBetaConfig from "./FBBetaConfig";

const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

type FBConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
};

type Organisation = {
  appConfig: FBConfig;
  name: string;
};

export const FirebaseContext = createContext<{
  fbMain?: app.app.App;
  fbManager?: app.app.App;
  managerAnalytics?: app.analytics.Analytics;
  orgAnalytics?: app.analytics.Analytics;
}>({});

const FirebaseProvider: React.FC = ({ children }) => {
  const [fbManager, setfbManager] = useState<app.app.App>();
  const [managerAnalytics, setManagerAnalytics] = useState<app.analytics.Analytics>();
  useEffect(() => {
    try {
      const existing = app.app("manager");
      console.log("manager already set up...");
      setfbManager(existing);
    } catch (error) {
      console.log("set up new fb instance");
      const appManager = app.initializeApp(fbConfig, "manager");
      setfbManager(appManager);
    }
  }, []);

  useEffect(() => {
    if (fbManager) {
      const analytics = app.analytics(fbManager);
      setManagerAnalytics(analytics);
    }
  }, [fbManager]);

  const { state } = useContext(store);
  const { organisationID, inLocalDevMode } = state;

  const [appConfig, setAppConfig] = useState<FBConfig>();
  useEffect(() => {
    if (organisationID && fbManager) {
      // use beta project for local emulators
      if (organisationID === "local") setAppConfig(fbBetaConfig);
      else
        fbManager
          .firestore()
          .collection("organisations")
          .doc(organisationID)
          .get()
          .then((doc) => {
            const data = { ...doc.data() } as Organisation;
            setAppConfig(data.appConfig);
          });
    } else {
      setAppConfig(undefined);
    }
  }, [fbManager, organisationID]);

  const [fbMain, setFbMain] = useState<app.app.App>();
  const [orgAnalytics, setOrgAnalytics] = useState<app.analytics.Analytics>();

  const registerMainApp = useCallback(
    async (conf: FBConfig, useEmulators: boolean) => {
      if (fbMain?.name === conf.appId) return;
      if (fbMain && fbMain.name !== conf.appId) {
        await fbMain.delete();
      }

      let mainApp: app.app.App;
      try {
        mainApp = app.app(conf.appId);
        console.log("main app already set up...");
      } catch (error) {
        console.log("set up new main app");
        mainApp = app.initializeApp(conf, conf.appId);
        if (useEmulators) {
          mainApp.functions().useEmulator("localhost", 5001);
          mainApp.auth().useEmulator("http://localhost:9099");
          mainApp.firestore().useEmulator("localhost", 8080);
        }
      }

      setFbMain(mainApp);
      if (conf.measurementId) {
        const orgAnalytics = app.analytics(mainApp);
        console.log("Started organisation analytics");
        setOrgAnalytics(orgAnalytics);
      }
    },
    [fbMain]
  );

  useEffect(() => {
    if (appConfig && fbMain?.name !== appConfig.appId) {
      registerMainApp(appConfig, organisationID === "local");
    }
  }, [appConfig, registerMainApp, fbMain, organisationID]);

  useEffect(() => {
    if (inLocalDevMode) {
      const analyticsOrg = fbMain?.analytics();
      if (analyticsOrg) {
        analyticsOrg.setAnalyticsCollectionEnabled(false);
        console.log("disable analytics for ORG..");
      }

      const analyticsMan = fbManager?.analytics();
      if (analyticsMan) {
        analyticsMan.setAnalyticsCollectionEnabled(false);
        console.log("disable analytics for manager..");
      }
    }
  }, [inLocalDevMode, fbManager, fbMain]);

  return (
    <FirebaseContext.Provider value={{ fbMain, fbManager, managerAnalytics, orgAnalytics }}>
      {children}
    </FirebaseContext.Provider>
  );
};
export default FirebaseProvider;
