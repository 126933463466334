import React from "react";

const RadioButton: React.FC<{ active: boolean; onClick?: () => void }> = ({
  active,
  onClick,
}) => {
  return (
    <div className="flex cursor-pointer items-center">
      <div
        onClick={() => {
          if (onClick) onClick();
        }}
        className={`h-4 w-4 rounded-full border-2 border-gray-400 flex items-center justify-center`}
      >
        {active && <div className="h-2 w-2 rounded-full bg-gray-600"></div>}
      </div>
    </div>
  );
};

export default RadioButton;
