import app from "firebase/app";
import { useState, useEffect, useContext, useMemo } from "react";
import { User } from "model/datatypes";
import { useAnalyticsLogger, useFirebase, useProject } from "./useFirebase";
import { store } from "store";

const useAuth = () => {
  const fb = useFirebase();
  const logEvent = useAnalyticsLogger();
  const { dispatch } = useContext(store);

  const [fbUser, setFbUser] = useState<app.User | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fb) {
      setLoading(true);
      return fb.auth().onAuthStateChanged((user) => {
        if (user) {
          setFbUser(user);
        } else {
          setFbUser(null);
        }
        setLoading(false);
      });
    }
  }, [fb]);

  useEffect(() => {
    if (fbUser && fb) {
      const unsub = fb
        .firestore()
        .collection("users")
        .doc(fbUser.uid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const user = {
              fbUser,
              ...doc.data(),
            } as User;
            dispatch({ type: "SET_USER", payload: user });

            //@ts-ignore
            if (user.appDeveloper)
              dispatch({
                type: "SET_IN_DEV_MODE",
              });
            else logEvent("sign_in", { name: user.fullName, uid: user.fbUser.uid });
          }
        });
      return unsub;
    } else {
      dispatch({ type: "SET_USER", payload: null });
    }
  }, [fb, fbUser, dispatch, logEvent]);

  useEffect(() => {
    if (fbUser) {
      const teamCollection = fb.firestore().collection("teams");
      return teamCollection.where("users", "array-contains", fbUser.uid).onSnapshot((snap) => {
        const teamIDs: string[] = [];
        snap.docs.forEach((doc) => {
          const teamID = doc.id;
          teamIDs.push(teamID);
        });
        dispatch({ type: "SET_TEAMS", payload: teamIDs });
      });
    }
  }, [fbUser, fb, dispatch]);

  return { loading };
};

export default useAuth;

export const useHasEditAccess = (ownerId: string) => {
  const { state } = useContext(store);
  const { user } = state;

  const hasEditAccess = useMemo(
    () =>
      user?.userRole === "developer" ||
      user?.userRole === "owner" ||
      user?.fbUser.uid === ownerId,
    [ownerId, user]
  );
  return hasEditAccess;
};

export const useUserRole = () => {
  const { state } = useContext(store);
  const { user } = state;
  const project = useProject(state.projectID);

  const isProjectOwner = useMemo(() => project?.ownerId === user?.fbUser.uid, [project, user]);
  const hasOwnerAccess = useMemo(() => user?.userRole === "owner", [user]);
  const hasDeveloperAccess = useMemo(
    () => user?.userRole === "developer" || user?.userRole === "owner",
    [user]
  );

  const hasSimulatorAccess = useMemo(
    () =>
      user?.userRole === "developer" ||
      user?.userRole === "owner" ||
      user?.userRole === "simulator",
    [user]
  );

  const hasProjectEditAccess = useMemo(
    () => user?.userRole === "developer" || user?.userRole === "owner" || isProjectOwner,
    [user, isProjectOwner]
  );

  return {
    hasDeveloperAccess,
    hasSimulatorAccess,
    hasOwnerAccess,
    userRole: user?.userRole || null,
    hasProjectEditAccess,
    isProjectOwner,
  };
};

export const useUserIDToken = () => {
  const fb = useFirebase();
  const [idToken, setIdToken] = useState<string>();
  useEffect(() => {
    fb.auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        setIdToken(token);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fb]);
  return idToken;
};
