import React, { useState, useEffect } from "react";

import { Scenario } from "model/datatypes";
import moment, { Moment } from "moment";

interface Props {
  traceback: Scenario["traceback"];
  traceback_report: Scenario["traceback_report"];
  logs: Scenario["logs"];
  headline: string;
}

const ConsoleLogger: React.FC<Props> = ({ traceback, traceback_report, logs, headline }) => {
  const [errorMode, setErrorMode] = useState<
    "simulation_logs" | "simulation_errors" | "report"
  >(logs ? "simulation_logs" : traceback ? "simulation_errors" : "report");

  const [currentLog, setCurrentLog] = useState<{ message: string; timestamp: Moment }[]>([]);

  useEffect(() => {
    let errorLog: { message: string; timestamp: Moment }[] = [];
    if (errorMode === "simulation_logs" && !!logs)
      errorLog = logs.map((log) => ({
        message: log.message,
        timestamp: moment(log.timestamp, "YYYY-DD-MM HH:mm:ss"),
      }));
    if (errorMode === "simulation_errors" && traceback)
      errorLog = traceback.map((error) => ({
        message: error.error,
        timestamp: moment(error.timestamp, "YYYY-DD-MM HH:mm:ss"),
      }));
    if (errorMode === "report" && !!traceback_report)
      errorLog = traceback_report.map((log) => ({
        message: log.error,
        timestamp: moment(log.timestamp, "YYYY-DD-MM HH:mm:ss"),
      }));
    setCurrentLog(errorLog.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf()));
  }, [errorMode, logs, traceback, traceback_report]);

  return (
    <div className={`px-8 py-4 bg-white z-50 shadow-lg rounded w-4/5`}>
      <div className="font-bold">Error log</div>
      <div className="text-xs font-medium">{headline}</div>
      <div className="flex my-1">
        {!!logs && (
          <button
            className={`${tw.smallBtn} mr-4 ${
              errorMode === "simulation_logs" ? "bg-blue-500 border-blue-600 text-white" : ""
            } ${!traceback_report ? "opacity-50" : ""}`}
            onClick={() => setErrorMode("simulation_logs")}
          >
            Log
          </button>
        )}
        {!!traceback && (
          <button
            className={`${tw.smallBtn} mr-4 ${
              errorMode === "simulation_errors" ? "bg-blue-500 border-blue-600 text-white" : ""
            }`}
            onClick={() => setErrorMode("simulation_errors")}
          >
            Simulation errors
          </button>
        )}
        {!!traceback_report && (
          <button
            className={`${tw.smallBtn} ${
              errorMode === "report" ? "bg-blue-500 border-blue-600 text-white" : ""
            } ${!traceback_report ? "opacity-50" : ""}`}
            onClick={() => setErrorMode("report")}
          >
            Report log
          </button>
        )}
      </div>
      <div className="max-h-50vh text-xs overflow-y-auto font-mono">
        {currentLog.map((log) => {
          return (
            <div key={log.timestamp.valueOf() + log.message} className="flex my-2">
              <span className="pr-4">{log.timestamp.format("DD/MM YYYY HH:mm:ss")}</span>
              <span className="w-2/3">{log.message}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ConsoleLogger;

const tw = {
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
};
