import React, { useState, useEffect } from "react";
import { WeatherAverages, LocationSearchResponse, getWeatherMetadata } from "api/WeatherAPI";
import * as Sentry from "@sentry/browser";

interface Props {
  location?: LocationSearchResponse | null;
}

const AvgWeatherDisplay: React.FC<Props> = ({ location }) => {
  const [avgWeather, setavgWeather] = useState<null | WeatherAverages>(null);
  useEffect(() => {
    if (location) {
      getWeatherMetadata(location.name)
        .then((response) => {
          setavgWeather(response.averages);
        })
        .catch((error) => {
          Sentry.captureException(error);
          setavgWeather(null);
        });
    } else {
      setavgWeather(null);
    }
  }, [location]);
  const weatherKeys: (keyof WeatherAverages)[] = avgWeather
    ? (Object.keys(avgWeather) as (keyof WeatherAverages)[])
    : [];
  return (
    <>
      <label className="text-xs font-bold mt-6">Average Temperature</label>
      {avgWeather ? (
        <table className="mt-2">
          <thead>
            <tr className="border-b border-t border-gray-400">
              <td className={`${twStyles.td}  pl-0`}>Season</td>
              <td className={`${twStyles.td} `}>Day</td>
              <td className={`${twStyles.td} `}>Night</td>
            </tr>
          </thead>
          <tbody>
            {weatherKeys.map((key) => (
              <tr>
                <td className={`${twStyles.td} pl-0`}>{key}</td>
                <td className={twStyles.td}>{avgWeather[key].day_average.toFixed(1)}°C</td>
                <td className={twStyles.td}>{avgWeather[key].night_average.toFixed(1)}°C</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-xs">No average weather found</div>
      )}
    </>
  );
};

const twStyles = {
  td: "px-2 text-xs",
};

export default AvgWeatherDisplay;
