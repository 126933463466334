import React, { useMemo } from "react";
import { InputVar } from "model/datatypes";
import InputVariableEditable from "./InputVariableEditable";
import getUUID from "utils/jsUtils/getUUID";
import RepositionList from "components/basic/RepositionList";

interface Props {
  componentVariables: InputVar[];
  onUpdate: (updatedVar: InputVar) => void;
  onDelete: (input: InputVar) => void;
  onUpdateAll: (updatedVars: InputVar[]) => void;
}

const InputEditor: React.FC<Props> = ({
  componentVariables,
  onUpdate,
  onDelete,
  onUpdateAll,
}) => {
  const inputList = useMemo(() => {
    return componentVariables.map((v, i) => ({
      id: v.uuid,
      element: (
        <InputVariableEditable
          key={v.uuid}
          value={v}
          onUpdate={(newVal) => {
            onUpdate(newVal);
          }}
          onDeleteInput={() => {
            onDelete(v);
          }}
        />
      ),
      val: v,
    }));
  }, [componentVariables, onDelete, onUpdate]);

  return (
    <div className="flex flex-col pb-4">
      <div className="flex-none">
        <div className="font-bold text-sm">Input variables</div>
        <div className="flex w-full px-4 py-2 items-center text-sm">
          <div className="pr-4 w-1/8 text-xs font-medium">ID</div>
          <div className="pr-4 w-1/5 text-xs font-medium">Display name</div>
          <div className="pr-4 w-1/5 text-xs font-medium">Tooltip</div>
          <div className="pr-4 w-1/5 text-xs font-medium">Unit</div>
          <div className="pr-4 w-1/5 text-xs font-medium">Standard value</div>
        </div>
      </div>
      <div className="flex-grow">
        <RepositionList
          list={inputList}
          onReposition={(updatedList) => {
            const updatedVars = updatedList.map((li) => li.val) as InputVar[];
            onUpdateAll(updatedVars);
          }}
        />
        {componentVariables.length === 0 && (
          <div className="italic text-xs px-2">No variables added</div>
        )}
      </div>
      <div className="py-2 flex-none px-2">
        <button
          onClick={() => {
            const newCompVariable: InputVar = {
              uuid: getUUID(),
              id: "",
              display: "",
              sourceType: "dynamic",
              scaling: 1,
              offset: 0,
              unit: "",
              value: 0,
            };
            onUpdate(newCompVariable);
          }}
          className="w-16 bg-white shadow-md rounded border border-gray-200 focus:outline-none"
        >
          +
        </button>
      </div>
    </div>
  );
};

export default InputEditor;
