import React, { useState, useRef } from "react";
import { Scenario } from "model/datatypes";
import { useMaxHeightTransition } from "utils/hooks/useMaxHeightTransition";

interface Props {
  meta_data: Scenario["meta_data"];
  updateMetadata: (updated: Scenario["meta_data"]) => void;
}

const MetadataSetup = ({ meta_data, updateMetadata }: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { open, setOpen, style } = useMaxHeightTransition("0", "350px");
  const [error, setError] = useState<string | null>(null);
  const [jsonText, setJsonText] = useState(
    meta_data ? JSON.stringify(meta_data, undefined, 4) : ""
  );
  const parseJson = (text: string) => {
    if (text.length > 0) {
      try {
        const json = JSON.parse(text);
        setError(null);
        updateMetadata(json);
      } catch (error) {
        console.log("error parsing:");
        console.log({ text });
        setError("Error parsing json");
      }
    }
  };

  return (
    <div className="text-xs">
      <label
        onClick={() => setOpen(!open)}
        className={`${tw.label} flex items-center cursor-pointer
         ${!open && error ? "text-red-500" : ""}`}
      >
        <span className="mr-1">Metadata</span> <OpenCloseArrow open={open} />
      </label>
      {open && (
        <div style={{ ...style }} className="bg-gray-200 px-4 pt-2 pb-4 overflow-hidden">
          <div className="flex flex-col">
            <label className={`${tw.label} mb-2`}>Json input</label>
            <textarea
              ref={textAreaRef}
              className="h-64 bg-gray-200 focus:outline-none focus:bg-white"
              onBlur={() => parseJson(jsonText)}
              onKeyDown={(e) => {
                if (!textAreaRef.current) return;
                if (e.keyCode === 9) {
                  //tab...
                  e.preventDefault();
                  // const start = textAreaRef.current.selectionStart;
                  // const end = textAreaRef.current.selectionEnd;
                  // setJsonText(`${jsonText.substring(0, start)}   ${jsonText.substring(end)}`);
                }
                if (e.keyCode === 27) {
                  //escape
                  e.preventDefault();
                  textAreaRef.current.blur();
                }
              }}
              value={jsonText}
              onChange={(e) => setJsonText(e.target.value)}
            />
          </div>
          {error && (
            <div className="italic text-red-500 mt-2">
              {error}
              <button
                className={`text-blue-700 hover:font-medium italic focus:outline-none`}
                onClick={() => {
                  setJsonText(meta_data ? JSON.stringify(meta_data, undefined, 4) : "");
                  setError(null);
                }}
              >
                reset
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MetadataSetup;

const OpenCloseArrow = ({ open }: { open: boolean }) => {
  return (
    <svg
      className="fill-current h-4 w-4"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      style={{
        transform: open ? "rotate(360deg)" : "rotate(270deg)",
        transition: "transform 0.4s ease",
      }}
    >
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
    </svg>
  );
};

const tw = {
  label: "text-xs font-bold",
};
