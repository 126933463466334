import React, { useContext, useMemo } from "react";
import {
  SimulationModel,
  Component,
  InputVariableReference,
  ComponentType,
  ScenarioStateEdited,
  FullCMHarvester,
} from "model/datatypes";
import * as Sentry from "@sentry/browser";
import { getInputVarRef } from "utils/getFullVarRef";
import { NewScenarioAction } from "../NewScenarioReducer";
import SimulationComponent from "./simComponent/SimulationComponent";
import { useCMHarvester, useContainer, useDataSource } from "api/useFirebase";
import { store } from "store";
import gtw from "gtw";
import { InstantiateComponent, InstantiateAllSubComponents } from "utils/ComponentTypeHelpers";
import ToggleButton from "components/basic/ToggleButton";

const ComponentSetup: React.FC<{
  scenarioState: ScenarioStateEdited;
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
}> = ({ scenarioState, scenarioDispatch }) => {
  const { simComponents, model, harvester } = scenarioState;

  //Static Data:
  const fullDatasource = useDataSource(scenarioState.dataSource?.id);
  //parse input variables availble for dynamic input selection
  const inputVarRefs = useMemo(
    () =>
      fullDatasource?.simulationVariables.map((simVar) =>
        getInputVarRef(fullDatasource, simVar)
      ) || [],
    [fullDatasource]
  );

  //Get full CM harvester tags:
  const CMHarvester = useCMHarvester(
    harvester?.harvesterType === "control_machines" ? harvester.id : undefined
  );

  const sortedSimComponents = useMemo(
    () =>
      simComponents
        .filter((comp) => comp.isMainComponent)
        .sort((a, b) => {
          if (a.fixed) return 1;
          else if (b.fixed) return -1;
          else if (!a.order && !b.order) return 0;
          else if (!a.order) return -1;
          else if (!b.order) return 1;
          else return a.order - b.order;
        }),
    [simComponents]
  );

  return (
    <>
      {model &&
        sortedSimComponents.map((c) => {
          if (c.containerID)
            return (
              <CompWithContainer
                key={c.uuid}
                model={model}
                containerID={c.containerID}
                component={c}
                inputVarRefs={inputVarRefs}
                scenarioDispatch={scenarioDispatch}
                scenarioState={scenarioState}
                CMHarvester={CMHarvester}
              />
            );
          else
            return (
              <SimulationComponent
                key={c.uuid}
                model={model}
                component={c}
                inputVarRefs={inputVarRefs}
                scenarioDispatch={scenarioDispatch}
                scenario={scenarioState}
                CMHarvester={CMHarvester}
              />
            );
        })}
    </>
  );
};

export default ComponentSetup;

const CompWithContainer: React.FC<{
  model: SimulationModel;
  component: Component;
  containerID: string;
  inputVarRefs: InputVariableReference[];
  scenarioDispatch: (value: NewScenarioAction) => void;
  scenarioState: ScenarioStateEdited;
  CMHarvester?: FullCMHarvester;
}> = ({
  model,
  component,
  containerID,
  inputVarRefs,
  scenarioDispatch,
  scenarioState,
  CMHarvester,
}) => {
  const { state } = useContext(store);
  const { projectID, selectedScenario } = state;

  const { container, selectableComponentTypes } = useContainer(
    containerID,
    model.id,
    projectID || undefined,
    selectedScenario?.id
  );

  const onSelectNewComp = async (compType: ComponentType) => {
    try {
      if (scenarioState.componentTypes) {
        //instantiate comp
        let newComponent = InstantiateComponent(compType, true, containerID, true);

        //components and subcomponents:
        let comps = InstantiateAllSubComponents([newComponent], scenarioState.componentTypes);

        //delete previous and swap for new:
        scenarioDispatch({
          type: "SWAP_COMPONENTS",
          payload: { oldComponent: component, newComponents: comps },
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (!container) return <div>Loading..</div>;
  return (
    <div
      className={`${
        component.disabled ? "opacity-50" : "shadow-md"
      } bg-white border rounded-lg border-gray-300 mb-4`}
    >
      <div className="border-b border-gray-300 px-4 py-2 flex items-center justify-between">
        <span className="text-xl font-medium">{container.displayName}</span>
        {container?.instantiationRules?.allowDisabling && (
          <div>
            <ToggleButton
              active={!component.disabled}
              onChange={() => {
                scenarioDispatch({
                  type: "UPDATE_COMPONENT",
                  payload: { ...component, disabled: !component.disabled },
                });
              }}
            />
            <div className="text-xs italic">{component.disabled ? "disabled" : "enabled"}</div>
          </div>
        )}
      </div>
      {!component.disabled && (
        <div className="px-4 pt-4">
          <div className="flex flex-wrap mb-4">
            {selectableComponentTypes.map((ctype) => {
              const isActive = ctype.id === component.id;
              return (
                <button
                  key={ctype.id}
                  className={`${gtw.smallBtn} mr-2 ${isActive ? gtw.activeBtn : ""}`}
                  onClick={() => onSelectNewComp(ctype)}
                >
                  {ctype.displayName}
                </button>
              );
            })}
          </div>
          <SimulationComponent
            model={model}
            component={component}
            inputVarRefs={inputVarRefs}
            scenarioDispatch={scenarioDispatch}
            scenario={scenarioState}
            CMHarvester={CMHarvester}
          />
        </div>
      )}
    </div>
  );
};
