import { useState, useEffect } from "react";

const useWebsocketData = (projectID: string, scenarioID: string, tags: string[]) => {
  const [loadingData, setloadingData] = useState(false);
  const [data, setData] = useState<Dataframe | null>(null);
  const [time, setTime] = useState<number[] | null>(null);
  const [loadedTags, setLoadedTags] = useState<string[]>([]);

  useEffect(() => {
    const newTags = tags.filter((t) => !loadedTags.some((lt) => lt === t));
    const getWSData = () => {
      setloadingData(true);
      // const URL = "ws://34.78.202.118:80/ws";
      const URL = "wss://sim-support-api.numerously.com/ws";
      const ws = new WebSocket(URL);

      let count = 0;
      // let tags = ["Stub.VS.P", "Stub.KB.P", "Stub.VV.P"];
      let t: number[] = [];
      let data_frame: { [key: string]: number[] } = {};
      newTags.forEach((tag) => {
        data_frame[tag] = [];
      });

      ws.onopen = () => {
        // When the websocket opsns send a request to get data for the tags specified:
        console.time("read finished");
        console.time("first_read");
        let stags = [...newTags, "t"];
        //Make request to get data for the stags
        ws.send(
          JSON.stringify({
            request: "query_scenario_data",
            project: projectID,
            scenario: scenarioID,
            tags: stags,
            start: 0,
            end: 400,
            limit: 2,
          })
        );
      };

      ws.onmessage = (evt) => {
        // on receiving a message, add it to the list of messages
        const message = JSON.parse(evt.data) as MessageData;

        //handle the message
        if (message.message_type === "tag_data") {
          if (count === 0) {
            console.timeEnd("first_read");
          }
          //tag data received distribute it to the data frame
          count++;
          if (message.tag === "t") {
            t.push(...message.values.map((v) => v / 3600));
          } else {
            data_frame[message.tag].push(...message.values);
          }
        }
        if (message.message_type === "end_data") {
          setData((prev) => ({ ...prev, ...data_frame }));
          setTime(t);
          setLoadedTags((prev) => [...prev, ...newTags]);
          console.log({ data_frame, t });
          //The requested data is received - close the connection
          console.timeEnd("read finished");
          ws.close();
        }
      };
      ws.onclose = () => {
        console.log("disconnected");
        setloadingData(false);
      };
    };
    if (projectID && scenarioID && newTags.length > 0) getWSData();
  }, [projectID, scenarioID, tags, loadedTags]);

  return { data, time, loadingData };
};

export default useWebsocketData;

type MessageData = {
  message_type: "tag_data" | "end_data" | string;
  tag: string;
  values: number[];
};

export type Dataframe = {
  [key: string]: number[];
};

export const fetchWebSocketData = (projectID: string, scenarioID: string, tags: string[]) => {
  return new Promise<Dataframe>((resolve, reject) => {
    const URL = "wss://sim-support-api.numerously.com/ws";
    const ws = new WebSocket(URL);

    let count = 0;
    // let tags = ["Stub.VS.P", "Stub.KB.P", "Stub.VV.P"];
    let t: number[] = [];
    let data_frame: { [key: string]: number[] } = {};
    tags.forEach((tag) => {
      data_frame[tag] = [];
    });

    ws.onopen = () => {
      // When the websocket opsns send a request to get data for the tags specified:
      console.time("read finished");
      console.time("first_read");
      let stags = [...tags, "t"];
      //Make request to get data for the stags
      ws.send(
        JSON.stringify({
          request: "query_scenario_data",
          project: projectID,
          scenario: scenarioID,
          tags: stags,
          start: 0,
          end: 400,
          limit: 2,
        })
      );
    };

    ws.onmessage = (evt) => {
      // on receiving a message, add it to the list of messages
      const message = JSON.parse(evt.data) as MessageData;

      //handle the message
      if (message.message_type === "tag_data") {
        if (count === 0) {
          console.timeEnd("first_read");
        }
        //tag data received distribute it to the data frame
        count++;
        if (message.tag === "t") {
          t.push(...message.values.map((v) => v / 3600));
        } else {
          data_frame[message.tag].push(...message.values);
        }
      }
      if (message.message_type === "end_data") {
        //The requested data is received - close the connection
        console.timeEnd("read finished");
        ws.close();
      }
    };
    ws.onclose = () => {
      console.log("disconnected");
      resolve({ ...data_frame, t });
      console.log({ ...data_frame, t });
    };
    ws.onerror = (evt) => {
      reject("error reading");
      console.log(evt);
    };
  });
};
