import { useAllUsers, useFirebase } from "api/useFirebase";
import { Team } from "model/datatypes";
import React, { useCallback, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import Modal from "components/basic/Modal";
import CrossIcon from "../basic/icons/CrossIcon";
import SearchDropdownMultiple from "components/basic/SearchDropdownMultiple";
import { useUserRole } from "api/useAuth";

export interface EditTeamProps {}

const EditTeam: React.FC<{ onFinish: () => void; team: Team }> = ({ onFinish, team }) => {
  const fb = useFirebase();
  const [loading, setLoading] = useState(false);
  const allUsers = useAllUsers();
  const { hasProjectEditAccess } = useUserRole();

  const allTags = useMemo(
    () => allUsers.map((rawUser) => ({ id: rawUser.id, displayName: rawUser.fullName })),
    [allUsers]
  );

  const [editedTeam, setEditedTeam] = useState<Team>(team);

  const updateTeam = useCallback(() => {
    if (editedTeam) {
      setLoading(true);
      const { id, ...updateableFields } = editedTeam;

      fb.firestore()
        .collection("teams")
        .doc(team.id)
        .update(updateableFields)
        .then(() => {
          setLoading(false);
          onFinish();
        })
        .catch((error) => {
          setLoading(false);
          Sentry.captureException(error);
        });
    }
  }, [editedTeam, team, fb, onFinish]);

  const renderEditableTeamName = () => {
    return (
      <>
        <div className="font-medium">Edit team</div>
        <input
          type="text"
          className={`${tw.input} mb-2`}
          value={editedTeam.name}
          onChange={(e) => setEditedTeam({ ...editedTeam, name: e.target.value })}
        />
      </>
    );
  };

  return (
    <Modal onClose={() => onFinish()}>
      <div className={`${tw.card} w-1/2 xl:w-1/3 z-30`}>
        {hasProjectEditAccess ? (
          renderEditableTeamName()
        ) : (
          <div className="font-bold text-lg">{team.name}</div>
        )}
        <hr />
        <div>
          <label className="font-bold text-xs">Members</label>

          {editedTeam.users.map((userID) => {
            const fullUserInfo = allUsers.find((u) => u.id === userID);

            if (fullUserInfo)
              return (
                <div
                  key={fullUserInfo.id}
                  className="flex border-t w-full border-gray-200 p-2 align-middle"
                >
                  <span className="flex-grow">{fullUserInfo.fullName}</span>
                  {hasProjectEditAccess && (
                    <button
                      onClick={() => {
                        setEditedTeam({
                          ...editedTeam,
                          users: editedTeam.users.filter((uid) => uid !== userID),
                        });
                      }}
                    >
                      <CrossIcon />
                    </button>
                  )}
                </div>
              );
            return null;
          })}
        </div>
        {hasProjectEditAccess && (
          <SearchDropdownMultiple
            tags={allTags}
            selectedTagIDs={editedTeam.users}
            selectTag={(tagID) => {
              setEditedTeam({
                ...editedTeam,
                users: [...editedTeam.users, tagID],
              });
            }}
          />
        )}
        {hasProjectEditAccess && (
          <div className="flex mt-4">
            <button
              className={`flex-1 ${tw.saveBtn} mr-2 ${loading ? "opacity-50" : ""}`}
              onClick={() => {
                !loading && updateTeam();
              }}
            >
              Save
            </button>
            <button
              className={`flex-1 ${tw.saveBtn} ml-2 ${loading ? "opacity-50" : ""}`}
              onClick={() => !loading && onFinish()}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditTeam;

const tw = {
  card: "border border-gray-300 rounded p-4 bg-white shadow",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
};
