import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FirebaseContext } from "./FirebaseProvider";
import app from "firebase/app";
import {
  Project,
  SimulationModel,
  Group,
  Scenario,
  ComponentType,
  LocalSimulationModel,
  SimFile,
  FileQuery,
  Container,
  DiscoveredGitModel,
  GitModelVersion,
  GroupProcessor,
  CMHarvester,
  Team,
  RawUser,
  DataSource,
  Invitation,
  RawDataReport,
  Comment,
  Notification,
  FullNotification,
  FullCMHarvester,
  CMTag,
  UserInfo,
  ProjectLog,
  LogEventTimed,
  LogEvent,
} from "model/datatypes";
import { store, useGlobalState } from "store";
import moment from "moment";
import {
  cleanFSDataForUpdate,
  convertFromFirestoreFormat,
  convertFromFirestoreFormatNew,
  convertToFirestoreFormat,
} from "utils/firebase/firestoreFormatter";
import { mergeArrays, updateArrayVal } from "utils/jsUtils/imutableArray";
import * as Sentry from "@sentry/browser";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

//returns firebase state from context when callen from a component.
export const useFirebase = () => {
  return useContext(FirebaseContext).fbMain as app.app.App;
};
export const useFBManager = () => {
  return useContext(FirebaseContext).fbManager as app.app.App;
};

export const useFirestore = () => {
  const fb = useFirebase();
  return fb.firestore();
};

export const useAnalyticsLogger = () => {
  const { state } = useContext(store);
  const { inLocalDevMode } = state;

  const fbManager = useFBManager();
  const logFunction = useMemo(() => fbManager?.analytics().logEvent, [fbManager]);

  const orgFB = useFirebase();
  const orgLogFunction = useMemo(() => orgFB?.analytics().logEvent, [orgFB]);

  const theLogger = useCallback(
    (eventName: string, ...params) => {
      if (inLocalDevMode) {
        console.log("In local dev mode - did not send event: " + eventName);
      } else {
        if (logFunction) logFunction(eventName, ...params);
        if (orgLogFunction) orgLogFunction(eventName, ...params);
      }
    },
    [orgLogFunction, logFunction, inLocalDevMode]
  );

  return theLogger as typeof logFunction;
};

export const useComponentTypes = (
  simModelID?: string,
  projectID?: string,
  scenarioID?: string
) => {
  const [componentTypes, setComponentTypes] = useState<ComponentType[]>([]);
  const fb = useFirebase();
  const { user } = useGlobalState();

  const getUpdatedVersion = () => {
    const isLocalModel = !!projectID && !!scenarioID;
    const latestEdited: SimulationModel["latestEdited"] = {
      userId: user!.id,
      time: dayjs(),
    };
    const nextVersion = app.firestore.FieldValue.increment(1);
    return {
      latestEdited: convertToFirestoreFormat(latestEdited),
      ...(isLocalModel && { localVersion: nextVersion }),
      ...(!isLocalModel && { version: nextVersion }),
    };
  };

  useEffect(() => {
    if (!simModelID) {
      setComponentTypes([]);
    } else {
      const modelDoc =
        projectID && scenarioID
          ? fb
              .firestore()
              .collection("Projects")
              .doc(projectID)
              .collection("Scenarios")
              .doc(scenarioID)
              .collection("SimulationModel")
              .doc(simModelID)
          : fb.firestore().collection("SimulationModels").doc(simModelID);

      const unsub = modelDoc.collection("Components").onSnapshot((snapshot) => {
        const compTypes: ComponentType[] = [];
        snapshot.forEach((doc) => {
          const c = { id: doc.id, ...doc.data() } as ComponentType;
          compTypes.push(c);
        });
        setComponentTypes(compTypes);
      });
      return () => {
        unsub();
      };
    }
  }, [simModelID, projectID, scenarioID, fb]);

  const removeComponent = (componentID: string) => {
    const isLocalModel = !!projectID && !!scenarioID;
    const modelDoc = isLocalModel
      ? fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenarioID)
          .collection("SimulationModel")
          .doc(simModelID)
      : fb.firestore().collection("SimulationModels").doc(simModelID);
    const batch = fb.firestore().batch();
    const componentDoc = modelDoc.collection("Components").doc(componentID);
    batch.delete(componentDoc);
    batch.update(modelDoc, getUpdatedVersion());
    return batch.commit();
  };

  const addComponentType = (newCompType: ComponentType) => {
    return new Promise<string>((res, rej) => {
      const batch = fb.firestore().batch();
      const isLocalModel = !!projectID && !!scenarioID;
      const modelDoc = isLocalModel
        ? fb
            .firestore()
            .collection("Projects")
            .doc(projectID)
            .collection("Scenarios")
            .doc(scenarioID)
            .collection("SimulationModel")
            .doc(simModelID)
        : fb.firestore().collection("SimulationModels").doc(simModelID);
      console.log("ADDING new component");
      const componentDoc = modelDoc.collection("Components").doc();
      newCompType.id = componentDoc.id;
      batch.set(componentDoc, newCompType);

      batch.update(modelDoc, getUpdatedVersion());
      batch
        .commit()
        .then(() => {
          res(componentDoc.id);
        })
        .catch(rej);
    });
  };

  const updateComponent = (componentID: string, newComponentProps: Partial<ComponentType>) => {
    const isLocalModel = !!projectID && !!scenarioID;
    const modelDoc = isLocalModel
      ? fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenarioID)
          .collection("SimulationModel")
          .doc(simModelID)
      : fb.firestore().collection("SimulationModels").doc(simModelID);
    const batch = fb.firestore().batch();
    const componentDoc = modelDoc.collection("Components").doc(componentID);

    delete newComponentProps.id;
    batch.update(componentDoc, convertToFirestoreFormat(newComponentProps, true));

    batch.update(modelDoc, getUpdatedVersion());

    return batch.commit();
  };
  return { componentTypes, updateComponent, removeComponent, addComponentType };
};

export const useContainers = (
  simModelID?: string,
  projectID?: string,
  scenarioID?: string
) => {
  const [containers, setContainers] = useState<Container[]>([]);
  const fb = useFirebase();
  const { user } = useGlobalState();

  const getUpdatedVersion = () => {
    const isLocalModel = !!projectID && !!scenarioID;
    const latestEdited: SimulationModel["latestEdited"] = {
      userId: user!.id,
      time: dayjs(),
    };
    const nextVersion = app.firestore.FieldValue.increment(1);
    return {
      latestEdited: convertToFirestoreFormat(latestEdited),
      ...(isLocalModel && { localVersion: nextVersion }),
      ...(!isLocalModel && { version: nextVersion }),
    };
  };

  useEffect(() => {
    if (!simModelID) {
      setContainers([]);
    } else {
      const modelDoc =
        projectID && scenarioID
          ? fb
              .firestore()
              .collection("Projects")
              .doc(projectID)
              .collection("Scenarios")
              .doc(scenarioID)
              .collection("SimulationModel")
              .doc(simModelID)
          : fb.firestore().collection("SimulationModels").doc(simModelID);

      const unsub = modelDoc.collection("Containers").onSnapshot((snapshot) => {
        const cts: Container[] = [];
        snapshot.forEach((doc) => {
          const c = { id: doc.id, ...doc.data() } as Container;
          cts.push(c);
        });
        setContainers(cts);
      });
      return () => {
        unsub();
      };
    }
  }, [simModelID, projectID, scenarioID, fb]);

  const removeContainer = (containerID: string) => {
    const isLocalModel = !!projectID && !!scenarioID;
    const modelDoc = isLocalModel
      ? fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenarioID)
          .collection("SimulationModel")
          .doc(simModelID)
      : fb.firestore().collection("SimulationModels").doc(simModelID);
    console.log("REMOVING CONTAINER: " + containerID);
    const batch = fb.firestore().batch();
    const containerDoc = modelDoc.collection("Containers").doc(containerID);
    batch.delete(containerDoc);

    batch.update(modelDoc, getUpdatedVersion());

    return batch.commit();
  };

  const updateContainer = (containerID: string, newContainerProps: Partial<Container>) => {
    const isLocalModel = !!projectID && !!scenarioID;
    const modelDoc = isLocalModel
      ? fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection("Scenarios")
          .doc(scenarioID)
          .collection("SimulationModel")
          .doc(simModelID)
      : fb.firestore().collection("SimulationModels").doc(simModelID);
    const batch = fb.firestore().batch();
    const containerDoc = modelDoc.collection("Containers").doc(containerID);
    batch.update(containerDoc, cleanFSDataForUpdate(newContainerProps));

    batch.update(modelDoc, getUpdatedVersion());

    return batch.commit();
  };
  return { containers, updateContainer, removeContainer };
};

export const useContainer = (
  containerID: string,
  simModelID: string,
  projectID?: string,
  scenarioID?: string
) => {
  const [container, setContainer] = useState<Container>();
  const [selectableComponentTypes, setSelectableComponentTypes] = useState<ComponentType[]>(
    []
  );
  const fb = useFirebase();
  useEffect(() => {
    if (projectID && scenarioID) {
      const modelDoc = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .collection("SimulationModel")
        .doc(simModelID);
      const unsub = modelDoc
        .collection("Containers")
        .doc(containerID)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const c = { id: doc.id, ...doc.data() } as Container;
            setContainer(c);
          } else {
            setContainer(undefined);
          }
        });
      return () => {
        unsub();
      };
    }
  }, [containerID, simModelID, projectID, scenarioID, fb]);

  useEffect(() => {
    if (container && projectID && scenarioID) {
      const modelDoc = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .collection("SimulationModel")
        .doc(simModelID);
      const unsubs = container.componentTypeIDs.map((compTypeID) => {
        return modelDoc
          .collection("Components")
          .doc(compTypeID)
          .onSnapshot((doc) => {
            const c = { id: doc.id, ...doc.data() } as ComponentType;
            setSelectableComponentTypes((prev) => updateArrayVal(prev, c));
          });
      });
      return () => {
        unsubs.forEach((unsub) => {
          unsub();
        });
        setSelectableComponentTypes([]);
      };
    }
  }, [container, fb, projectID, scenarioID, simModelID]);

  const updateContainer = (newContainerProps: Partial<Container>) => {
    // const isLocalModel = !!projectID && !!scenarioID;
    const modelDoc = fb
      .firestore()
      .collection("Projects")
      .doc(projectID)
      .collection("Scenarios")
      .doc(scenarioID)
      .collection("SimulationModel")
      .doc(simModelID);
    const containerDoc = modelDoc.collection("Containers").doc(containerID);
    return containerDoc.update(cleanFSDataForUpdate(newContainerProps));
  };
  return { container, updateContainer, selectableComponentTypes };
};

export const useModelComponent = (
  simModelID: string,
  compoentID: string,
  projectID?: string | null,
  scenarioID?: string
) => {
  const [component, setComponent] = useState<ComponentType | null>(null);
  const fb = useFirebase();
  useEffect(() => {
    if (!simModelID || !compoentID) setComponent(null);
    else {
      const isLocalModel = !!projectID && !!scenarioID;
      const modelDoc = isLocalModel
        ? fb
            .firestore()
            .collection("Projects")
            .doc(projectID as string)
            .collection("Scenarios")
            .doc(scenarioID)
            .collection("SimulationModel")
            .doc(simModelID)
        : fb.firestore().collection("SimulationModels").doc(simModelID);
      const unsub = modelDoc
        .collection("Components")
        .doc(compoentID)
        .onSnapshot((docSnap) => {
          if (docSnap.exists) {
            const comp = { id: docSnap.id, ...docSnap.data() } as ComponentType;
            setComponent(comp);
          } else {
            setComponent(null);
          }
        });
      return () => {
        unsub();
      };
    }
  }, [simModelID, compoentID, fb, projectID, scenarioID]);

  return component;
};

export const useComponentLibrary = () => {
  const [componentLib, setcomponentLib] = useState<ComponentType[]>([]);
  const fb = useFirebase();
  useEffect(() => {
    const unsub = fb
      .firestore()
      .collection("ComponentLibrary")
      .onSnapshot((snapshot) => {
        const comp: ComponentType[] = [];
        snapshot.forEach((doc) => {
          const c = { id: doc.id, ...doc.data() } as ComponentType;
          comp.push(c);
        });
        setcomponentLib(comp);
      });
    return () => {
      unsub();
    };
  }, [fb]);

  const removeComponentFromLib = (componentID: string) => {
    console.log("REMOVING" + componentID);
    // console.log({ componentID, newComponent, simModelID });
    return fb.firestore().collection("ComponentLibrary").doc(componentID).delete();
  };
  const updateLibraryComponent = (componentID: string, newComponent: ComponentType) => {
    // console.log({ componentID, newComponent, simModelID });
    return fb
      .firestore()
      .collection("ComponentLibrary")
      .doc(componentID)
      .set({ ...newComponent }, { merge: true });
  };

  return { componentLib, updateLibraryComponent, removeComponentFromLib };
};

export const useProjects = (
  hasDeveloperAccess: boolean,
  teamIDs: string[],
  userID?: string
) => {
  const [collaboratorProjects, setCollaboratorProjects] = useState<Project[]>([]);
  const [teamProjects, setTeamProjects] = useState<Project[]>([]);
  const [ownedProjects, setOwnedProjects] = useState<Project[]>([]);
  const [otherProjects, setOtherProjects] = useState<Project[]>([]);

  const [projectsLoading, setProjectsLoading] = useState(true);

  const myProjects = useMemo(() => {
    //take all owned projects and add accesible projects.
    const allCollaboratorProjects = mergeArrays(
      teamProjects,
      collaboratorProjects
    ) as Project[];
    return mergeArrays(ownedProjects, allCollaboratorProjects) as Project[];
  }, [collaboratorProjects, ownedProjects, teamProjects]);

  const allProjects = useMemo(() => {
    setProjectsLoading(false);
    let projects = mergeArrays(teamProjects, collaboratorProjects) as Project[];
    projects = mergeArrays(projects, ownedProjects) as Project[];
    return mergeArrays(projects, otherProjects) as Project[];
  }, [collaboratorProjects, teamProjects, ownedProjects, otherProjects]);

  const fb = useFirebase();

  //Get all projects if have developer access:
  useEffect(() => {
    setProjectsLoading(true);
    if (userID && hasDeveloperAccess) {
      const projectCollection = fb.firestore().collection("Projects");
      let query = projectCollection.where("deleted", "==", null);
      const unsub = query.onSnapshot((snap) => {
        const ownedP: Project[] = [];
        const colabProjects: Project[] = [];
        const teamP: Project[] = [];
        const otherP: Project[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          if (data.created !== null) {
            const project = convertFromFirestoreFormatNew({ ...data, id: doc.id }) as Project;
            if (project.ownerId === userID) ownedP.push(project);
            else if (project.collaborators.some((colaboratorID) => colaboratorID === userID))
              colabProjects.push(project);
            else if (project.teams.some((team) => teamIDs.some((tID) => tID === team)))
              teamP.push(project);
            else otherP.push(project);
          }
        });
        setCollaboratorProjects(colabProjects);
        setTeamProjects(teamP);
        setOwnedProjects(ownedP);
        setOtherProjects(otherP);
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, teamIDs, userID]);

  //Get all colaborator projects if not developer
  useEffect(() => {
    if (userID && !hasDeveloperAccess) {
      const projectCollection = fb.firestore().collection("Projects");
      let query = projectCollection
        .where("deleted", "==", null)
        .where("collaborators", "array-contains", userID);

      const unsub = query.onSnapshot((snap) => {
        const projects: Project[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          if (data.created !== null) {
            const project = convertFromFirestoreFormatNew({ ...data, id: doc.id }) as Project;
            projects.push(project);
          }
        });
        setCollaboratorProjects(projects);
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, teamIDs, userID]);

  //Get all owned projects if not developer
  useEffect(() => {
    if (userID && !hasDeveloperAccess) {
      const projectCollection = fb.firestore().collection("Projects");
      let query = projectCollection.where("deleted", "==", null);
      query = query.where("ownerId", "==", userID);

      const unsub = query.onSnapshot((snap) => {
        const projects: Project[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          if (data.created !== null) {
            const project = convertFromFirestoreFormatNew({ ...data, id: doc.id }) as Project;
            projects.push(project);
          }
        });
        setOwnedProjects(projects);
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, teamIDs, userID]);

  //get team projects if not developer
  useEffect(() => {
    if (userID && !hasDeveloperAccess && teamIDs.length > 0) {
      const projectCollection = fb.firestore().collection("Projects");
      let query = projectCollection.where("deleted", "==", null);
      query = query.where("teams", "array-contains-any", teamIDs);

      const unsub = query.onSnapshot((snap) => {
        const projects: Project[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          if (data.created !== null) {
            const project = convertFromFirestoreFormatNew({ ...data, id: doc.id }) as Project;
            projects.push(project);
          }
        });
        setTeamProjects(projects);
      });
      return unsub;
    }
  }, [fb, hasDeveloperAccess, teamIDs, userID]);

  return { myProjects, allProjects, projectsLoading };
};

export const useTeams = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const fb = useFirebase();

  useEffect(() => {
    const unsub = fb
      .firestore()
      .collection("teams")
      .onSnapshot((snap) => {
        const t: Team[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          const team = convertFromFirestoreFormat({ ...data, id: doc.id }) as Team;
          t.push(team);
        });
        setTeams(t);
      });
    return () => {
      unsub();
    };
  }, [fb]);

  return teams;
};

export const useProject = (projectID: string | null | undefined) => {
  const [project, setproject] = useState<Project | null>(null);
  const fb = useFirebase();
  useEffect(() => {
    if (projectID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .onSnapshot((doc) => {
          const data = doc.data();
          if (data) {
            setproject(convertFromFirestoreFormatNew({ ...data, id: doc.id }) as Project);
          }
        });
      return () => {
        usub();
      };
    }
  }, [fb, projectID]);
  return project;
};

const getAvgRunTime = (logs?: LogEventTimed[]) => {
  if (!logs) return null;
  let totalRuntime = logs.reduce((prev, cur) => prev + cur.runTime, 0);
  return totalRuntime / logs.length;
};
const getLogAmount = (logs?: LogEvent[]) => {
  if (!logs) return 0;
  return logs.length;
};
const findMostRecentAction = (simStarted?: LogEvent[], processorStarted?: LogEvent[]) => {
  const recentSim = simStarted?.reduce((prev, cur) =>
    cur.timeStamp.isAfter(prev.timeStamp) ? cur : prev
  );
  if (!processorStarted) return recentSim;
  const recentProcessor = processorStarted.reduce((prev, cur) =>
    cur.timeStamp.isAfter(prev.timeStamp) ? cur : prev
  );
  if (!recentSim) return recentProcessor;
  return recentProcessor.timeStamp.isAfter(recentSim.timeStamp) ? recentProcessor : recentSim;
};
export const useProjectStatistics = (projectID: string) => {
  const [projectLog, setProjectLog] = useState<ProjectLog>();
  const statistics = useMemo(
    () => ({
      avgSimTimeSuccess: getAvgRunTime(projectLog?.log_simulation_finished),
      avgSimTimeFailed: getAvgRunTime(projectLog?.log_simulation_error),
      avgProcessorTimeSuccess: getAvgRunTime(projectLog?.log_processor_finished),
      avgProcessorTimeFailed: getAvgRunTime(projectLog?.log_processor_error),

      totalSimStarted: getLogAmount(projectLog?.log_simulation_started),
      totalSimSuccess: getLogAmount(projectLog?.log_simulation_finished),
      totalSimFailed: getLogAmount(projectLog?.log_simulation_error),

      totalProcessorStarted: getLogAmount(projectLog?.log_processor_started),
      totalProcessorSuccess: getLogAmount(projectLog?.log_processor_finished),
      totalProcessorFailed: getLogAmount(projectLog?.log_processor_error),

      totalStarted:
        getLogAmount(projectLog?.log_simulation_started) +
        getLogAmount(projectLog?.log_processor_started),
      totalSuccess:
        getLogAmount(projectLog?.log_simulation_finished) +
        getLogAmount(projectLog?.log_processor_finished),
      totalFailed:
        getLogAmount(projectLog?.log_simulation_error) +
        getLogAmount(projectLog?.log_processor_error),

      mostRecent: findMostRecentAction(
        projectLog?.log_simulation_started,
        projectLog?.log_processor_started
      ),
    }),
    [projectLog]
  );
  // if (projectLog) console.log({ projectLog, statistics });

  const fs = useFirestore();
  useEffect(() => {
    fs.collection("Logs")
      .doc(projectID)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setProjectLog(
            convertFromFirestoreFormatNew({ ...data, id: projectID }) as ProjectLog
          );
        }
      });
  }, [projectID, fs]);
  return { projectLog, statistics };
};

export const useGroups = (projectID?: string) => {
  const [groups, setgroups] = useState<Group[] | null>(null);
  const fb = useFirebase();

  useEffect(() => {
    if (projectID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Groups")
        .onSnapshot((snap) => {
          const groups: Group[] = [];
          snap.docs.forEach((doc) => {
            const createdDate = doc.data().created.toDate() as Date;
            groups.push({ ...doc.data(), id: doc.id, created: moment(createdDate) } as Group);
          });
          setgroups(groups);
        });
      return () => {
        usub();
      };
    }
  }, [fb, projectID]);
  return groups;
};

export const useTeamCollaborators = (team: Team) => {
  const [collaborators, setCollaborators] = useState<RawUser[]>([]);
  const fs = useFirestore();

  useEffect(() => {
    setCollaborators([]);
    team.users.forEach((userID) => {
      fs.collection("users")
        .doc(userID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const user = { ...doc.data(), id: doc.id } as RawUser;
            setCollaborators((prev) => updateArrayVal(prev, user));
          }
        });
    });
  }, [team, fs]);

  return collaborators;
};

export const useScenarios = (projectID: string | null, groupID: string, limit?: number) => {
  const [scenarios, setscenarios] = useState<Scenario[] | null>(null);
  const fb = useFirebase();
  useEffect(() => {
    if (projectID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .where("groupID", "==", groupID)
        .limit(limit || 30)
        .onSnapshot((snap) => {
          const scenarios: Scenario[] = [];
          snap.docs.forEach((doc) => {
            const fsTimestamp = doc.data().created?.toDate() as Date | undefined;
            const created = fsTimestamp ? moment(fsTimestamp) : moment();
            scenarios.push({ ...doc.data(), id: doc.id, created } as Scenario);
          });
          setscenarios(scenarios);
        });
      return () => {
        usub();
      };
    }
  }, [fb, projectID, groupID, limit]);
  return scenarios;
};

export const useScenario = (projectID?: string, scenarioID?: string) => {
  const [scenario, setscenario] = useState<Scenario>();
  const fb = useFirebase();
  useEffect(() => {
    if (projectID && scenarioID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .onSnapshot((doc) => {
          if (doc.data) {
            const scenarioData = { ...doc.data(), id: doc.id };
            setscenario(convertFromFirestoreFormat(scenarioData) as Scenario);
          }
        });
      return () => {
        usub();
      };
    }
  }, [fb, projectID, scenarioID]);
  return scenario;
};

export const useScenariosRecent = (projectID: string | null | undefined, limit: number) => {
  const [scenarios, setscenarios] = useState<Scenario[]>([]);
  const fb = useFirebase();
  useEffect(() => {
    if (projectID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .orderBy("created", "desc")
        .limit(limit)
        .onSnapshot((snap) => {
          const scenarios: Scenario[] = [];
          snap.docs.forEach((doc) => {
            const fsTimestamp = doc.data().created?.toDate() as Date | undefined;
            const created = fsTimestamp ? moment(fsTimestamp) : moment();
            scenarios.push({ ...doc.data(), id: doc.id, created } as Scenario);
          });
          setscenarios(scenarios);
        });
      return () => {
        usub();
      };
    } else setscenarios([]);
  }, [fb, projectID, limit]);
  return scenarios;
};

export const useSimulationModels = (
  hasDeveloperAccess: boolean,
  teamIds: string[],
  userID?: string
) => {
  const [allPublicModels, setAllPublicModels] = useState<SimulationModel[]>([]);
  const [draftModels, setDraftModels] = useState<SimulationModel[]>([]);

  const [collaboratorModels, setCollaboratorModels] = useState<SimulationModel[]>([]);
  const [teamCollaboratorModels, setTeamCollaboratorModels] = useState<SimulationModel[]>([]);

  const [allModels, setAllModels] = useState<SimulationModel[]>([]);

  //set all models for developter:
  useEffect(() => {
    if (hasDeveloperAccess) {
      setAllModels([...allPublicModels, ...draftModels]);
    }
  }, [hasDeveloperAccess, allPublicModels, draftModels]);
  //setAllmodels for non-developers
  useEffect(() => {
    if (!hasDeveloperAccess) {
      setAllModels(
        mergeArrays(collaboratorModels, teamCollaboratorModels) as SimulationModel[]
      );
    }
  }, [hasDeveloperAccess, collaboratorModels, teamCollaboratorModels]);

  const fb = useFirebase();

  //get all public models for developers
  useEffect(() => {
    if (hasDeveloperAccess) {
      let collection = fb
        .firestore()
        .collection("SimulationModels")
        .where("status", "==", "published");

      const usub = collection.onSnapshot((snap) => {
        const models: SimulationModel[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          models.push(
            convertFromFirestoreFormatNew({
              ...data,
              id: doc.id,
              version: data.version || 0,
            }) as SimulationModel
          );
        });
        setAllPublicModels(models);
      });
      return () => {
        usub();
      };
    }
  }, [fb, hasDeveloperAccess]);

  //get all drafs for developers:
  useEffect(() => {
    if (hasDeveloperAccess) {
      let collection = fb
        .firestore()
        .collection("SimulationModels")
        .where("status", "==", "draft")
        .where("ownerId", "==", userID);

      const usub = collection.onSnapshot((snap) => {
        const models: SimulationModel[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          models.push(
            convertFromFirestoreFormatNew({
              ...data,
              id: doc.id,
              version: data.version || 0,
            }) as SimulationModel
          );
        });
        setDraftModels(models);
      });
      return () => {
        usub();
      };
    }
  }, [fb, userID, hasDeveloperAccess]);

  //get collaboartor models for non-dev users:
  useEffect(() => {
    if (!hasDeveloperAccess && userID) {
      let collection = fb
        .firestore()
        .collection("SimulationModels")
        .where("status", "==", "published")
        .where("collaborators", "array-contains", userID);

      const usub = collection.onSnapshot((snap) => {
        const models: SimulationModel[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          models.push({
            ...convertFromFirestoreFormatNew(data),
            id: doc.id,
            version: data.version || 0,
          } as SimulationModel);
        });
        setCollaboratorModels(models);
      });
      return () => {
        usub();
      };
    }
  }, [fb, hasDeveloperAccess, userID]);

  //get team collaborator models for non-developers
  useEffect(() => {
    if (!hasDeveloperAccess && teamIds.length > 0) {
      let collection = fb
        .firestore()
        .collection("SimulationModels")
        .where("status", "==", "published")
        .where("teams", "array-contains-any", teamIds);

      const usub = collection.onSnapshot((snap) => {
        const models: SimulationModel[] = [];
        snap.docs.forEach((doc) => {
          const data = doc.data();
          models.push({
            ...convertFromFirestoreFormatNew(data),
            id: doc.id,
            version: data.version || 0,
          } as SimulationModel);
        });
        setTeamCollaboratorModels(models);
      });
      return () => {
        usub();
      };
    }
  }, [fb, hasDeveloperAccess, teamIds]);

  return { draftModels, allModels };
};

export const useSimulationModel = (id?: string) => {
  const [simulationModel, setSimulationModel] = useState<SimulationModel | null>(null);
  const fb = useFirebase();

  useEffect(() => {
    if (id) {
      const usub = fb
        .firestore()
        .collection("SimulationModels")
        .doc(id)
        .onSnapshot((doc) => {
          let data = doc.data();
          if (doc.exists && data) {
            const model: SimulationModel = {
              ...convertFromFirestoreFormatNew(data),
              id: doc.id,
              version: data.version || 0,
            } as SimulationModel;
            setSimulationModel(model);
          } else {
            setSimulationModel(null);
          }
        });
      return () => {
        usub();
      };
    } else setSimulationModel(null);
  }, [fb, id]);
  return simulationModel;
};

export const useDiscoveredModel = (modelID: string) => {
  const [discoveredModel, setDiscoveredModel] = useState<DiscoveredGitModel | null>(null);
  const [modelVersions, setModelVersions] = useState<GitModelVersion[]>([]);
  const fb = useFirebase();

  useEffect(() => {
    const usub = fb
      .firestore()
      .collection("discovered_models")
      .doc(modelID)
      .onSnapshot((doc) => {
        const data = doc.data();
        if (doc.exists && data) {
          const model = {
            id: doc.id,
            modelName: data.modelName,
            path: data.path,
          } as DiscoveredGitModel;
          setDiscoveredModel(model);
        } else {
          setDiscoveredModel(null);
        }
      });
    return () => {
      usub();
    };
  }, [fb, modelID]);

  useEffect(() => {
    const usub = fb
      .firestore()
      .collection("discovered_models")
      .doc(modelID)
      .collection("models")
      .onSnapshot((snap) => {
        const modelV: GitModelVersion[] = [];
        snap.docs.forEach((doc) => {
          const data = convertFromFirestoreFormat({ ...doc.data() });
          modelV.push({
            ...data,
            id: doc.id,
          } as GitModelVersion);
        });
        setModelVersions(modelV);
      });
    return () => {
      usub();
    };
  }, [fb, modelID]);

  return { discoveredModel, modelVersions };
};

export const useDiscoveredModels = () => {
  const [discoveredModels, setDiscoveredModels] = useState<DiscoveredGitModel[]>([]);
  const fb = useFirebase();

  useEffect(() => {
    const usub = fb
      .firestore()
      .collection("discovered_models")
      .onSnapshot((snap) => {
        const models: DiscoveredGitModel[] = [];
        snap.docs.forEach((doc) => {
          const model = {
            id: doc.id,
            ...doc.data(),
          } as DiscoveredGitModel;
          models.push(model);
        });
        setDiscoveredModels(models);
      });
    return () => {
      usub();
    };
  }, [fb]);

  return discoveredModels;
};

export const useGroupProcessors = () => {
  const [groupProcessors, setGroupProcessors] = useState<GroupProcessor[]>([]);
  const fb = useFirebase();

  useEffect(() => {
    const usub = fb
      .firestore()
      .collection("GroupProcessors")
      .onSnapshot((snap) => {
        const processors: GroupProcessor[] = [];
        snap.docs.forEach((doc) => {
          const gp = {
            id: doc.id,
            ...doc.data(),
          } as GroupProcessor;
          processors.push(gp);
        });
        setGroupProcessors(processors);
      });
    return () => {
      usub();
    };
  }, [fb]);

  return groupProcessors;
};

export const useLocalSimulationModel = (
  simModelID?: string,
  projectID?: string,
  scenarioID?: string
) => {
  const [simulationModel, setSimulationModel] = useState<LocalSimulationModel | null>(null);
  const fb = useFirebase();

  useEffect(() => {
    if (simModelID && projectID && scenarioID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .collection("SimulationModel")
        .doc(simModelID)
        .onSnapshot((doc) => {
          const data = doc.data();
          if (doc.exists && data) {
            const model: LocalSimulationModel = {
              ...data,
              id: doc.id,
              version: data.version || 0,
              localVersion: data.localVersion || 0,
            } as LocalSimulationModel;
            setSimulationModel(model);
          } else {
            setSimulationModel(null);
          }
        });
      return () => {
        usub();
      };
    } else setSimulationModel(null);
  }, [fb, projectID, scenarioID, simModelID]);
  return simulationModel;
};

export const useSimFiles = ({
  tags,
  type,
  projectRef: project,
  modelRef: model,
}: FileQuery) => {
  const [simFiles, setSimFiles] = useState<SimFile[]>([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const fb = useFirebase();
  useEffect(() => {
    const projectID = project?.id;
    const modelID = model?.id;
    if (tags.length > 0 || type || modelID || projectID) {
      let fileCol:
        | app.firestore.Query<app.firestore.DocumentData>
        | app.firestore.CollectionReference<app.firestore.DocumentData> = fb
        .firestore()
        .collection("FileLibrary");
      if (tags.length > 0) fileCol = fileCol.where("tags", "array-contains-any", tags);
      if (projectID) fileCol = fileCol.where("projectID", "==", projectID);
      if (modelID) fileCol = fileCol.where("modelID", "==", modelID);
      if (type) fileCol = fileCol.where("type", "==", type);

      setLoadingFiles(true);
      const usub = fileCol.onSnapshot((snap) => {
        const files: SimFile[] = [];
        snap.docs.forEach((doc) => {
          files.push({ ...doc.data(), id: doc.id } as SimFile);
        });
        setLoadingFiles(false);
        setSimFiles(files);
      });
      return () => {
        setLoadingFiles(false);
        usub();
      };
    } else {
      setSimFiles([]);
      setLoadingFiles(false);
    }
  }, [fb, tags, project, model, type]);
  return { simFiles, loadingFiles };
};

//tags for finding files:
export const useFileTags = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const fb = useFirebase();

  useEffect(() => {
    const usub = fb
      .firestore()
      .collection("FileTags")
      .doc("tags")
      .onSnapshot((doc) => {
        const tags: string[] = doc.data()?.tags;
        if (tags) setTags(tags);
      });
    return () => {
      usub();
    };
  }, [fb]);
  useEffect(() => {
    const usub = fb
      .firestore()
      .collection("FileTags")
      .doc("typeTags")
      .onSnapshot((doc) => {
        const types: string[] = doc.data()?.types;
        if (types) setTypes(types);
      });
    return () => {
      usub();
    };
  }, [fb]);

  return { tags, types };
};

type MongoDBConfig = {
  id: string;
  Name: string;
  Tags: string[];
  [key: string]: any;
};

export const useConfigs = (tag?: string) => {
  const [configs, setConfigs] = useState<MongoDBConfig[]>([]);
  const [loadingConfigs, setLoadingConfigs] = useState(false);
  const fb = useFirebase();

  useEffect(() => {
    if (tag) {
      setLoadingConfigs(true);
      const usub = fb
        .firestore()
        .collection("Configs")
        .where("Tags", "array-contains", tag)
        .onSnapshot((snap) => {
          const confs: any[] = [];
          snap.docs.forEach((doc) => {
            confs.push({ ...doc.data(), id: doc.id });
          });
          setLoadingConfigs(false);
          setConfigs(confs);
        });
      return () => {
        usub();
      };
    } else setConfigs([]);
  }, [fb, tag]);
  return { configs, loadingConfigs };
};

export const useDataSources = () => {
  const [dataSources, setdatasources] = useState<DataSource[]>([]);
  const { state } = useContext(store);
  const fb = useFirebase();
  const { projectID } = state;
  useEffect(() => {
    if (projectID) {
      const unsub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .onSnapshot((snapshot) => {
          const sources: DataSource[] = [];
          snapshot.forEach((doc) => {
            const c = { ...doc.data(), id: doc.id } as DataSource;
            sources.push(c);
          });
          setdatasources(sources);
        });
      return () => {
        unsub();
      };
    }
  }, [projectID, fb]);

  return { dataSources };
};

export const useDataSource = (dataSourceID?: string) => {
  const [dataSource, setdatasource] = useState<DataSource | null>(null);
  const { state } = useContext(store);
  const fb = useFirebase();
  const { projectID } = state;

  useEffect(() => {
    if (dataSourceID && projectID) {
      const unsub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .doc(dataSourceID)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const ds: DataSource = { ...doc.data(), id: doc.id } as DataSource;
            setdatasource(ds);
          } else {
            setdatasource(null);
          }
        });
      return () => {
        unsub();
      };
    } else setdatasource(null);
  }, [dataSourceID, fb, projectID]);
  return dataSource;
};

export const useCMHarvesters = () => {
  const [harvesters, setHarvesters] = useState<CMHarvester[]>([]);
  const { state } = useContext(store);
  const fb = useFirebase();
  const { projectID } = state;
  useEffect(() => {
    if (projectID) {
      const unsub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("CMHarvesters")
        .onSnapshot((snapshot) => {
          const sources: CMHarvester[] = [];
          snapshot.forEach((doc) => {
            const c = { ...doc.data(), id: doc.id } as CMHarvester;
            sources.push(c);
          });
          setHarvesters(sources);
        });
      return () => {
        unsub();
      };
    }
  }, [projectID, fb]);

  return harvesters;
};

export const useCMHarvester = (harvesterID?: string) => {
  const [harvester, setHarvester] = useState<FullCMHarvester>();

  const { state } = useContext(store);
  const fb = useFirebase();
  const { projectID } = state;
  useEffect(() => {
    if (projectID && harvesterID) {
      const unsub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("CMHarvesters")
        .doc(harvesterID)
        .onSnapshot(async (doc) => {
          if (!doc.exists) return;
          let allTags: FullCMHarvester["tags"] = [];
          const harvester = { ...doc.data(), id: doc.id } as CMHarvester;
          if (harvester.tagDocs) {
            for (let i = 0; i < harvester.tagDocs.length; i++) {
              const docID = harvester.tagDocs[i];
              const tagDoc = await doc.ref.collection("CMTags").doc(docID).get();
              const { tags } = tagDoc.data() as { tags: CMTag[] };
              allTags.push(...tags);
            }
          }
          console.log({ harvester, allTags });

          setHarvester({ tags: allTags, ...harvester });
        });
      return () => {
        unsub();
      };
    }
  }, [projectID, fb, harvesterID]);

  return harvester;
};

export const useDataParamValues = (
  inputDataID: string,
  variableID?: string,
  projectID?: string | null
) => {
  const fs = useFirestore();
  const [parameterData, setparameterData] = useState<number[]>([]);
  const [dataLoading, setLoading] = useState(false);
  useEffect(() => {
    if (!variableID || !projectID) setparameterData([]);
    else {
      setLoading(true);
      fs.collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .doc(inputDataID)
        .collection("Data")
        .doc(variableID)
        .onSnapshot(
          (doc) => {
            const docData = doc.data();
            if (docData) {
              const vals = docData.values as (string | number)[];
              const parsed = vals.map((v) => (typeof v !== "number" ? parseFloat(v) : v));
              setparameterData(parsed);
            }
            setLoading(false);
          },
          (e) => {
            Sentry.captureException(e);
            console.log(e);
            setLoading(false);
          }
        );
    }
  }, [variableID, fs, projectID, inputDataID]);

  return { parameterData, dataLoading };
};

export const useAllUserInvitations = () => {
  const fs = useFirestore();

  const [invitations, setInvitations] = useState<Invitation[]>([]);

  useEffect(() => {
    return fs.collection("invitations").onSnapshot((snap) => {
      const invitations: Invitation[] = [];
      snap.docs.forEach((doc) => {
        const invitation = convertFromFirestoreFormat({
          id: doc.id,
          ...doc.data(),
        }) as Invitation;
        invitations.push(invitation);
      });
      setInvitations(invitations);
    });
  }, [fs]);
  return invitations;
};

export const useAllUsers = () => {
  const fs = useFirestore();

  const [users, setUsers] = useState<RawUser[]>([]);

  useEffect(() => {
    return fs.collection("users").onSnapshot((snap) => {
      const u: RawUser[] = [];
      snap.docs.forEach((doc) => {
        const user = { id: doc.id, ...doc.data() } as RawUser;
        u.push(user);
      });
      setUsers(u);
    });
  }, [fs]);
  return users;
};

export const useUserNotifications = () => {
  const fs = useFirestore();
  const [userNotifications, setUserNotifications] = useState<Notification[]>([]);
  const [fullUserNotifications, setFullUserNotifications] = useState<FullNotification[]>();
  const { user } = useGlobalState();

  // Get all notifications tied to current UID
  useEffect(() => {
    return fs
      .collection("users")
      .doc(user?.fbUser.uid)
      .collection("notifications")
      .onSnapshot((snap) => {
        const n: Notification[] = [];
        snap.docs.forEach((doc) => {
          const notification = convertFromFirestoreFormat({
            id: doc.id,
            ...doc.data(),
          }) as Notification;
          n.push(notification);
        });
        console.log({ n });
        setUserNotifications(n);
      });
  }, [user, fs]);

  // Get full notifications with comments tied to them
  useEffect(() => {
    const getNotificationData = async () => {
      const fn: FullNotification[] = [];

      for (let i = 0; i < userNotifications.length; i++) {
        const notification = userNotifications[i];
        if (notification.CommentID) {
          const doc = await fs.collection("Comments").doc(notification.CommentID).get();

          const fullNotification = convertFromFirestoreFormat({
            ...doc.data(),
            ...notification,
          }) as FullNotification;

          fn.push(fullNotification);
        }
      }

      const sortedFn = fn.sort((a, b) => {
        if (a.read === b.read) return b.timestamp.valueOf() - a.timestamp.valueOf();
        if (a.read) return 1;
        return -1;
      });

      setFullUserNotifications(sortedFn);
    };

    getNotificationData();
  }, [userNotifications, fs]);

  return { fullUserNotifications };
};

//gets all comments in a group and below....:
export const useGroupComments = (groupIDs: string[], projectID?: string) => {
  const fs = useFirestore();
  const [rawComments, setRawComments] = useState<Comment[]>([]);

  const location = useLocation();
  const currentPage = useMemo(() => location.pathname.split("/")[1], [location]);
  const groupCommentsActive = useMemo(() => {
    return currentPage === "simulations";
  }, [currentPage]);

  //Load comments:
  useEffect(() => {
    if (groupCommentsActive && projectID && groupIDs.length > 0) {
      let query = fs
        .collection("Comments")
        .where("projectID", "==", projectID)
        .where("groupID", "in", groupIDs);

      return query.onSnapshot((snap) => {
        const c: Comment[] = [];
        snap.docs.forEach((doc) => {
          const comment = convertFromFirestoreFormat({ id: doc.id, ...doc.data() }) as Comment;
          c.push(comment);
        });
        setRawComments(c);
      });
    } else setRawComments([]);
  }, [groupIDs, groupCommentsActive, projectID, fs]);

  return rawComments;
};

export const useProjectComments = () => {
  const fs = useFirestore();
  const [comments, setComments] = useState<Comment[]>([]);

  const { projectID } = useGlobalState();

  const location = useLocation();
  const currentPage = useMemo(() => location.pathname.split("/")[1], [location]);
  const projectCommentsActive = useMemo(() => {
    return currentPage === "simulations" || currentPage === "" || currentPage === "overview";
  }, [currentPage]);

  //Load comments:
  useEffect(() => {
    if (projectCommentsActive) {
      let query = fs.collection("Comments").where("commentScope", "==", "project");
      if (projectID) query = query.where("projectID", "==", projectID);
      return query.onSnapshot((snap) => {
        const c: Comment[] = [];
        snap.docs.forEach((doc) => {
          const comment = convertFromFirestoreFormat({ id: doc.id, ...doc.data() }) as Comment;
          c.push(comment);
        });
        setComments(c);
      });
    } else {
      setComments([]);
    }
  }, [projectCommentsActive, projectID, fs, currentPage]);

  return comments;
};

export const useSystemComments = () => {
  const fs = useFirestore();
  const [comments, setComments] = useState<Comment[]>([]);

  const location = useLocation();
  const currentPage = useMemo(() => location.pathname.split("/")[1], [location]);
  const simModelID = useMemo(() => location.pathname.split("/")[2] as string | undefined, [
    location,
  ]);

  //Load comments:
  useEffect(() => {
    if (currentPage === "systems") {
      let query: app.firestore.Query<app.firestore.DocumentData> = fs.collection("Comments");
      if (simModelID) query = query.where("systemID", "==", simModelID);
      else query = query.where("commentScope", "==", "system");
      return query.onSnapshot((snap) => {
        const c: Comment[] = [];
        snap.docs.forEach((doc) => {
          const comment = convertFromFirestoreFormat({ id: doc.id, ...doc.data() }) as Comment;
          c.push(comment);
        });
        setComments(c);
      });
    } else {
      setComments([]);
    }
  }, [simModelID, fs, currentPage]);

  return comments;
};

export const useCheckForComments = (
  commentIDKey: "scenarioID" | "projectID" | "groupID" | "componentID",
  id: string
) => {
  const [commentExists, setCommentExists] = useState(false);
  const { comments } = useGlobalState();
  useEffect(() => {
    const exists = comments.some((c: Comment) => c[commentIDKey] === id);
    setCommentExists(exists);
  }, [comments, commentIDKey, id]);

  return commentExists;
};

export const useUserInfo = (userID?: string) => {
  const [userInfo, setuserInfo] = useState<UserInfo>();
  const fs = useFirestore();

  useEffect(() => {
    if (userID)
      return fs
        .collection("users")
        .doc(userID)
        .onSnapshot((doc) => {
          const data = { id: doc.id, ...doc.data() } as UserInfo;
          if (data) setuserInfo(data);
        });
    else setuserInfo(undefined);
  }, [userID, fs]);
  return userInfo;
};

// export const usePIDiagrams = (modelID: string, projectID?: string, scenarioID?: string) => {
//   const [piDiagrams, setPIDiagrams] = useState<PIDiagram[]>([]);
//   const fs = useFirestore();
//   useEffect(() => {
//     const docSource =
//       projectID && scenarioID
//         ? fs.collection("Projects").doc(projectID).collection("Scenarios").doc(scenarioID)
//         : fs.collection("SimulationModels").doc(modelID);
//     if (projectID && scenarioID) {
//       const usub = docSource.collection("PIDiagrams").onSnapshot((snap) => {
//         const diagrams: PIDiagram[] = [];
//         snap.docs.forEach((doc) => {
//           diagrams.push({ ...doc.data(), id: doc.id } as PIDiagram);
//         });
//         setPIDiagrams(diagrams);
//       });
//       return () => {
//         usub();
//         setPIDiagrams([]);
//       };
//     }
//   }, [modelID, projectID, scenarioID, fs]);

//   return piDiagrams;
// };

export const useRawdataReports = (
  modelID: string,
  projectID?: string,
  scenarioID?: string
) => {
  const [rawDataReports, setRawDataReports] = useState<RawDataReport[]>([]);
  const fs = useFirestore();
  useEffect(() => {
    const docSource =
      projectID && scenarioID
        ? fs.collection("Projects").doc(projectID).collection("Scenarios").doc(scenarioID)
        : fs.collection("SimulationModels").doc(modelID);
    if (projectID && scenarioID) {
      const usub = docSource.collection("RawDataReports").onSnapshot((snap) => {
        const reports: RawDataReport[] = [];
        snap.docs.forEach((doc) => {
          reports.push({ ...doc.data(), id: doc.id } as RawDataReport);
        });
        setRawDataReports(reports);
      });
      return () => {
        usub();
        setRawDataReports([]);
      };
    }
  }, [modelID, projectID, scenarioID, fs]);

  return rawDataReports;
};
