import React, { useState, useMemo, useRef, useCallback } from "react";
import { useMaxHeightTransition } from "utils/hooks/useMaxHeightTransition";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";

// const appRoot = document.getElementById("root");

const SearchDropdownMultiple: React.FC<{
  tags: { id: string; displayName: string }[];
  selectedTagIDs: string[];
  selectTag: (tagID: string) => void;
}> = ({ tags, selectedTagIDs, selectTag }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { open, setOpen, style } = useMaxHeightTransition("0", "400px");

  const closeDropdown = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useClickOutsideEffect(ref, closeDropdown);

  // const selectedTags = useMemo(() => {
  //   return tags.filter((tag) => selectedTagIDs.some((id) => id === tag.id));
  // }, [tags, selectedTagIDs]);

  const matchedTags = useMemo(() => {
    setOpen(searchTerm.length > 0);
    return tags
      .filter((tag) => !selectedTagIDs.some((id) => id === tag.id)) //dont include selected tags
      .filter((tag) => tag.displayName.search(new RegExp(searchTerm, "i")) !== -1); //find the search word in displaynames
  }, [searchTerm, tags, selectedTagIDs, setOpen]);

  return (
    <div className="relative w-full text-xs" ref={ref}>
      <input
        ref={inputRef}
        className={`focus:outline-none w-full py-1 px-2 border rounded`}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {open && (
        <div
          style={style}
          className="absolute top-0 right-0 w-full mt-8 z-20 border border-gray-200 bg-white shadow-md rounded-lg text-xs overflow-auto"
        >
          {matchedTags.map((mt) => (
            <div
              key={mt.id}
              className="w-full px-4 border-b border-gray-100 hover:bg-gray-100 py-1 cursor-pointer"
              onClick={() => {
                selectTag(mt.id);
                inputRef.current?.focus();
                setSearchTerm("");
              }}
            >
              {mt.displayName}
            </div>
          ))}
          {(!matchedTags || matchedTags.length === 0) && (
            <div className="w-full text-center py-2">No tags found...</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchDropdownMultiple;

// const RootPortal: React.FC = ({ children }) => {
//   return appRoot && createPortal(children, appRoot);
// };
