import React, { useState, useMemo } from "react";
import {
  useFirebase,
  useDiscoveredModel,
  useDiscoveredModels,
  useFirestore,
} from "api/useFirebase";
import {
  SimulationModel,
  DiscoveredGitModel,
  GitModelVersion,
  DiscoveredComponent,
  ComponentType,
} from "model/datatypes";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import * as Sentry from "@sentry/browser";
import gtw from "gtw";
import Dropdown from "components/basic/Dropdown";
import Modal from "components/basic/Modal";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { Popup } from "components/basic/Popup";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import CheckmarkIcon from "components/basic/icons/CheckmarkIcon";
import HoverTooltip from "components/basic/HoverTooltip";
import Toast from "components/basic/Toast";
import LoadingOverlay from "components/basic/LoadingOverlay";
import InfoIcon from "components/basic/icons/InfoIcon";
import { compTypeFromDiscovered } from "utils/ComponentTypeHelpers";
import { fsFieldvalue } from "utils/firebase/helpers";

export const DiscoveredGitModels: React.FC<{
  simulationModel: SimulationModel;
  onAddcomponent: (comp: DiscoveredComponent, model: DiscoveredGitModel) => Promise<any>;
  addedComponentTypes: ComponentType[];
}> = ({ simulationModel, onAddcomponent, addedComponentTypes }) => {
  const [editedModels, setEditedModels] = useState(simulationModel.discovered_models);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatingModelsMerge, setUpdatingModelsMerge] = useState(false);
  const [addingModel, setAddingModel] = useState(false);
  const fb = useFirebase();
  const discoveredModels = useDiscoveredModels();
  const notAddedModels = useMemo(
    () => discoveredModels.filter((dm) => !editedModels.some((am) => dm.id === am.id)),
    [discoveredModels, editedModels]
  );

  const saveModelChanges = () => {
    //check if model changed, and some comp uses model:
    const changesPending = editedModels.some((updatedModel) => {
      const prevModel = simulationModel.discovered_models.find(
        (pm) => pm.id === updatedModel.id
      );
      return (
        prevModel?.selected_version &&
        updatedModel.selected_version?.id !== prevModel.selected_version.id &&
        addedComponentTypes.some((act) => act.modelID === updatedModel.id)
      );
    });
    if (changesPending) {
      //Open modal to update the components to the new possibly changed components
      //Modal will show changed and ask for confirmation!
      setUpdatingModelsMerge(true);
    } else {
      setLoading(true);
      //No components changes pending, just upgrade
      const updatedModels = convertToFirestoreFormat({
        discovered_models: editedModels,
      });
      fb.firestore()
        .collection("SimulationModels")
        .doc(simulationModel.id)
        .update({ ...updatedModels, version: fsFieldvalue.increment(1) })
        .then(() => {
          setIsChanged(false);
          setLoading(false);
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          setLoading(false);
        });
    }
  };

  const renderAddModelModal = () => {
    return (
      <Modal onClose={() => setAddingModel(false)}>
        <div
          className={`w-1/3 bg-white rounded shadow-lg border border-gray-300 z-50 px-4 py-4`}
        >
          <div className="font-bold mb-2 pl-2">Select Model</div>
          <div className="flex font-medium text-xs">
            <div className="w-1/3 pl-2 pr-2">Name</div>
            <div className="w-2/3 pl-2 pr-4">Path</div>
          </div>
          <div className="flex flex-col h-64 overflow-auto ">
            {notAddedModels.map((discoveredModel) => (
              <div
                key={discoveredModel.id}
                className="flex cursor-pointer py-2 text-xs hover:bg-gray-300 hover:shadow-xs rounded-full"
                onClick={() => {
                  setAddingModel(false);
                  setIsChanged(true);
                  setEditedModels([...editedModels, discoveredModel]);
                }}
              >
                <div className="w-1/3 pl-2 pr-2">{discoveredModel.modelName}</div>
                <div className="w-2/3 pl-2 pr-2 truncate">{discoveredModel.path}</div>
              </div>
            ))}
            {notAddedModels.length === 0 && (
              <div className="italic text-xs pl-2">
                {discoveredModels.length > 0
                  ? "All models have been added"
                  : "No discovered models available"}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className="mb-4">
      <div>Discovered models</div>
      <div className={`flex flex-wrap ${loading ? "opacity-50" : ""}`}>
        {editedModels.map((model, i) => {
          return (
            <ModelDiscoveredCard
              addedComponentTypes={addedComponentTypes.filter((ct) => ct.modelID === model.id)}
              key={model.id}
              model={model}
              onSelectVersion={(version) => {
                const updatedModel = { ...model, selected_version: version };
                setEditedModels(updateArrayVal(editedModels, updatedModel));
                setIsChanged(true);
              }}
              onRemove={() => {
                //only allow removing if no components are added!?
                setEditedModels(editedModels.filter((m) => m.id !== model.id));
                setIsChanged(true);
              }}
              addComp={(comp) => {
                //if not added already otherwise scroll to....
                return onAddcomponent(comp, model);
              }}
            />
          );
        })}
      </div>
      {isChanged && (
        <div className="py-2">
          <button className={`${gtw.smallBtn} mr-2`} onClick={() => saveModelChanges()}>
            Save Changes
          </button>
          <button
            className={gtw.smallBtn}
            onClick={() => {
              setEditedModels(simulationModel.discovered_models);
              setIsChanged(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
      <button
        className={`${gtw.smallBtn}`}
        onClick={() => {
          setAddingModel(true);
        }}
      >
        Add Model
      </button>
      {addingModel && renderAddModelModal()}
      {updatingModelsMerge && (
        <ModelVersionChanger
          simulationModel={simulationModel}
          onClose={() => setUpdatingModelsMerge(false)}
          onSaved={() => {
            setUpdatingModelsMerge(false);
            setIsChanged(false);
          }}
          editedModels={editedModels}
          addedComponentTypes={addedComponentTypes}
        />
      )}
    </div>
  );
};

const ModelDiscoveredCard: React.FC<{
  model: DiscoveredGitModel;
  onSelectVersion: (version: GitModelVersion) => void;
  onRemove: () => void;
  addComp: (comp: DiscoveredComponent) => Promise<any>;
  addedComponentTypes: ComponentType[];
}> = ({ model, onSelectVersion, onRemove, addComp, addedComponentTypes }) => {
  const { modelVersions } = useDiscoveredModel(model.id);
  const modelversionOptions = useMemo(() => {
    return modelVersions.map((mV) => ({
      display: `${mV.branch} ${mV.timestamp.format("HH:MM DD/MM")}`,
      id: mV.id,
      val: mV,
    }));
  }, [modelVersions]);
  const [loading, setLoading] = useState(false);

  const isOutdated = useMemo(() => {
    return (
      model.selected_version &&
      modelVersions.some((mV) => mV.timestamp.isAfter(model.selected_version?.timestamp))
    );
  }, [modelVersions, model.selected_version]);

  const renderSelectedModel = (selected_version: GitModelVersion) => {
    return (
      <>
        <div className="flex mt-2">
          <div className="text-xs font-medium w-32">Branch</div>
          <div className="text-xs">{selected_version.branch}</div>
        </div>
        <div className="flex mt-2">
          <div className="text-xs font-medium w-32 flex-none">Commit Message</div>
          <div className="text-xs">{selected_version.commit_message || "No message"}</div>
        </div>
      </>
    );
  };

  const renderOption = () => {
    return (
      <Popup
        pos={"right"}
        useHover
        mt={15}
        className="text-xs"
        content={(closeMe) => {
          return (
            <>
              <button
                className={`${gtw.popupBtn}`}
                onClick={() => {
                  onRemove();
                  closeMe();
                }}
              >
                Remove model
              </button>
            </>
          );
        }}
      >
        <DotDotDotIcon />
      </Popup>
    );
  };

  const renderComp = (comp: DiscoveredComponent) => {
    const addedComp = addedComponentTypes.find((cType) => comp.name === cType.name);
    const isSelected = !!addedComp;
    return (
      <div
        key={comp.name}
        onClick={() => {
          if (!isSelected && !loading) {
            setLoading(true);
            addComp(comp)
              .then(() => {
                setLoading(false);
                Toast(`Added ${comp.displayName} to system.`, { icon: "success" });
              })
              .catch((e) => {
                setLoading(false);
                Toast("error adding compoent", { icon: "error" });
              });
          }
        }}
        className="w-full flex mb-2 px-4 py-3 border border-gray-300 shadow-md rounded-lg text-xs cursor-pointer bg-white"
      >
        <div className="w-40 font-medium truncate">{comp.displayName}</div>
        <div className="flex-grow truncate italic">
          {comp.parameters.length} parameters, {comp.inputVariables.length} inputs
        </div>
        {isSelected && (
          <div
            onClick={() => {
              if (addedComp) {
                const element = document.getElementById(addedComp.id);
                element && element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            <HoverTooltip
              className="border border-green-400 text-green-400 rounded-full"
              mt={-35}
              text="Added to system, click to viev"
            >
              <CheckmarkIcon className="w-4 h-4" />
            </HoverTooltip>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mb-4 w-full xl:w-1/2 xl:pr-4 xl:pb-4">
      <div className="bg-white border border-gray-200 shadow-md w-full rounded relative">
        <div className="px-4 py-2 ">
          <div className="absolute top-0 right-0 mt-2 mr-2">{renderOption()}</div>
          <div className="text-md font-medium">{model.modelName}</div>
          <div className="flex mt-2">
            <div className="text-xs font-medium w-32 flex-none">Path</div>
            <div className="text-xs truncate">{model.path}</div>
          </div>
          <div className="flex mt-2 items-center">
            <div className="text-xs font-medium w-32 flex-none flex">
              <span className="mr-2">Version</span>
              {isOutdated && (
                <HoverTooltip mt={-25} text="Never version exists">
                  <InfoIcon className="w-4 h-4" />
                </HoverTooltip>
              )}
            </div>
            <Dropdown
              className="text-xs flex-grow"
              placeholder="Select version"
              options={modelversionOptions}
              selectedID={model.selected_version?.id}
              onSelect={(option) => {
                onSelectVersion(option.val);
              }}
            />
          </div>
          {model.selected_version && renderSelectedModel(model.selected_version)}
        </div>
        <div className="mt-2 border-b border-gray-200 px-4 font-medium">Components</div>
        <div
          className="flex flex-col py-2 px-4 bg-gray-100 overflow-auto scrollbar-light relative"
          style={{ maxHeight: "16rem" }}
        >
          {model.selected_version && model.selected_version.components.map(renderComp)}
          {!model.selected_version && (
            <div className="italic text-xs">Select version to view components</div>
          )}
          {loading && <LoadingOverlay />}
        </div>
      </div>
    </div>
  );
};

const ModelVersionChanger: React.FC<{
  simulationModel: SimulationModel;
  onClose: () => void;
  onSaved: () => void;
  editedModels: DiscoveredGitModel[];
  addedComponentTypes: ComponentType[];
}> = ({ onClose, editedModels, addedComponentTypes, simulationModel, onSaved }) => {
  const fs = useFirestore();
  const [loading, setLoading] = useState(false);

  const updatedCompTypes = useMemo(() => {
    const updated: ComponentType[] = [];
    const removed: ComponentType[] = [];
    addedComponentTypes.forEach((addedCompType) => {
      const newDiscoveredComp = editedModels
        .find((m) => m.id === addedCompType.modelID)
        ?.selected_version?.components.find((c) => c.item_class === addedCompType.item_class);

      if (newDiscoveredComp) {
        const updatedCompType = {
          ...compTypeFromDiscovered(
            newDiscoveredComp,
            addedCompType.modelID || "",
            addedCompType.order
          ),
          id: addedCompType.id,
        };
        updated.push(updatedCompType);
      } else {
        removed.push(addedCompType);
      }
    });
    return { updated, removed };
  }, [addedComponentTypes, editedModels]);

  const mergeAndSave = () => {
    setLoading(true);
    const batch = fs.batch();
    const modelDoc = fs.collection("SimulationModels").doc(simulationModel.id);

    //update the components:
    updatedCompTypes.updated.forEach((updatedComp) => {
      const compDoc = modelDoc.collection("Components").doc(updatedComp.id);
      batch.update(compDoc, convertToFirestoreFormat(updatedComp));
    });

    updatedCompTypes.removed.forEach((removedComp) => {
      const compDoc = modelDoc.collection("Components").doc(removedComp.id);
      batch.delete(compDoc);
    });

    //Upgrade the model itself:
    const updatedModels = convertToFirestoreFormat({
      discovered_models: editedModels,
    });
    batch.update(modelDoc, { ...updatedModels, version: fsFieldvalue.increment(1) });

    batch
      .commit()
      .then(() => {
        setLoading(false);
        onSaved();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Modal onClose={onClose}>
      <div className="z-50 px-4 py-2 bg-white shadow-lg rounded-lg w-1/2 relative">
        {loading && <LoadingOverlay />}
        <div className="font-medium">Merge model changes</div>
        <div className="italic text-xs">
          Model version has been changed, all components will be updated to different version.
        </div>
        {updatedCompTypes.updated.length > 0 && (
          <>
            <div className="text-xs">The following components might change:</div>
            <div className="flex flex-wrap text-xs">
              {updatedCompTypes.updated.map((compType) => {
                return (
                  <div
                    key={compType.id}
                    className="px-4 py-2 border border-gray-200 rounded shadow mr-2 mb-2"
                  >
                    <div className="font-medium">{compType.displayName}</div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {updatedCompTypes.removed.length > 0 && (
          <>
            <div className="text-xs">The following components no longer exists:</div>
            <div className="flex flex-wrap text-xs">
              {updatedCompTypes.removed.map((compType) => {
                return (
                  <div
                    key={compType.id}
                    className="px-4 py-2 border border-gray-200 rounded shadow mr-2 mb-2"
                  >
                    <div className="font-medium">{compType.displayName}</div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <div className="flex">
          <button
            className={`${gtw.smallBtn} flex-1 mr-2`}
            onClick={() => {
              !loading && mergeAndSave();
            }}
          >
            Update now
          </button>
          <button className={`${gtw.smallBtn} flex-1 ml-2`} onClick={() => onClose()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
