import React, { useContext, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { store } from "store";
import { useProjects, useFirebase } from "api/useFirebase";
import InputDataIcon from "components/basic/icons/InputDataIcon";
import { useUserRole } from "api/useAuth";
import { User } from "model/datatypes";
import { Popup } from "components/basic/Popup";
import gtw from "gtw";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import SimIcon from "components/basic/icons/SimIcon";
import CodeIcon from "components/basic/icons/CodeIcon";
const isBeta = process.env.REACT_APP_BETA === "true";

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(store);
  const { projectID, projectName, user, teamIds } = state;
  const { hasDeveloperAccess, hasOwnerAccess, hasSimulatorAccess } = useUserRole();

  const { myProjects } = useProjects(hasDeveloperAccess, teamIds, user?.fbUser.uid);
  const setProject = (projectID: string | null, projectName: string | null) => {
    //save selected project locally...
    if (projectID && projectName) {
      localStorage.setItem("selectedProjectID", projectID);
      localStorage.setItem("selectedProjectName", projectName);
    } else {
      localStorage.removeItem("selectedProjectID");
      localStorage.removeItem("selectedProjectName");
    }

    dispatch({
      type: "SET_CURRENT_PROJECT",
      payload: { projectID, projectName },
    });
  };

  //when a project not in my projects, is active:
  const otherActiveProject = useMemo(() => {
    return !myProjects.some((p) => p.id === projectID);
  }, [projectID, myProjects]);

  const location = useLocation();
  const currentPage = useMemo(() => location.pathname.split("/")[1], [location]);

  const renderProject = (
    projectID: string,
    projectName: string,
    isActive: boolean,
    description?: string
  ) => {
    return (
      <div
        key={projectID}
        className={`text-gray-700 ${
          isActive ? " border-l-4 border-blue-600 bg-gray-100 py-1" : "opacity-75"
        }`}
      >
        <button
          className={`px-4 flex flex-col ${
            isActive ? "pt-1 pb-2" : "hover:bg-gray-200 py-2"
          } ${tw.sidebarBtn}`}
          onClick={() => {
            setProject(projectID, projectName);
            if ((currentPage !== "simulations" && currentPage !== "input") || isActive)
              history.push("/overview");
            else if (currentPage === "simulations") history.push("/simulations");
          }}
        >
          <span className="font-bold text-sm">{projectName}</span>
          <span className="text-xs truncate w-full">
            {description ? description : "no project description"}
          </span>
        </button>
        {isActive && hasSimulatorAccess && (
          <div className="px-2 text-xs">
            <button
              onClick={() => history.push("/input")}
              className={`${
                tw.sidebarBtn
              }  font-medium px-2 py-1 mb-1 flex border items-center rounded-lg ${
                currentPage === "input"
                  ? tw.activeSideBtn
                  : "text-gray-700 opacity-75 border-gray-100 hover:bg-white"
              }`}
            >
              <InputDataIcon />
              <span className="pl-2">Input</span>
            </button>
            <button
              className={`${
                tw.sidebarBtn
              }  font-medium px-2 py-1 mb-1 flex border items-center rounded-lg  ${
                currentPage === "simulations"
                  ? tw.activeSideBtn
                  : "text-gray-700 opacity-75 border-gray-100 hover:bg-white"
              }`}
              onClick={() => history.push("/simulations")}
            >
              <SimIcon />
              <span className="pl-2">Simulation</span>
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-56 position fixed top-0 left-0 bg-white h-full border-r border-gray-300 flex flex-col text-sm overflow-hidden">
      <div className="mt-4 w-full text-gray-700 font-bold text-md px-4">
        Simulation Project Manager
      </div>
      {isBeta && <div className="px-4 text-xs text-gray-600 mb-2">Beta</div>}
      <div className="border-b border-t border-gray-200 py-2">
        <div className="px-2">
          <Link
            to={""}
            onClick={() => setProject(null, null)}
            className={`${tw.topSideBtn} mb-1 ${
              currentPage === "" ? tw.activeSideBtn : `${tw.inactiveSideBtn} border-white`
            }`}
          >
            <div className="mr-3 h-6 w-6">{homeIcon}</div>
            <div className={`font-medium`}>Overview</div>
          </Link>
          {hasDeveloperAccess && (
            <Link
              to={"/systems"}
              className={`${tw.topSideBtn} mb-1 ${
                currentPage === "systems"
                  ? tw.activeSideBtn
                  : `${tw.inactiveSideBtn} border-white`
              }`}
            >
              <div style={{ padding: "2px" }} className="mr-3 h-6 w-6">
                {componentIcon}
              </div>
              <div className={`font-medium`}>Systems</div>
            </Link>
          )}
          {hasDeveloperAccess && (
            <Link
              to={"/files"}
              className={`${tw.topSideBtn} ${
                currentPage === "files"
                  ? tw.activeSideBtn
                  : `${tw.inactiveSideBtn} border-white`
              }`}
            >
              <div style={{ padding: "1px" }} className="mr-3 h-6 w-6">
                {fileIcon}
              </div>
              <div className={`font-medium`}>Files</div>
            </Link>
          )}
          {hasDeveloperAccess && (
            <Link
              to={"/api"}
              className={`${tw.topSideBtn} ${
                currentPage === "api" ? tw.activeSideBtn : `${tw.inactiveSideBtn} border-white`
              }`}
            >
              <div style={{ padding: "1px" }} className="mr-3 h-6 w-6">
                <CodeIcon />
              </div>
              <div className={`font-medium`}>API</div>
            </Link>
          )}
          {hasOwnerAccess && (
            <Link
              to={"/administration"}
              className={`${tw.topSideBtn} ${
                currentPage === "administration"
                  ? tw.activeSideBtn
                  : `${tw.inactiveSideBtn} border-white`
              }`}
            >
              <div style={{ padding: "1px" }} className="mr-3 h-6 w-6">
                {UserAdministration}
              </div>
              <div className={`font-medium`}>User administration</div>
            </Link>
          )}
        </div>
      </div>
      {otherActiveProject && projectID && projectName && (
        <div className="border-b border-gray-200">
          {renderProject(projectID, projectName, true, "")}
        </div>
      )}
      <div className="flex-grow flex flex-col overflow-hidden py-2">
        <div className="text-gray-700 text-xs px-4 mb-2 font-bold">My projects</div>
        <div className="flex-grow overflow-y-auto overflow-x-hidden scrollbar-light">
          {myProjects.map((project) => {
            const isActive = projectID === project.id;
            return renderProject(
              project.id,
              project.projectName,
              isActive,
              project.description
            );
          })}
        </div>
      </div>

      <div>
        <UserInfo user={user} />
      </div>
    </div>
  );
};

export default Sidebar;

const UserInfo: React.FC<{ user: User | null }> = ({ user }) => {
  const fb = useFirebase();
  return (
    <div className="flex items-center py-2 px-3 border-t border-gray-200">
      <div className="h-8 w-8 flex items-center justify-center uppercase bg-green-numerous font-bold text-white rounded-full">
        {user?.firstName && user.lastName
          ? user?.firstName.slice(0, 1) + user?.lastName.slice(0, 1)
          : "N"}
      </div>
      <div className="ml-4 flex-grow">{user?.fullName || "Logged in"}</div>
      <Popup
        mt={-30}
        content={(closeMe) => {
          return (
            <button
              className={`${gtw.popupBtn} text-xs`}
              name="logoutBtn"
              onClick={() => fb.auth().signOut()}
            >
              log out
            </button>
          );
        }}
      >
        <DotDotDotIcon className={"cursor-pointer"} />
      </Popup>
    </div>
  );
};

const homeIcon = (
  <svg
    className="w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      fill="currentColor"
      d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"
    />
  </svg>
);

const componentIcon = (
  <svg
    className="w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      fill="currentColor"
      d="M5 2c0-.55-.45-1-1-1s-1 .45-1 1v4H2c-.55 0-1 .45-1 1v5h6V7c0-.55-.45-1-1-1H5V2zm4 14c0 1.3.84 2.4 2 2.82V22c0 .55.45 1 1 1s1-.45 1-1v-3.18c1.16-.41 2-1.51 2-2.82v-2H9v2zm-8 0c0 1.3.84 2.4 2 2.82V22c0 .55.45 1 1 1s1-.45 1-1v-3.18C6.16 18.4 7 17.3 7 16v-2H1v2zM21 6V2c0-.55-.45-1-1-1s-1 .45-1 1v4h-1c-.55 0-1 .45-1 1v5h6V7c0-.55-.45-1-1-1h-1zm-8-4c0-.55-.45-1-1-1s-1 .45-1 1v4h-1c-.55 0-1 .45-1 1v5h6V7c0-.55-.45-1-1-1h-1V2zm4 14c0 1.3.84 2.4 2 2.82V22c0 .55.45 1 1 1s1-.45 1-1v-3.18c1.16-.41 2-1.51 2-2.82v-2h-6v2z"
    />
  </svg>
);

const UserAdministration = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path
      fill={"currentColor"}
      d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7a2.5 2.5 0 000 5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z"
    ></path>
  </svg>
);

const fileIcon = (
  <svg
    className="w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path
      fill={"currentColor"}
      d="M19.35 10.04A7.49 7.49 0 0012 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 000 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z"
    ></path>
  </svg>
);

const tw = {
  topSideBtn: "flex items-center text-xs rounded-lg hover:bg-gray-200 px-2 py-1 border",
  sidebarBtn: "w-full text-left focus:outline-none",
  activeSideBtn: "text-blue-600 bg-white border-gray-200",
  inactiveSideBtn: "text-gray-800 opacity-75",
};
