import React, { useState } from "react";
import { useFirebase } from "api/useFirebase";
import Modal from "components/basic/Modal";
import { Project } from "model/datatypes";
import MetadataSetup from "components/simulations/newSimulation/simSetup/MetadataSetup";

const EditProject: React.FC<{ onFinish: () => void; project: Project }> = ({
  onFinish,
  project,
}) => {
  const [editedProject, setEditedProject] = useState<Project>(project);
  const fb = useFirebase();
  const [loading, setLoading] = useState(false);
  const updateProject = () => {
    if (editedProject) {
      setLoading(true);
      const { created, id, ...updateableFields } = editedProject;
      fb.firestore()
        .collection("Projects")
        .doc(project.id)
        .update(updateableFields)
        .then(() => {
          setLoading(false);
          onFinish();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  return (
    <Modal onClose={() => onFinish()}>
      <div className={`${tw.card} z-30`}>
        <div className="font-medium">Edit project</div>
        <label className="font-bold text-xs">Project name</label>
        <input
          type="text"
          className={`${tw.input} mb-2`}
          value={editedProject.projectName}
          onChange={(e) => setEditedProject({ ...editedProject, projectName: e.target.value })}
        />
        <label className="font-bold text-xs">Project description</label>
        <textarea
          className={`${tw.input} h-20 mb-2`}
          value={editedProject.description}
          onChange={(e) => setEditedProject({ ...editedProject, description: e.target.value })}
        />
        <MetadataSetup
          meta_data={project.meta_data}
          updateMetadata={(updated) => {
            setEditedProject({ ...editedProject, meta_data: updated });
          }}
        />
        <div className="flex mt-4">
          <button
            className={`flex-1 ${tw.saveBtn} mr-2 ${loading ? "opacity-50" : ""}`}
            onClick={() => {
              !loading && updateProject();
            }}
          >
            Save
          </button>
          <button
            className={`flex-1 ${tw.saveBtn} ml-2 ${loading ? "opacity-50" : ""}`}
            onClick={() => !loading && onFinish()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditProject;

const tw = {
  card: "border border-gray-300 rounded p-4 bg-white shadow",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
};
