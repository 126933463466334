import React, { useEffect, useMemo, useState } from "react";
import gtw from "gtw";
import { PIDiagram, RawDataReport } from "model/datatypes";
import { useFirestore, useRawdataReports, useSimulationModels } from "api/useFirebase";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import getUUID from "utils/jsUtils/getUUID";
import PIDSetup from "components/PID/PIDEditor";
import PIDViewer from "components/PID/PIDViewer";
import RawLineGraph from "./RawLineGraph";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Toast from "components/basic/Toast";
import Modal from "components/basic/Modal";
import Dropdown from "components/basic/Dropdown";
import { fsFieldvalue } from "utils/firebase/helpers";
import useWebsocketData from "api/useWebsocketData";
import { useGlobalState } from "store";
import { useUserRole } from "api/useAuth";

interface Props {
  availableFields: string[];
  projectID: string;
  scenarioID: string;
  modelID: string;
  result_resolution: number; //seconds between results output....
  usePipelines: boolean; //decide which endpoint to load data from......
}

const RawDataViewer: React.FC<Props> = ({
  availableFields,
  projectID,
  scenarioID,
  modelID,
  result_resolution,
  usePipelines,
}) => {
  const fs = useFirestore();

  const [addingReport, setAddingReport] = useState(false);
  const reports = useRawdataReports(modelID, projectID, scenarioID);
  const [selectedReportID, setSelectedReportID] = useState<string | null>(null);
  useEffect(() => {
    if (reports.length > 0 && selectedReportID === null) setSelectedReportID(reports[0].id);
  }, [reports, selectedReportID]);

  const selectedReport = useMemo(
    () => reports.find((report) => report.id === selectedReportID),
    [selectedReportID, reports]
  );

  const [newReportName, setNewReportName] = useState("My report");
  const [addReportLoading, setAddReportLoading] = useState(false);
  const addNewReport = () => {
    if (!addReportLoading) {
      const newReportDoc = fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .collection("RawDataReports")
        .doc();
      const report: RawDataReport = {
        id: newReportDoc.id,
        name: newReportName,
        globalVersion: 0,
        localVersion: 1,
        lineGraphs: [],
        piDiagrams: [],
      };
      setAddReportLoading(true);
      newReportDoc
        .set(report)
        .then(() => {
          setAddReportLoading(false);
          setAddingReport(false);
          setSelectedReportID(report.id);
        })
        .catch((error) => {
          setAddReportLoading(false);
          console.log(error);
        });
    }
  };

  const renderAddNewReport = () => {
    return (
      <Modal
        onClose={() => {
          setAddingReport(false);
        }}
      >
        <div
          className={` z-40 bg-white border border-gray-200 rounded shadow-xl w-1/2 px-8 py-4`}
        >
          <div className={`font-medium mb-4`}>Add new report</div>
          <div className="text-xs font-medium">Report name</div>
          <input
            className={`${gtw.input} w-full mb-4`}
            value={newReportName}
            onChange={(e) => {
              setNewReportName(e.target.value);
            }}
          />
          <div className="flex">
            <button
              className={`${gtw.smallBtn} mr-2 flex-1`}
              onClick={() => {
                addNewReport();
              }}
            >
              Save
            </button>
            <button
              className={`${gtw.smallBtn} ml-2 flex-1`}
              onClick={() => setAddingReport(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const reportOptions = useMemo(
    () => reports.map((report) => ({ id: report.id, display: report.name, val: report })),
    [reports]
  );

  const renderReportSelecter = () => {
    return (
      <div className="flex pb-2">
        <Dropdown
          className="text-xs"
          options={reportOptions}
          onSelect={(option) => {
            setSelectedReportID(option.id);
          }}
          selectedID={selectedReportID || undefined}
          placeholder="No report selected"
          onAddNew={() => {
            setAddingReport(true);
          }}
        />
      </div>
    );
  };

  return (
    <div className="px-4 py-4 flex flex-col relative">
      <div className="flex justify-between mb-2">
        <div className="font-medium">View simulation outputs</div>
        <div className="flex">{renderReportSelecter()}</div>
      </div>
      <div className="flex-grow overflow-auto">
        {selectedReport && (
          <RawDataReportView
            selectedReport={selectedReport}
            availableFields={availableFields}
            modelID={modelID}
            projectID={projectID}
            result_resolution={result_resolution}
            scenarioID={scenarioID}
            usePipelines={usePipelines}
          />
        )}

        {reports.length === 0 && <div className="mt-4 italic">No reports yet</div>}
      </div>
      {addingReport && renderAddNewReport()}
    </div>
  );
};

export default RawDataViewer;

const getNewSelectedOutputField = () => {
  return { id: getUUID(), fields: [] } as { id: string; fields: string[] };
};

const RawDataReportView: React.FC<Props & { selectedReport: RawDataReport }> = ({
  selectedReport,
  availableFields,
  projectID,
  modelID,
  result_resolution,
  scenarioID,
  usePipelines,
}) => {
  const fs = useFirestore();
  const [viewingPID, setViewingPID] = useState<null | PIDiagram>(null);
  const [pidEditiorOpen, setPIDEditorOpen] = useState(false);
  const [savingReportToSystem, setSavingReportToSystem] = useState(false);
  const [editingDiagram, setEditingDiagram] = useState<PIDiagram>();
  const { hasDeveloperAccess } = useUserRole();

  const [dataFields, setDataFields] = useState<string[]>([]);
  useEffect(() => {
    console.log("UPDATE FIELDS");
    let fields: string[] = [];
    selectedReport.piDiagrams.forEach((diagram) => {
      diagram.tags.forEach((tag) => {
        if (tag.sourceID) fields.push(tag.sourceID);
      });
    });
    selectedReport.lineGraphs.forEach((lineGraph) => {
      fields = [...fields, ...lineGraph.fields];
    });
    setDataFields([...new Set(fields)]); //make sure fields are unique
  }, [selectedReport]);

  const { data, loadingData, time } = useWebsocketData(projectID, scenarioID, dataFields);

  const updateGraph = (updatedGraph: { id: string; fields: string[] }) => {
    if (selectedReport)
      fs.collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .collection("RawDataReports")
        .doc(selectedReport.id)
        .update({
          lineGraphs: updateArrayVal(selectedReport.lineGraphs, updatedGraph),
          localVersion: fsFieldvalue.increment(1),
        })
        .then(() => {})
        .catch((e) => {
          console.log(e);
          Toast("Error updaing graph...");
        });
  };

  const removeOutputFieldGraph = async (fieldsID: string) => {
    if (selectedReport)
      await fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .collection("RawDataReports")
        .doc(selectedReport.id)
        .update({
          lineGraphs: selectedReport.lineGraphs.filter((fields) => fields.id !== fieldsID),
          localVersion: fsFieldvalue.increment(1),
        })
        .then(() => {})
        .catch((e) => {
          console.log(e);
          Toast("Error removing graph...");
        });
  };

  const savePID = (pid: PIDiagram) => {
    return fs
      .collection("Projects")
      .doc(projectID)
      .collection("Scenarios")
      .doc(scenarioID)
      .collection("RawDataReports")
      .doc(selectedReport!.id)
      .update({
        piDiagrams: updateArrayVal(selectedReport!.piDiagrams, pid),
        localVersion: fsFieldvalue.increment(1),
      })
      .then(() => {
        // setDataUpToData(false);
      })
      .catch((e) => {
        console.log(e);
        Toast("Error saving PID...");
      });
  };

  const allTags = useMemo(
    () => availableFields.map((field) => ({ id: field, displayName: field })),
    [availableFields]
  );

  const renderLinegraphs = (lineGraphs: RawDataReport["lineGraphs"]) => {
    return (
      <>
        {lineGraphs?.map((graph) => {
          return (
            <RawLineGraph
              data={data}
              t={time}
              key={graph.id}
              lineGraph={graph}
              allTags={allTags}
              setTitle={(newTitle: string) => {
                // updateGraphTitle(newTitle);
              }}
              onUpdate={updateGraph}
              onRemove={() => {
                return removeOutputFieldGraph(graph.id);
              }}
            />
          );
        })}
      </>
    );
  };

  const renderDiagramSaveOptions = () => {
    const canSave =
      !selectedReport.globalVersion ||
      selectedReport.localVersion > selectedReport.globalVersion;

    if (selectedReport && hasDeveloperAccess && canSave) {
      return (
        <button
          className={`${gtw.smallBtn} mr-2`}
          onClick={() => {
            setSavingReportToSystem(true);
          }}
        >
          {!selectedReport.globalVersion ? "Save to system" : "Save updates to system"}
        </button>
      );
    }
    return null;
  };

  const renderPIDDiagrams = (PIDDiagrams: PIDiagram[]) => {
    return (
      <>
        {PIDDiagrams.map((diagram) => {
          return (
            <div
              key={diagram.id}
              style={{ backgroundColor: "#F7FAFC" }}
              className={`w-full shadow flex items-center px-4 text-xs py-2 rounded border border-gray-200 my-2`}
            >
              <div className="font-medium flex-grow">{diagram.name}</div>
              <button
                className={`${gtw.smallBtn} mr-2`}
                onClick={() => {
                  setViewingPID(diagram);
                }}
              >
                View
              </button>
              <button
                className={gtw.smallBtn}
                onClick={() => {
                  setEditingDiagram(diagram);
                  setPIDEditorOpen(true);
                }}
              >
                Edit
              </button>
            </div>
          );
        })}
        {viewingPID && (
          <PIDViewer
            usePipelines={usePipelines}
            projectID={projectID}
            scenarioID={scenarioID}
            result_resolution={result_resolution}
            onFinish={() => setViewingPID(null)}
            diagram={viewingPID}
            data={data}
            t={time}
          />
        )}
        {pidEditiorOpen && (
          <PIDSetup
            savePID={savePID}
            onFinish={() => {
              setEditingDiagram(undefined);
              setPIDEditorOpen(false);
            }}
            availableFields={availableFields}
            modelID={modelID}
            scenarioID={scenarioID}
            projectID={projectID}
            startDiagram={editingDiagram}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div className="flex-grow overflow-auto">
        <div className="flex justify-end">
          {selectedReport && (
            <div className="flex mt-2">
              <button
                className={`${gtw.smallBtn}`}
                onClick={() => updateGraph(getNewSelectedOutputField())}
              >
                Add graph
              </button>
              <button
                className={`${gtw.smallBtn} mx-2`}
                onClick={() => setPIDEditorOpen(true)}
              >
                New PID
              </button>
              {renderDiagramSaveOptions()}
            </div>
          )}
        </div>

        {selectedReport && renderLinegraphs(selectedReport.lineGraphs)}
        {selectedReport && renderPIDDiagrams(selectedReport.piDiagrams)}
        {savingReportToSystem && (
          <ReportSaver
            onFinish={() => setSavingReportToSystem(false)}
            scenarioID={scenarioID}
            projectID={projectID}
            modelID={modelID}
            selectedReport={selectedReport}
          />
        )}
        {loadingData && <LoadingOverlay />}
      </div>
    </>
  );
};

const ReportSaver: React.FC<{
  onFinish: () => void;
  scenarioID: string;
  projectID: string;
  modelID: string;
  selectedReport: RawDataReport;
}> = ({ onFinish, scenarioID, projectID, modelID, selectedReport }) => {
  const { user, teamIds } = useGlobalState();
  const { hasDeveloperAccess } = useUserRole();

  const [selectedModel, setSelectedModel] = useState(modelID);
  const { allModels } = useSimulationModels(hasDeveloperAccess, teamIds, user?.fbUser.uid);
  const fs = useFirestore();

  const [savingToModel, setSavingReport] = useState(false);

  const saveReportToSystem = () => {
    if (!savingToModel) {
      setSavingReport(true);
      const batch = fs.batch();
      const modelDoc = fs.collection("SimulationModels").doc(selectedModel);
      const modelReportDoc = modelDoc.collection("RawDataReports").doc(selectedReport.id);
      const localReportDoc = fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenarioID)
        .collection("RawDataReports")
        .doc(selectedReport.id);
      batch.set(modelReportDoc, {
        ...selectedReport,
        globalVersion: selectedReport.localVersion,
      }); //save to model...
      batch.update(modelDoc, { version: fsFieldvalue.increment(1) }); // save that theres a new model version...

      if (selectedModel === modelID)
        batch.update(localReportDoc, { globalVersion: selectedReport.localVersion }); //update report local version

      batch
        .commit()
        .then(() => {
          setSavingReport(false);
          onFinish();
          Toast("Successfully saved to system", { icon: "success" });
        })
        .catch((e) => {
          console.log(e);
          setSavingReport(false);
          Toast("Error saving to system", { icon: "error" });
        });
    }
  };

  return (
    <Modal
      onClose={() => {
        onFinish();
      }}
    >
      <div className="modal-content relative z-30 w-1/2">
        <div className="font-medium mb-2">Save report</div>
        <Dropdown
          className="text-xs mb-4"
          selectedID={selectedModel}
          options={allModels.map((model) => ({ id: model.id, display: model.displayName }))}
          onSelect={(option) => {
            setSelectedModel(option.id);
          }}
        />
        <div className="flex">
          <button
            className="button-small flex-1 mr-1"
            onClick={() => {
              saveReportToSystem();
            }}
          >
            Save
          </button>
          <button className="button-small flex-1 ml-1" onClick={() => onFinish()}>
            Cancel
          </button>
        </div>
        {savingToModel && <LoadingOverlay />}
      </div>
    </Modal>
  );
};
