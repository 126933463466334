import React, { useContext, useMemo, useState } from "react";
import { CMHarvester, CMTag, FullCMHarvester } from "model/datatypes";
import Dropdown from "components/basic/Dropdown";
import { useCMHarvester, useCMHarvesters, useFirestore } from "api/useFirebase";

import Modal from "components/basic/Modal";
import gtw from "gtw";
import Toast from "components/basic/Toast";
import Axios from "axios";
import { store } from "store";
import * as Sentry from "@sentry/browser";
import LoadingOverlay from "components/basic/LoadingOverlay";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";

const COMHarvesterSelecter: React.FC<{
  selectedHarvesterID?: string;
  onSelect: (COMHarvester: CMHarvester) => void;
}> = ({ selectedHarvesterID, onSelect }) => {
  const [addingCOMHarvester, setAddingCOMHarvester] = useState(false);
  const CMHarvesters = useCMHarvesters();
  const harvesterOptions = useMemo(
    () =>
      CMHarvesters.map((harvester) => ({
        id: harvester.id,
        display: harvester.name,
        val: harvester,
      })),
    [CMHarvesters]
  );

  const [displayedHarvester, setDisplayedHarvester] = useState<null | CMHarvester>(null);

  return (
    <>
      {addingCOMHarvester && (
        <AddNewHarvester
          onFinish={(newHarvester) => {
            setAddingCOMHarvester(false);
            newHarvester && onSelect(newHarvester);
          }}
        />
      )}
      {displayedHarvester && (
        <DisplayCMHarvesterInfo
          harvester={displayedHarvester}
          onClose={() => {
            setDisplayedHarvester(null);
          }}
        />
      )}
      <Dropdown
        selectedID={selectedHarvesterID}
        options={harvesterOptions}
        onSelect={(option) => onSelect(option.val)}
        placeholder="Select harvester"
        displayInfoModal={(option) => {
          setDisplayedHarvester(option.val);
        }}
        onAddNew={() => {
          setAddingCOMHarvester(true);
        }}
      />
    </>
  );
};

const AddNewHarvester: React.FC<{ onFinish: (newHarvester?: CMHarvester) => void }> = ({
  onFinish,
}) => {
  const { state } = useContext(store);
  const { projectID } = state;

  const [newHarvester, setNewHarvester] = useState<FullCMHarvester>(initialCMHarvester);
  const [cmAPILoaded, setCmAPILoaded] = useState(false);
  const [CMToken, setCMToken] = useState(""); //Token will be stored seperately on a read only doc!
  const [loadingCMApi, setLoadingCMApi] = useState(false);

  const [loadingSaveHarvester, setLoadingSaveHarvester] = useState(false);
  const fs = useFirestore();

  const SaveHarvester = async () => {
    if (projectID) {
      //todo: add checks all ok...
      try {
        const tags = newHarvester.tags;

        setLoadingSaveHarvester(true);
        const batch = fs.batch();
        const harvesterDoc = fs
          .collection("Projects")
          .doc(projectID)
          .collection("CMHarvesters")
          .doc();
        const tokenDoc = harvesterDoc.collection("token").doc();
        batch.set(tokenDoc, { token: CMToken }); //Save token to secret doc...
        //split tags if too many to save in one document....
        const tagDocIDs: string[] = [];
        const chunks = Math.ceil(tags.length / 5000);
        const splitTags = new Array(chunks)
          .fill(0)
          .map((v, i) => tags.slice(5000 * i, 5000 * (i + 1)));
        splitTags.forEach((tags) => {
          const tagsDoc = harvesterDoc.collection("CMTags").doc();
          batch.set(tagsDoc, { tags });
          tagDocIDs.push(tagsDoc.id);
        });

        const finalHarvester: CMHarvester = {
          ...newHarvester,
          id: harvesterDoc.id,
          tokenID: tokenDoc.id,
          tagDocs: tagDocIDs,
        };
        //@ts-ignore
        delete finalHarvester.tags; //only save tags in subcollections
        batch.set(harvesterDoc, finalHarvester); //Save harvester to FS.....
        await batch.commit();
        setLoadingSaveHarvester(false);
        onFinish(finalHarvester);
        setNewHarvester(initialCMHarvester);
      } catch (error) {
        Sentry.captureException(error);
        setLoadingSaveHarvester(false);
      }
    }
  };

  const LoadProject = async () => {
    if (!loadingCMApi) {
      try {
        setLoadingCMApi(true);
        const res = await Axios.get<{ id: string; name: string }>(
          `${newHarvester.url}${newHarvester.com_projectID}/project`,
          {
            headers: { Authorization: `Bearer ${CMToken}` },
          }
        );
        const resTags = await Axios.get<CMTag[]>(
          `${newHarvester.url}${newHarvester.com_projectID}/tag/list`,
          {
            headers: { Authorization: `Bearer ${CMToken}` },
          }
        );
        const projectInfo = res.data;
        const tags = resTags.data;
        setNewHarvester({ ...newHarvester, tags, name: projectInfo.name });
        setCmAPILoaded(true);

        setLoadingCMApi(false);
      } catch (error) {
        Toast("Error connecting to controlmachines", { icon: "warning" });
        console.log(error);
        setLoadingCMApi(false);
      }
    }
  };

  return (
    <Modal onClose={onFinish}>
      <div className="w-2/3 bg-white z-30 rounded shadow-xl py-4 px-8 flex flex-col relative">
        <div className="mb-2 font-medium text-lg">Add Harvester</div>
        <div className={`${gtw.label}`}>Control machines Project ID</div>
        <input
          type="text"
          className={`${gtw.input} w-full mb-4`}
          value={newHarvester.com_projectID}
          onChange={(e) => setNewHarvester({ ...newHarvester, com_projectID: e.target.value })}
        />
        <div className={`${gtw.label}`}>Token (secret)</div>
        <input
          className={`${gtw.input} w-full mb-4`}
          type={"password"}
          value={CMToken}
          onChange={(e) => setCMToken(e.target.value)}
        />
        {!cmAPILoaded && (
          <button className={`${gtw.smallBtn} mb-4`} onClick={() => LoadProject()}>
            <span>Load control machines harvester</span>
          </button>
        )}
        {cmAPILoaded && (
          <>
            <div className={`${gtw.label}`}>Name</div>
            <div className="mb-4">{newHarvester.name}</div>
            <div className={`${gtw.label}`}>Tags ({newHarvester.tags.length})</div>
            <TagViewer tags={newHarvester.tags} />
          </>
        )}
        {cmAPILoaded && (
          <div className="flex mt-2">
            <button
              className={`${gtw.smallBtn} relative flex-1 mr-2`}
              onClick={() => {
                if (!loadingSaveHarvester) SaveHarvester();
              }}
            >
              <span>Save</span>
              {loadingSaveHarvester && <LoadingOverlay />}
            </button>
            <button className={`${gtw.smallBtn} flex-1 ml-2`}>Cancel</button>
          </div>
        )}
        {loadingCMApi && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

export const DisplayCMHarvesterInfo: React.FC<{
  harvester: CMHarvester;
  onClose: () => void;
}> = ({ harvester, onClose }) => {
  const fullHarvester = useCMHarvester(harvester.id);

  const renderHarvester = () => {
    return (
      <>
        <div className={`${gtw.label}`}>Control machines Project ID</div>
        <input
          type="text"
          className={`${gtw.input} w-full mb-4 text-gray-500`}
          value={harvester.com_projectID}
          readOnly
        />
        <div className={`${gtw.label}`}>Token (secret)</div>
        <input
          className={`${gtw.input} w-full mb-4 text-gray-500`}
          type={"password"}
          value={harvester.tokenID}
          readOnly
        />

        <div className={`${gtw.label}`}>Name</div>
        <div className="mb-4">{harvester.name}</div>
        <div className={`${gtw.label}`}>Tags ({fullHarvester?.tags.length})</div>
        {fullHarvester && <TagViewer tags={fullHarvester.tags} />}
        {!fullHarvester && <LoadingOverlay />}
      </>
    );
  };

  return (
    <Modal zIndex={30} onClose={onClose}>
      <div className="w-2/3 bg-white z-30 rounded shadow-xl py-4 px-8 flex flex-col relative">
        <div className="mb-2 font-medium text-lg">View Harvester</div>
        {renderHarvester()}
        <button className={`${gtw.smallBtn} w-full mt-2`} onClick={() => onClose()}>
          Close
        </button>
      </div>
    </Modal>
  );
};

const TagViewer: React.FC<{ tags: CMTag[] }> = ({ tags }) => {
  const mappedTags = useMemo(() => {
    const mapped = new Map<string, CMTag[]>();
    tags.forEach((tag) => {
      const splitTag = tag.path.split("/");
      const tagRoute = splitTag.length > 1 ? splitTag[0] : "Global";
      const prev = mapped.get(tagRoute);
      if (prev) mapped.set(tagRoute, [...prev, tag]);
      else mapped.set(tagRoute, [tag]);
    });

    return mapped;
  }, [tags]);
  const routes = useMemo(() => [...mappedTags.keys()], [mappedTags]);

  const [selectedRoutes, setSelectedRoutes] = useState<string[]>([]);

  const renderTag = (tag: CMTag) => {
    return (
      <div
        key={tag.id}
        className="bg-gray-200 mr-2 mb-2 px-2 rounded-full border border-gray-200 shadow"
      >
        {tag.path}
      </div>
    );
  };

  const renderRoute = (route: string) => {
    const isSelected = selectedRoutes.some((sr) => sr === route);
    const tags = isSelected && mappedTags.get(route);
    return (
      <div key={route}>
        <div className="mr-2 mb-2 px-2 py-1  shadow">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              if (isSelected) setSelectedRoutes(selectedRoutes.filter((r) => r !== route));
              else setSelectedRoutes([...selectedRoutes, route]);
            }}
          >
            <OpenCloseArrow isOpen={isSelected} />
            <span className="ml-2">{route}</span>
          </div>
          {tags && <div className="flex flex-wrap px-2 py-1">{tags.map(renderTag)}</div>}
        </div>
      </div>
    );
  };

  return (
    <div className="overflow-y-auto px-2 py-2 bg-gray-100" style={{ maxHeight: "16rem" }}>
      {routes.map(renderRoute)}
    </div>
  );
};

const initialCMHarvester: FullCMHarvester = {
  id: "",
  tokenID: "", //link to Firebase doc with the actual token
  com_projectID: "",
  name: "",
  harvesterType: "control_machines",
  requestType: "POST",
  url: "https://controlmachines.cloud/api/v1/",
  tags: [],
  tagDocs: [],
};

export default COMHarvesterSelecter;
