import React, { useContext, useMemo, useState } from "react";
import { useSimulationModels, useFirebase, useUserInfo } from "api/useFirebase";
import { deleteSystemModel } from "utils/firebase/firebaseUtils";
import Modal from "components/basic/Modal";
import { Link } from "react-router-dom";
import { SimulationModel } from "model/datatypes";
import ComponentLibrary from "components/systems/ComponentLibrary";
import { Popup } from "components/basic/Popup";
import gtw from "gtw";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import AddNewModel from "./AddNewModel";
import { store } from "store";
import AddIcon from "components/basic/icons/AddIcon";
import GroupProcessors from "./GroupProcessors";
import ComponentLibIcon from "components/basic/icons/ComponentLibIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import { useUserRole } from "api/useAuth";
import SearchBar from "components/basic/SearchBar";
import CommentableComponent from "components/comments/CommentableComponent";

interface Props {}

const SystemsOverview = (props: Props) => {
  const [addingModel, setAddingModel] = useState<boolean | SimulationModel>(false);
  const { state } = useContext(store);
  const { user, teamIds } = state;

  const { hasDeveloperAccess } = useUserRole();

  const { allModels, draftModels } = useSimulationModels(
    hasDeveloperAccess,
    teamIds,
    user?.fbUser.uid
  );

  const [searchWord, setSearchWord] = useState("");
  const [shownSystems, setshownSystems] = useState<"all" | "drafts">("all");

  const shownModels = useMemo(() => {
    let models = shownSystems === "all" ? allModels : draftModels;
    if (searchWord.length > 0)
      models = models.filter(
        (model) => !!model.displayName.match(new RegExp(searchWord, "i"))
      );
    return models;
  }, [allModels, draftModels, searchWord, shownSystems]);

  return (
    <div className={gtw.innerContainer}>
      <div className={`${tw.headline} pl-4 mb-4`}>System Models</div>

      <div className="flex items-center w-full px-4 mb-6">
        {allModels > draftModels && (
          <>
            <button
              onClick={() => setshownSystems("all")}
              className={`${gtw.smallBtn} mr-4
            ${shownSystems === "all" ? gtw.activeBtn : ""}
          `}
            >
              All systems
            </button>
            <button
              onClick={() => setshownSystems("drafts")}
              className={`${gtw.smallBtn} mr-4
            ${shownSystems === "drafts" ? gtw.activeBtn : ""}
          `}
            >
              My drafts
            </button>
          </>
        )}
        <div className="flex-grow"></div>
        <SearchBar value={searchWord} onUpdate={(updated) => setSearchWord(updated)} />
        <button
          onClick={() => setAddingModel(true)}
          className={`flex items-center justify-center px-4 py-1 border-2 border-gray-700 text-gray-700 border-dashed rounded focus:outline-none`}
        >
          <AddIcon className="w-4 h-4 mr-4" />
          <span className="font-medium text-xs">Add new system</span>
        </button>
      </div>

      <div className="flex items-center w-full px-6 py-1">
        <div className="w-1/3 font-medium text-sm px-4">System name</div>
        <div className="w-1/6 font-medium text-sm px-2">Created by</div>
        <div className="w-1/6 font-medium text-sm px-2">Last edited</div>
        <div className="w-1/6 font-medium text-sm px-2">Status</div>
      </div>
      {shownModels.length === 0 && (
        <div className="px-4 text-sm italic py-2 border-t border-gray-200">
          Added system models will appear here.
        </div>
      )}
      <div className="px-4">
        {shownModels.map((simModel, i) => {
          return (
            <div key={simModel.id} className="py-1">
              <CommentableComponent
                commentTaget={{
                  systemID: simModel.id,
                  systemName: simModel.displayName,
                }}
                className={`relative`}
              >
                <Link to={`/systems/${simModel.id}`}>
                  <SimModelRow
                    simModel={simModel}
                    i={i}
                    onDuplicate={() => {
                      setAddingModel(simModel);
                    }}
                  />
                </Link>
              </CommentableComponent>
            </div>
          );
        })}
      </div>

      {addingModel && (
        <Modal onClose={() => setAddingModel(false)}>
          <AddNewModel
            onSuccess={() => setAddingModel(false)}
            startModel={typeof addingModel === "object" ? addingModel : undefined}
          />
        </Modal>
      )}

      <div className={`${tw.headline} pl-4 mt-8`}>Group processors</div>
      <GroupProcessors />

      <div className={`${tw.headline} pl-4 mt-8`}>Other</div>
      <div className="w-1/3 px-4 my-2">
        <CompLibraryCard />
      </div>
    </div>
  );
};

export default SystemsOverview;

const SimModelRow: React.FC<{
  simModel: SimulationModel;
  i: number;
  onDuplicate: () => void;
}> = ({ simModel, i, onDuplicate }) => {
  const creator = useUserInfo(simModel.ownerId);
  const latestEditor = useUserInfo(simModel.latestEdited?.userId);

  const [loading, setLoading] = useState(false);
  const fb = useFirebase();

  const DeleteSimModel = () => {
    if (!loading) {
      setLoading(true);
      deleteSystemModel(simModel.id, fb)
        .then(() => {
          //nothing to do here, model is deleted...
          //
          //
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const renderOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        pos={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`${gtw.popupBtn}`}
              onClick={(e) => {
                e.preventDefault();
                onDuplicate();
                closeMe();
              }}
            >
              Duplicate System
            </button>
            {DeleteOption(closeMe)}
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  const DeleteOption = (onFinish: () => void) => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete system model?</div>
              <div className="italic mb-2">
                This is a destructive event that can't be undone.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`${tw.smallBtn} border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!loading) {
                        DeleteSimModel();
                        closeDeletePopup();
                        onFinish();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`${gtw.smallBtn} w-full`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`${gtw.popupBtn} text-red-500 border-t border-gray-400`}>
          Delete System
        </button>
      </Popup>
    );
  };

  return (
    <div
      className={`flex items-center w-full px-6 py-4 shadow-sm bg-white cursor-pointer relative rounded border border-gray-200 hover:border-gray-300`}
    >
      <div className="font-medium w-1/3 text-sm">{simModel.displayName}</div>
      <div className="text-xs italic w-1/6 px-2">
        {creator?.fullName && <span>{creator.fullName}</span>}
      </div>
      <div className="text-xs italic w-1/6 px-2">
        <div className="font-medium">{latestEditor?.fullName}</div>
        <div>{simModel.latestEdited?.time.format("HH:mm MM/DD YY")}</div>
      </div>
      <div className="text-xs italic w-1/6 px-2">{simModel.status}</div>
      <div className="flex items-center justify-end w-1/6 px-2">{renderOptions()}</div>
      {loading && <LoadingOverlay />}
    </div>
  );
};

const CompLibraryCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <ComponentLibrary />
        </Modal>
      )}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${tw.cardBtn} flex items-center bg-white`}
      >
        <div className="h-8 w-8 mr-4 text-gray-700">
          <ComponentLibIcon />
        </div>
        <span className="">Component Library</span>
      </button>
    </>
  );
};

const tw = {
  headline: "font-bold text-gray-700 text-xl",
  card: "rounded px-4 py-2 bg-white shadow h-24",
  label: "font-bold text-xs",
  dropdown: "w-40",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  cardBtn:
    "py-2 px-4 w-full shadow rounded focus:outline-none text-sm font-medium hover:font-bold",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-100 focus:outline-none text-xs",
};
