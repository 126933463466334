import React, { useState, useRef, useEffect } from "react";
import { usePagePosition } from "utils/hooks/usePagePosition";
import { createPortal } from "react-dom";

interface Props {
  className?: string;
  mt?: number;
  pos?: "center" | "left" | "right";
  content: (closePopup: () => void) => React.ReactNode;
  useHover?: boolean;
  allowOverflow?: true;
  width?: string;
}

export const Popup: React.FC<Props> = ({
  children,
  mt,
  pos,
  className,
  content,
  useHover,
  allowOverflow,
  width,
}) => {
  const [hovered, sethovered] = useState(false);
  const [clicked, setclicked] = useState(false);

  //clickhandler for closing
  const ref = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const { pagePosition, updatePosition } = usePagePosition(ref);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && e.target instanceof Node && ref.current.contains(e.target)) {
        return;
      }
      if (
        popupRef.current &&
        e.target instanceof Node &&
        popupRef.current.contains(e.target)
      ) {
        return;
      }
      setclicked(false);
    };
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("scroll", updatePosition);
    return () => {
      setclicked(false);
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("scroll", updatePosition);
    };
  }, [updatePosition]);

  const closeMe = () => {
    sethovered(false);
    setclicked(false);
  };

  return (
    <div
      ref={ref}
      // className={`relative p-1`}
      onMouseEnter={() => {
        if (useHover) {
          updatePosition();
          sethovered(true);
        }
      }}
      onMouseLeave={() => sethovered(false)}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          updatePosition();
          setclicked(true);
        }}
      >
        {children}
      </div>
      {(hovered || clicked) && (
        <RootPortal>
          <div
            ref={popupRef}
            className={`absolute top-0 
              ${pos === "right" ? "right-0" : "left-0"} 
            `}
            style={{
              marginTop: mt ? pagePosition.marginTop + mt : pagePosition.marginTop,
              marginLeft: pos !== "right" ? pagePosition.marginLeft : 0,
              marginRight: pos === "right" ? pagePosition.marginRight : 0,
              zIndex: 1000,
            }}
          >
            <div
              style={{ width: width || "14rem" }}
              className={`z-50 bg-white shadow-xl rounded border text-left ${
                className ? className : ""
              } ${allowOverflow ? "" : "overflow-hidden"}`}
            >
              {content(closeMe)}
            </div>
          </div>
        </RootPortal>
      )}
    </div>
  );
};

const RootPortal: React.FC = ({ children }) => {
  const appRoot = document.getElementById("root");
  return appRoot && createPortal(children, appRoot);
};
