import Modal from "components/basic/Modal";
import { coy as prismStyle } from "react-syntax-highlighter/dist/esm/styles/prism";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import React from "react";

interface Props {
  onClose: () => void;
}

const Datatypes = (props: Props) => {
  return (
    <Modal onClose={props.onClose}>
      <div
        className="z-30 w-3/4 bg-white rounded border border-gray-200 shadow-lg px-4 py-4 flex flex-col"
        style={{ maxHeight: "90vh" }}
      >
        <div className="text-lg pb-2 border-b border-gray-200">All datatypes</div>
        <div className="px-4 py-2 text-xs w-full flex-grow overflow-auto">
          <SyntaxHighlighter
            showLineNumbers
            className={`w-full`}
            language={"typescript"}
            style={prismStyle}
            // customStyle={{ backgroundColor: "#5A5A5A", color: "#fff" }}
          >
            {tsTypes}
          </SyntaxHighlighter>
        </div>
      </div>
    </Modal>
  );
};

export default Datatypes;

const tsTypes = `
//////////////////////////////////////////////
//////////  SIMULATION management  ///////////
//////////////////////////////////////////////

export type Project = {
  projectName: string;
  id: string;
  created: Moment;
  deleted: Moment | null;
  ownerId: string;
  description?: string;
  meta_data?: {
    [key: string]: any;
  };
  collaborators: string[];
  teams: string[];
  amountOfScenarios: number;
};

export type Group = {
  id: string;
  ownerId: string;
  created: Moment;
  groupName: string;
  scenarios: string[];
  description?: string;
  meta_data?: {
    [key: string]: any;
  };
  groupProcessor?: GroupProcessor;
};

export type Scenario = {
  id: string;
  ownerId: string;
  created: Moment;
  model: SimulationModel;
  selectedPostProcessor?: PostProcessor;
  dataSourceType: "static" | "control_machines" | "sim_output";
  dataSource?: { id: string; dataSetName: string } | null;
  harvester?: Harvester | CMHarvesterInstance;
  groupID: string;
  scenarioName: string;
  simComponents: Component[];
  status?: { status: ScenarioStatus; progress?: number };
  report_status?: {
    custom_message?: string;
    command: string;
    progress?: number;
    status: "TERMINATING" | "PROCESSING" | "READY" | "FAILED" | "requested" | "finished";
  };
  report_download?: {
    bucket_id: string;
    report_path: string;
    timestamp: string;
  };
  reportChanges?: ReportChange[];
  run_settings: {
    duration: number; //overall SECONDS to run simulation....
    resolution: number; //seconds between calculations
    result_resolution: number; //seconds between results output....
    result_unit: SimTimeUnit; //Used by UI for which unit you want to show in
    duration_unit: SimTimeUnit; //Used by UI for which unit you want to show in
  };
  description?: string;
  meta_data?: {
    [key: string]: any;
  };
  sim_api_id?: string;
  logs?: { message: string; timestamp: string }[];
  traceback?: { error: string; timestamp: string }[];
  traceback_report?: { error: string; timestamp: string }[];
  availableFields?: string[];
  selectedOutputFields?: { id: string; fields: string[] }[];
  results?: string[];
};
export type ScenarioStateEdited = Scenario & {
  changed?: boolean;
  pendingSync?: boolean;
  componentTypes?: ComponentType[];
};

export type SimTimeUnit = "months" | "days" | "hours" | "minutes" | "seconds";

export type ScenarioStatus =
  | "incomplete"
  | "Components Incomplete"
  | "ready"
  | "running"
  | "finished"
  | "terminated"
  | "failed"
  | "requested"
  | "Environment Initializing"
  | "Model Initializing"
  | "Equations Assembly";

//////////////////////////////////////////////
//////////  SIMULATION Components  ///////////
//////////////////////////////////////////////

export type Component = {
  uuid: string;
  displayName: string;
  id: string;
  name: string;
  type: string;
  item_class: string;
  disabled: boolean;
  parameters: ComponentParameter[];
  inputVariables: InputVar[];
  worker?: WorkerComponentInstanceRef;
  subcomponents?: Subcomponent[];
  fixed?: boolean;
  order: number;
  isMainComponent: boolean;
  containerID?: string;
};

export type ComponentType = {
  displayName: string;
  id: string;
  name: string;
  type: string;
  item_class: string;
  order: number;
  parameters: ComponentParamType[];
  inputVariables: InputVar[];
  instantiationRules: { isMain: boolean; allowDisabling: boolean; min?: number; max?: number };
  subCompRules?: SubCompRules[];
  modelID?: string;
  fixed?: boolean;
  version?: number;
  workerType?: WorkerTypeRef;
};

export type Container = {
  displayName: string;
  id: string;
  name: string;
  order: number;
  instantiationRules: { isMain: boolean; allowDisabling: boolean; min?: number; max?: number };
  componentTypeIDs: string[];
  selectedComponent?: { id: string; uuid?: string };
};

export type SubCompRules = {
  id: string;
  fixed?: boolean;
  default: number;
  min: number;
  max: number | null;
};

export type ComponentTypeReference = {
  id: string;
  displayName: string;
};

export type Subcomponent = {
  id: string;
  uuid: string;
};

export type ComponentParameter = {
  uuid: string;
  value: number | string | boolean | null | number[] | FileRef;
  type:
    | "number"
    | "string"
    | "boolean"
    | "selecter"
    | "month"
    | "array_numbers"
    | "config"
    | "reference"
    | "file";
  id: string;
  tag?: string;
  displayName: string;
  tooltip?: string;
  optional?: boolean;
  displayMode?: "normal" | "fixed" | "hidden";
  fixed?: boolean;
  workerParameter?: true;
};

export type ComponentParamType = ComponentParameter & {
  fileQuery?: FileQuery;
};


export type InputVar = {
  uuid: string;
  value: number;
  display: string;
  tooltip?: string;
  id: string;
  sourceType: "dynamic" | "static";
  source?: InputVariableReference; //Optional: reference to a input variable
  CMTag?: CMTag; //Optional: tag for control machines.
  unit?: string;
  scaling: number;
  offset: number;
  mode?: "fixed" | "hidden";
};

//////////////////////////////////
//////////  SIM model  ///////////
//////////////////////////////////

export type SimulationModel = {
  id: string;
  displayName: string;
  ownerId: string;
  model_code: ManualGitModel[];
  discovered_models: DiscoveredGitModel[];
  postProcessors?: PostProcessor[];
  groupProcessors?: string[];
  type: "simple" | "energy_model";
  version: number;
  status: "published" | "draft";
  default_run_settings: Scenario["run_settings"];
};

export type ManualGitModel = {
  id: string;
  name: string;
  path: string;
  reference: string | "master";
  type: "manual";
};

export type DiscoveredGitModel = {
  id: string;
  modelName: string;
  path: string;
  selected_version?: GitModelVersion;
};

export type GitModelVersion = {
  id: string;
  branch: string;
  ref: string;
  commit_id: string;
  commit_message: string;
  type: "discovered";
  timestamp: Moment;
  components: DiscoveredComponent[];
};

export type DiscoveredComponent = {
  displayName: string;
  name: string;
  type: string;
  item_class: string;
  parameters: {
    id: string;
    value: number | string | null;
    displayName: string;
    type: "float" | "string" | "int";
    optional: boolean;
    tooltip: string;
  }[];
  inputVariables: {
    id: string;
    value: number;
    display: string;
    sourceType: "dynamic" | "static";
    tooltip?: string;
    unit?: string;
    scaling: number;
    offset: number;
  }[];
};

export type LocalSimulationModel = {
  localVersion: number;
} & SimulationModel;

export type PostProcessor = {
  id: string;
  name: string;
  path: string;
  reference: string | "master";
};

export type GroupProcessor = {
  id: string;
  name: string;
  path: string;
  reference: string | "master";
};

///////////////////////////////
//////////  INPUTS  ///////////
///////////////////////////////

export type InputData = {
  variableName: string;
  variableType: "string" | "number";
  values: (string | number)[];
};

export type DataSource = {
  id: string;
  dataSetName: string;
  origin?:
    | { type: "manual" }
    | { type: "output"; projectID: string; groupID: string; scenarioID: string };
  simulationVariables: VariableReference[];
};

export type VariableReference = { id: string; variableName: string; origin?: string }; // local reference used in source

export type InputVariableReference = {
  id: string;
  variableName: string;
  sourceID: string;
  parameterID: string;
};

export type HarvesterTagReference = {
  tagID: string;
  harvesterID: string;
  displayName: string;
};

export type Harvester = {
  id: string;
  url: string;
  harvesterType: "API";
  requestType: "POST" | "GET";
  params: { [key: string]: any };
};

export type CMHarvester = {
  id: string;
  url: "https://controlmachines.cloud/api/v1/";
  harvesterType: "control_machines";
  requestType: "POST";
  name: string;
  com_projectID: string;
  tags: CMTag[];
  tokenID: string; //link to FS-doc with the actual token
  params?: {
    groupingsType: "MINUTES" | "HOURS";
    tagList: string[];
    timeFrom: number;
    timeTo: number;
  };
};

export type CMHarvesterInstance = {
  id: string; //id in firestore where the harvester is saved
  url: "https://controlmachines.cloud/api/v1/";
  harvesterType: "control_machines";
  requestType: "POST";
  name: string;
  com_projectID: string;
  tokenID: string; //link to FS-doc with the actual token
  params: {
    groupingsType: "MINUTES" | "HOURS";
    tagList: string[];
    timeFrom: number;
    timeTo: number;
  };
};

export type CMTag = { id: string; path: string; isWritable: boolean; unit: string };

////////////////////////////////
//////////  RESULTS  ///////////
////////////////////////////////

export type ResultDataset = {
  id: string;
  scenario: string;
  model_id: string;
  resultSetName: string;
  resultsMeta: { key: string; id: string; data_type: "string" | "number" }[];
};

// export type Result = StringResult;

// export type StringResult = {
//   data: string;
//   data_type: "string";
//   included: boolean;
//   resultName: string;
// };

export type ReportChange = {
  id: string;
  innerHTML: string;
  original: string;
  pos: { top: number; left: number };
};

//////////////////////////////
//////////  FILES  ///////////
//////////////////////////////

export type FileQuery = {
  tags: string[];
  type?: string;
  projectRef?: { id: string; displayName: string };
  modelRef?: { id: string; displayName: string };
};

export type SimFile = {
  id: string;
  path: string;
  name: string;
  tags: string[];
  projectID?: string;
  modelID?: string;
  type: string;
};

export type FileRef = {
  id: string;
  path: string;
  name: string;
};

//////////////////////////////
//////////  WORKERS  ///////////
//////////////////////////////

export type WorkerType = {
  id: string;
  displayName: string;
  inputs: { [key: string]: "number" | "string" };
  workers: number;
};

export type WorkerTypeRef = {
  displayName: string;
  worker_id: string;
};

export type WorkerComponentInstance = {
  id: string;
  event:
    | null
    | "update_results"
    | "validate_inputs"
    | "terminate_gc_job"
    | "leased"
    | "released"
    | "error";
  event_result?: string;
  // gc_name: string;
  // gc_namespace: string;
  inputs: { [key: string]: string | number };
  logs: null | { message: string; timestamp: string }[];
  progress?: number;
  result_ref?: string;
  status?: { status: "finished" | "started" | "requested" | "terminated" };
  inSync?: boolean;
};

export type WorkerComponentInstanceRef = {
  workerInstanceID: string;
  workerTypeID: string;
  resultsID?: string;
};


////////////////////////////////////////
//////////  USER MANAGEMENT  ///////////
////////////////////////////////////////

export type Team = {
  name: string;
  id: string;
  users: string[];
  ownerId: string;
};

export type RawUser = {
  id: string;
  name: string;
  userRole: User["userRole"];
};

export type User = {
  fbUser: app.User;
  userRole: "owner" | "developer" | "simulator" | null;
  name: string;
  selectedProject?: string;
};

export type ManagerUser = {
  fbUser: app.User;
  role: "admin" | "user" | null;
  organisation?: string;
};
`;
