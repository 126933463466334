import React, { useState, useContext, useMemo } from "react";
import { store } from "store";
import { useFirebase, useDataParamValues } from "api/useFirebase";
import Plot from "react-plotly.js";
import * as Sentry from "@sentry/browser";
import { Popup } from "components/basic/Popup";
import gtw from "gtw";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { VariableReference } from "model/datatypes";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import app from "firebase/app";

interface Props {
  variable: VariableReference | null;
  inputDataID: string;
  scaling?: number;
  offset?: number;
  allowDelete?: true;
  onDelete?: () => void;
}

const VariableDetails: React.FC<Props> = ({
  variable,
  inputDataID,
  scaling,
  offset,
  allowDelete,
  onDelete,
}) => {
  const { state } = useContext(store);
  const { projectID } = state;
  const fb = useFirebase();
  const [loading, setLoading] = useState(false);
  const variableID = variable?.id;
  const [editedName, setEditedName] = useState<string | null>(null);
  const { dataLoading, parameterData } = useDataParamValues(
    inputDataID,
    variableID,
    projectID
  );

  const processedData = useMemo(() => {
    let data = parameterData;
    if (scaling) {
      data = data.map((d) => d * scaling);
    }
    if (offset) {
      data = data.map((d) => d + offset);
    }
    return data;
  }, [scaling, offset, parameterData]);

  const deleteSelectedVariable = () => {
    if (projectID && variable && !loading) {
      setLoading(true);
      const batch = fb.firestore().batch();
      const datasourceDoc = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .doc(inputDataID);
      const dataDoc = datasourceDoc.collection("Data").doc(variable.id);
      batch.delete(dataDoc);
      batch.update(datasourceDoc, {
        simulationVariables: app.firestore.FieldValue.arrayRemove(variable),
      });
      batch
        .commit()
        .then(() => {
          setLoading(false);
          onDelete && onDelete();
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          setLoading(false);
        });
    }
  };

  const updateVariableName = () => {
    if (projectID && variable && editedName && !loading) {
      setLoading(true);
      const batch = fb.firestore().batch();
      const datasourceDoc = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .doc(inputDataID);

      //remove and add new variable:s
      batch.update(datasourceDoc, {
        simulationVariables: app.firestore.FieldValue.arrayRemove(variable),
      });
      const updatedVariable: VariableReference = { ...variable, variableName: editedName };
      batch.update(datasourceDoc, {
        simulationVariables: app.firestore.FieldValue.arrayUnion(updatedVariable),
      });
      const dataDoc = datasourceDoc.collection("Data").doc(variable.id);
      batch.update(dataDoc, { variableName: editedName });

      batch
        .commit()
        .then(() => {
          setLoading(false);
          setEditedName(null);
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          setLoading(false);
        });
    }
  };

  const renderOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        pos={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button className={`${gtw.popupBtn} opacity-50`} onClick={(e) => {}}>
              Modify variable
            </button>
            <button
              className={`${gtw.popupBtn} border-t`}
              onClick={() => setEditedName(variable?.variableName || null)}
            >
              Re-name variable
            </button>
            {allowDelete && DeleteOption(closeMe)}
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  const DeleteOption = (onFinish: () => void) => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete variable?</div>
              <div className="italic mb-2">
                This is a destructive event that can't be undone.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`${gtw.smallBtn} border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!loading) {
                        deleteSelectedVariable();
                        closeDeletePopup();
                        onFinish();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`${gtw.smallBtn} w-full`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`${gtw.popupBtn} text-red-500 border-t border-gray-400`}>
          Delete Variable
        </button>
      </Popup>
    );
  };

  const renderName = () => {
    if (editedName)
      return (
        <div className="flex relative">
          <input
            type="text"
            value={editedName}
            onChange={(e) => {
              setEditedName(e.target.value);
            }}
          />
          <button className={`${gtw.smallBtn} mx-2`} onClick={() => updateVariableName()}>
            Save
          </button>
          <button
            className={gtw.smallBtn}
            onClick={() => {
              setEditedName(null);
            }}
          >
            Cancel
          </button>
        </div>
      );
    else if (variable) return variable.variableName;
    else return "";
  };

  return (
    <div className="relative rounded bg-gray-100 overflow-hidden border border-gray-300">
      {!variable && (
        <div className="absolute w-full h-full bg-gray-200 opacity-50 flex items-center justify-center z-10">
          <div className="italic text-lg">Select variable to view data</div>
        </div>
      )}
      {(loading || dataLoading) && (
        <div className="absolute w-full h-full bg-gray-400 opacity-75 text-white flex items-center justify-center z-50 text-6xl">
          <LoadingIcon />
        </div>
      )}
      <div className="px-6 py-4 flex justify-between">
        <div className="font-medium text-sm">{renderName()}</div>
        {variable && renderOptions()}
      </div>
      <Plot
        className={`${!variable ? "opacity-25" : ""}`}
        data={[
          {
            x: processedData.map((d, i) => new Date(1577836800000 + i * 60 * 60 * 1000)),
            y: processedData,
            type: "scatter",
            mode: "lines",
            line: { color: "#3182ce" },
          },
        ]}
        useResizeHandler
        config={{ displayModeBar: false }}
        style={{ width: "100%", height: "100%" }}
        layout={{
          autosize: true,
          paper_bgcolor: "#F7FAFC",
          plot_bgcolor: "#F7FAFC",
          xaxis: {
            tickformat: "%e %B",
          },
        }}
      />
    </div>
  );
};

export default VariableDetails;
