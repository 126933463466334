import { useFirestore, useGroupProcessors } from "api/useFirebase";
import AddIcon from "components/basic/icons/AddIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import gtw from "gtw";
import { GroupProcessor } from "model/datatypes";
import React, { useState } from "react";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";

interface Props {}

const GroupProcessors = (props: Props) => {
  const [addingGroupProcessor, setAddingGroupProcessor] = useState<boolean | GroupProcessor>(
    false
  );
  const groupProcessors = useGroupProcessors();

  return (
    <div className="flex w-full flex-wrap">
      {groupProcessors.map((groupProcessor) => {
        return (
          <div key={groupProcessor.id} className="w-1/3 px-4 my-2">
            <div
              className={`${gtw.card} h-20 font-medium cursor-pointer`}
              onClick={() => setAddingGroupProcessor(groupProcessor)}
            >
              {groupProcessor.name}
            </div>
          </div>
        );
      })}

      <button
        onClick={() => setAddingGroupProcessor(true)}
        className={`ml-4 mt-4 flex items-center justify-center px-4 py-1 border-2 border-gray-700 text-gray-700 border-dashed rounded focus:outline-none`}
      >
        <AddIcon className="w-4 h-4 mr-4" />{" "}
        <span className="font-medium text-xs">Add group processor</span>
      </button>
      {addingGroupProcessor && (
        <Modal onClose={() => setAddingGroupProcessor(false)}>
          <AddEditGroupProcessor
            onClose={() => setAddingGroupProcessor(false)}
            edtingProcessor={
              typeof addingGroupProcessor === "object" ? addingGroupProcessor : undefined
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default GroupProcessors;

export const AddEditGroupProcessor: React.FC<{
  edtingProcessor?: GroupProcessor;
  onClose: () => void;
}> = ({ edtingProcessor, onClose }) => {
  const [groupPostProcessor, setGroupPostProcessor] = useState<GroupProcessor>(
    edtingProcessor || { id: "", name: "", path: "", reference: "master" }
  );
  const [loading, setLoading] = useState(false);
  const fs = useFirestore();

  const onSave = async () => {
    try {
      setLoading(true);
      console.log({ edtingProcessor, groupPostProcessor });
      if (edtingProcessor)
        await fs
          .collection("GroupProcessors")
          .doc(edtingProcessor.id)
          .set(convertToFirestoreFormat(groupPostProcessor));
      else fs.collection("GroupProcessors").add(convertToFirestoreFormat(groupPostProcessor));
      setLoading(false);
      onClose();
    } catch (error) {
      console.log(error);
      Toast("error saving");
      setLoading(false);
    }
  };

  return (
    <div className="z-30 bg-white w-1/2 px-6 py-3 rounded shadow-xl flex flex-col">
      <div className="font-bold pb-1"> {edtingProcessor ? "Edit" : "New"} group processor</div>
      <label className="font-bold text-xs">Name</label>
      <input
        type="text"
        className={`${gtw.input} w-full mb-2`}
        value={groupPostProcessor.name}
        onChange={(e) =>
          setGroupPostProcessor({ ...groupPostProcessor, name: e.target.value })
        }
      />
      <label className="font-bold text-xs">Path</label>
      <input
        type="text"
        className={`${gtw.input} w-full mb-2`}
        value={groupPostProcessor.path}
        onChange={(e) =>
          setGroupPostProcessor({ ...groupPostProcessor, path: e.target.value })
        }
      />
      <label className="font-bold text-xs">Reference</label>
      <input
        type="text"
        className={`${gtw.input} w-full mb-2`}
        value={groupPostProcessor.reference}
        onChange={(e) =>
          setGroupPostProcessor({ ...groupPostProcessor, reference: e.target.value })
        }
      />
      <button onClick={() => onSave()} className={`${gtw.smallBtn} w-full relative`}>
        {edtingProcessor ? "Edit" : "Add"}
        {loading && <LoadingOverlay />}
      </button>
    </div>
  );
};
