import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy as prismStyle } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useUserIDToken } from "api/useAuth";
import gtw from "gtw";
import React, { useContext, useState } from "react";
import { store } from "store";
import { DropdownAtRoot } from "./basic/Dropdown";
import CopyIcon from "./basic/icons/CopyIcon";
import Toast from "./basic/Toast";
import LockIcon from "./basic/icons/LockIcon";
import Datatypes from "./API/Datatypes";
import { getHeadersExampleCode } from "./API/codeExamples/headersExamplesCode";
import {
  apiDeleteScenarioExample,
  apiDuplicateScenarioExample,
  apiGetFirestoreExample,
  apiLaunchProcessorExample,
  apiLaunchSimulationExample,
  apiReadProjectExample,
  apiReadScenarioExample,
  apiStopSimulationExample,
  apiUpdateScenarioExample,
} from "./API/codeExamples/apiCallExamples";

interface Props {}

type SupportedCodeLanguage = "node" | "python";

const APIPage = (props: Props) => {
  const userIDToken = useUserIDToken();
  const { state } = useContext(store);
  const { google_project } = state;
  const [showDataTypes, setShowDataTypes] = useState(false);
  const [codeLanguage, setCodeLanguage] = useState<SupportedCodeLanguage>("node");

  const copyTextToClipboard = (text: string) => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Token copied to clipboard", { icon: "success" });
  };

  return (
    <div className={gtw.innerContainer}>
      <div className="text-2xl font-medium">Simulation API</div>
      <div className="italic text-sm">
        Create manage and launch simulation through the API. API access provides an alternative
        way to work, when creating large numbers of simulations.
      </div>

      {/* Request format */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className="text-xl mb-4 font-medium">Request format</div>
          <div className="text-sm">
            <div className="mb-4">
              In order to use numerous through API access, you must request the API in the
              correct format.
            </div>
            <div className="mb-4">
              All request must contain the following required headers:
            </div>
            <div className="pl-2 mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`Authorization: Bearer <Token>`}</span>
              <ul className="list-disc ml-6">
                <li>
                  Authorization token is personal for the current logged in user. The token
                  will expire after 1 hour, visit this page to retrieve it again.
                </li>
              </ul>
            </div>
            <div className="pl-2 mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`Content-Type: application/json`}</span>
              <ul className="list-disc ml-6">
                <li>Required</li>
              </ul>
            </div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{ text: "Prepare request" }}
          >
            {getHeadersExampleCode(
              codeLanguage,
              userIDToken || "-- token error --",
              `https://us-central1-${
                google_project || "<project-id>"
              }.cloudfunctions.net/simulationAPI`
            )}
          </CodeInfoBox>
          {userIDToken && (
            <button
              className={`${gtw.smallBtn} flex items-center text-gray-800`}
              onClick={() => {
                copyTextToClipboard(userIDToken);
              }}
            >
              <LockIcon />
              <span className="ml-2">Copy token</span>
            </button>
          )}
        </CodeSegmentCol>
      </CodeSegment>

      {/* Read project */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className="text-xl mb-4 font-medium">Read Project</div>
          <div className="text-sm">
            <div className="mb-4 pb-2 border-b border-gray-300 font-medium text-md">
              Parameters
            </div>
            <div className="mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`:projectID`}</span>
              <ul className="list-disc ml-6">
                <li>The id of the project you want to read from.</li>
              </ul>
            </div>

            <div className="mb-2 pb-2 border-b border-gray-300 font-medium text-md">
              Result
            </div>
            <div className="mb-4">
              Returns all data for specified project along with all the groups in it.
            </div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{ text: "/project/:projectID", type: "GET" }}
          >
            {apiReadProjectExample(codeLanguage)}
          </CodeInfoBox>
        </CodeSegmentCol>
      </CodeSegment>

      {/* Read scenariro */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className="text-xl mb-4 font-medium">Read Scenario</div>
          <div className="text-sm">
            <div className="mb-4 pb-2 border-b border-gray-300 font-medium text-md">
              Parameters
            </div>
            <div className="mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`:projectID`}</span>
              <ul className="list-disc ml-6">
                <li>The id of the project you want to read from.</li>
              </ul>
            </div>
            <div className="mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`:scenarioID`}</span>
              <ul className="list-disc ml-6">
                <li>The id of the scenario you want to read.</li>
              </ul>
            </div>
            <div className="mb-2 pb-2 border-b border-gray-300 font-medium text-md">
              Result
            </div>
            <div className="mb-4">Returns all data for specified scenario</div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{ text: "/project/:projectID/scenario/:scenarioID", type: "GET" }}
          >
            {apiReadScenarioExample(codeLanguage)}
          </CodeInfoBox>
        </CodeSegmentCol>
      </CodeSegment>

      {/* Duplicate scenario */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className="text-xl mb-4 font-medium">Duplicate Scenario</div>
          <div className="text-sm">
            <div className="mb-4">
              Duplicating a scenario will create an copy of the scenario configuration as a new
              Scenario.
            </div>
            <div className="mb-4 pb-2 border-b border-gray-300 font-medium text-md">
              Parameters
            </div>
            <div className="mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`newScenarioName`}</span>
              <ul className="list-disc ml-6">
                <li>Name of the new scenario that gets created</li>
              </ul>
            </div>
            <div className="mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`newScenarioGroupID`}</span>
              <ul className="list-disc ml-6">
                <li>GroupID where the scenario should be placed</li>
              </ul>
            </div>

            <div className="mb-2 pb-2 border-b border-gray-300 font-medium text-md">
              Result
            </div>
            <div className="mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`scenarioID`}</span>
              <ul className="list-disc ml-6">
                <li>ID of the new scenario created.</li>
              </ul>
            </div>
            <div className="mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`status`}</span>
              <ul className="list-disc ml-6">
                <li>Status of the new scenario</li>
              </ul>
            </div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{
              text: "/project/:projectid/scenario/:scenarioid/duplicate",
              type: "POST",
            }}
          >
            {apiDuplicateScenarioExample(codeLanguage)}
          </CodeInfoBox>
        </CodeSegmentCol>
      </CodeSegment>

      {/* Update scenario */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className="text-xl mb-4 font-medium">Update Scenario</div>
          <div className="text-sm">
            <div className="mb-4">
              Update a scenario information with an object containing a partial scenario.
            </div>
            <div className="mb-4 pb-2 border-b border-gray-300 font-medium text-md">
              Parameters
            </div>
            <div className="mb-4">
              <span
                className="font-medium bg-gray-300 px-1 mb-1 cursor-pointer"
                onClick={() => setShowDataTypes(true)}
              >{`{...}`}</span>
              <ul className="list-disc ml-6">
                <li>Object containing all scenario parameters you want to overwite.</li>
                <li>
                  Each parameter specified overwrites the current existing parameter. To update
                  a nested parameter supply the full object.
                </li>
              </ul>
            </div>

            <div className="mb-2 pb-2 border-b border-gray-300 font-medium text-md">
              Result
            </div>
            <div className="mb-4">Status </div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{
              text: "/project/:projectID/scenario/:scenarioID/update",
              type: "POST",
            }}
          >
            {apiUpdateScenarioExample(codeLanguage)}
          </CodeInfoBox>
        </CodeSegmentCol>
      </CodeSegment>

      {/* Delete scenario */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className="text-xl mb-4 font-medium">Delete Scenario</div>
          <div className="text-sm">
            <div className="mb-4">Delete a scenario and all data associated with it.</div>
            <div className="mb-4 pb-2 border-b border-gray-300 font-medium text-md">
              Parameters
            </div>
            <div className="mb-4">No parameters required</div>

            <div className="mb-2 pb-2 border-b border-gray-300 font-medium text-md">
              Result
            </div>
            <div className="mb-4">Status</div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{
              text: "/project/:projectID/scenario/:scenarioID/delete",
              type: "POST",
            }}
          >
            {apiDeleteScenarioExample(codeLanguage)}
          </CodeInfoBox>
        </CodeSegmentCol>
      </CodeSegment>

      {/* Launch Simulation */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className="text-xl mb-4 font-medium">Launch Simulation</div>
          <div className="text-sm">
            <div className="mb-4">Use this endpoint to start running a new simulation.</div>
            <div className="mb-4 pb-2 border-b border-gray-300 font-medium text-md">
              Parameters
            </div>
            <div className="mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`beta`}</span> *optional
              <ul className="list-disc ml-6">
                <li>
                  Set to true if the simulation should run in the beta simulation engine image.
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <span className="font-medium bg-gray-300 px-1 mb-1">{`usePipelines`}</span>{" "}
              *optional
              <ul className="list-disc ml-6">
                <li>
                  Set to true if the simulation should run in the pipelines infrastructure.
                </li>
              </ul>
            </div>

            <div className="mb-2 pb-2 border-b border-gray-300 font-medium text-md">
              Result
            </div>
            <div className="mb-4">
              Status telling if the simulation was succesfully launched.
            </div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{
              text: "/project/:projectid/scenario/:scenarioid/launch_simulation",
              type: "POST",
            }}
          >
            {apiLaunchSimulationExample(codeLanguage)}
          </CodeInfoBox>
        </CodeSegmentCol>
      </CodeSegment>

      {/* Stop Simulation */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className="text-xl mb-4 font-medium">Stop Simulation</div>
          <div className="text-sm">
            <div className="mb-4">Use this endpoint to stop running a simulation.</div>
            <div className="mb-4 pb-2 border-b border-gray-300 font-medium text-md">
              Parameters
            </div>
            <div className="mb-4">No parameters required</div>

            <div className="mb-2 pb-2 border-b border-gray-300 font-medium text-md">
              Result
            </div>
            <div className="mb-4">
              Status telling if the simulation was succesfully stopped.
            </div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{
              text: "/project/:projectid/scenario/:scenarioid/stop_simulation",
              type: "POST",
            }}
          >
            {apiStopSimulationExample(codeLanguage)}
          </CodeInfoBox>
        </CodeSegmentCol>
      </CodeSegment>

      {/* Launch Processor */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className="text-xl mb-4 font-medium">Launch Processor</div>
          <div className="text-sm">
            <div className="mb-4">
              Use this endpoint to start running a processor after a simulation is started.
            </div>
            <div className="mb-4 pb-2 border-b border-gray-300 font-medium text-md">
              Parameters
            </div>
            <div className="mb-4">No parameters required</div>

            <div className="mb-2 pb-2 border-b border-gray-300 font-medium text-md">
              Result
            </div>
            <div className="mb-4">
              Status telling if the processor was succesfully launched.
            </div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{
              text: "/project/:projectid/scenario/:scenarioid/launch_processor",
              type: "POST",
            }}
          >
            {apiLaunchProcessorExample(codeLanguage)}
          </CodeInfoBox>
        </CodeSegmentCol>
      </CodeSegment>

      {/* Raw DB access */}
      <CodeSegment>
        <CodeSegmentCol>
          <div className=" mb-4 flex">
            <h3 className=" text-xl font-medium">Database API acess</h3>
            <span className="text-sm text-green-numerous ml-2">* advanced</span>
          </div>
          <div className="text-sm">
            <div className="mb-4">
              If the standard endpoints above are not sufficient, you can try accessing the{" "}
              <a
                href="https://cloud.google.com/firestore/docs/reference/rest/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600"
              >
                Google Firestore REST API
              </a>{" "}
              and read or write data directly to the database.
            </div>
            <div className="mb-4">
              Since input formats are not checked, you should be carefull when writing directly
              to the database. Providing wrong input in the database may cause simulations to
              fail and other errors.
            </div>
            <div className="mb-4">You can acces the API on the following URL:</div>
            <div className="pl-2 mb-4">
              <span className="font-medium bg-gray-300 px-1">{`https://firestore.googleapis.com/v1/projects/${
                google_project || "GPROJECT_ID"
              }/databases/`}</span>
              <ul className="list-disc ml-6">
                <li>The base url of the api with your organisation.</li>
              </ul>
            </div>
          </div>
        </CodeSegmentCol>
        <CodeSegmentCol>
          <CodeInfoBox
            selectedLanguage={codeLanguage}
            onSelectLanguage={(newLang) => setCodeLanguage(newLang)}
            headline={{ text: "Firestore Rest API example" }}
          >
            {apiGetFirestoreExample(codeLanguage, google_project)}
          </CodeInfoBox>
        </CodeSegmentCol>
      </CodeSegment>

      {showDataTypes && <Datatypes onClose={() => setShowDataTypes(false)} />}
    </div>
  );
};

export default APIPage;

const CodeSegment: React.FC<{}> = ({ children }) => {
  return <div className="flex flex-wrap mt-12 py-12 border-t border-gray-300">{children}</div>;
};

const CodeSegmentCol: React.FC<{}> = ({ children }) => {
  return <div className={`w-full lg:w-1/2 px-4 py-4`}>{children}</div>;
};

const CodeInfoBox: React.FC<{
  headline: { type?: "GET" | "POST"; text: string };
  children: string;
  selectedLanguage: SupportedCodeLanguage;
  onSelectLanguage: (newLanguage: SupportedCodeLanguage) => void;
}> = ({ headline, children, onSelectLanguage, selectedLanguage }) => {
  const copyCodeToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = children;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Code copied to clipboard", { icon: "success" });
  };
  return (
    <div className="shadow-md border rounded bg-white border-gray-300 overflow-hidden w-full mb-8">
      <div className="border-gray-300 bg-gray-200 flex items-center py-2 px-2">
        <div className="flex-grow text-sm font-medium px-2 flex items-center flex-wrap">
          {headline.type && (
            <span className={` text-green-numerous mr-2`}>{headline.type}</span>
          )}
          <span>{headline.text}</span>
        </div>
        <DropdownAtRoot
          className="text-xs w-20 mr-2 flex-none"
          headlessStyle
          selectedID={selectedLanguage}
          onSelect={(option) => {
            onSelectLanguage(option.id as SupportedCodeLanguage);
          }}
          options={[
            { id: "node", display: "Node.js" },
            { id: "python", display: "Python" },
          ]}
        />
        <div className="border-l border-gray-300 px-2 flex-none">
          <div
            className="w-6 h-6 p-1 cursor-pointer hover:bg-gray-300 rounded-lg"
            onClick={() => {
              copyCodeToClipboard();
            }}
          >
            <CopyIcon />
          </div>
        </div>
      </div>
      <div className="px-4 py-2 text-xs w-full">
        <SyntaxHighlighter
          showLineNumbers
          className={`w-full`}
          language={selectedLanguage === "node" ? "javascript" : "python"}
          style={prismStyle}
          // customStyle={{ backgroundColor: "#5A5A5A", color: "#fff" }}
        >
          {children}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};
