import { Component, DataSource, InputVar } from "model/datatypes";
import { getInputVarRef } from "./getFullVarRef";

export const matchCompToDS = (components: Component[], dataSource: DataSource) => {
  //Match component with datasource here:
  const matchedComponents: Component[] = components.map((component) => {
    const varsWithSources = component.inputVariables.map((inputVar) => {
      // dont match already same.
      if (inputVar.source?.sourceID === dataSource.id) return inputVar;
      const prevMatch = dataSource.simulationVariables.find(
        (simVar) => inputVar.source?.variableName === simVar.variableName
      );
      if (prevMatch) {
        return { ...inputVar, source: getInputVarRef(dataSource, prevMatch) };
      }
      const updatedVar = { ...inputVar, sourceType: "static" } as InputVar;
      delete updatedVar.source;
      return updatedVar;
    });

    return { ...component, inputVariables: varsWithSources };
  });
  return matchedComponents;
};

export const firstMatchCompToDS = (components: Component[], dataSource: DataSource) => {
  //Match component with datasource here:
  const matchedComponents: Component[] = components.map((component) => {
    const varsWithSources = component.inputVariables.map((inputVar) => {
      const matchedParam = dataSource.simulationVariables.find((d) =>
        d.variableName.includes(inputVar.id)
      );
      if (matchedParam)
        return { ...inputVar, source: getInputVarRef(dataSource, matchedParam) };
      else return { ...inputVar, sourceType: "static" } as InputVar;
    });

    return { ...component, inputVariables: varsWithSources };
  });
  return matchedComponents;
};
