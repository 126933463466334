import React, { useContext, useState } from "react";
import { useFirebase, useComponentTypes, useContainers } from "api/useFirebase";
import { newModel } from "utils/firebase/firebaseUtils";
import { SimulationModel } from "model/datatypes";
import { store } from "store";

interface Props {
  onSuccess: () => void;
  startModel?: SimulationModel;
}

const AddNewModel: React.FC<Props> = ({ onSuccess, startModel }) => {
  const { state } = useContext(store);
  const { user } = state;
  const ownerId = user?.fbUser.uid;
  const [loading, setloading] = useState(false);
  const [displayName, setDisplayName] = useState(
    startModel ? `${startModel.displayName} copy` : "Example model"
  );

  const [status] = useState<SimulationModel["status"]>("draft");

  const { componentTypes } = useComponentTypes(startModel?.id);
  const { containers } = useContainers(startModel?.id);

  const fb = useFirebase();

  const addModel = () => {
    if (ownerId) {
      setloading(true);
      newModel(
        fb.firestore(),
        displayName,
        startModel ? startModel.model_code : [],
        ownerId,
        status,
        componentTypes.length > 0 ? componentTypes : undefined,
        containers
      )
        .then(() => {
          setloading(false);
          onSuccess();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className={`${tw.card} mb-2 z-50`}>
      <div className="font-medium">New system model</div>
      {componentTypes.length > 0 && (
        <div className="italic text-xs">{componentTypes.length} copied components</div>
      )}
      <label className="font-bold text-xs">Model display name</label>
      <input
        type="text"
        className={`${tw.input} mb-2`}
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
      />

      <button
        className={`${tw.cardBtn} mt-2 ${loading ? "opacity-50" : ""}`}
        onClick={() => {
          !loading && addModel();
        }}
      >
        Add model
      </button>
    </div>
  );
};

const tw = {
  headline: "font-medium mb-4 text-xl",
  card: "border border-gray-300 rounded p-4 bg-white shadow",
  label: "font-bold text-xs",
  dropdown: "w-40",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  cardBtn:
    "py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-sm hover:font-medium bg-white",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
};

export default AddNewModel;
