import React, { useState } from "react";
import { useFirebase } from "api/useFirebase";
import { SimFile } from "model/datatypes";
import { Popup } from "components/basic/Popup";
import gtw from "gtw";
import LoadingOverlay from "components/basic/LoadingOverlay";

export const FileViewer: React.FC<{ files: SimFile[]; loadingFiles: boolean }> = ({
  files,
  loadingFiles,
}) => {
  const [loading, setLoading] = useState(false);
  const fb = useFirebase();
  const removeFile = async (file: SimFile) => {
    try {
      setLoading(true);
      //delete from fs
      await fb.firestore().collection("FileLibrary").doc(file.id).delete();

      //remove all references where selected as default value GOES HERE!

      //remove from bucket
      const fileRef = fb.storage().ref(file.path);
      await fileRef.delete();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const openFile = async (fle: SimFile) => {
    if (!loading) {
      try {
        setLoading(true);
        const getFileURL = fb.functions().httpsCallable("getFileURL");
        const { data } = await getFileURL({ filePath: fle.path });
        const url = data as string;
        window.open(url, "_blank");
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const renderFile = (file: SimFile) => {
    return (
      <div key={file.id} className="py-2 flex items-center text-xs border-b border-gray-200">
        <div className="w-4/12 flex-none truncate">{file.name}</div>
        <div className="w-4/12 flex-none flex">
          {file.tags
            .filter((tag) => !!tag.indexOf("model_") && !!tag.indexOf("project_"))
            .map((tag) => {
              return (
                <div
                  key={tag}
                  className="px-2 border border-green-500 text-green-600 rounded-full text-xs mr-1 mb-1"
                >
                  {tag}
                </div>
              );
            })}
        </div>
        <div className="w-1/12  flex-none">{file.type}</div>
        <div className="w-1/12 flex-none truncate">{file.projectID || ""}</div>
        <div className="w-1/12 flex-none truncate">{file.modelID || ""}</div>
        <div className="w-1/12 flex-none flex justify-end">
          <Popup
            pos={"right"}
            useHover
            className="text-xs ml-2"
            content={(closeMe) => {
              return (
                <>
                  <button
                    className={`${gtw.popupBtn}`}
                    onClick={() => {
                      openFile(file);
                      closeMe();
                    }}
                  >
                    Go to file
                  </button>
                  <button
                    className={`${gtw.popupBtn} border-t border-gray-400`}
                    onClick={() => {
                      if (!loading) {
                        removeFile(file);
                        closeMe();
                      }
                    }}
                  >
                    Remove file
                  </button>
                </>
              );
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
            </svg>
          </Popup>
        </div>
      </div>
    );
  };

  return (
    <div className="overflow-hidden bg-white w-full h-full border border-gray-300 shadow-md rounded-lg py-2 flex flex-col relative">
      <div className="px-4">
        <div className="text-xs font-medium border-b border-gray-300 py-2 flex">
          <div className="w-4/12 flex-none">File Name</div>
          <div className="w-4/12 flex-none">Tags</div>
          <div className="w-1/12 flex-none">Type</div>
          <div className="w-1/12 flex-none">Project</div>
          <div className="w-1/12 flex-none">System</div>
        </div>
      </div>
      {files.length === 0 && (
        <div className="italic text-xs py-2 px-4">No files found for selected search term</div>
      )}
      {files.length > 0 && (
        <div className="flex-grow overflow-auto flex flex-col px-4">
          {files.map(renderFile)}
        </div>
      )}
      {(loading || loadingFiles) && <LoadingOverlay />}
    </div>
  );
};

export default FileViewer;
