import { useFirestore, useGroupProcessors } from "api/useFirebase";
import Dropdown from "components/basic/Dropdown";
import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import gtw from "gtw";
import { GroupProcessor } from "model/datatypes";
import React, { useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";

interface Props {
  onClose: () => void;
  addedProcessor?: GroupProcessor;
  projectID: string;
  groupID: string;
}

const AddGroupProcessor: React.FC<Props> = ({
  onClose,
  addedProcessor,
  projectID,
  groupID,
}) => {
  const fs = useFirestore();
  const groupProcessors = useGroupProcessors();
  const options = useMemo(
    () =>
      groupProcessors.map((p) => ({
        id: p.id,
        display: p.name,
        val: p,
      })),
    [groupProcessors]
  );
  const [selectedProcessor, setSelectedProcessor] = useState(addedProcessor);

  const saveProcessor = async () => {
    if (selectedProcessor) {
      try {
        await fs
          .collection("Projects")
          .doc(projectID)
          .collection("Groups")
          .doc(groupID)
          .update({ groupProcessor: selectedProcessor });
        onClose();
      } catch (error) {
        Sentry.captureException(error);
        Toast("Error saving group processor");
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <div className="bg-white px-4 py-4 rounded shadow w-1/2 z-40">
        <div className="font-medium mb-2">Add Group Processor</div>
        <Dropdown
          className="text-xs"
          placeholder="Select group processor"
          selectedID={selectedProcessor?.id}
          options={options}
          onSelect={(option) => {
            setSelectedProcessor(option.val);
          }}
        />
        <div className="mt-2 flex">
          <button
            className={`${gtw.smallBtn} flex-1 mr-1`}
            onClick={() => {
              saveProcessor();
            }}
          >
            Add
          </button>
          <button
            className={`${gtw.smallBtn} flex-1 ml-1`}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddGroupProcessor;
