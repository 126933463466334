import {
  useCMHarvesters,
  useFirebase,
  useFirestore,
  useProject,
  useScenariosRecent,
} from "api/useFirebase";
import gtw from "gtw";
import { CMHarvester, Project, Scenario } from "model/datatypes";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { store } from "store";

import { useUserRole } from "api/useAuth";
import ProjectInfo from "./projects/ProjectInfo";
import { useHistory } from "react-router-dom";
import SimIcon from "./basic/icons/SimIcon";
import moment from "moment";
import { DisplayCMHarvesterInfo } from "components/simulations/newSimulation/simSetup/inputs/COMHarvesterSelecter";
import { Popup } from "./basic/Popup";
import DotDotDotIcon from "./basic/icons/DotDotDotIcon";
import LoadingOverlay from "./basic/LoadingOverlay";
import ReportIcon from "./basic/icons/ReportIcon";
import EditCollaborators from "./collaboratos/EditCollaborators";

export interface OverviewPageProps {}

const OverviewPage: React.FC<OverviewPageProps> = () => {
  const fb = useFirebase();
  const [loading, setLoading] = useState<Boolean>(false);
  const CMHarvesters = useCMHarvesters();

  const { state } = useContext(store);
  const project = useProject(state.projectID);

  const recentScenarios = useScenariosRecent(project?.id, 11); // up to 11 most recent scenarios

  const { hasProjectEditAccess, hasSimulatorAccess } = useUserRole();

  const updateProject = async (updates: Partial<Project>) => {
    if (project && !loading) {
      try {
        setLoading(true);
        await fb.firestore().collection("Projects").doc(project.id).update(updates);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  return (
    <div className="py-8">
      <div className="px-8">
        <ProjectInfo />

        <div className="flex mb-12">
          <div className="w-1/3 pr-6">
            {project && (
              <ProjectSimulationsInfo recentScenarios={recentScenarios} project={project} />
            )}
          </div>
        </div>
      </div>
      {project && <PublishedReports projectID={project.id} />}
      {hasSimulatorAccess && (
        <>
          <div className="px-8 mb-8">
            <div className={`font-medium text-gray-700 text-xl mb-4`}>
              Project collaborators
            </div>
            {project && (
              <EditCollaborators
                allowEditing={hasProjectEditAccess}
                collaborators={project.collaborators}
                teamIds={project.teams}
                updateTeams={(updatedTeams) => {
                  return updateProject({ teams: updatedTeams });
                }}
                updateCollaborators={(updatedCollaboratos) => {
                  return updateProject({ collaborators: updatedCollaboratos });
                }}
              />
            )}
          </div>
          <div className="px-4">
            <div className={`font-medium text-gray-700 text-xl mb-4 px-4`}>
              Controlmachines harvesters
            </div>
            {project && (
              <div className="flex flex-wrap">
                {CMHarvesters.map((harvester) => (
                  <HarvesterCard
                    key={harvester.id}
                    harvester={harvester}
                    projectID={project.id}
                  />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OverviewPage;

export interface ProjectTeamCardProps {}

const ProjectSimulationsInfo: React.FC<{ project: Project; recentScenarios: Scenario[] }> = ({
  project,
  recentScenarios,
}) => {
  const history = useHistory();
  const recent = useMemo(() => {
    const lastWeek = moment().add(-7, "days");
    const weekScenarios = recentScenarios.filter((scenario) =>
      scenario.created.isAfter(lastWeek)
    ).length;
    if (weekScenarios > 0)
      return {
        amount: weekScenarios === 11 ? `> ${weekScenarios - 1}` : weekScenarios,
        time: "Last week",
      };
    else return { amount: 0, time: "Last week" };
  }, [recentScenarios]);
  const { hasSimulatorAccess } = useUserRole();

  return (
    <div
      className={`bg-numerous-green h-32 w-full bg-white text-gray-700 px-4  rounded  shadow border border-gray-200 flex flex-col 
        ${hasSimulatorAccess ? "cursor-pointer" : ""}
      `}
      onClick={() => {
        if (hasSimulatorAccess) history.push("/simulations");
      }}
    >
      <div className="flex pb-3 pt-3 border-b border-gray-300">
        <SimIcon className="w-6 h-6" />
        <h4 className="ml-4 font-medium text-lg">Simulations</h4>
      </div>
      <div className="flex-grow flex items-center">
        <div className="flex-1 flex flex-col">
          <div className="font-bold text-xl leading-tight">
            {project.amountOfScenarios || 0}
          </div>
          <div className="italic text-sm">Total</div>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="font-bold text-xl leading-tight">{recent.amount}</div>
          <div className="italic text-sm">{recent.time}</div>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="font-bold text-xl leading-tight">0</div>
          <div className="italic text-sm">Running now</div>
        </div>
      </div>
    </div>
  );
};

const HarvesterCard: React.FC<{ harvester: CMHarvester; projectID: string }> = ({
  harvester,
  projectID,
}) => {
  const [displayHarvesterInfo, setDisplayHarvesterInfo] = useState(false);
  const [loading, setLoading] = useState(false);

  const fs = useFirestore();
  const deleteHarvester = async () => {
    try {
      setLoading(true);

      const batch = fs.batch();
      const harvesterDoc = fs
        .collection("Projects")
        .doc(projectID)
        .collection("CMHarvesters")
        .doc(harvester.id);
      batch.delete(harvesterDoc);
      batch.delete(harvesterDoc.collection("token").doc(harvester.tokenID));
      if (harvester.tagDocs)
        harvester.tagDocs.forEach((tagDocID) => {
          batch.delete(harvesterDoc.collection("CMTags").doc(tagDocID));
        });
      console.log("try to delete");
      await batch.commit();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const renderDeleteOption = (onFinish: () => void) => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete harvester?</div>
              <div className="italic mb-2">
                Scenarios using this harvester might stop working..
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`${gtw.smallBtn} border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!loading) {
                        deleteHarvester();
                        closeDeletePopup();
                        onFinish();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`${gtw.smallBtn} w-full`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`${gtw.popupBtn} text-red-500`}>Delete harvester</button>
      </Popup>
    );
  };

  const renderOptions = () => {
    return (
      <div className="absolute top-0 right-0 mt-1 mr-2">
        <Popup
          useHover
          mt={15}
          pos={"right"}
          content={(closeMe) => {
            return <div className="text-xs">{renderDeleteOption(closeMe)}</div>;
          }}
        >
          <DotDotDotIcon />
        </Popup>
      </div>
    );
  };

  return (
    <>
      <div className="px-4 py-4 w-1/3 ">
        <div
          className={`${gtw.card} h-24 w-full font-medium flex items-center justify-center relative`}
        >
          <div
            className="cursor-pointer px-4 py-4"
            onClick={() => {
              setDisplayHarvesterInfo(true);
            }}
          >
            {harvester.name}
          </div>
          {renderOptions()}
          {loading && <LoadingOverlay />}
        </div>
      </div>
      {displayHarvesterInfo && (
        <DisplayCMHarvesterInfo
          harvester={harvester}
          onClose={() => {
            setDisplayHarvesterInfo(false);
          }}
        />
      )}
    </>
  );
};

const PublishedReports: React.FC<{ projectID: string }> = ({ projectID }) => {
  const published = usePublishedReports(projectID);
  const baseURL = window.location.protocol + "//" + window.location.host;
  const publishedVerified = useMemo(
    () => published.filter((scenario) => !!scenario.report_download),
    [published]
  );

  if (publishedVerified.length > 0)
    return (
      <div className="mb-8">
        <div className={`font-medium text-gray-700 text-xl px-8 mb-2`}>Published reports</div>
        <div className="flex flex-wrap px-4">
          {publishedVerified.map((scenario) => {
            const date = scenario.report_download?.timestamp.split(" ")[0];
            let description = scenario.description;
            if (description && description.length > 90)
              description = description.slice(0, 90) + "...";
            return (
              <a
                href={`${baseURL}/reports/${projectID}/${scenario.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className=" "
              >
                <div
                  key={scenario.id}
                  style={{ height: "12rem" }}
                  className={`w-56 border border-gray-200 shadow-lg rounded bg-white py-4 px-4 mx-4 mb-8 relative flex flex-col items-center cursor-pointer`}
                >
                  <ReportIcon className="w-16 h-16 text-green-numerous mb-2 " />
                  <div className="font-medium text-sm text-center">
                    {scenario.scenarioName}
                  </div>
                  {description && <div className="text-xs text-center">{description}</div>}
                  <div
                    className={`text-xs text-center ${
                      description ? "absolute bottom-0 right-0 mb-2 mr-4" : "mt-2"
                    }`}
                  >
                    {date}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    );
  else return null;
};

const usePublishedReports = (projectID: string) => {
  const [scenarios, setscenarios] = useState<Scenario[]>([]);
  const fb = useFirebase();
  useEffect(() => {
    if (projectID) {
      const usub = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .where("reportPublished", "==", true)
        .orderBy("created", "desc")
        .onSnapshot((snap) => {
          const scenarios: Scenario[] = [];
          snap.docs.forEach((doc) => {
            const fsTimestamp = doc.data().created?.toDate() as Date | undefined;
            const created = fsTimestamp ? moment(fsTimestamp) : moment();
            scenarios.push({ ...doc.data(), id: doc.id, created } as Scenario);
          });
          setscenarios(scenarios);
        });
      return () => {
        usub();
      };
    } else setscenarios([]);
  }, [fb, projectID]);
  return scenarios;
};
