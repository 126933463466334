import React, { useMemo, useState } from "react";
import { CMHarvesterInstance, Group, Scenario, SimulationModel } from "model/datatypes";
import Dropdown from "components/basic/Dropdown";
import { useFirebase, useGroups, useSimulationModels } from "api/useFirebase";

import NewGroup from "../../group/NewGroup";
import { NewScenarioAction } from "../NewScenarioReducer";
import COMHarvesterSelecter from "./inputs/COMHarvesterSelecter";
import DatasourceSelecter from "./inputs/DatasourceSelecter";
import { getCMHarvesterInstance } from "utils/dataTransform/harvesters";
import InputNumber from "components/basic/headless/InputNumber";
import gtw from "gtw";
import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import { mergeScenarioSystem } from "utils/simulations/mergeScenarioSystem";
import { useGlobalState } from "store";
import LoadingOverlay from "components/basic/LoadingOverlay";
import { useUserRole } from "api/useAuth";

const EngineSetup: React.FC<{
  scenarioState: Scenario;
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
}> = ({ scenarioState, scenarioDispatch }) => {
  const { model, dataSource, groupID, scenarioName, harvester } = scenarioState;

  const renderStaticDatasourceSelecter = () => {
    return (
      <>
        <label className={`${tw.label} mt-4`}>Input data set</label>
        <DatasourceSelecter
          selectedDataSourceID={dataSource?.id}
          onSelect={(source) => {
            scenarioDispatch({ type: "SET_SIM_DATASOURCE", payload: source });
          }}
        />
      </>
    );
  };

  const updateCMHarvesterParams = (
    updated: Partial<CMHarvesterInstance["params"]>,
    CMHarvesterInstance: CMHarvesterInstance
  ) => {
    scenarioDispatch({
      type: "SET_HARVESTER",
      payload: {
        ...CMHarvesterInstance,
        params: {
          ...CMHarvesterInstance.params,
          ...updated,
        },
      },
    });
  };

  const renderCOMHarvesterSelecter = () => {
    //if CM harvester instance has been selected
    const CMHarvesterInstance = harvester?.harvesterType === "control_machines" && harvester;

    return (
      <>
        <label className={`${tw.label} mt-4`}>Control machines havester</label>
        <COMHarvesterSelecter
          selectedHarvesterID={harvester?.id}
          onSelect={(harvester) => {
            const harvesterInstance = getCMHarvesterInstance(harvester);
            scenarioDispatch({ type: "SET_HARVESTER", payload: harvesterInstance });
          }}
        />
        {CMHarvesterInstance &&
          CMHarvesterInstance.params &&
          renderCMHarvesterParams(CMHarvesterInstance.params, (updatedParams) =>
            updateCMHarvesterParams(updatedParams, CMHarvesterInstance)
          )}
      </>
    );
  };

  const renderCMHarvesterParams = (
    CMHarvesterParams: CMHarvesterInstance["params"],
    onChange: (updated: Partial<CMHarvesterInstance["params"]>) => void
  ) => {
    return (
      <>
        <label className={`${tw.label} mt-4`}>Harvester Settings</label>
        <div className="bg-gray-100 mb-4 px-4 py-2">
          <label className={`${tw.label}`}>Groupings Type</label>
          <Dropdown
            className="bg-white"
            selectedID={CMHarvesterParams.groupingsType}
            options={[
              { id: "MINUTES", display: "Minutes" },
              { id: "HOURS", display: "Hours" },
            ]}
            onSelect={(option) => {
              const newGroupingsType = option.id as CMHarvesterInstance["params"]["groupingsType"];
              onChange({ groupingsType: newGroupingsType });
            }}
          />
          <div className={`${tw.label} mt-2`}>Time from</div>
          <InputNumber
            className={gtw.input}
            value={CMHarvesterParams.timeFrom}
            onChange={(val) => {
              onChange({ timeFrom: val });
            }}
          />
          <div className={`${tw.label} mt-2`}>Time to</div>
          <InputNumber
            className={gtw.input}
            value={CMHarvesterParams.timeTo}
            onChange={(val) => {
              onChange({ timeTo: val });
            }}
          />
        </div>
      </>
    );
  };

  const [changingModel, setChangingModel] = useState(false);
  const renderSimulationModel = () => {
    return (
      <>
        <label className={`${tw.label}`}>Simulation model</label>
        <div className="mt-2 mb-4">
          {model.displayName}{" "}
          <button
            onClick={() => {
              setChangingModel(true);
            }}
            className="focus:outline-none italic underline text-blue-600"
          >
            change
          </button>
        </div>
        {changingModel && (
          <ModelChangeSelecter
            scenarioDispatch={scenarioDispatch}
            scenario={scenarioState}
            onFinish={() => setChangingModel(false)}
          />
        )}
      </>
    );
  };

  return (
    <div className={`flex mb-4`}>
      <div className="w-1/2 flex flex-col mr-6">
        <label className={`${tw.label}`}>Scenario Name</label>
        <input
          type="text"
          className={`${tw.input} text-sm mb-4`}
          value={scenarioName}
          onChange={(e) =>
            scenarioDispatch({ type: "SCENARIO_NAME_UPADTE", payload: e.target.value })
          }
        />
        <label className={`${tw.label}`}>Group</label>
        <GroupSelecter
          selectedGroupID={groupID || undefined}
          onSelect={(group) => {
            scenarioDispatch({ type: "SET_GROUP", payload: group.id });
          }}
        />
        <label className={`${tw.label} mt-4`}>Input data type</label>
        <Dropdown
          selectedID={scenarioState.dataSourceType}
          options={[
            { id: "static", display: "Static dataset" },
            { id: "control_machines", display: "Control machines harvester" },
            { id: "sim_output", display: "Simulation Output" },
          ]}
          onSelect={(option) => {
            const newValue = option.id as Scenario["dataSourceType"];
            scenarioDispatch({ type: "SET_DATASOURCE_TYPE", payload: newValue });
          }}
          placeholder="Select input type"
        />
        {scenarioState.dataSourceType === "static" && renderStaticDatasourceSelecter()}
        {scenarioState.dataSourceType === "control_machines" && renderCOMHarvesterSelecter()}
      </div>
      <div className="w-1/2 flex flex-col">
        {renderSimulationModel()}
        <label className={`${tw.label}`}>Description</label>
        <textarea
          className={`${tw.input} h-24`}
          value={scenarioState.description}
          onChange={(e) =>
            scenarioDispatch({ type: "UPDATE_DESCRIPTION", payload: e.target.value })
          }
        />
      </div>
    </div>
  );
};

export const GroupSelecter: React.FC<{
  selectedGroupID?: string;
  onSelect: (group: Group) => void;
}> = ({ selectedGroupID, onSelect }) => {
  const { projectID } = useGlobalState();
  const groups = useGroups(projectID || undefined);

  const [addingGroup, setaddingGroup] = useState(false);
  const onNewGroup = (newGroup?: Group) => {
    if (newGroup) {
      onSelect(newGroup);
    }
    setaddingGroup(false);
  };

  return (
    <>
      <Dropdown
        onSelect={(option) => {
          onSelect(option.val);
        }}
        placeholder="Select group"
        selectedID={selectedGroupID}
        options={groups ? groups.map((g) => ({ id: g.id, display: g.groupName, val: g })) : []}
        onAddNew={() => setaddingGroup(true)}
      />
      {addingGroup && <NewGroup onFinish={onNewGroup} />}
    </>
  );
};

export default EngineSetup;

const ModelChangeSelecter: React.FC<{
  scenario: Scenario;
  scenarioDispatch: React.Dispatch<NewScenarioAction>;
  onFinish: () => void;
}> = ({ scenario, scenarioDispatch, onFinish }) => {
  const fb = useFirebase();
  const { user, teamIds } = useGlobalState();
  const { hasDeveloperAccess } = useUserRole();

  const { allModels } = useSimulationModels(hasDeveloperAccess, teamIds, user?.fbUser.uid);

  const { projectID } = useGlobalState();
  const modelOptions = useMemo(
    () => allModels.map((model) => ({ id: model.id, display: model.displayName, val: model })),
    [allModels]
  );

  const [newModel, setNewModel] = useState<null | SimulationModel>(null);

  const selectedID = useMemo(() => (newModel ? newModel.id : scenario.model.id), [
    newModel,
    scenario,
  ]);

  const newModelSelected = useMemo(() => selectedID !== scenario.model.id, [
    selectedID,
    scenario,
  ]);

  const [loading, setLoading] = useState(false);
  const changeAndMergeModels = async () => {
    if (newModelSelected && newModel && !loading && projectID) {
      setLoading(true);
      try {
        const updates = await mergeScenarioSystem(fb, projectID, scenario, newModel);
        scenarioDispatch({
          type: "HARD_UPDATE_SCENARIO",
          payload: { ...updates, changed: false },
        });

        setLoading(false);
        onFinish();
      } catch (error) {
        setLoading(false);
        console.log(error);
        Toast("Error changing to new model");
      }
    }
  };

  return (
    <Modal onClose={onFinish}>
      <div className="z-30 bg-white p-4 rounded shadow-lg relative">
        <div className="text-lg font-medium">Change simulation model</div>
        <div className="italic text-xs mb-4">
          This will overwrite with paramters from the new model while matching paramters and
          inputs.
        </div>
        <Dropdown
          className="text-xs"
          options={modelOptions}
          selectedID={selectedID}
          onSelect={(option) => {
            setNewModel(option.val);
          }}
        />
        <div className="flex mt-4">
          <button
            onClick={() => {
              if (newModelSelected) changeAndMergeModels();
            }}
            className={`${gtw.smallBtn} ${newModelSelected ? "" : "opacity-50"} flex-1 mr-2`}
          >
            Change and save
          </button>
          <button onClick={() => onFinish()} className={`${gtw.smallBtn} flex-1 ml-2`}>
            Cancel
          </button>
        </div>
        {loading && <LoadingOverlay />}
      </div>
    </Modal>
  );
};

const tw = {
  container: "w-screen h-screen overflow-y-scroll relative pl-40 bg-gray-bg text-gray-800",
  innerContainer: "px-8 py-8",
  headline: "font-medium mb-4 text-xl",
  card: "border border-gray-300 rounded p-4 bg-white shadow w-full",
  label: "text-xs font-bold",
  smallBtn:
    "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs bg-white",
  runBtn: "py-2 px-4 shadow rounded border focus:outline-none text-xs font-medium w-full",
  input: "px-2 py-1 focus:outline-none border rounded",
  activeBtn: "bg-gray-700 border-gray-700 text-white font-medium",
};
