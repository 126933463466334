import {
  Component,
  Scenario,
  PostProcessor,
  ScenarioStateEdited,
  ComponentType,
} from "model/datatypes";
import { DataSource } from "model/datatypes";
import { matchCompToDS, firstMatchCompToDS } from "utils/matchCompToDs";
import { updateArrayValUUID } from "utils/jsUtils/imutableArray";
import { updateComponentsNames } from "utils/ComponentTypeHelpers";

export type NewScenarioAction =
  | { type: "SET_SIM_DATASOURCE"; payload: DataSource | null }
  | { type: "SET_DATASOURCE_TYPE"; payload: Scenario["dataSourceType"] }
  | { type: "SET_HARVESTER"; payload: Scenario["harvester"] }
  | { type: "SET_GROUP"; payload: string }
  | { type: "SCENARIO_NAME_UPADTE"; payload: string }
  | { type: "UPDATE_RUN_SETTINGS"; payload: Scenario["run_settings"] }
  | { type: "UPDATE_COMPONENTS"; payload: Component[] }
  | { type: "UPDATE_COMPONENT"; payload: Component }
  | { type: "SELECT_POSTPROCESSOR"; payload: PostProcessor }
  | { type: "UPDATE_DESCRIPTION"; payload: string }
  | { type: "UPDATE_METADATA"; payload: Scenario["meta_data"] }
  | { type: "RESET_SCENARIO"; payload: ScenarioStateEdited }
  | { type: "SCENARIO_SAVED" }
  | { type: "REQUEST_SAVE_SIMULATION" }
  | {
      type: "SWAP_COMPONENTS";
      payload: { oldComponent: Component; newComponents: Component[] };
    }
  | { type: "HARD_UPDATE_SCENARIO"; payload: Partial<ScenarioStateEdited> }
  | { type: "SET_COMPONENT_TYPES"; payload: ComponentType[] };

export const newScenarioReducer = (state: ScenarioStateEdited, action: NewScenarioAction) => {
  console.log(action);
  switch (action.type) {
    case "SET_DATASOURCE_TYPE":
      return { ...state, dataSourceType: action.payload, changed: true };
    case "SET_SIM_DATASOURCE":
      let newState = {
        ...state,
        dataSource: action.payload
          ? { id: action.payload.id, dataSetName: action.payload.dataSetName }
          : null,
        changed: true,
      };
      if (state.simComponents && action.payload)
        newState = {
          ...newState,
          simComponents: state.dataSource
            ? matchCompToDS(state.simComponents, action.payload)
            : firstMatchCompToDS(state.simComponents, action.payload),
        };
      return newState;
    case "SET_HARVESTER":
      return { ...state, harvester: action.payload, changed: true };
    case "SET_GROUP":
      return { ...state, groupID: action.payload, changed: true };
    case "SCENARIO_NAME_UPADTE":
      return { ...state, scenarioName: action.payload, changed: true };
    case "UPDATE_COMPONENTS":
      return { ...state, simComponents: action.payload, changed: true };
    case "UPDATE_COMPONENT":
      return {
        ...state,
        simComponents: updateArrayValUUID(state.simComponents, action.payload),
        changed: true,
      };
    case "SWAP_COMPONENTS":
      const swappedComponents = removeCompAndSubcomps(
        action.payload.oldComponent,
        state.simComponents,
        action.payload.newComponents
      );
      return {
        ...state,
        simComponents: swappedComponents,
      };
    case "UPDATE_RUN_SETTINGS":
      return { ...state, run_settings: action.payload, changed: true };
    case "SELECT_POSTPROCESSOR":
      return { ...state, selectedPostProcessor: action.payload, changed: true };
    case "UPDATE_DESCRIPTION":
      return { ...state, description: action.payload, changed: true };
    case "UPDATE_METADATA":
      return { ...state, meta_data: action.payload, changed: true };
    case "SCENARIO_SAVED":
      return { ...state, changed: false, pendingSync: false };
    case "REQUEST_SAVE_SIMULATION":
      return { ...state, pendingSync: true };
    case "RESET_SCENARIO":
      return { ...action.payload, componentTypes: state.componentTypes };
    case "SET_COMPONENT_TYPES":
      return { ...state, componentTypes: action.payload };
    case "HARD_UPDATE_SCENARIO":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const removeCompAndSubcomps = (
  componentToRemove: Component,
  allComponents: Component[],
  newComponents?: Component[]
) => {
  let compForRemove = [componentToRemove];
  let updatedSimComps = [...allComponents];

  //remove the component and its subcomponents:
  while (compForRemove.length > 0) {
    const nextLevelSubs: Component[] = [];
    for (let i = 0; i < compForRemove.length; i++) {
      const comp = compForRemove[i];
      if (comp.subcomponents) {
        //find all subcomponents
        for (let i = 0; i < comp.subcomponents.length; i++) {
          const subC = comp.subcomponents[i];
          const c = updatedSimComps.find((c) => c.uuid === subC.uuid);
          c && nextLevelSubs.push(c);
        }
      }
      //remove the comp:
      updatedSimComps = updatedSimComps.filter((sc) => sc.uuid !== comp.uuid);
    }
    compForRemove = nextLevelSubs;
  }

  if (newComponents) {
    //add the new.
    updatedSimComps = [...updatedSimComps, ...newComponents];
    //update names:
    updatedSimComps = updateComponentsNames(updatedSimComps);
  }

  return updatedSimComps;
};
