import React from "react";

const OpenCloseButton: React.FC<{ open: boolean; onClick: () => void }> = ({
  onClick,
  open,
}) => {
  return (
    <button
      onClick={() => onClick()}
      className="focus:outline-none h-8 w-8 flex justify-center items-center"
    >
      <svg
        style={{
          transform: open ? "rotate(0deg)" : "rotate(-90deg)",
          transition: "transform 0.2s ease",
        }}
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
        <path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z" />
      </svg>
    </button>
  );
};

export default OpenCloseButton;
