import React, { useMemo, useState } from "react";
import { useSimFiles } from "api/useFirebase";
import { FileQuery } from "model/datatypes";
import FileUploader from "components/files/FileUploader";
import FileViewer from "components/files/FileViewer";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";

type LocalFileProps = {
  modelName: string;
  modelID: string;
};

const LocalFiles = ({ modelID, modelName }: LocalFileProps) => {
  const fileQuery: FileQuery = useMemo(
    () => ({
      tags: [],
      modelRef: { id: modelID, displayName: modelName },
    }),
    [modelID, modelName]
  );
  const { simFiles, loadingFiles } = useSimFiles(fileQuery);
  const [showFiles, setShowFiles] = useState(false);
  const renderFileUI = () => {
    return (
      <>
        <div className="h-64 my-4">
          <FileViewer files={simFiles} loadingFiles={loadingFiles} />
        </div>
        <FileUploader modelID={modelID} />
      </>
    );
  };

  return (
    <div>
      <div className="font-bold mt-4 mb-2">Simulation files attached to system</div>
      <div className="w-full bg-white border-gray-200 px-4 py-4 rounded-lg shadow-md">
        <button
          className="focus:outline-none italic flex items-center text-sm font-bold"
          onClick={() => setShowFiles((p) => !p)}
        >
          <span className="mr-1">
            {simFiles.length > 0
              ? `${simFiles.length} File${simFiles.length > 1 ? "s" : ""} in system`
              : "No files in system"}
          </span>
          <OpenCloseArrow isOpen={showFiles} />
        </button>
        {showFiles && renderFileUI()}
      </div>
    </div>
  );
};

export default LocalFiles;
