import Axios from "axios";
import firebase from "firebase/app";
import { Scenario } from "model/datatypes";
const isBeta = process.env.REACT_APP_BETA === "true";

type StartSimulationRequest = {
  scenarioID: string;
  projectID: string;
  endpoint?: Scenario["endpoint"];
  beta?: boolean;
  groupID?: string;
};

export const startSimulation = async (
  projectID: string,
  scenarioID: string,
  fb: firebase.app.App,
  endpoint?: Scenario["endpoint"],
  groupID?: string
) => {
  const requestParams: StartSimulationRequest = {
    projectID,
    scenarioID,
    beta: isBeta,
    endpoint,
    groupID,
  };
  const startSimulation = fb.functions().httpsCallable("startSimulation");
  await startSimulation(requestParams);
};

type StopSimRequest = {
  scenarioID: string;
  projectID: string;
  groupID?: string;
  endpoint?: Scenario["endpoint"];
};

export const stopSimulation = async (req: StopSimRequest, fb: firebase.app.App) => {
  const stopSimulation = fb.functions().httpsCallable("stopSimulation");
  await stopSimulation(req);
};

export const startReport = async (
  projectID: string,
  scenarioID: string,
  fb: firebase.app.App
) => {
  const startProcessor = fb.functions().httpsCallable("startProcessor");
  await startProcessor({ projectID, scenarioID });
};

export const startGroupProcessor = async (
  project_id: string,
  group_id: string,
  scenario_id_list: string[],
  fb: firebase.app.App
) => {
  const startGProcessor = fb.functions().httpsCallable("startGroupProcessor");
  await startGProcessor({ project_id, group_id, scenario_id_list });
};

export type RawDataOutput = {
  data: (number | string)[][];
  fields: string[];
};

type RawDataRequest = {
  project_id: string;
  scenario_id: string;
  fields: string[];
  size?: number;
  last_t?: string;
};

export const loadSimulationRawData = async (
  dataRequest: RawDataRequest,
  fb: firebase.app.App,
  usePipelines?: boolean
) => {
  const loadRawData = fb.functions().httpsCallable("loadRawData");
  const data = await loadRawData({ dataRequest, usePipelines });
  return data.data as RawDataOutput;
};

type RawDataAPIRequest = {
  fields: string[];
  size?: number;
  last_t?: string;
};

const rawDataAPI = "http://34.76.57.83:8001/v1_api/data/pop";
export const loadRawData = async (dataRequest: RawDataAPIRequest, token: string) => {
  const headers = { "API-token": token };
  const data = await Axios.post(rawDataAPI, { dataRequest }, { headers });
  return data.data as RawDataOutput;
};

type TokenRequest = {
  project_id: string;
  scenario_id: string;
  fields: string[];
};
export const loadRawDataToken = async (tokenRequest: TokenRequest, fb: firebase.app.App) => {
  const loadRawData = fb.functions().httpsCallable("getRawDataToken");
  const data = await loadRawData(tokenRequest);
  return data.data as string;
};
