import { useAllUsers, useAllUserInvitations, useFirebase } from "api/useFirebase";
import Dropdown from "components/basic/Dropdown";
import LinkIcon from "components/basic/icons/LinkIcon";
import MailIcon from "components/basic/icons/MailIcon";
import UserRoleIcon from "components/basic/icons/UserRoleIcon";
import Modal from "components/basic/Modal";
import Toast from "components/basic/Toast";
import moment from "moment";
import React, { useState } from "react";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { copyToClipboard } from "utils/jsUtils/CopyToClipBoard";

export interface InvitationModalProps {
  onFinish: () => void;
}

const InvitationModal: React.SFC<InvitationModalProps> = ({ onFinish }) => {
  const [inputMail, setInputMail] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState<string>();
  const users = useAllUsers();
  const invitations = useAllUserInvitations();
  const orgID = window.localStorage.getItem("organisationID");
  const fb = useFirebase();
  const [invitationLink, setInvitationLink] = useState("");
  const [inputError, setInputError] = useState(false);
  const [invitationLinkGenerated, setInvitationLinkGenerated] = useState(false);

  const roleOptions = [
    { id: "developer", display: "Developer" },
    { id: "simulator", display: "Simulator" },
    { id: "guest", display: "Guest" },
    // vil vi tilbyde at man kan ændre nogle til owner? De kan ikke ændres tilbage til sim/dev efter den ændring
  ];

  const generateInvitationLink = (invitationMail: string) => {
    if (
      users.some((user) => user.mail === invitationMail) ||
      invitations.some((invitation) => invitation.mail === invitationMail)
    ) {
      Toast("This mail is already in use", { icon: "error" });
      return;
    }
    if (!orgID) {
      Toast("There was an issue with the organisation ID", { icon: "error" });
      return;
    }

    const invitationDoc = fb.firestore().collection("invitations").doc();

    const invitation = {
      mail: inputMail,
      userRole: selectedUserRole,
      created: moment(),
      invitationLink: `signup/${orgID}/${invitationDoc.id}`,
    };

    invitationDoc
      .set(convertToFirestoreFormat(invitation))
      .then(() => {
        console.log("Successfully set document");

        const link = `${window.location.protocol}//${window.location.host}/${invitation.invitationLink}`;
        copyToClipboard(link);
        setInvitationLink(link);

        // Finishing
        Toast("Invitation link copied to clipboard", { icon: "success" });
        setInputError(false);
        setInputMail("");
        setSelectedUserRole(undefined);
        setInvitationLinkGenerated(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal onClose={() => onFinish()}>
      <div className={`${tw.card} z-50 p-6`}>
        {!invitationLinkGenerated && (
          <div>
            <div className="text-xl text-center mb-2">Add collaborator to organisation</div>
            <div className="text-center text-xs">
              This will generate an invitation link you can send to the new collaborator
            </div>
            <div className="px-4 mt-4 flex relative items-center bg-gray-100 rounded">
              <span className="mr-2">
                <MailIcon />
              </span>
              <input
                aria-label="Mail input"
                value={inputMail}
                onChange={(e) => setInputMail(e.target.value)}
                type="email"
                className="py-1 mr-2 h-8 text-sm outline-none w-full bg-gray-100"
              ></input>
            </div>
            <div className="pl-4 mt-4 flex relative items-center bg-gray-100 rounded">
              <span>
                <UserRoleIcon />
              </span>
              <Dropdown
                headlessStyle
                className="py-1 h-8 text-sm outline-none w-full bg-gray-100"
                selectedID={selectedUserRole}
                placeholder={"Select user role"}
                options={roleOptions}
                onSelect={(option) => setSelectedUserRole(option.id)}
              />
            </div>

            <button
              onClick={() =>
                selectedUserRole && inputMail
                  ? generateInvitationLink(inputMail)
                  : setInputError(true)
              }
              className={`${
                selectedUserRole && inputMail ? "bg-blue-400" : "bg-blue-200"
              } mt-4 w-full p-1 shadow rounded border text-white border-blue-200 focus:outline-none text-sm font-bold hover:font-medium`}
            >
              Generate invitation link
            </button>
          </div>
        )}
        {invitationLinkGenerated && (
          <div className="flex items-center w-64">
            <LinkIcon></LinkIcon>
            <div className="ml-2 text-sm">Invitation link</div>
          </div>
        )}

        {inputError && (
          <div className="text-red-600 text-sm mt-2">Mail and user role are required.</div>
        )}
        {invitationLink && (
          <textarea
            className="resize-none overflow-auto w-full mt-4"
            readOnly
            id="link"
            value={invitationLink}
          />
        )}
      </div>
    </Modal>
  );
};

export default InvitationModal;

const tw = {
  headline: "font-bold text-gray-700 text-xl",
  card: "border border-gray-300 rounded bg-white shadow",
  label: "font-bold text-xs",
  dropdown: "w-40",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
};
