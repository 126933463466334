import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  ComponentType,
  FileQuery,
  ComponentTypeReference,
  SubCompRules,
} from "model/datatypes";
import gtw from "gtw";
import EditableName from "components/basic/EditableName";
import { Popup } from "components/basic/Popup";
import { BooleanDropdownSelecter } from "components/basic/Dropdown";
import * as Sentry from "@sentry/browser";
import ConfirmDeleteButton from "components/basic/ConfirmDeleteButton";
import CloseIcon from "components/basic/icons/CloseIcon";
import RadioButton from "components/basic/RadioButton";

interface Props {
  container: Container;
  updateContainer: (containerID: string, updatedParams: Partial<Container>) => Promise<any>;
  allComponentTypes: ComponentType[];
  removeContainer: () => Promise<any>;
  modelRef: FileQuery["modelRef"];
  allowedParamRefs: ComponentTypeReference[];
  subCompOptions: { displayName: string; subCompRules: SubCompRules }[];
  onMove: (dir: "up" | "down") => void;
  maxOrder: number;
}

const ContainerEditor: React.FC<Props> = ({
  container,
  allComponentTypes,
  updateContainer,
  removeContainer,
  onMove,
  maxOrder,
}) => {
  const [editedContainer, setEditedContainer] = useState<Container>(container);
  const [containerChanged, setContainerChanged] = useState(false);

  const { addableComponentTypes, addedComponentTypes } = useMemo(() => {
    const addableComponentTypes: ComponentType[] = [];
    const addedComponentTypes: ComponentType[] = [];
    allComponentTypes.forEach((ct) => {
      if (editedContainer.componentTypeIDs.some((ctID) => ctID === ct.id))
        addedComponentTypes.push(ct);
      else addableComponentTypes.push(ct);
    });
    return { addableComponentTypes, addedComponentTypes };
  }, [editedContainer, allComponentTypes]);

  useEffect(() => {
    //In case of container changes from outside - reset it here:
    setEditedContainer(container);
    setContainerChanged(false);
  }, [container]);

  const [loading, setLoading] = useState(false);

  const renderOptions = () => {
    return (
      <div className="flex items-center">
        <div className="mr-1 text-xs font-medium">{container.order}</div>
        <Popup
          pos={"right"}
          useHover
          className="text-xs"
          content={(closeMe) => {
            return (
              <>
                <ConfirmDeleteButton
                  onDelete={removeContainer}
                  buttonText={"Delete container"}
                  warningHeadline={"Delete container?"}
                />
                {container.order > 0 && (
                  <button
                    className={`${gtw.popupBtn} ${
                      container.order < maxOrder ? "border-b border-gray-400" : ""
                    }`}
                    onClick={() => {
                      onMove("up");
                      closeMe();
                    }}
                  >
                    Move up
                  </button>
                )}
                {container.order < maxOrder && (
                  <button
                    className={`${gtw.popupBtn}`}
                    onClick={() => {
                      onMove("down");
                      closeMe();
                    }}
                  >
                    Move down
                  </button>
                )}
              </>
            );
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
        </Popup>
      </div>
    );
  };

  const renderCommonInputs = () => {
    return (
      <div className="flex pt-2 text-xs">
        <div className="w-40 flex flex-col">
          <label className={gtw.label}>Allow disabling</label>
          <BooleanDropdownSelecter
            className="w-40 text-xs mb-2"
            value={editedContainer.instantiationRules?.allowDisabling}
            onChange={(newVal) => {
              setEditedContainer({
                ...editedContainer,
                instantiationRules: {
                  ...editedContainer.instantiationRules,
                  allowDisabling: newVal,
                },
              });
              setContainerChanged(true);
            }}
          />
          <div className={gtw.label}>Instantiate on start</div>
          <BooleanDropdownSelecter
            className="w-40 text-xs mb-2"
            value={editedContainer.instantiationRules?.isMain}
            onChange={(newVal) => {
              setEditedContainer({
                ...editedContainer,
                instantiationRules: { ...editedContainer.instantiationRules, isMain: newVal },
              });
              setContainerChanged(true);
            }}
          />
        </div>
      </div>
    );
  };

  const renderComponent = (c: ComponentType) => {
    const isDefault = editedContainer.defaultComponentType === c.id;

    return (
      <div key={c.id} className={`${gtw.card} my-2 relative flex items-center`}>
        <div
          className="cursor-pointer flex-grow"
          onClick={() => {
            console.log("SCROLL TO THIS COMPONENT!");
          }}
        >
          {c.displayName}
        </div>
        <DefaultSelecterIcon
          isDefault={isDefault}
          onClick={() => {
            setEditedContainer({
              ...editedContainer,
              defaultComponentType: isDefault ? undefined : c.id,
            });
            setContainerChanged(true);
          }}
        />
        <div
          className="ml-4 cursor-pointer h-5 w-5 text-gray-600"
          onClick={() => {
            setEditedContainer({
              ...editedContainer,
              componentTypeIDs: editedContainer.componentTypeIDs.filter((id) => id !== c.id),
              ...(isDefault && { defaultComponentType: undefined }),
            });
            setContainerChanged(true);
          }}
        >
          <CloseIcon />
        </div>
      </div>
    );
  };

  return (
    <div className={`border-2 border-gray-400 border-dashed rounded p-4 w-full flex flex-col`}>
      <div className="pb-4">
        <div className="flex justify-between">
          <EditableName
            className="w-26"
            loading={loading}
            name={editedContainer.displayName}
            onChange={(newName) => {
              if (!loading) {
                setLoading(true);
                updateContainer(container.id, {
                  displayName: newName,
                })
                  .then(() => {
                    setLoading(false);
                  })
                  .catch((e) => {
                    Sentry.captureException(e);
                    setLoading(false);
                  });
              }
            }}
          />
          {renderOptions()}
        </div>
        <div className={gtw.label}>Name: {container.name}</div>
        {renderCommonInputs()}
      </div>

      {addedComponentTypes.map(renderComponent)}

      <Popup
        mt={35}
        className="text-xs"
        content={(closeMe) => {
          return (
            <div>
              {addableComponentTypes.map((compType) => (
                <button
                  key={compType.id}
                  className={gtw.popupBtn}
                  onClick={() => {
                    setEditedContainer({
                      ...editedContainer,
                      componentTypeIDs: [...editedContainer.componentTypeIDs, compType.id],
                    });
                    setContainerChanged(true);
                    closeMe();
                  }}
                >
                  {compType.displayName}
                </button>
              ))}
            </div>
          );
        }}
      >
        <button className={`${gtw.smallBtn}`}>Add Component to container</button>
      </Popup>

      {containerChanged && (
        <div className="flex mt-4">
          <button
            className={`${gtw.smallBtn} flex-1 mr-2`}
            onClick={() => {
              updateContainer(editedContainer.id, editedContainer)
                .then(() => {
                  setContainerChanged(false);
                })
                .catch((e) => {
                  Sentry.captureException(e);
                });
            }}
          >
            Save
          </button>
          <button
            className={`${gtw.smallBtn} flex-1 ml-2`}
            onClick={() => {
              setEditedContainer(container);
              setContainerChanged(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default ContainerEditor;

export const DefaultSelecterIcon: React.FC<{ onClick: () => void; isDefault: boolean }> = ({
  onClick,
  isDefault,
}) => {
  return (
    <div className="flex cursor-pointer items-center" onClick={() => onClick()}>
      <div className="text-xs font-medium mr-2">Default</div>
      <RadioButton active={isDefault} />
    </div>
  );
};
