import React, { useContext, useState } from "react";
import { useFileTags, useSimulationModels, useProjects } from "api/useFirebase";
import { FileQuery } from "model/datatypes";
import gtw from "gtw";
import Dropdown from "components/basic/Dropdown";
import CloseIcon from "components/basic/icons/CloseIcon";
import { useUserRole } from "api/useAuth";
import { store } from "store";

const FileFilter: React.FC<{
  query: FileQuery;
  updateQuery: (newQuery: FileQuery) => void;
}> = ({ query, updateQuery }) => {
  const { tags, types } = useFileTags();
  const { state } = useContext(store);
  const { user, teamIds } = state;

  const { hasDeveloperAccess } = useUserRole();

  const { allModels } = useSimulationModels(hasDeveloperAccess, teamIds, user?.fbUser.uid);
  const { myProjects } = useProjects(hasDeveloperAccess, teamIds, user?.fbUser.uid);

  const renderType = (type: string) => {
    const isSelected = type === query.type;
    return (
      <div
        key={type}
        className={`px-3 py-1 mx-1 my-1 text-xs cursor-pointer rounded border-2 border-blue-600 shadow ${
          isSelected ? "text-white bg-blue-600" : "text-gray-600"
        }`}
        onClick={() => {
          const newQuery = { ...query };
          isSelected ? delete newQuery.type : (newQuery.type = type);
          updateQuery(newQuery);
        }}
      >
        {type}
      </div>
    );
  };

  return (
    <div className="px-4 py-4 text-xs">
      <div className="font-medium">Search for tags (OR)</div>
      <div className="w-48 mb-2">
        <TagSearcher
          tags={tags}
          selectedTags={query.tags}
          selectTag={(tag) => updateQuery({ ...query, tags: [...query.tags, tag] })}
          removeTag={(tag) =>
            updateQuery({ ...query, tags: query.tags.filter((t) => t !== tag) })
          }
        />
      </div>
      <div className="font-medium">Filter by type</div>
      <div className="flex mb-2 flex-wrap">{types.map(renderType)}</div>
      <div className="font-medium">In project</div>
      <Dropdown
        className="w-40"
        placeholder="Filter by project"
        selectedID={query.projectRef?.id}
        onSelect={(option) => {
          updateQuery({ ...query, projectRef: option.val });
        }}
        options={[
          ...myProjects.map((p) => ({
            id: p.id,
            display: p.projectName,
            val: { id: p.id, displayName: p.projectName },
          })),
          { id: "none", display: "No Project", val: undefined },
        ]}
      />
      <div className="font-medium">In system</div>
      <Dropdown
        className="w-40"
        placeholder="Filter by model"
        selectedID={query.modelRef?.id}
        onSelect={(option) => {
          updateQuery({ ...query, modelRef: option.val });
        }}
        options={[
          ...allModels.map((simModel) => ({
            id: simModel.id,
            display: simModel.displayName,
            val: { id: simModel.id, displayName: simModel.displayName },
          })),
          { id: "none", display: "No model", val: undefined },
        ]}
      />
    </div>
  );
};

export default FileFilter;

export const TagSearcher: React.FC<{
  tags: string[];
  selectedTags: string[];
  selectTag: (tag: string) => void;
  removeTag: (tag: string) => void;
}> = ({ tags, selectedTags, selectTag, removeTag }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const renderTag = (tag: string) => {
    return (
      <div
        key={tag}
        className={`px-1 mx-1 my-1 text-xs cursor-pointer rounded-lg border-2 border-green-600 shadow text-white bg-green-600 flex items-center`}
        onClick={() => {
          removeTag(tag);
        }}
      >
        <div className="pr-1 pl-1">{tag}</div>
        <div className="h-4 w-4">
          <CloseIcon />
        </div>
      </div>
    );
  };
  const matchedTags =
    searchTerm.length > 0 &&
    tags
      .filter((tag) => !selectedTags.some((st) => st === tag))
      .filter((tag) => tag?.search(searchTerm) !== -1);
  return (
    <div className="relative w-full">
      <input
        className={gtw.input}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {matchedTags && (
        <div className="absolute top-0 right-0 w-full mt-8 z-20 border border-gray-200 bg-white shadow-md rounded-lg text-xs">
          {matchedTags.map((mt) => (
            <div
              className="w-full text-center border-b border-gray-200 hover:bg-gray-100 py-2 cursor-pointer"
              onClick={() => {
                selectTag(mt);
                setSearchTerm("");
              }}
            >
              {mt}
            </div>
          ))}
          {(!matchedTags || matchedTags.length === 0) && (
            <div className="w-full text-center py-2">No tags found...</div>
          )}
        </div>
      )}
      <div className="flex">{selectedTags.map(renderTag)}</div>
    </div>
  );
};
