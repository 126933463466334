import React, { useMemo, useState } from "react";
import { useFirebase, useGroupProcessors } from "api/useFirebase";
import { SimulationModel, GroupProcessor } from "model/datatypes";
import * as Sentry from "@sentry/browser";
import gtw from "gtw";
import { Popup } from "components/basic/Popup";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import Toast from "components/basic/Toast";
import { AddEditGroupProcessor } from "components/systems/GroupProcessors";
import Modal from "components/basic/Modal";
import { fsFieldvalue } from "utils/firebase/helpers";

const GroupProcessorSelecter: React.FC<{ simulationModel: SimulationModel }> = ({
  simulationModel,
}) => {
  const [editedProcessors, setEditedProcessors] = useState<string[]>(
    simulationModel.groupProcessors || []
  );

  const groupProcessors = useGroupProcessors();
  const attachedGroupProcessors = useMemo(
    () => groupProcessors.filter((gp) => editedProcessors.some((epID) => epID === gp.id)),
    [editedProcessors, groupProcessors]
  );
  const selectableProcessors = useMemo(
    () => groupProcessors.filter((gp) => !editedProcessors.some((epID) => epID === gp.id)),
    [editedProcessors, groupProcessors]
  );
  const [editingProcessor, setEditingProcessor] = useState<false | GroupProcessor>(false);

  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const fb = useFirebase();

  const saveModelChanges = () => {
    setLoading(true);
    fb.firestore()
      .collection("SimulationModels")
      .doc(simulationModel.id)
      .update({
        groupProcessors: editedProcessors,
        version: fsFieldvalue.increment(1),
      })
      .then(() => {
        setIsChanged(false);
        setLoading(false);
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        console.log(error);
        Toast("Error saving");
        setLoading(false);
      });
  };

  const renderOption = (processor: GroupProcessor) => {
    return (
      <Popup
        useHover
        className="text-xs"
        mt={15}
        content={(closeMe) => {
          return (
            <>
              <button
                className={`${gtw.popupBtn} text-xs`}
                onClick={() => {
                  setEditedProcessors(editedProcessors.filter((pp) => pp !== processor.id));
                  setIsChanged(true);
                  closeMe();
                }}
              >
                Remove from system
              </button>
              <button
                className={`${gtw.popupBtn} text-xs`}
                onClick={() => {
                  setEditingProcessor(processor);
                  closeMe();
                }}
              >
                Edit group processor
              </button>
            </>
          );
        }}
      >
        <DotDotDotIcon />
      </Popup>
    );
  };

  const renderPostProcessorCard = (processor: GroupProcessor, i: number) => {
    return (
      <div
        key={processor.id}
        className="px-4 py-2 bg-white border border-gray-200 shadow-md rounded mr-4 relative text-xs"
      >
        <div className="absolute top-0 mt-2 right-0 mr-2">{renderOption(processor)}</div>
        <div className=" font-medium mr-6">{processor.name}</div>
        <div className={""}>{processor.path}</div>
        <div className={""}>{processor.reference}</div>
      </div>
    );
  };

  return (
    <div className="mt-4 mb-6">
      <div className="font-medium pb-2">Included group processors</div>
      <div className={`flex flex-wrap items-end ${loading ? "opacity-50" : ""}`}>
        {attachedGroupProcessors.map(renderPostProcessorCard)}
        <Popup
          mt={35}
          content={(closeMe) => (
            <>
              {selectableProcessors.map((spp) => (
                <button
                  key={spp.id}
                  className={`${gtw.popupBtn} text-xs`}
                  onClick={() => {
                    setEditedProcessors([...editedProcessors, spp.id]);
                    setIsChanged(true);
                    closeMe();
                  }}
                >
                  {spp.name}
                </button>
              ))}
              {selectableProcessors.length === 0 && (
                <div className="italic text-xs">No processors available</div>
              )}
            </>
          )}
        >
          <button className={` ${gtw.smallBtn}`}>Add group processor</button>
        </Popup>
      </div>
      {isChanged && (
        <div className="py-2">
          <button className={`${gtw.smallBtn} mr-2`} onClick={() => saveModelChanges()}>
            Save Changes
          </button>
          <button
            className={gtw.smallBtn}
            onClick={() => {
              setEditedProcessors(simulationModel.groupProcessors || []);
              setIsChanged(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
      {editingProcessor && (
        <Modal onClose={() => setEditingProcessor(false)}>
          <AddEditGroupProcessor
            edtingProcessor={editingProcessor}
            onClose={() => setEditingProcessor(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default GroupProcessorSelecter;
