import {
  ComponentType,
  Container,
  RawDataReport,
  Scenario,
  SimulationModel,
} from "model/datatypes";
import { instantiateAllComponentsFromPrev } from "utils/ComponentTypeHelpers";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import app from "firebase/app";

export const mergeScenarioSystem = async (
  fb: app.app.App,
  projectID: string,
  scenario: Scenario,
  newModel: SimulationModel
) => {
  const previousComponents = scenario.simComponents;
  const fs = fb.firestore();
  //get simulation model and merge new components to the updated version.....
  const batch = fs.batch();

  const scenarioDoc = fs
    .collection("Projects")
    .doc(projectID)
    .collection("Scenarios")
    .doc(scenario.id);

  const modelDoc = fs.collection("SimulationModels").doc(newModel.id);

  const compSnap = await modelDoc.collection("Components").get();
  const updatedCompTypes: ComponentType[] = [];
  compSnap.forEach((doc) => {
    const c = { id: doc.id, ...doc.data() } as ComponentType;
    updatedCompTypes.push(c);
  });

  const contSnap = await modelDoc.collection("Containers").get();
  const updatedContainers: Container[] = [];
  contSnap.forEach((doc) => {
    const c = { id: doc.id, ...doc.data() } as Container;
    updatedContainers.push(c);
  });

  //instantiate from types and merge with previoous simcomponents:
  let simComponents = instantiateAllComponentsFromPrev(
    updatedCompTypes,
    updatedContainers,
    scenario.simComponents
  );

  const scenarioFieldsToUpdate: Partial<Scenario> = {
    model: newModel,
    groupProcessors: newModel.groupProcessors,
    simComponents,
  };

  //update the scenario
  batch.update(scenarioDoc, convertToFirestoreFormat(scenarioFieldsToUpdate));

  //overwrite local system model with updated model
  const newLocalModelDoc = scenarioDoc.collection("SimulationModel").doc(newModel.id);
  const globalModelCopy = { ...newModel, localVersion: newModel.version };

  batch.set(newLocalModelDoc, convertToFirestoreFormat(globalModelCopy));
  console.log({ globalModelCopy });

  //Copy all components from global model
  updatedCompTypes.forEach((protoC) => {
    // console.log({ protoC, convertedFS: convertToFirestoreFormat(protoC) });
    const localModelCompDoc = newLocalModelDoc.collection("Components").doc(protoC.id);
    batch.set(localModelCompDoc, convertToFirestoreFormat(protoC));
  });
  //Copy all containers from global model
  updatedContainers.forEach((container) => {
    const localModelCompDoc = newLocalModelDoc.collection("Containers").doc(container.id);
    batch.set(localModelCompDoc, convertToFirestoreFormat(container));
  });

  //clean up after updated/new model is merged.
  if (scenario.model.id === newModel.id) {
    previousComponents.forEach((simC) => {
      if (!updatedCompTypes.some((protoC) => protoC.id === simC.id)) {
        batch.delete(newLocalModelDoc.collection("Components").doc(simC.id));
      }
      //delete all prev containers previously used but are now removed
      if (
        simC.containerID &&
        !updatedContainers.some((cont) => cont.id === simC.containerID)
      ) {
        batch.delete(newLocalModelDoc.collection("Containers").doc(simC.containerID!));
      }
    });
  } else {
    const prevModelDoc = scenarioDoc.collection("SimulationModel").doc(scenario.model.id);
    //delete all prev model that is now removed
    previousComponents.forEach((simC) => {
      batch.delete(prevModelDoc.collection("Components").doc(simC.id));
      if (simC.containerID) {
        batch.delete(prevModelDoc.collection("Containers").doc(simC.containerID!));
      }
    });
    batch.delete(prevModelDoc);
  }

  if (scenario.model.id !== newModel.id) {
    //delete prev data reports
    const reportsSnap = await scenarioDoc.collection("RawDataReports").get();
    reportsSnap.forEach((doc) => {
      batch.delete(doc.ref);
    });
  }

  //overwrite all rawdata reports:
  const reportsSnap = await modelDoc.collection("RawDataReports").get();
  reportsSnap.forEach((doc) => {
    const report = { id: doc.id, ...doc.data() } as RawDataReport;
    const localReportDoc = scenarioDoc.collection("RawDataReports").doc(report.id);
    batch.set(localReportDoc, convertToFirestoreFormat(report));
  });

  await batch.commit();
  return scenarioFieldsToUpdate;
};
