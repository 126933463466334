import { DataSource, VariableReference, InputVariableReference } from "model/datatypes";

export const getInputVarRef = (source: DataSource, varRef: VariableReference) => {
  return {
    id: source.id + varRef.id,
    variableName: varRef.variableName,
    sourceID: source.id,
    parameterID: varRef.id,
  } as InputVariableReference;
};

export const getSimpleVariableRef = (ref: InputVariableReference) => {
  return {
    id: ref.parameterID,
    variableName: ref.variableName,
  } as VariableReference;
};
