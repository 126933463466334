import React, { useContext, useEffect, useMemo } from "react";
import "./index.css";
import useAuth, { useUserRole } from "./api/useAuth";
import Login from "./components/login/Login";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import FirebaseProvider from "api/FirebaseProvider";
import InputPage from "components/InputPage";
import HomePage from "components/HomePage";
import userAdministration from "components/UserAdministrationPage";
import OverviewPage from "components/OverviewPage";
import SimulationPage from "components/SimulationsPage";
import { StateProvider, store } from "store";
import AdminPages from "components/AdminPage";
import SignUpPage from "components/SignUpPage";
import Sidebar from "components/sidebar/Sidebar";
import { useFirebase } from "api/useFirebase";
import LoadingOverlay from "components/basic/LoadingOverlay";
import { ReportViewPage } from "components/ReportViewPage";
import APIPage from "components/APIPage";
import Comments from "components/comments/Collaboration";

const isLocalHost = window.location.host.match("localhost") !== null;

const App: React.FC = () => {
  return (
    <StateProvider>
      <FirebaseProvider>
        <BrowserRouter>
          <LoginWall>
            <TheApp />
          </LoginWall>
        </BrowserRouter>
      </FirebaseProvider>
    </StateProvider>
  );
};

//Log into main and try to log into ORG:
const LoginWall: React.FC = ({ children }) => {
  useLocalStorageCache();
  useCheckIfDevMode();
  const { loading: authLoading } = useAuth();
  const { state } = useContext(store);
  const { user } = state;
  const fb = useFirebase(); //check firebase is actually ready!
  const isReady = useMemo(() => !!user && !!fb, [user, fb]);

  return (
    <>
      {!isReady ? (
        <div className="relative">
          <Switch>
            <Route path="/signup/:inviteID" component={SignUpPage} />
            <Route component={Login} />
          </Switch>
          {authLoading && <LoadingOverlay />}
        </div>
      ) : (
        children
      )}
    </>
  );
};

const TheApp: React.FC = () => {
  const { hasOwnerAccess } = useUserRole();
  return (
    <div className={tw.container}>
      <Sidebar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/input" component={InputPage} />
        <Route path="/systems" component={AdminPages} />
        <Route path="/files" component={AdminPages} />
        <Route path="/api" component={APIPage} />
        <Route path="/overview" component={OverviewPage} />
        {hasOwnerAccess && <Route path="/administration" component={userAdministration} />}
        <Route
          path="/simulations/:projectName?/:projectID?/:groupID?/:scenarioID?"
          component={SimulationPage}
        />
        <Route exact path="/reports/:projectID/:scenarioID" component={ReportViewPage} />
      </Switch>
      <Comments />
    </div>
  );
};

export default App;

const tw = {
  container: "w-screen min-h-screen relative pl-56 bg-gray-bg text-gray-800",
};

const useLocalStorageCache = () => {
  const { dispatch } = useContext(store);
  useEffect(() => {
    const cachedProjectID = localStorage.getItem("selectedProjectID");
    const cachedProjectName = localStorage.getItem("selectedProjectName");
    const cachedOrganisationID = localStorage.getItem("organisationID");
    const cachedOrganisationName = localStorage.getItem("organisationName");
    const cachedGoogleProject = localStorage.getItem("googleProject");

    if (cachedProjectID)
      dispatch({
        type: "SET_CURRENT_PROJECT",
        payload: {
          projectID: cachedProjectID,
          projectName: cachedProjectName,
        },
      });
    if (cachedOrganisationID && cachedOrganisationName && cachedGoogleProject)
      dispatch({
        type: "SET_ORGANISATION",
        payload: {
          id: cachedOrganisationID,
          name: cachedOrganisationName,
          google_project: cachedGoogleProject,
        },
      });
  }, [dispatch]);
};

const useCheckIfDevMode = () => {
  const { dispatch } = useContext(store);
  useEffect(() => {
    if (isLocalHost)
      dispatch({
        type: "SET_IN_DEV_MODE",
      });
  }, [dispatch]);
};
