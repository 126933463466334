import { useFirebase } from "api/useFirebase";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { store } from "store";
import { completeUserSignup } from "utils/firebase/firebaseUtils";
import LoadingOverlay from "./basic/LoadingOverlay";

const SignUpPage: React.FC<{}> = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [userMail, setUserMail] = useState("");
  const fb = useFirebase();
  const { dispatch } = useContext(store);
  const history = useHistory();
  const [validInvitation, setValidInvitation] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const location = useLocation();

  let invitationID: string = location.pathname.split("/").slice(-1)[0];
  let orgID: string = location.pathname.split("/").slice(-2)[0];

  useEffect(() => {
    dispatch({
      type: "SET_ORGANISATION",
      payload: {
        id: orgID,
      },
    });
  }, [dispatch, orgID]);

  useEffect(() => {
    setPageLoading(true);
    if (fb) {
      let docRef = fb.firestore().collection("invitations").doc(invitationID);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setValidInvitation(true);
          setPageLoading(false);
          if (data) {
            setUserRole(data.userRole);
            setUserMail(data.mail);
          }
        } else {
          setValidInvitation(false);
          setPageLoading(false);
        }
      });
    }
  }, [fb, invitationID]);

  const finishSignUp = () => {
    fb.auth()
      .createUserWithEmailAndPassword(userMail, password)
      .then((user) => {
        if (!loading) {
          setLoading(true);
          const userID = user.user?.uid;
          if (userID) {
            completeUserSignup(
              {
                invitationID: invitationID,
                firstName: firstName,
                lastName: lastName,
                userID: userID,
              },
              fb
            )
              .then(() => {
                console.log("Succesful sign up");
              })
              .catch((err) => {
                console.log("Unsuccesful sign up", err);
              });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorCode = error.code;
        let errorMessage = error.message;
        if (errorCode === "auth/weak-password") {
          alert("The password is too weak.");
        } else {
          alert(errorMessage);
        }
        console.log(error);
      });
  };

  const renderSignUpForm = () => {
    return (
      <div className="bg-white shadow-md rounded w-1/2 px-8 pt-6 pb-8 mb-4">
        <div className="text-lg font-bold text-center mb-4">Finish your sign-up</div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">First name</label>
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autoComplete="first-name"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Last name</label>
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autoComplete="last-name"
            className=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="new-password">
            Password
          </label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="off"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">User role</label>
          <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-300 leading-tight">
            {userRole}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Mail</label>
          <div className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-300 leading-tight">
            {userMail}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-green-400 hover:bg-green-600 w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={finishSignUp}
          >
            Sign Up
          </button>
        </div>
        <div
          onClick={() => history.push("/")}
          className="cursor-pointer text-xs text-center mt-2"
        >
          Already a user? Login here
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full relative flex justify-center mt-6 align-middle">
      {(pageLoading || loading) && <LoadingOverlay />}
      {validInvitation && !pageLoading && renderSignUpForm()}
      {!pageLoading && !validInvitation && (
        <div className="bg-white shadow-md rounded w-full px-8 pt-6 pb-8 mb-4">
          <div className="text-lg font-bold text-center mb-4">This invitation is invalid</div>
          <div className="text-sm text-center mt-2">Please contact your administrator</div>
        </div>
      )}
    </div>
  );
};

export default SignUpPage;
