import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  Scenario,
  SimulationModel,
  LocalSimulationModel,
  RawDataReport,
} from "model/datatypes";
import Dropdown from "components/basic/Dropdown";
import {
  useSimulationModels,
  useFirebase,
  useGroups,
  useComponentTypes,
  useContainers,
  useAnalyticsLogger,
  useProjects,
  useFirestore,
} from "api/useFirebase";
import gtw from "gtw";
import { store } from "store";
import * as Sentry from "@sentry/browser";
import Modal from "components/basic/Modal";
import { checkScenarioStatus } from "utils/checkScenarioStatus";
import { instantiateAllComponents } from "utils/ComponentTypeHelpers";
import moment from "moment";
import app from "firebase/app";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { useUserRole } from "api/useAuth";

interface Props {
  onFinish: () => void;
  projectID: string;
  groupID?: string;
  fromScenario?: Scenario;
}

const NewScenario: React.FC<Props> = ({ onFinish, groupID, fromScenario, projectID }) => {
  const analyticsLogger = useAnalyticsLogger();
  const { state, dispatch } = useContext(store);
  const { user, teamIds } = state;
  const { hasDeveloperAccess } = useUserRole();

  const [selectedProjectID, setSelectedProjectID] = useState(projectID);
  const { allProjects } = useProjects(hasDeveloperAccess, teamIds, user?.fbUser.uid);

  const { allModels } = useSimulationModels(hasDeveloperAccess, teamIds, user?.fbUser.uid);

  const fb = useFirebase();
  const fs = useFirestore();

  const [selectedModel, setselectedModel] = useState<null | SimulationModel>(null);

  const { componentTypes } = useComponentTypes(selectedModel?.id, projectID, fromScenario?.id);
  const { containers } = useContainers(selectedModel?.id, projectID, fromScenario?.id);

  const [scenarioName, setscenarioName] = useState("");
  const [selectedGroupID, setSelectedGroupID] = useState(
    groupID ? groupID : fromScenario ? fromScenario.groupID : undefined
  );

  const groups = useGroups(selectedProjectID || undefined);

  useEffect(() => {
    if (fromScenario) {
      setselectedModel(fromScenario.model);
      setscenarioName(`${fromScenario.scenarioName} copy`);
    }
  }, [fromScenario]);

  const modelOptions = useMemo(
    () =>
      allModels.map((model) => ({
        id: model.id,
        display: model.displayName,
        val: model,
      })),
    [allModels]
  );

  const readyToSave = useMemo(
    () => scenarioName.length > 0 && !!selectedGroupID && !!selectedModel,
    [scenarioName, selectedGroupID, selectedModel]
  );

  const saveNewScenario = async () => {
    if (selectedModel && scenarioName.length > 0 && selectedProjectID && selectedGroupID) {
      try {
        const batch = fb.firestore().batch();
        const projectDoc = fb.firestore().collection("Projects").doc(selectedProjectID);
        batch.update(projectDoc, { amountOfScenarios: app.firestore.FieldValue.increment(1) });
        const scenarioDoc = projectDoc.collection("Scenarios").doc();

        //get initial state:
        const initState = initialScenarioStateHelper(fromScenario);

        let simComponents = fromScenario
          ? fromScenario.simComponents
          : instantiateAllComponents(componentTypes, containers);

        const run_settings = fromScenario?.run_settings ||
          selectedModel.default_run_settings || {
            duration: 31622400,
            resolution: 30,
            result_resolution: 3600,
          };

        const endpoint =
          fromScenario?.endpoint || selectedModel.default_endpoint || "kubernetes";

        const firstProcessor = selectedModel.postProcessors
          ? selectedModel.postProcessors[0]
          : undefined;

        const defaultSelectedPostProcessor =
          fromScenario?.selectedPostProcessor || firstProcessor;

        //set the scennario:
        const newScenario: Scenario = {
          ...initState,
          id: scenarioDoc.id,
          created: moment(),
          simComponents,
          ownerId: state.user?.fbUser.uid!,
          dataSourceType: fromScenario?.dataSourceType || "static",
          model: selectedModel,
          scenarioName,
          groupID: selectedGroupID,
          status: fromScenario ? checkScenarioStatus(fromScenario) : { status: "incomplete" },
          run_settings,
          endpoint,
          groupProcessors: selectedModel.groupProcessors,
          selectedPostProcessor: defaultSelectedPostProcessor,
        };

        //if creating in a new project remove the datasources:
        if (selectedProjectID !== projectID && fromScenario) {
          delete newScenario.harvester;
          delete newScenario.dataSource;
          newScenario.status = checkScenarioStatus(newScenario);
        }

        batch.set(scenarioDoc, convertToFirestoreFormat(newScenario));

        //save group
        const groupDoc = projectDoc.collection("Groups").doc(newScenario.groupID);
        batch.update(groupDoc, {
          scenarios: app.firestore.FieldValue.arrayUnion(scenarioDoc.id),
        });

        //copy model to scenario for local model.
        const modelCopyDoc = scenarioDoc.collection("SimulationModel").doc(selectedModel.id);

        const modelCopy: LocalSimulationModel = {
          ...selectedModel,
          localVersion: selectedModel.version,
        };
        batch.set(modelCopyDoc, convertToFirestoreFormat(modelCopy));
        //copy component types:
        componentTypes.forEach((compoent) => {
          const compCopyDoc = modelCopyDoc.collection("Components").doc(compoent.id);
          const compCopy = { ...compoent };
          batch.set(compCopyDoc, convertToFirestoreFormat(compCopy));
        });

        //copy containers
        containers.forEach((container) => {
          const contCopydoc = modelCopyDoc.collection("Containers").doc(container.id);
          batch.set(contCopydoc, convertToFirestoreFormat(container));
        });

        const reportsCol = fromScenario
          ? fs
              .collection("Projects")
              .doc(projectID)
              .collection("Scenarios")
              .doc(fromScenario.id)
              .collection("RawDataReports")
          : fs
              .collection("SimulationModel")
              .doc(selectedModel.id)
              .collection("RawDataReports");

        //overwrite all reports:
        const reportsSnap = await reportsCol.get();
        reportsSnap.forEach((doc) => {
          const report = { id: doc.id, ...doc.data() } as RawDataReport;
          const localReportDoc = scenarioDoc.collection("RawDataReports").doc(report.id);
          batch.set(localReportDoc, convertToFirestoreFormat(report));
        });

        //commit changes:
        batch.commit().then(() => {
          dispatch({
            type: "SELECT_SCENARIO",
            payload: { ...newScenario, id: scenarioDoc.id },
          });
          dispatch({ type: "SELECT_GROUP", payload: selectedGroupID });
          const projectName =
            allProjects.find((p) => p.id === selectedProjectID)?.projectName || "";

          if (selectedProjectID !== projectID) {
            dispatch({
              type: "SET_CURRENT_PROJECT",
              payload: { projectName, projectID: selectedProjectID },
            });
          }
          analyticsLogger("scenario_created", {
            project_name: projectName,
            project_id: selectedProjectID,
            scenarioName: newScenario.scenarioName,
            user_name: user?.fullName || "",
            user_id: user?.fbUser.uid || "",
          });
        });
        onFinish();
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        onFinish();
      }
    }
  };

  return (
    <Modal onClose={() => onFinish()}>
      <div className="relative z-40 w-1/2 bg-white px-8 py-4 rounded shadow-xl border border-gray-200 text-xs">
        <div className="font-medium text-lg">New simulation scenario</div>
        {fromScenario && <div className="italic">From copy</div>}
        <div className={`${gtw.label} mt-4`}>Scenario Name</div>
        <input
          type="text"
          className={`${gtw.input} w-full text-sm mb-4`}
          value={scenarioName}
          onChange={(e) => {
            setscenarioName(e.target.value);
          }}
        />
        {!fromScenario && (
          <>
            <div className={`${gtw.label}`}>Simulation model</div>
            <Dropdown
              className={`w-full mb-4`}
              selectedID={selectedModel?.id}
              options={modelOptions}
              placeholder="Select model"
              onSelect={(option) => {
                setselectedModel(option.val as SimulationModel);
              }}
            />
          </>
        )}
        <div className={`${gtw.label}`}>Project</div>
        <Dropdown
          className={`w-full mb-4`}
          selectedID={selectedProjectID}
          options={allProjects.map((project) => ({
            id: project.id,
            display: project.projectName,
          }))}
          placeholder="Select Group"
          onSelect={(option) => {
            if (option.id !== selectedProjectID) {
              setSelectedGroupID(undefined);
              setSelectedProjectID(option.id);
            }
          }}
        />

        <div className={`${gtw.label}`}>Group</div>
        {groups && (
          <Dropdown
            className={`w-full mb-4`}
            selectedID={selectedGroupID}
            options={groups.map((group) => ({
              id: group.id,
              display: group.groupName,
              val: group.id,
            }))}
            placeholder="Select Group"
            onSelect={(option) => {
              setSelectedGroupID(option.val);
            }}
          />
        )}
        <button
          className={`w-full py-1 shadow-md rounded border border-gray-200 ${
            !readyToSave ? "opacity-50" : ""
          }`}
          onClick={() => {
            readyToSave && saveNewScenario();
          }}
        >
          Ok
        </button>
      </div>
    </Modal>
  );
};

export default NewScenario;

const initialScenarioStateHelper: (fromScenario?: Scenario) => Partial<Scenario> = (
  fromScenario?: Scenario
) => {
  if (fromScenario)
    return {
      created: moment(),
      dataSource: fromScenario.dataSource,
      scenarioName: fromScenario.scenarioName + " copy",
      description: fromScenario.description,
      harvester: fromScenario.harvester,
      meta_data: fromScenario.meta_data,
    };
  return {
    created: moment(),
    dataSource: null,
    scenarioName: "",
    description: "",
  };
};
