import React, { useState, useEffect, useContext, useMemo } from "react";
import Dropzone from "components/basic/upload/Dropzone";
import Papa from "papaparse";
import { store } from "store";
import { useFirebase, useScenario } from "api/useFirebase";
import Modal from "components/basic/Modal";
import { VariableReference } from "model/datatypes";
import { DataSource } from "model/datatypes";
import { Popup } from "components/basic/Popup";
import VariableDetails from "components/plots/VariableDetails";
import { parseInput } from "utils/parseInputFormater";
import { immutableSplice } from "utils/jsUtils/imutableArray";
import ScheduleInput from "components/input/schedule/ScheduleInput";
import WeatherInput from "components/input/weather/WeatherInput";
import * as Sentry from "@sentry/browser";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import EditableName from "components/basic/EditableName";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import gtw from "gtw";
import LoadingOverlay from "components/basic/LoadingOverlay";
import app from "firebase/app";

type InputData = {
  variableName: string;
  variableType: "string" | "number";
  values: (string | number)[];
};

const Dataset: React.FC<{ dataSource: DataSource }> = ({ dataSource }) => {
  const [selectedVariableID, setselectedVariableID] = useState<string | null>(null);
  const selectedVariable = useMemo(
    () => dataSource.simulationVariables.find((v) => v.id === selectedVariableID),
    [dataSource, selectedVariableID]
  );
  const [file, setfile] = useState<File | null>(null);
  const fb = useFirebase();
  const { state } = useContext(store);
  const { projectID } = state;
  const [inputWeatherOpen, setinputWeatherOpen] = useState(false);
  const [scheduleInputOpen, setScheduleInputOpen] = useState<boolean | string>(false);
  const [loading, setLoading] = useState(false);

  const changeDatasetName = (newName: string) => {
    if (projectID) {
      setLoading(true);
      fb.firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .doc(dataSource.id)
        .update({ dataSetName: newName })
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          setLoading(false);
        });
    }
  };
  const deleteDataset = () => {
    if (projectID) {
      setLoading(true);
      const deleteFullInput = fb.functions().httpsCallable("deleteFullInput");
      deleteFullInput({ projectID, inputID: dataSource.id })
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          setLoading(false);
        });
    }
  };
  const renderOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        pos={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button className={`${gtw.popupBtn} opacity-50`} onClick={(e) => {}}>
              Do something to dataset?
            </button>
            {DeleteOption(closeMe)}
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  const DeleteOption = (onFinish: () => void) => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete dataset?</div>
              <div className="italic mb-2">
                This is a destructive event that can't be undone.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`${gtw.smallBtn} border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!loading) {
                        deleteDataset();
                        closeDeletePopup();
                        onFinish();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`${gtw.smallBtn} w-full`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`${gtw.popupBtn} text-red-500 border-t border-gray-400`}>
          Delete Dataset
        </button>
      </Popup>
    );
  };

  const renderAddOptions = (manualOpen: () => void, error: string | null) => {
    return (
      <Popup
        useHover
        mt={20}
        content={(closePopup) => (
          <div className="text-xs">
            <button
              className={`border-b border-gray-400 ${tw.popupBtn}`}
              onClick={() => {
                manualOpen();
                closePopup();
              }}
            >
              Add csv
            </button>
            <button
              className={`border-b border-gray-400 ${tw.popupBtn}`}
              onClick={() => {
                setinputWeatherOpen(true);
                closePopup();
              }}
            >
              Add weather
            </button>
            <button
              className={`border-b border-gray-400 ${tw.popupBtn}`}
              onClick={() => {
                setScheduleInputOpen(true);
                closePopup();
              }}
            >
              Add schedule
            </button>
            <button className={`${tw.popupBtn} opacity-50`}>Find input</button>
          </div>
        )}
      >
        <div className="flex items-center">
          <button className="relative bg-white mr-4 focus:outline-none shadow rounded-full h-8 w-8 flex justify-center items-center border border-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z" />
            </svg>
          </button>
          {error && <div className="text-xs ml-2 text-red-600 italic">{error}</div>}
        </div>
      </Popup>
    );
  };

  return (
    <Dropzone
      allowedTypes="csv"
      className=""
      onFilesAdded={(files) => {
        setfile(files[0]);
      }}
    >
      {(dropHovered, error, manualOpen) => {
        return (
          <div
            className={`${tw.card} relative ${
              dropHovered ? "border-4 border-green-600" : ""
            } mb-4`}
          >
            {file && (
              <Modal onClose={() => setfile(null)}>
                <div className="w-3/4 z-50">
                  <CSVInput
                    inputDataID={dataSource.id}
                    file={file}
                    onFinish={() => setfile(null)}
                  />
                </div>
              </Modal>
            )}
            {inputWeatherOpen && (
              <Modal onClose={() => setinputWeatherOpen(false)}>
                <WeatherInput />
              </Modal>
            )}
            {scheduleInputOpen && (
              <Modal onClose={() => setScheduleInputOpen(false)}>
                <div className={`${tw.card} w-3/4 z-50`}>
                  <ScheduleInput
                    onFinished={(data) => {
                      console.log(data);
                      setScheduleInputOpen(false);
                    }}
                    inputDataID={dataSource.id}
                    savedScheduleID={
                      typeof scheduleInputOpen === "string" ? scheduleInputOpen : undefined
                    }
                  />
                </div>
              </Modal>
            )}
            {loading && <LoadingOverlay />}
            <div className="px-8 py-4">
              <div className="flex items-center justify-between">
                <EditableName
                  name={dataSource.dataSetName}
                  onChange={changeDatasetName}
                  loading={loading}
                  className=""
                />
                {renderOptions()}
              </div>
              <InputOrigin origin={dataSource.origin} />
              <div className="flex">
                <div
                  className="flex flex-col h-full overflow-auto w-1/3 pr-2"
                  style={{ maxHeight: "482px" }}
                >
                  {dataSource.simulationVariables.map((variable) => {
                    const isSelected = variable.id === selectedVariableID;
                    const isSchedule = variable.origin === "schedule";
                    return (
                      <div
                        onClick={() => setselectedVariableID(isSelected ? null : variable.id)}
                        className={`${tw.variable} ${
                          isSelected ? tw.selectedVar : "text-gray-700"
                        }`}
                        key={variable.id}
                      >
                        <span>{variable.variableName}</span>
                        {isSchedule && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setScheduleInputOpen(variable.id);
                            }}
                            className="focus:outline-none italic"
                          >
                            edit
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="w-2/3 pl-2">
                  <VariableDetails
                    variable={selectedVariable || null}
                    inputDataID={dataSource.id}
                    allowDelete
                    onDelete={() => {
                      setselectedVariableID(null);
                    }}
                  />
                </div>
              </div>
              {renderAddOptions(manualOpen, error)}
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};

type CSVData = {
  variableName: string;
  variableType: "string" | "number";
  values: (string | number)[];
  included: boolean;
};

const CSVInput: React.FC<{ inputDataID: string; file: File; onFinish: () => void }> = ({
  inputDataID,
  file,
  onFinish,
}) => {
  const [csvData, setcsvData] = useState<CSVData[] | null>(null);
  const [dataName, setdataName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const fb = useFirebase();
  const { state } = useContext(store);
  const { projectID } = state;
  const [uploading, setuploading] = useState(false);

  const onCsvParsed = (res: Papa.ParseResult<any>) => {
    try {
      //create variable to put data in:
      const headlineRow = res.data[0];
      let data: CSVData[] = headlineRow.map((headline: string) => {
        return { variableName: headline, values: [], included: true };
      });
      //populate with row values:
      res.data.forEach((row: string[], rowI) => {
        //skip headline
        if (rowI === 0) {
          return;
        }
        row.forEach((colVal, i) => {
          const parsedVal = parseInput(colVal);
          data[i].values.push(parsedVal);
        });
      });

      data = data.map((d) => ({
        ...d,
        variableType: typeof d.values[0] === "number" ? "number" : "string",
      }));
      console.log({ res, data });
      setcsvData(data);
    } catch (error) {
      console.log(error);
      setdataName(null);
      setError("Error parsing data");
    }
  };

  useEffect(() => {
    //parse the CSV data to JS object
    if (file) {
      console.log(file);
      Papa.parse(file, { complete: onCsvParsed });
      setdataName(file.name.split(".")[0]);
    }
  }, [file]);

  const onSave = () => {
    //save the input to Firestore.
    const dataToSave = csvData?.filter((d) => d.included);
    if (dataToSave && dataToSave.length > 0 && dataName && projectID) {
      setuploading(true);
      const savedParams: VariableReference[] = [];
      const batch = fb.firestore().batch();
      const inputDataRef = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .doc(inputDataID);
      //save the actual data in a subcollection
      dataToSave.forEach((data) => {
        const newDataRef = inputDataRef.collection("Data").doc();
        const inputDataToSave: InputData = {
          variableName: data.variableName,
          values: data.values,
          variableType: data.variableType,
        };
        batch.set(newDataRef, inputDataToSave);
        savedParams.push({ id: newDataRef.id, variableName: data.variableName });
      });

      //set update the main doc with the ref to its subcollections
      batch.update(inputDataRef, {
        simulationVariables: app.firestore.FieldValue.arrayUnion(...savedParams),
      });

      batch
        .commit()
        .then(() => {
          setcsvData(null);
          setuploading(false);
          onFinish();
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          setuploading(false);
        });
    }
  };

  return (
    <div className={`${tw.card} px-4 py-4 relative`}>
      {uploading && (
        <div
          className={`absolute top-0 left-0 w-full h-full font-bolder text-xl bg-gray-200 opacity-75 flex items-center justify-center z-50`}
        >
          Uploading... <LoadingIcon className="ml-2" />
        </div>
      )}
      <div className="font-bold">CSV input</div>
      {file && csvData && (
        <CSVPreviewer
          csvData={csvData}
          onUpdate={(newData) => {
            setcsvData(newData);
          }}
        />
      )}
      {csvData && dataName && (
        <div className="flex flex-col">
          <label className={`${tw.label}`}>Data reference</label>
          <input
            type="text"
            className={`${tw.input}`}
            value={dataName}
            onChange={(e) => setdataName(e.target.value)}
          />
        </div>
      )}
      {csvData && (
        <button
          className={`${tw.saveBtn} mt-2`}
          onClick={() => {
            if (dataName && csvData.length > 0 && !uploading) onSave();
          }}
        >
          Add CSV data to dataset
        </button>
      )}
      {error && <div className="italic text-red-500">{error}</div>}
    </div>
  );
};

const CSVPreviewer: React.FC<{
  csvData: CSVData[];
  onUpdate: (newData: CSVData[]) => void;
}> = ({ csvData, onUpdate }) => {
  const onToggleParam = (param: CSVData, index: number) => {
    const updatedParam = { ...param, included: !param.included };
    onUpdate(immutableSplice(csvData, index, 1, updatedParam));
  };

  return (
    <div className="my-4 relative max-w-full overflow-hidden">
      <div className="flex overflow-x-auto pb-4">
        {csvData.map((param, i) => (
          <div
            key={param.variableName}
            className={`mr-4 flex-none border border-gray-200 rounded shadow-md overflow-hidden
              ${param.included ? "" : "opacity-50"}
            `}
          >
            <div className="pt-4 pb-2 px-4">
              <div className={`${tw.label}`}>{param.variableName}</div>
              <div className="text-xs italic">
                {param.values.slice(0, 5).map((val, i) => (
                  <div key={i}>{val}</div>
                ))}
                <div>...</div>
              </div>
            </div>
            <div
              className="flex bg-gray-200 items-center px-2 py-1"
              onClick={() => onToggleParam(param, i)}
            >
              <input
                className="cursor-pointer"
                type="checkbox"
                checked={param.included}
                readOnly
              />
              <label className="text-xs ml-2 cursor-pointer font-medium">include</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const InputOrigin: React.FC<{ origin: DataSource["origin"] }> = ({ origin }) => {
  const projectID = useMemo(() => origin?.type === "output" && origin.projectID, [origin]);
  const scenarioID = useMemo(() => origin?.type === "output" && origin.scenarioID, [origin]);
  const scenario = useScenario(projectID || undefined, scenarioID || undefined);

  if (origin?.type === "output")
    return (
      <div className="text-xs italic">
        From scenario:
        <span className="font-bold"> {scenario ? scenario.scenarioName : "loading"}</span>
      </div>
    );
  else return <div className="text-xs italic">Manual input</div>;
};

export default Dataset;

const tw = {
  container: "w-screen min-h-screen relative pl-40 bg-gray-bg text-gray-800",
  headline: "font-medium mb-4 text-xl",
  card: "border border-gray-300 rounded bg-white shadow",
  label: "font-bold text-xs",
  dropdown: "w-40",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
  input: "px-2 py-2 focus:outline-none border rounded",
  saveBtn:
    "py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-sm hover:font-medium",
  closeBtn:
    "w-6 h-6 rounded-full text-xs text-gray-700 flex items-center justify-center bg-white shadow focus:outline-none border border-gray-200",
  variable:
    "cursor-pointer text-left px-2 py-1 mr-4 mb-2 text-xs font-medium flex items-center justify-between",
  selectedVar: "bg-blue-600 text-white rounded shadow",
  popupBtn: "py-2 w-full font-medium text-gray-700 focus:outline-none hover:bg-gray-200",
};
