import dayjs from "dayjs";
import { SimTimeUnit } from "model/datatypes";

export const convertRawTimeFromUnit = (rawTime: number, unit: SimTimeUnit) => {
  if (unit === "second") return rawTime;
  const startTime = dayjs("01/01", "DD/MM");
  let end = startTime.add(rawTime, unit);
  return end.diff(startTime, "second");
};

export const convertRawTimeToUnit = (rawTime: number, unit: SimTimeUnit) => {
  if (unit === "second") return rawTime;
  const startTime = dayjs("01/01", "DD/MM");
  let end = startTime.add(rawTime, "second");
  return end.diff(startTime, unit);
};
