import { SimulationModel, ComponentType, SimFile, Container } from "model/datatypes";
import firebase from "firebase/app";

export const newProject = (
  fs: firebase.firestore.Firestore,
  projectName: string,
  description: string,
  ownerId: string
) => {
  const batch = fs.batch();

  //create new project
  const projectDoc = fs.collection("Projects").doc();
  batch.set(projectDoc, {
    projectName,
    description,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    deleted: null,
    ownerId,
    teams: [],
    collaborators: [],
  });

  return new Promise((res, rej) => {
    batch
      .commit()
      .then(() => res(projectDoc.id))
      .catch((error) => rej(error));
  });
};

export const newTeam = (fs: firebase.firestore.Firestore, name: string, ownerId: string) => {
  const batch = fs.batch();

  const teamDoc = fs.collection("teams").doc();
  batch.set(teamDoc, {
    name,
    deleted: null,
    ownerId,
    teams: [],
    collaborators: [],
  });
  return batch.commit();
};

export const getReportURL = (
  reportPath: { bucket_id: string; report_path: string },
  fb: firebase.app.App
) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const getReportURL = fb.functions().httpsCallable("getReportURL");
      const { data } = await getReportURL(reportPath);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const completeUserSignup = (
  signUpData: {
    invitationID: string;
    firstName: string;
    lastName: string;
    userID: string;
  },
  fb: firebase.app.App
) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const completeUserSignup = fb.functions().httpsCallable("completeUserSignup");

      const { data } = await completeUserSignup(signUpData);
      resolve(data);
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
};

export const deleteUser = (userID: string, fb: firebase.app.App) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const deleteUser = fb.functions().httpsCallable("deleteUser");
      await deleteUser({ userID });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteSystemModel = (modelID: string, fb: firebase.app.App) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const deleteSystemModel = fb.functions().httpsCallable("deleteSystemModel");
      await deleteSystemModel(modelID);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const newModel = (
  fs: firebase.firestore.Firestore,
  displayName: string,
  model_code: SimulationModel["model_code"],
  ownerId: SimulationModel["ownerId"],
  status: SimulationModel["status"],
  startComp?: ComponentType[],
  startContainers?: Container[]
) => {
  const batch = fs.batch();

  //create new model
  const modelDoc = fs.collection("SimulationModels").doc(); //empty doc() === new document in fs
  const newModel: Partial<SimulationModel> = {
    displayName,
    type: "simple",
    model_code,
    discovered_models: [],
    ownerId,
    status,
    version: 1,
    default_run_settings: {
      duration: 31622400,
      resolution: 30,
      result_resolution: 3600,
      result_unit: "minute",
      duration_unit: "month",
    },
  };
  batch.set(modelDoc, newModel);

  let modelStartComponents: ComponentType[] = [];
  //upload standard components
  const componentCol = modelDoc.collection("Components");
  if (!!startComp) modelStartComponents = startComp;
  else {
    modelStartComponents = standardComponents;
  }
  modelStartComponents.forEach((component) => {
    const { id, ...componentFields } = component;
    const componentDoc = componentCol.doc(id);
    batch.set(componentDoc, componentFields);
  });

  const containerCol = modelDoc.collection("Containers");
  if (startContainers) {
    startContainers.forEach((container) => {
      batch.set(containerCol.doc(container.id), container);
    });
  }

  return batch.commit();
};

const standardComponents: ComponentType[] = [];

export const saveFileInFs = (
  fs: firebase.firestore.Firestore,
  uploadedFile: SimFile,
  tags: string[]
) => {
  const batch = fs.batch();

  const fileLibDoc = fs.collection("FileLibrary").doc(uploadedFile.id);
  batch.set(fileLibDoc, uploadedFile); //store the file reference

  if (tags.length > 0) {
    const tagDoc = fs.collection("FileTags").doc("tags");
    batch.update(tagDoc, {
      tags: firebase.firestore.FieldValue.arrayUnion(...tags),
    });
  }
  const typeTagsDoc = fs.collection("FileTags").doc("typeTags");
  batch.update(typeTagsDoc, {
    types: firebase.firestore.FieldValue.arrayUnion(uploadedFile.type),
  });

  return batch.commit();
};
