import React, { createContext, useReducer, useContext, useMemo } from "react";
import { Scenario, User, ManagerUser, Comment, Group } from "model/datatypes";
import { mergeArrays } from "utils/jsUtils/imutableArray";

type AppState = {
  projectID: null | string;
  projectName: null | string;
  projectDescription?: null | string;
  selectedScenario: Scenario | null;
  selectedGroups: string[];
  selectedScenarioChanged: boolean;
  user: null | User;
  teamIds: string[];
  organisationID?: string | null;
  organisationName?: string | null;
  google_project?: string;
  managerUser: ManagerUser | null;
  linkedScenario: string | null;
  inLocalDevMode?: boolean;
  isAddingComment: boolean;
  commentTarget?: Partial<Comment>;
  comments: Comment[];
  highlightedComment: string;
  currentGroups: Group[];
  currentScenarios: Scenario[];
};

type ActionType =
  | {
      type: "SET_CURRENT_PROJECT";
      payload: {
        projectID: null | string;
        projectName: string | null;
      };
    }
  | { type: "SELECT_SCENARIO"; payload: Scenario | null }
  | { type: "SELECT_GROUP"; payload: string }
  | { type: "DESELECT_GROUP"; payload: string }
  | { type: "SET_LINKED_SCENARIO"; payload: string | null }
  | { type: "SET_USER"; payload: User | null }
  | { type: "SET_TEAMS"; payload: string[] }
  | {
      type: "SET_ORGANISATION";
      payload: { name?: string; id?: string; google_project?: string };
    }
  | { type: "SET_MANAGER_USER"; payload: ManagerUser | null }
  | { type: "SET_IN_DEV_MODE" }
  | { type: "START_ADDING_COMMENT" }
  | { type: "CANCEL_ADDING_COMMENT" }
  | { type: "SELECT_COMMENT_TARGET"; payload: AppState["commentTarget"] }
  | { type: "FINISHED_ADDING_COMMENT" }
  | { type: "UPDATE_COMMENTS"; payload: AppState["comments"] }
  | { type: "SET_HIGHLIGHTED_COMMENT"; payload: AppState["highlightedComment"] }
  | { type: "UPDATED_CURRENT_GROUPS"; payload: Group[] }
  | { type: "REMOVE_CURRENT_GROUPS"; payload: Group[] }
  | { type: "UPDATE_CURRENT_SCENARIOS"; payload: Scenario[] }
  | { type: "REMOVE_FROM_CURRENT_SCENARIOS"; payload: Scenario[] };

type ContextState = { state: AppState; dispatch: React.Dispatch<ActionType> };

//@ts-ignore
const store = createContext<ContextState>();
const { Provider } = store;

const initialState: AppState = {
  projectID: null,
  projectName: null,
  selectedScenario: null,
  selectedGroups: [],
  selectedScenarioChanged: false,
  user: null,
  teamIds: [],
  managerUser: null,
  linkedScenario: null,
  isAddingComment: false,
  highlightedComment: "",
  comments: [],
  currentGroups: [],
  currentScenarios: [],
};

const reducer = (state: AppState, action: ActionType) => {
  switch (action.type) {
    case "SET_CURRENT_PROJECT":
      return {
        ...state,
        projectID: action.payload.projectID,
        projectName: action.payload.projectName,
        selectedGroups: [],
      };
    case "SELECT_SCENARIO":
      return { ...state, selectedScenario: action.payload };
    case "SELECT_GROUP":
      return { ...state, selectedGroups: [...state.selectedGroups, action.payload] };
    case "DESELECT_GROUP":
      return {
        ...state,
        selectedGroups: state.selectedGroups.filter((group) => group !== action.payload),
      };
    case "SET_LINKED_SCENARIO":
      return { ...state, linkedScenario: action.payload };
    case "SET_IN_DEV_MODE":
      return { ...state, inLocalDevMode: true };
    case "SET_USER":
      const projectID = action.payload?.selectedProject
        ? action.payload?.selectedProject
        : state.projectID;
      return { ...state, user: action.payload, projectID };
    case "SET_TEAMS":
      return { ...state, teamIds: action.payload };
    case "SET_MANAGER_USER":
      return { ...state, managerUser: action.payload };
    case "SET_ORGANISATION":
      return {
        ...state,
        organisationID: action.payload.id,
        organisationName: action.payload.name,
        google_project: action.payload.google_project,
      };
    case "START_ADDING_COMMENT":
      return {
        ...state,
        isAddingComment: true,
      };
    case "CANCEL_ADDING_COMMENT":
      return {
        ...state,
        isAddingComment: false,
      };
    case "SELECT_COMMENT_TARGET":
      return {
        ...state,
        isAddingComment: false,
        commentTarget: action.payload,
      };
    case "SET_HIGHLIGHTED_COMMENT":
      return {
        ...state,
        highlightedComment: action.payload,
      };
    case "FINISHED_ADDING_COMMENT":
      return { ...state, isAddingComment: false, commentTarget: undefined };
    case "UPDATE_COMMENTS":
      return {
        ...state,
        comments: action.payload,
      };
    case "UPDATED_CURRENT_GROUPS":
      return { ...state, currentGroups: action.payload };
    case "REMOVE_CURRENT_GROUPS":
      return { ...state, currentGroups: [] };
    case "UPDATE_CURRENT_SCENARIOS":
      return {
        ...state,
        currentScenarios: mergeArrays(action.payload, state.currentScenarios) as Scenario[],
      };
    case "REMOVE_FROM_CURRENT_SCENARIOS":
      return {
        ...state,
        currentScenarios: state.currentScenarios.filter(
          (scenario) =>
            !action.payload.some((removedScenario) => removedScenario.id === scenario.id)
        ),
      };
    default:
      return state;
  }
};

const StateProvider: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const useSelector: (
  selector: (state: AppState) => Partial<AppState>
) => Partial<AppState> | undefined = (selector) => {
  const { state } = useContext(store);
  const returnVal = useMemo(() => {
    return selector(state);
  }, [state, selector]);
  return returnVal;
};

const useGlobalState = () => {
  const { state } = useContext(store);
  return state;
};
const useGlobalDispatch = () => {
  const { dispatch } = useContext(store);
  return dispatch;
};
const useGlobalStore = () => {
  const { state, dispatch } = useContext(store);
  return { state, dispatch };
};

export {
  store,
  StateProvider,
  useSelector,
  useGlobalState,
  useGlobalDispatch,
  useGlobalStore,
};
