import React, { useState } from "react";
import { useFirebase } from "api/useFirebase";
import { SimulationModel, ManualGitModel } from "model/datatypes";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import * as Sentry from "@sentry/browser";
import gtw from "gtw";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { Popup } from "components/basic/Popup";
import getUUID from "utils/jsUtils/getUUID";
import LoadingOverlay from "components/basic/LoadingOverlay";
import { fsFieldvalue } from "utils/firebase/helpers";

const ManualGitModels: React.FC<{ simulationModel: SimulationModel }> = ({
  simulationModel,
}) => {
  const [editedGitRefs, setEditedGitRefs] = useState(
    simulationModel.model_code.map((ref) => ({ ...ref, id: ref.id || getUUID() }))
  );
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const fb = useFirebase();

  const saveModelChanges = () => {
    if (!loading) {
      setLoading(true);
      fb.firestore()
        .collection("SimulationModels")
        .doc(simulationModel.id)
        .update({
          model_code: editedGitRefs,
          version: fsFieldvalue.increment(1),
        })
        .then(() => {
          setIsChanged(false);
          setLoading(false);
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div>Models references (manual)</div>
      <ModelEditor
        editedGitRefs={editedGitRefs}
        setEditedGitRefs={(updated) => {
          setEditedGitRefs(updated);
          setIsChanged(true);
        }}
      />
      {isChanged && (
        <div className="py-2">
          <button
            className={`${gtw.smallBtn} mr-2 relative`}
            onClick={() => saveModelChanges()}
          >
            Save Changes
            {loading && <LoadingOverlay />}
          </button>
          <button
            className={gtw.smallBtn}
            onClick={() => {
              setEditedGitRefs(
                simulationModel.model_code.map((ref) => ({ ...ref, id: ref.id || getUUID() }))
              );
              setIsChanged(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default ManualGitModels;

const getNewModelRef = () => {
  const blankModelCode: ManualGitModel = {
    id: getUUID(),
    path: "",
    name: "",
    reference: "",
    type: "manual",
  };
  return blankModelCode;
};

export const ModelEditor: React.FC<{
  editedGitRefs: SimulationModel["model_code"];
  setEditedGitRefs: (updated: SimulationModel["model_code"]) => void;
}> = ({ editedGitRefs, setEditedGitRefs }) => {
  const renderOption = (gitRef: ManualGitModel) => {
    return (
      <Popup
        useHover
        className="text-xs"
        mt={15}
        content={(closeMe) => {
          return (
            <>
              <button
                className={`${gtw.popupBtn}`}
                onClick={() => {
                  setEditedGitRefs(editedGitRefs.filter((ref) => ref.id !== gitRef.id));
                  closeMe();
                }}
              >
                Remove model
              </button>
            </>
          );
        }}
      >
        <DotDotDotIcon />
      </Popup>
    );
  };

  const renderGitModelRef = (gitRef: ManualGitModel) => {
    return (
      <div
        key={gitRef.id}
        className="px-2 py-2 bg-white border border-gray-200 shadow-md rounded mr-4 relative"
      >
        <div className="absolute top-0 mt-2 right-0 mr-2">{renderOption(gitRef)}</div>
        <div className="text-xs font-medium">Name</div>
        <input
          className={`${gtw.input} text-xs`}
          value={gitRef.name}
          onChange={(e) => {
            setEditedGitRefs(
              updateArrayVal(editedGitRefs, {
                ...gitRef,
                name: e.target.value,
              })
            );
          }}
        />
        <div className="text-xs font-medium">Path</div>
        <input
          className={`${gtw.input} text-xs`}
          value={gitRef.path}
          onChange={(e) => {
            setEditedGitRefs(
              updateArrayVal(editedGitRefs, {
                ...gitRef,
                path: e.target.value,
              })
            );
          }}
        />
        <div className="text-xs font-medium">Reference</div>
        <input
          className={`${gtw.input} text-xs`}
          value={gitRef.reference}
          onChange={(e) => {
            setEditedGitRefs(
              updateArrayVal(editedGitRefs, {
                ...gitRef,
                reference: e.target.value,
              })
            );
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className={`flex flex-wrap items-end`}>
        {editedGitRefs.map(renderGitModelRef)}
        <button
          className={`${gtw.smallBtn}`}
          onClick={() => {
            setEditedGitRefs([...editedGitRefs, getNewModelRef()]);
          }}
        >
          Add model reference
        </button>
      </div>
    </>
  );
};
