import Axios from "axios";

const APIUrl = "http://104.155.127.96/api";

export type LocationSearchResponse = {
  file_id: number;
  name: string;
  lat: number;
  lng: number;
  distance: number;
};

export type WeatherAverages = {
  winter: {
    season_average: number;
    day_average: number;
    night_average: number;
  };
  spring: {
    season_average: number;
    day_average: number;
    night_average: number;
  };
  summer: {
    season_average: number;
    day_average: number;
    night_average: number;
  };
  autumn: {
    season_average: number;
    day_average: number;
    night_average: number;
  };
};

type MetaDataResponse = { averages: WeatherAverages };

export const getClosestWeatherStations = (location: { lat: number; lng: number }) => {
  return new Promise<LocationSearchResponse[]>(async (resolve, reject) => {
    try {
      const locSearch = await Axios.post<LocationSearchResponse[][]>(
        APIUrl + "/climatedata/closest_climate_file/",
        location
      );
      const locations = locSearch.data[0];
      if (!locations || locations.length === 0) {
        reject("no locations found..");
        return;
      }
      resolve(locations);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getWeatherMetadata = (name: string) => {
  return new Promise<MetaDataResponse>((resolve, reject) => {
    Axios.post<MetaDataResponse>(APIUrl + "/climatedata/get_metadata/", { name })
      .then((res) => {
        console.log(res);
        resolve(res.data);
      })
      .catch((error) => reject(error));
  });
};

export const getDailyWeather = () => {
  return new Promise((resolve, reject) => {});
};
