import React from "react";

const TimeIcon: React.FC<{ time: number; flipped?: boolean }> = ({ time, flipped }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="32" viewBox="0 0 44 32">
      <defs>
        <clipPath id="a">
          <path d="M0 0H44V32H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#a)" data-name="Artboard – 7">
        <text
          fill="#5f5f5f"
          data-name="6"
          fontFamily="Roboto-Regular, Roboto"
          fontSize="11"
          transform={`translate(${flipped ? 1.306 : 30.306} 10.843)`}
        >
          <tspan x="0" y="0">
            {time < 10 ? `0${time}` : time}
          </tspan>
        </text>
        <g
          fill="none"
          stroke="#555"
          strokeWidth="1"
          data-name="Group 2086"
          transform="translate(8276 6621)"
        >
          <g data-name="Ellipse 81" transform="translate(-8259.28 -6619)">
            <circle cx="5" cy="5" r="5" stroke="none"></circle>
            <circle cx="5" cy="5" r="4.5"></circle>
          </g>
          <path d="M-8254.275-6613.818v-3.348" data-name="Path 1084"></path>
          <path
            strokeDasharray="2"
            d="M0 0L0 17"
            data-name="Line 437"
            transform="translate(-8254.275 -6607.5)"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default TimeIcon;
