import React, { useState, useRef } from "react";
import gtw from "gtw";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";

interface Props {
  name: string;
  onChange: (newDisplayName: string) => void;
  className?: string;
  loading: boolean;
}

const EditableName: React.FC<Props> = ({ name, onChange, className, loading }) => {
  const [editedName, setEditedName] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  return (
    <div className="flex">
      {editedName ? (
        <>
          <input
            ref={ref}
            className={`${gtw.input} ${className ? className : ""}`}
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
          <button
            className={`mx-4 ${gtw.smallBtn} ${loading ? "opacity-50" : ""}`}
            onClick={() => {
              onChange(editedName);
              setEditedName(null);
            }}
          >
            save
          </button>
          <button
            className={`${gtw.smallBtn} ${loading ? "opacity-50" : ""}`}
            onClick={() => {
              setEditedName(null);
            }}
          >
            cancel
          </button>
        </>
      ) : (
        <>
          <div className={`font-bold flex-none ${className ? className : ""}`}>{name}</div>
          {loading ? (
            <LoadingIcon className="ml-2" />
          ) : (
            <EditIcon
              onClick={() => {
                setEditedName(name);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

const EditIcon = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      className="ml-2 cursor-pointer"
      onClick={() => {
        onClick();
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="black"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        fill="currentColor"
        d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
      />
    </svg>
  );
};

export default EditableName;
