import { useAllUsers, useFirebase } from "api/useFirebase";
import LoadingOverlay from "components/basic/LoadingOverlay";
import { Popup } from "components/basic/Popup";
import firebase from "firebase";
import gtw from "gtw";
import React, { useEffect, useRef, useState } from "react";
import { useGlobalState } from "store";
import getUUID from "utils/jsUtils/getUUID";
import { Comment, Notification, RawUser } from "model/datatypes";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import moment from "moment";
import CrossCircleIcon from "components/basic/icons/CrossCircleIcon";
import Toast from "components/basic/Toast";

export interface CommentCompProps {}

const CommentComp: React.FC<{ comment: Comment }> = ({ comment }) => {
  const { user, highlightedComment } = useGlobalState();
  const isHighlightedComment = highlightedComment === comment.id;
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [editedComment, setEditedComment] = useState("");
  const fb = useFirebase();
  const [loading, setLoading] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [replyComment, setReplyComment] = useState("");
  const textCursorPosition = useRef(0);
  const [tagged, setTagged] = useState<RawUser[]>([]);
  const [showTagList, setShowTagList] = useState(false);
  const users = useAllUsers();
  const [searchword, setsearchword] = useState<string>();
  const [tagableUsers, setTagableUsers] = useState<RawUser[]>([]);
  const [repliesCollapsed, setRepliesCollapsed] = useState(false);
  const [activeTag, setActiveTag] = useState<number>(-1);

  // Update the searchable users
  useEffect(() => {
    if (searchword) {
      const filteredUsers = users.filter(
        (u) => u.fullName.toLowerCase().search(searchword) !== -1
      );
      setTagableUsers(filteredUsers.slice(0, 5));
    }
  }, [searchword, users]);

  useEffect(() => {
    const regex = /@[A-z]+\b/gi;
    if (replyComment) {
      const messageBeforeCursor = replyComment.substr(0);

      const matches = messageBeforeCursor.match(regex); // .source på regex
      const matchedWord = matches?.pop()?.substr(1).toLowerCase();
      if (matchedWord) {
        setShowTagList(true);
        setsearchword(matchedWord);
      } else {
        setShowTagList(false);
      }
    }
  }, [replyComment]);

  const saveReply = () => {
    if (!loading) {
      if (replyComment.length <= 0) {
        console.log("There is no message");
        Toast("The comment text is empty", { icon: "warning", time: 4000 });
        return;
      }
      setLoading(true);
      fb.firestore()
        .collection("Comments")
        .doc(comment.id)
        .update({
          replies: firebase.firestore.FieldValue.arrayUnion({
            author: user?.fullName,
            reply: replyComment,
            id: getUUID(),
            tagged: tagged,
          }),
        })
        .then(() => {
          sendNotifications(comment.id);
          setReplyComment("");
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const editComment = (comment: Comment) => {
    return (
      <>
        <div
          onClick={() => {
            if (comment.comment) {
              setEditedComment(comment.comment);
            }
            setIsEditingComment(true);
          }}
          className="cursor-pointer text-blue-800 mr-3"
        >
          Edit
        </div>
      </>
    );
  };

  const saveEditedComment = () => {
    setLoading(true);
    if (!loading) {
      fb.firestore()
        .collection("Comments")
        .doc(comment.id)
        .update({ comment: editedComment })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const deleteComment = (comment: Comment) => {
    return (
      <Popup
        mt={15}
        pos={"right"}
        content={(closeMe) => (
          <div className="text-xs p-4  ">
            <div className="mb-2 text-center">Are you sure?</div>
            <div className="flex justify-center">
              <button
                className={`${gtw.popupBtn} w-1/2 text-red-400`}
                onClick={() => {
                  fb.firestore()
                    .collection("Comments")
                    .doc(comment.id)
                    .delete()
                    .then(() => {})
                    .catch((error) => {
                      console.log(error);
                    });
                  closeMe();
                  console.log("Delete clicked");
                }}
              >
                Delete Comment
              </button>
              <button className=" w-1/2 text-blue-800">Cancel</button>
            </div>
          </div>
        )}
      >
        <button className="focus:outline-none cursor-pointer text-red-700 mr-3">Delete</button>
      </Popup>
    );
  };

  const renderCommentOptions = (comment: Comment) => {
    return (
      <div className="mt-2 flex justify-end">
        {!isEditingComment && !isReplying && (
          <div
            onClick={() => {
              setIsReplying(!isReplying);
              setRepliesCollapsed(true);
            }}
            className=" cursor-pointer text-blue-800 mr-3"
          >
            Reply
          </div>
        )}
        {comment.senderID === user?.fbUser.uid && !isEditingComment && !isReplying && (
          <>
            {editComment(comment)}
            {deleteComment(comment)}
          </>
        )}
        {isEditingComment && (
          <div className="flex w-full">
            <button
              onClick={(e) => {
                saveEditedComment();
                setIsEditingComment(false);
              }}
              className={`${gtw.popupBtn} hover:text-blue-800`}
            >
              OK
            </button>
            <button
              className={`${gtw.popupBtn} hover:text-blue-800`}
              onClick={() => setIsEditingComment(false)}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  };

  const sendNotifications = (commentID: string) => {
    tagged.forEach((tag) => {
      const docRef = fb
        .firestore()
        .collection("users")
        .doc(tag.id)
        .collection("notifications")
        .doc();
      const notification: Notification = {
        id: docRef.id,
        read: false,
        CommentID: commentID,
        timestamp: moment(),
      };
      docRef
        .set(convertToFirestoreFormat(notification))
        .then(() => {
          console.log("Succesfully sent notifications");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const addToTagged = (user: RawUser) => {
    setTagged([...tagged, user]);

    let lastIndex = replyComment.lastIndexOf(" ");
    setReplyComment(replyComment.substring(0, lastIndex) + " " + user.fullName);

    setShowTagList(false);
  };

  return (
    <div
      key={comment.id}
      className={`my-2 p-4 bg-gray-100 rounded-lg text-xs ${
        isHighlightedComment ? "border-blue-300 border-2" : ""
      }`}
    >
      {loading && <LoadingOverlay />}
      {comment.commentScope === "system" ? (
        comment.systemName
      ) : (
        <div className="text-gray-500 italic">
          {`${comment.projectName}${
            comment.groupName ? "/" + comment.groupName + "/" + comment.scenarioName : ""
          }`}
        </div>
      )}
      <div className="text-gray-500 flex justify-between italic mt-4 pl-1 mb-2">
        <span>{comment.senderUserName} commented:</span>
        <span className="pr-1">{comment.timestamp.format("HH:mm DD/MM YY")}</span>
      </div>
      <div
        className={`mb-4 bg-white py-2 px-2 rounded-sm ${isEditingComment ? " hidden" : ""}`}
      >
        {comment.comment}
      </div>

      {isEditingComment && (
        <textarea
          onChange={(e) => setEditedComment(e.target.value)}
          className="w-full"
          rows={2}
          value={editedComment}
        >
          {comment.comment}
        </textarea>
      )}
      {comment.replies && comment.replies.length > 0 && (
        <div className="">
          <span
            onClick={() => setRepliesCollapsed(!repliesCollapsed)}
            className={`cursor-pointer text-blue-600 block text-right mr-2`}
          >
            {!repliesCollapsed ? "View" : "Hide"} replies ({comment.replies.length})
          </span>
          {repliesCollapsed && (
            <div>
              {comment.replies.map((reply) => {
                return (
                  <div key={reply.id} className="bg-white p-1 ml-4 rounded-lg my-2">
                    <div className="px-2 text-xs italic text-gray-600">
                      {reply.author} replied:
                    </div>
                    <div className="pb-4 bg-white py-2 px-2 rounded-sm">{reply.reply}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      {renderCommentOptions(comment)}
      {isReplying && (
        <div className="ml-4 relative">
          <div>
            <textarea
              className="w-full rounded-sm p-2"
              rows={2}
              required
              value={replyComment}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" && activeTag > 0) {
                  setActiveTag(activeTag - 1);
                }
                if (e.key === "ArrowDown" && activeTag < tagableUsers.length - 1) {
                  setActiveTag(activeTag + 1);
                }
                if (e.key === "Enter") {
                  if (activeTag !== -1) {
                    addToTagged(tagableUsers[activeTag]);
                    setActiveTag(-1);
                    setShowTagList(false);
                  }
                  if (activeTag === -1) {
                    saveReply();
                    setIsReplying(false);
                    setTagged([]);
                    setReplyComment("");
                  }
                }
              }}
              placeholder="Comment..."
              onChange={(e) => {
                textCursorPosition.current = e.target.selectionEnd;
                setReplyComment(e.target.value);
              }}
            />
          </div>
          {tagged.length > 0 && (
            <>
              <div className="text-xs text-gray-500 mb-1">This comment will notify:</div>
              <div className="mb-3 flex flex-wrap">
                {tagged.map((tag) => {
                  return (
                    <span
                      key={tag.id}
                      className=" flex justify-center items-center mr-1 px-2 text-xs text-white font-bold bg-blue-400 py-1 rounded-full mt-1"
                    >
                      <span>{tag.fullName}</span>
                      <span
                        className="ml-1 cursor-pointer"
                        onClick={() => setTagged(tagged.filter((t) => t.id !== tag.id))}
                      >
                        <CrossCircleIcon />
                      </span>
                    </span>
                  );
                })}
              </div>
            </>
          )}

          {showTagList && tagableUsers.length > 0 && (
            <div style={{ left: 0, top: "172px" }} className="z-40">
              <div className=" w-full overflow-y-auto bg-white rounded shadow p-2 ">
                {tagableUsers.map((user, i) => {
                  const isActive = activeTag === i;
                  return (
                    <div
                      key={user.id}
                      className={`m-1 p-1 rounded hover:bg-blue-100 ${
                        isActive ? "bg-blue-100" : ""
                      }`}
                      onClick={(e) => addToTagged(user)}
                    >
                      {user.fullName}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="flex">
            <button
              onClick={() => {
                saveReply();
                setIsReplying(false);
                setTagged([]);
                setReplyComment("");
              }}
              className={`${gtw.popupBtn} hover:text-blue-800`}
            >
              OK
            </button>
            <button
              className={`${gtw.popupBtn} hover:text-blue-800`}
              onClick={() => {
                setIsReplying(false);
                setTagged([]);
                setReplyComment("");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentComp;
