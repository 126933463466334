import React, { useState, useEffect } from "react";
import { WorkerType } from "model/datatypes";
import Dropdown from "components/basic/Dropdown";
import { useFirebase } from "api/useFirebase";

const WorkerSelecter: React.FC<{
  selectedWorker: WorkerType | undefined;
  onSelect: (selected: WorkerType | undefined) => void;
}> = ({ selectedWorker, onSelect }) => {
  const componentWorkerTypes = useComponentWorkerTypes();
  const options = componentWorkerTypes.map((w) => ({
    id: w.id,
    display: w.displayName,
    val: w,
  }));
  return (
    <Dropdown
      className="text-xs"
      options={[...options, { id: "none", display: "No worker" }]}
      placeholder="Select worker component"
      selectedID={selectedWorker?.id}
      onSelect={(option) => {
        const selected = option.val as WorkerType | undefined;
        onSelect(selected);
      }}
    />
  );
};

const useComponentWorkerTypes = () => {
  const [componentWorkerTypes, setcomponentWorkerTypes] = useState<WorkerType[]>([]);
  const fb = useFirebase();
  useEffect(() => {
    const unsub = fb
      .firestore()
      .collection("component_workers")
      .onSnapshot((snap) => {
        const stw: WorkerType[] = [];
        snap.docs.forEach((doc) => {
          const worker = { id: doc.id, ...doc.data() } as WorkerType;
          stw.push(worker);
        });
        setcomponentWorkerTypes(stw);
      });
    return () => {
      unsub();
    };
  }, [fb]);
  return componentWorkerTypes;
};

export default WorkerSelecter;
