import React, { useState, useMemo } from "react";
import {
  CMTag,
  FullCMHarvester,
  InputVar,
  InputVariableReference,
  Scenario,
} from "model/datatypes";
import Dropdown, { DropdownAtRoot } from "components/basic/Dropdown";
import VariableDetails from "components/plots/VariableDetails";
import HoverTooltip from "components/basic/HoverTooltip";
import InfoIcon from "components/basic/icons/InfoIcon";
import { getSimpleVariableRef } from "utils/getFullVarRef";
import InputNumber from "components/basic/headless/InputNumber";
import { DynamicGraphIcon, FlatGraphIcon } from "components/basic/icons/GraphIcon";
import Modal from "components/basic/Modal";
import CloudDataIcon from "components/basic/icons/CloudDataIcon";
import SearchSelecter from "components/basic/SearchSelecter";

export const InputVariable: React.FC<{
  inputVariable: InputVar;
  onUpdate: (updatedFields: Partial<InputVar>) => void;
  inputVarRefs: InputVariableReference[];
  dataSourceType: Scenario["dataSourceType"];
  harvester?: FullCMHarvester;
}> = ({ inputVariable, onUpdate, inputVarRefs, dataSourceType, harvester }) => {
  const isValid = useMemo(() => {
    if (dataSourceType === "static")
      return inputVariable.sourceType === "static" || !!inputVariable.source;
    else return !!harvester;
  }, [inputVariable, harvester, dataSourceType]);
  const [viewGraph, setViewGraph] = useState(false);

  const renderStatic = () => {
    return (
      <>
        <div className="w-1/6"></div>
        <div className="w-1/2 px-4">
          <div
            className="flex  items-center border border-gray-300 rounded w-full"
            style={{ height: "1.75rem" }}
          >
            <div className="border-r border-gray-300 px-2 flex-none h-full flex items-center">
              <FlatGraphIcon className="h-6" />
            </div>
            <div className="flex-grow">
              <InputNumber
                className="px-2 py-1 focus:outline-none w-full"
                value={inputVariable.value}
                onChange={(newVal) => onUpdate({ value: newVal })}
              />
            </div>
            <span className="border-l flex-none w-12 border-gray-300 px-2 h-full flex items-center">
              {inputVariable.unit}
            </span>
          </div>
        </div>
      </>
    );
  };
  const renderDynamic = () => {
    return (
      <>
        <div className="w-1/12 px-2">
          <InputNumber
            className="px-2 py-1 focus:outline-none w-full border border-gray-300 rounded"
            value={inputVariable.scaling}
            onChange={(newVal) => onUpdate({ scaling: newVal })}
          />
        </div>
        <div className="w-1/12 px-2">
          <InputNumber
            className="px-2 py-1 focus:outline-none w-full border border-gray-300 rounded"
            value={inputVariable.offset}
            onChange={(newVal) => onUpdate({ offset: newVal })}
          />
        </div>
        <div className="w-1/2 px-4">
          <div
            className={`flex items-center border border-gray-300 rounded w-full bg-white  ${
              isValid ? "" : "text-red-400"
            }`}
            style={{ height: "1.75rem" }}
          >
            <div
              className={`border-r border-gray-300 px-2 h-full flex items-center flex-none ${
                isValid ? "hover:text-gray-800 cursor-pointer hover:bg-gray-100" : ""
              }`}
              onClick={() => {
                if (isValid) setViewGraph(true);
              }}
            >
              <DynamicGraphIcon className={`h-6`} />
            </div>
            <DropdownAtRoot
              className="flex-grow"
              drawerClasses="text-gray-700"
              headlessStyle
              onSelect={(selectedOption) => {
                onUpdate({
                  source: selectedOption.val as InputVariableReference,
                });
              }}
              placeholder={"missing source"}
              options={inputVarRefs.map((pRef) => ({
                id: pRef.id,
                display: pRef.variableName,
                val: pRef,
              }))}
              selectedID={inputVariable.source?.id}
            />
            <span className="w-12 flex-none border-l border-gray-300 px-2 h-full flex items-center">
              <span className="max-w-full truncate">{inputVariable.unit}</span>
            </span>
          </div>
        </div>
      </>
    );
  };

  const renderCMTagSelecter = () => {
    return (
      <>
        <div className="w-1/12 flex-none px-2 hide-spinners">
          <InputNumber
            className="px-2 py-1 focus:outline-none w-full border border-gray-300 rounded"
            value={inputVariable.scaling}
            onChange={(newVal) => onUpdate({ scaling: newVal })}
          />
        </div>
        <div className="w-1/12 flex-none px-2 hide-spinners">
          <InputNumber
            className="px-2 py-1 focus:outline-none w-full border border-gray-300 rounded"
            value={inputVariable.offset}
            onChange={(newVal) => onUpdate({ offset: newVal })}
          />
        </div>
        <div className="w-1/2 flex-none px-4">
          <div
            className={`flex items-center border border-gray-300 rounded w-full bg-white  ${
              isValid ? "" : "text-red-400"
            }`}
            style={{ height: "1.75rem" }}
          >
            <div
              className={`border-r border-gray-300 px-2 h-full flex items-center flex-none`}
            >
              <CloudDataIcon className={`h-6`} />
            </div>
            <div className="flex-grow overflow-hidden">
              {harvester ? (
                <HarvesterTagSelecter
                  harvester={harvester}
                  onSelect={(tag) => {
                    onUpdate({ CMTag: tag });
                  }}
                  selectedTagID={inputVariable.CMTag?.id}
                />
              ) : (
                <span className="px-2">No harvester selected</span>
              )}
            </div>
            <span className="w-12 flex-none border-l border-gray-300 px-2 h-full flex items-center truncate">
              {inputVariable.unit}
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`flex items-center w-full py-2 border-b border-gray-200`}>
        <div className={`text-xs font-medium flex items-center w-1/6 px-4`}>
          <span className="">{inputVariable.display}</span>
          {inputVariable.tooltip && (
            <HoverTooltip className="ml-2" mx={24} text={inputVariable.tooltip}>
              <InfoIcon className="w-4 h-4" />
            </HoverTooltip>
          )}
        </div>
        <div className="w-1/6 px-4">
          <Dropdown
            selectedID={inputVariable.sourceType}
            className="bg-white "
            options={[
              { id: "dynamic", display: "Dynamic" },
              { id: "static", display: "Static" },
            ]}
            onSelect={(option) => {
              //@ts-ignore
              onUpdate({ sourceType: option.id });
            }}
          />
        </div>
        {inputVariable.sourceType === "static" && renderStatic()}
        {inputVariable.sourceType === "dynamic" &&
          dataSourceType === "static" &&
          renderDynamic()}
        {inputVariable.sourceType === "dynamic" &&
          dataSourceType === "control_machines" &&
          renderCMTagSelecter()}
      </div>
      {viewGraph && (
        <ViewVariableGraph inputVariable={inputVariable} onClose={() => setViewGraph(false)} />
      )}
    </>
  );
};

export const ViewVariableGraph: React.FC<{
  inputVariable: InputVar;
  onClose: () => void;
}> = ({ inputVariable, onClose }) => {
  return (
    <Modal onClose={onClose}>
      <div className="z-50 w-2/3 rounded shadow-xl">
        {inputVariable.source && (
          <>
            <VariableDetails
              inputDataID={inputVariable.source.sourceID}
              variable={getSimpleVariableRef(inputVariable.source)}
              scaling={inputVariable.scaling}
              offset={inputVariable.offset}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

const HarvesterTagSelecter: React.FC<{
  harvester: FullCMHarvester;
  selectedTagID?: string;
  onSelect: (tag: CMTag) => void;
}> = ({ harvester, selectedTagID, onSelect }) => {
  const options = useMemo(
    () => harvester.tags.map((tag) => ({ id: tag.id, display: tag.path, val: tag })),
    [harvester]
  );
  return (
    <SearchSelecter
      className="w-full"
      headlessStyle
      placeholder="Search tags"
      options={options}
      selectedID={selectedTagID}
      onSelect={(option) => onSelect(option.val)}
    />
  );
};
