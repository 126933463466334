import React from "react";
import {
  ComponentParameter,
  ComponentParamType,
  FileQuery,
  ComponentTypeReference,
} from "model/datatypes";
import { DropdownAtRoot } from "components/basic/Dropdown";
import { ParamEditValue } from "components/simulations/newSimulation/simSetup/simComponent/Parameter";
import {
  ReferenceSelecter,
  ParamEditMode,
  FileQuerySelecter,
  SelecterParamSetup,
} from "./ParamSystemInputs";
import gtw from "gtw";
import { InputText } from "components/basic/Input";
import ParamTableColumnEdit from "./ParamTableColumnEdit";

const parameterTypes = [
  "number",
  "string",
  "boolean",
  "selecter",
  "month",
  "array_numbers",
  "config",
  "file",
  "table",
];

export const EditableParameterRow: React.FC<{
  modelRef: FileQuery["modelRef"];
  param: ComponentParamType;
  onUpdateParam: (newParam: ComponentParamType) => void;
  onDeleteParam: () => void;
  allowedParamRefs: ComponentTypeReference[];
}> = ({ param, onUpdateParam, onDeleteParam, allowedParamRefs, modelRef }) => {
  const types = [...parameterTypes];
  if (!!allowedParamRefs) types.push("reference");

  return (
    <div className="w-full flex items-center py-2 px-2 border-t border-gray-200 hover:bg-gray-200 text-xs">
      <div className={`w-1/8 pr-4`}>
        <div className="w-full border rounded flex items-center bg-white">
          <div className="flex-grow">
            <InputText
              value={param.id}
              className={`focus:outline-none px-2 py-1`}
              onChange={(val) => {
                onUpdateParam({ ...param, id: val });
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-1/8 pr-4">
        <InputText
          value={param.displayName}
          className={`${tw.input} w-full`}
          onChange={(val) => {
            onUpdateParam({ ...param, displayName: val });
          }}
        />
      </div>
      <div className="w-1/8 pr-4">
        <InputText
          value={param.tooltip || ""}
          className={`${tw.input} w-full`}
          onChange={(val) => {
            onUpdateParam({ ...param, tooltip: val });
          }}
        />
      </div>
      <div className="w-1/8 pr-4">
        <DropdownAtRoot
          className={"text-xs bg-white"}
          options={types.map((type) => ({
            id: type,
            display: type,
            val: type,
          }))}
          selectedID={param.type}
          onSelect={(option) => {
            let updatedParam: ComponentParameter = {
              ...param,
              type: option.val,
            };
            //clean up when parameter type changes:
            if (param.type === "file" && option.val !== "file") {
              updatedParam.value = null;
            }
            if (param.type === "table" && option.val !== "table") {
              updatedParam.value = null;
            }
            if (option.val === "table") {
              if (param.tableColumns) {
                const entries = param.tableColumns.map((col) => [col.colKey, [0]]); //create empty default values if table already exists
                updatedParam.value = Object.fromEntries(entries);
              } else {
                updatedParam.value = null;
              }
            }
            if (option.val === "reference")
              updatedParam = { ...updatedParam, displayMode: "hidden" };
            if (param.type === "reference" && option.val !== "reference")
              updatedParam = { ...updatedParam, displayMode: "normal" };
            onUpdateParam(updatedParam);
          }}
        />
      </div>
      <div className="w-1/8 pr-4">
        {param.type === "config" && (
          <InputText
            value={param.tag || ""}
            className={`${tw.input} w-full`}
            onChange={(val) => {
              onUpdateParam({ ...param, tag: val });
            }}
          />
        )}
        {param.type === "file" && (
          <FileQuerySelecter
            modelRef={modelRef}
            parameter={param}
            onUpdate={(newParam) => {
              onUpdateParam(newParam);
            }}
          />
        )}
        {param.type === "table" && (
          <ParamTableColumnEdit
            parameter={param}
            onUpdate={(newParam) => {
              console.log(newParam);
              onUpdateParam(newParam);
            }}
          />
        )}
        {param.type === "selecter" && (
          <SelecterParamSetup
            parameter={param}
            onUpdate={(newParam) => {
              console.log(newParam);
              onUpdateParam(newParam);
            }}
          />
        )}
      </div>
      <div className="w-1/8 pr-4">
        {param.type === "reference" && (
          <ReferenceSelecter
            allowedParamRefs={allowedParamRefs}
            parameter={param}
            onUpdate={(newParam) => {
              onUpdateParam(newParam);
            }}
          />
        )}
        {param.type !== "reference" && (
          <ParamEditValue
            parameter={param}
            protoParam={param}
            onUpdate={(newParam) => {
              console.log(newParam);
              onUpdateParam(newParam);
            }}
          />
        )}
      </div>
      <div className="w-1/12 pr-4">
        <ParamEditMode
          parameter={param}
          onUpdate={(newParam) => {
            onUpdateParam(newParam);
          }}
        />
      </div>
      <div className="w-1/12 pr-4">
        <input
          type="checkbox"
          checked={!!param.optional}
          onChange={(e) => {
            onUpdateParam({ ...param, optional: e.target.checked ? true : undefined });
          }}
        />
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => {
            onDeleteParam();
          }}
          className={gtw.smallBtn}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

const tw = {
  input: "px-2 py-1 focus:outline-none border rounded text-xs",
};
