import React, { useReducer, useCallback, useState, useEffect } from "react";
import { Scenario } from "model/datatypes";
import EngineSetup from "./simSetup/EngineSetup";
import ComponentSetup from "./simSetup/ComponentSetup";
import { newScenarioReducer } from "./NewScenarioReducer";
import { useComponentTypes, useFirebase } from "api/useFirebase";
import MetadataSetup from "./simSetup/MetadataSetup";
import { useSimulationSaver } from "./simSetup/useSimulationSaver";
import * as Sentry from "@sentry/browser";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import { checkScenarioIsRunning } from "utils/checkScenarioStatus";
import Modal from "components/basic/Modal";
import gtw from "gtw";
import { stopSimulation } from "api/SimulationAPI";
import LoadingOverlay from "components/basic/LoadingOverlay";

interface Props {
  scenario: Scenario;
  topBarMargin: number;
  projectID?: string;
}

const ScenarioBuilder: React.FC<Props> = ({ scenario, topBarMargin, projectID }) => {
  const [scenarioState, scenarioDispatch] = useReducer(newScenarioReducer, scenario);
  const { model, changed } = scenarioState;
  // const fullDatasource = useDataSource(scenarioState.dataSource?.id);
  const fb = useFirebase();
  const { componentTypes } = useComponentTypes(model.id, projectID, scenario.id);
  useEffect(() => {
    scenarioDispatch({ type: "SET_COMPONENT_TYPES", payload: componentTypes });
  }, [componentTypes]);

  const [opacity, setOpacity] = useState(1);
  useEffect(() => {
    if (topBarMargin > 0) {
      setOpacity(0);
      const timeout = setTimeout(() => {
        setOpacity(1);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    } else setOpacity(1);
  }, [topBarMargin]);

  const { saveSimulation, loading } = useSimulationSaver(scenarioState, scenarioDispatch);

  const [saveConfirmModalOpen, setSaveConfirmModalOpen] = useState(false);
  const [stoppingSimulation, setStoppingSimulation] = useState(false);
  const [stopSimError, setstopSimError] = useState<null | string>(null);
  const saveSim = useCallback(() => {
    if (checkScenarioIsRunning(scenario.status)) {
      setSaveConfirmModalOpen(true);
    } else {
      saveSimulation()
        .then(() => {
          scenarioDispatch({ type: "SCENARIO_SAVED" });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
        });
    }
  }, [saveSimulation, scenarioDispatch, scenario.status]);

  const renderScenario = () => {
    return (
      <div className="w-full bg-white pt-4 text-xs relative">
        <EngineSetup scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
        <MetadataSetup
          meta_data={scenarioState.meta_data}
          updateMetadata={(updated) => {
            scenarioDispatch({ type: "UPDATE_METADATA", payload: updated });
          }}
        />
        <div className={`${tw.headline} my-8`}>Components Setup</div>
        <ComponentSetup scenarioDispatch={scenarioDispatch} scenarioState={scenarioState} />
      </div>
    );
  };

  const renderSaveConfirmModal = () => {
    return (
      <Modal zIndex={30}>
        <div className="z-50 w-64 bg-white px-4 py-2 relative">
          <div className="font-bold">Overwrite current scenario?</div>
          <div className="text-sm mb-2">
            Scenario is currently processing with previous settings. Saving scenario changes
            will termniate current running simulation.
          </div>
          {stopSimError && <div>{stopSimError}</div>}
          <div className={`flex w-full`}>
            <button
              className={`${gtw.smallBtn}  flex-1 mr-1`}
              onClick={async () => {
                if (!stoppingSimulation && projectID) {
                  try {
                    setStoppingSimulation(true);
                    await stopSimulation(
                      {
                        projectID,
                        scenarioID: scenario.id,
                        endpoint: scenario.endpoint,
                        groupID: scenario.groupID,
                      },
                      fb
                    );
                    setStoppingSimulation(false);
                    setSaveConfirmModalOpen(false);
                    saveSimulation()
                      .then(() => {
                        scenarioDispatch({ type: "SCENARIO_SAVED" });
                      })
                      .catch((error) => {
                        Sentry.captureException(error);
                        console.log(error);
                      });
                  } catch (error) {
                    Sentry.captureException(error);
                    setstopSimError("Error stopping simulation");
                    setStoppingSimulation(false);
                  }
                }
              }}
            >
              Termniate & save
            </button>
            <button
              className={`${gtw.smallBtn} flex-1 ml-1`}
              onClick={() => setSaveConfirmModalOpen(false)}
            >
              Cancel
            </button>
          </div>
          {stoppingSimulation && <LoadingOverlay />}
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div className="px-8 py-2 z-10">{renderScenario()}</div>
      {saveConfirmModalOpen && renderSaveConfirmModal()}
      {changed && (
        <div
          style={{
            marginTop: topBarMargin + 10,
            opacity,
            transition: "margin, opacity 200ms",
          }}
          className="absolute z-20 top-0 right-0 mr-12 flex items-center"
        >
          <button
            className={`${tw.smallBtn} mr-4 w-14 text-center flex items-center justify-center`}
            onClick={() => saveSim()}
          >
            Save {loading && <LoadingIcon className="ml-2" />}
          </button>
          <button
            className={`${tw.smallBtn} w-14 text-center mr-2`}
            onClick={() => {
              scenarioDispatch({ type: "RESET_SCENARIO", payload: scenario });
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default ScenarioBuilder;

const tw = {
  headline: "font-medium text-xl",
  card: "border border-gray-300 rounded p-4 bg-white shadow w-full",
  label: "text-xs font-bold",
  dropdown: "w-40",
  smallBtn:
    "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs bg-white",
  runBtn: "py-1 px-2 shadow rounded border focus:outline-none text-xs font-medium w-full",
  input: "px-2 py-1 focus:outline-none border rounded",
  activeBtn: "bg-gray-700 border-gray-700 text-white font-medium",
};
