import React, { useContext, useState, useMemo, useEffect } from "react";
import { store } from "store";
import { Scenario, DataSource, ScheduledSimulationTask } from "model/datatypes";
import { useFirebase, useFirestore } from "api/useFirebase";
import {
  SmulationPipelineIcon,
  PostProcessorPipelineIcon,
  ResultsView,
} from "./PipelineControls";
import DataOutputStage from "./stages/DataOutputStage";
import { stopSimulation } from "api/SimulationAPI";
import RunSimulation from "../newSimulation/simSetup/RunSimulation";
import Modal from "components/basic/Modal";
import ReportEditor from "../reportEditor/ReportEditor";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Dataset from "components/input/Dataset";
import StartPostProcessorModal from "./StartPostProcessorModal";
import RawDataViewer from "../dataOutput/RawDataViewer";
import Toast from "components/basic/Toast";
import {
  convertFromFirestoreFormatNew,
  convertToFirestoreFormat,
} from "utils/firebase/firestoreFormatter";
import ScheduleIcon from "components/basic/icons/ScheduleIcon";
import { Popup } from "components/basic/Popup";
import gtw from "gtw";

interface Props {
  scenario: Scenario;
  outputData?: DataSource;
}

const ScenarioStages: React.FC<Props> = ({ scenario, outputData }) => {
  const fb = useFirebase();
  const { state } = useContext(store);
  const { projectID, projectName } = state;
  const [runSimOpen, setRunSimOpen] = useState(false);
  const [runPostProcessorOpen, setRunPostProcessorOpen] = useState(false);
  const [viewingReport, setviewingReport] = useState(false);
  const [showOutput, setShowOutput] = useState(false);

  const scheduledSimulationTask = useSchedule(scenario.simulationScheduleID);
  const scheduledProcessorTask = useSchedule(scenario.processorScheduleID);

  const [dataViewerOpen, setDataViewerOpen] = useState(false);

  const hasSimulationStage = useMemo(
    () =>
      (scenario.model?.discovered_models && scenario.model?.discovered_models?.length > 0) ||
      scenario.model?.model_code?.length > 0,
    [scenario.model]
  );

  const SimulationDone = scenario.status?.status === "finished";

  const [loading, setLoading] = useState(false);

  const stopSim = async () => {
    if (projectID && scenario.status?.status !== "finished") {
      try {
        setLoading(true);
        await stopSimulation(
          {
            projectID,
            scenarioID: scenario.id,
            endpoint: scenario.endpoint,
            groupID: scenario.groupID,
          },
          fb
        ); //Sucess
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const removeSchedule = async (scheduleTask: ScheduledSimulationTask) => {
    if (!loading && projectID) {
      setLoading(true);
      const fs = fb.firestore();
      const batch = fs.batch();
      const scheduleDoc = fs.collection("ScheduledSimulationTasks").doc(scheduleTask.id);

      const scenarioDoc = fs
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .doc(scenario.id);
      batch.delete(scheduleDoc);
      const scenarioUpdates =
        scheduleTask.type === "start_processor"
          ? { processorScheduleID: undefined }
          : { simulationScheduleID: undefined };
      batch.update(scenarioDoc, convertToFirestoreFormat(scenarioUpdates, true));
      batch
        .commit()
        .then(() => {
          setLoading(false);
          Toast("Schedule canceled", { icon: "success" });
        })
        .catch((error) => {
          setLoading(false);
          Toast("Error removing schedule..", { icon: "error" });
        });
    }
  };
  const postProcessorDone = useMemo(
    () =>
      (!scenario.report_status ||
        scenario.report_status?.status === "READY" ||
        scenario.report_status?.status === "finished") &&
      !loading,
    [scenario, loading]
  );

  const renderScheduleInfo = (schedule: ScheduledSimulationTask) => {
    return (
      <div className=" mr-4 cursor-pointer">
        <Popup
          mt={20}
          pos={"left"}
          content={(closeMe) => (
            <div className="text-xs">
              <button
                className={`${gtw.popupBtn}`}
                onClick={() => {
                  removeSchedule(schedule);
                  closeMe();
                }}
              >
                Remove schedule
              </button>
            </div>
          )}
        >
          <div className="text-xs z-10 flex items-center px-4 py-1 rounded-full border border-gray-200 cursor-pointer">
            <ScheduleIcon />
            <div className="ml-2">
              <div className="font-medium leading-tight">
                {schedule.type === "start_simulation" ? "Simulation" : "Report"}
              </div>
              <div className="italic leading-tight">
                {schedule.launchTime.format("HH:mm DD/MM")}
              </div>
              {schedule.runInterval && (
                <div className="italic leading-tight">
                  Repeating every {schedule.runInterval.displayValue}
                  {schedule.runInterval.displayUnit}s
                </div>
              )}
            </div>
          </div>
        </Popup>
      </div>
    );
  };

  return (
    <>
      <div className="px-8 py-2 flex my-1 z-10 bg-white">
        {hasSimulationStage && (
          <>
            <SmulationPipelineIcon
              pipelineReady={true}
              status={scenario.status}
              onStart={() => {
                if (scheduledSimulationTask)
                  Toast("Remove scheduled task before running", { icon: "warning" });
                else setRunSimOpen(true);
              }}
              onStop={() => {
                !loading && stopSim();
              }}
            />
            {scenario.availableFields && projectID && (
              <DataOutputStage
                openDataOutput={() => setDataViewerOpen(true)}
                simulationGenerating={!!scenario.status?.progress}
              />
            )}
          </>
        )}
        <PostProcessorPipelineIcon
          report_status={scenario.report_status}
          reportCanRun={
            scenario?.report_status?.status !== "PROCESSING" &&
            scenario?.report_status?.status !== "requested"
          }
          report_download={scenario.report_download}
          pipelineReady={!hasSimulationStage || SimulationDone}
          onStart={() => {
            setRunPostProcessorOpen(true);
          }}
        />
        {scenario.report_download && (
          <ResultsView
            reportLink={`/reports/${projectID}/${scenario.id}`}
            isUpdated={(SimulationDone || !hasSimulationStage) && postProcessorDone}
            onViewReport={() => setviewingReport(true)}
            timeStamp={scenario.report_download.timestamp}
            onViewOutput={() => setShowOutput(true)}
            hasDatasource={!!outputData}
          />
        )}
      </div>
      {(scheduledProcessorTask || scheduledSimulationTask) && (
        <div className="flex px-6 pb-4 z-10">
          {scheduledProcessorTask && renderScheduleInfo(scheduledProcessorTask)}
          {scheduledSimulationTask && renderScheduleInfo(scheduledSimulationTask)}
        </div>
      )}
      {runSimOpen && projectID && (
        <RunSimulation
          scenario={scenario}
          onFinish={() => setRunSimOpen(false)}
          projectID={projectID}
        />
      )}
      {runPostProcessorOpen && projectID && (
        <StartPostProcessorModal
          scenario={scenario}
          projectID={projectID}
          onFinish={() => setRunPostProcessorOpen(false)}
        />
      )}
      {dataViewerOpen && scenario.availableFields && projectID && (
        <Modal
          onClose={() => {
            setDataViewerOpen(false);
          }}
          canOverflow
        >
          <div className="z-40 bg-white border border-gray-200 shadow-xl rounded w-full xl:w-11/12">
            <RawDataViewer
              result_resolution={scenario.run_settings.result_resolution}
              availableFields={scenario.availableFields}
              projectID={projectID}
              scenarioID={scenario.id}
              modelID={scenario.model.id}
              usePipelines={!!scenario.usePipelines}
            />
          </div>
        </Modal>
      )}
      {showOutput && outputData && (
        <Modal onClose={() => setShowOutput(false)}>
          <div className="z-40 shadow-lg w-5/6">
            <Dataset dataSource={outputData} />
          </div>
        </Modal>
      )}
      {loading && <LoadingOverlay />}
      {viewingReport && scenario.report_download && (
        <ReportEditor
          sourcetype="scenario"
          sourceID={scenario.id}
          savedChanges={scenario.reportChanges}
          onClose={() => setviewingReport(false)}
          report_download={scenario.report_download}
          useModal={true}
          reportName={`${projectName}_${scenario.scenarioName}`}
        />
      )}
    </>
  );
};

export default ScenarioStages;

const useSchedule = (scheduleID?: string) => {
  const fs = useFirestore();
  const [schedule, setSchedule] = useState<null | ScheduledSimulationTask>(null);

  useEffect(() => {
    if (scheduleID)
      return fs
        .collection("ScheduledSimulationTasks")
        .doc(scheduleID)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setSchedule(
              convertFromFirestoreFormatNew({
                ...doc.data(),
                id: doc.id,
              }) as ScheduledSimulationTask
            );
          } else setSchedule(null);
        });
    else setSchedule(null);
  }, [scheduleID, fs]);
  return schedule;
};
