import { useFirestore } from "api/useFirebase";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Toast from "components/basic/Toast";
import { FullNotification } from "model/datatypes";
import * as React from "react";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalState, useGlobalDispatch } from "store";

export interface NotificationCardProps {}

const NotificationCard: React.FC<{
  notification: FullNotification;
  switchToComments: () => void;
}> = ({ notification, switchToComments }) => {
  const [loading, setLoading] = useState(false);
  const { user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const fs = useFirestore();
  const history = useHistory();

  const markAsRead = (notificationID: string) => {
    if (!notification.read) {
      setLoading(true);
      fs.collection("users")
        .doc(user?.fbUser.uid)
        .collection("notifications")
        .doc(notificationID)
        .update({
          read: true,
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error setting notification to read", err);
        });
    }
  };

  const setCommentID = () => {
    dispatch({ type: "SET_HIGHLIGHTED_COMMENT", payload: notification.CommentID });
  };

  const goToSystem = () => {
    if (notification.systemID) history.push(`/systems/${notification.systemID}`);
  };
  const goToProjectOverview = () => {
    if (!notification.projectID || !notification.projectName) {
      Toast("Something went wront", { icon: "error" });
      return;
    }
    dispatch({
      type: "SET_CURRENT_PROJECT",
      payload: {
        projectID: notification.projectID,
        projectName: notification.projectName,
      },
    });
    history.push("/overview");
  };

  const goToSimulation = () => {
    if (!notification.projectID || !notification.projectName) {
      Toast("Something went wront", { icon: "error" });
      return;
    }

    dispatch({
      type: "SET_CURRENT_PROJECT",
      payload: {
        projectID: notification.projectID,
        projectName: notification.projectName,
      },
    });
    history.push(`/simulations`);
    if (notification.groupID) {
      dispatch({ type: "SELECT_GROUP", payload: notification.groupID });
    }
    if (notification.commentScope === "scenario" && notification.scenarioID) {
      dispatch({ type: "SET_LINKED_SCENARIO", payload: notification.scenarioID });
    } else if (notification.commentScope === "component" && notification.scenarioID) {
      // dispatch({type:"SELECT_SCENARIO", payload: {} })
    }
  };

  const viewNotification = (notificationID: string) => {
    markAsRead(notificationID);
    if (notification.commentScope === "system") {
      goToSystem();
    } else if (notification.commentScope === "project") {
      goToProjectOverview();
    } else {
      goToSimulation();
    }
    setCommentID();
    switchToComments();
  };

  const notificationName = useMemo(() => {
    switch (notification.commentScope) {
      case "project":
        return notification.projectName;
      case "scenario":
        return notification.scenarioName;
      case "component":
        return `${notification.scenarioName}: component`;
      case "group":
        return notification.groupName;
      case "system":
        return "System notification";
    }
  }, [notification]);

  return (
    <div
      onClick={() => viewNotification(notification.id)}
      className={`${
        notification.read ? "bg-gray-100" : "bg-blue-100"
      } my-2 p-4  rounded-lg overflow-x-hidden border-gray-200 text-xs cursor-pointer hover:bg-gray-200`}
    >
      {loading && <LoadingOverlay />}
      <div className="italic  text-gray-500">
        {notification.senderUserName} mentioned you in a comment:
      </div>
      <div className="rounded-sm mt-1 text-xs">{notification.comment}</div>
      <div className="flex justify-between mt-5">
        <div className="text-gray-500 font-normal text-xs">{notificationName}</div>
        <div className="text-gray-500 text-xs">
          {notification.timestamp.format("HH:mm DD/MM YY")}
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
