import Modal from "components/basic/Modal";
import SearchDropdownMultiple from "components/basic/SearchDropdownMultiple";
import gtw from "gtw";
import { Team } from "model/datatypes";
import React, { useMemo, useState } from "react";

export interface AddTeamProps {}

const AddTeam: React.FC<{
  onFinish: () => void;
  onSave: (uppdatedTeamIds: string[]) => Promise<any>;
  addedTeams: string[];
  allTeams: Team[];
}> = ({ onFinish, addedTeams, allTeams, onSave }) => {
  const [loading, setLoading] = useState<Boolean>(false);
  const [editedTeams, setEditedTeams] = useState<string[]>(addedTeams);
  const selectedTeams = useMemo(
    () => allTeams.filter((t) => editedTeams.some((tid) => tid === t.id)),
    [editedTeams, allTeams]
  );
  const allOptions = useMemo(
    () => allTeams.map((rawTeam) => ({ id: rawTeam.id, displayName: rawTeam.name })),
    [allTeams]
  );

  return (
    <Modal onClose={() => onFinish()}>
      <div className={`${tw.card} w-1/2 z-30`}>
        <div className="font-medium mb-4">Add team as to project</div>
        {selectedTeams.map((team) => {
          return (
            <div
              key={team.id}
              className="flex items-center w-full mb-2 text-sm border-b py-1 border-gray-200 pr-4"
            >
              <span className="flex-grow">{team.name}</span>
            </div>
          );
        })}
        <div className={`${gtw.label}`}>Search</div>
        <SearchDropdownMultiple
          selectTag={(teamId) => {
            setEditedTeams([...editedTeams, teamId]);
          }}
          selectedTagIDs={editedTeams} // when this is changed - update DB
          tags={allOptions}
        />
        <button
          className={`${tw.saveBtn} mt-4`}
          onClick={() => {
            if (!loading) {
              setLoading(true);
              onSave(editedTeams)
                .then(() => {
                  setLoading(false);
                  onFinish();
                })
                .catch((error) => {
                  setLoading(false);
                });
            }
          }}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default AddTeam;

const tw = {
  card: "border border-gray-300 rounded p-4 bg-white shadow",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
};
