import React, { useState } from "react";
import { useFirebase } from "api/useFirebase";
import { SimulationModel, PostProcessor } from "model/datatypes";
import { immutableSplice } from "utils/jsUtils/imutableArray";
import * as Sentry from "@sentry/browser";
import getUUID from "utils/jsUtils/getUUID";
import gtw from "gtw";
import { Popup } from "components/basic/Popup";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import { fsFieldvalue } from "utils/firebase/helpers";

const PostProcessorSetup: React.FC<{ simulationModel: SimulationModel }> = ({
  simulationModel,
}) => {
  const [editedProcessors, setEditedProcessors] = useState<PostProcessor[]>(
    simulationModel.postProcessors || []
  );
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const fb = useFirebase();

  const saveModelChanges = () => {
    setLoading(true);
    fb.firestore()
      .collection("SimulationModels")
      .doc(simulationModel.id)
      .update({
        postProcessors: editedProcessors,
        version: fsFieldvalue.increment(1),
      })
      .then(() => {
        setIsChanged(false);
        setLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log(error);
        setLoading(false);
      });
  };

  const renderOption = (processor: PostProcessor) => {
    return (
      <Popup
        useHover
        className="text-xs"
        mt={15}
        content={(closeMe) => {
          return (
            <>
              <button
                className={`${gtw.popupBtn}`}
                onClick={() => {
                  setEditedProcessors(editedProcessors.filter((pp) => pp.id !== processor.id));
                  setIsChanged(true);
                  closeMe();
                }}
              >
                Remove post processor
              </button>
            </>
          );
        }}
      >
        <DotDotDotIcon />
      </Popup>
    );
  };

  const renderPostProcessorCard = (processor: PostProcessor, i: number) => {
    return (
      <div
        key={processor.id}
        className="px-2 py-2 bg-white border border-gray-200 shadow-md rounded mr-4 relative"
      >
        <div className="absolute top-0 mt-2 right-0 mr-2">{renderOption(processor)}</div>
        <div className="text-xs font-medium">Name</div>
        <input
          className={`${gtw.input} text-xs`}
          value={processor.name}
          onChange={(e) => {
            const updatedProcessor = { ...processor, name: e.target.value };
            setEditedProcessors(immutableSplice(editedProcessors, i, 1, updatedProcessor));
            setIsChanged(true);
          }}
        />
        <div className="text-xs font-medium">Path</div>
        <input
          className={`${gtw.input} text-xs`}
          value={processor.path}
          onChange={(e) => {
            const updatedProcessor = {
              ...processor,
              path: e.target.value,
            };
            setEditedProcessors(immutableSplice(editedProcessors, i, 1, updatedProcessor));
            setIsChanged(true);
          }}
        />
        <div className="text-xs font-medium">Reference</div>
        <input
          className={`${gtw.input} text-xs`}
          value={processor.reference}
          onChange={(e) => {
            const updatedProcessor = {
              ...processor,
              reference: e.target.value,
            };
            setEditedProcessors(immutableSplice(editedProcessors, i, 1, updatedProcessor));
            setIsChanged(true);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="mt-4">Included report processors</div>
      <div className={`flex flex-wrap items-end ${loading ? "opacity-50" : ""}`}>
        {editedProcessors.map(renderPostProcessorCard)}
        <button
          className={` ${gtw.smallBtn}`}
          onClick={() => {
            const newProcessor = {
              id: getUUID(),
              path: "",
              name: "",
              reference: "master",
            };
            setEditedProcessors([...editedProcessors, newProcessor]);
            setIsChanged(true);
          }}
        >
          Add report processor
        </button>
      </div>
      {isChanged && (
        <div className="py-2">
          <button className={`${gtw.smallBtn} mr-2`} onClick={() => saveModelChanges()}>
            Save Changes
          </button>
          <button
            className={gtw.smallBtn}
            onClick={() => {
              setEditedProcessors(simulationModel.postProcessors || []);
              setIsChanged(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default PostProcessorSetup;
