import React, { useState, useEffect, useMemo } from "react";
import {
  InputVar,
  ComponentType,
  ComponentParamType,
  FileQuery,
  ComponentTypeReference,
  SubCompRules,
} from "model/datatypes";
import { Popup } from "components/basic/Popup";
import RemoveComponentButton from "./RemoveComponentButton";
import SubComponentsRules from "./subcomponents/SubComponentsRules";
import { BooleanDropdownSelecter } from "components/basic/Dropdown";
import EditableName from "components/basic/EditableName";
import { updateArrayVal, updateArrayValUUID } from "utils/jsUtils/imutableArray";
import WorkerSelecter from "./workers/WorkerSelecter";
import ParametersEditTable from "./parameters/ParametersEditTable";
import InputEditor from "./variable/InputEditor";
import InfoIcon from "components/basic/icons/InfoIcon";
import HoverTooltip from "components/basic/HoverTooltip";

export const ComponentEditer: React.FC<{
  comp: ComponentType;
  updateComponent: (componentID: string, newComponent: ComponentType) => Promise<void>;
  removeComponent?: (componentID: string) => Promise<void>;
  subCompOptions: { displayName: string; subCompRules: SubCompRules }[];
  allowedParamRefs: ComponentTypeReference[];
  onMove?: (dir: "up" | "down") => void;
  onDuplicate?: () => void;
  onSaveToLib?: () => void;
  modelRef: FileQuery["modelRef"];
  maxOrder?: number;
}> = ({
  comp,
  updateComponent,
  subCompOptions,
  removeComponent,
  onMove,
  maxOrder,
  allowedParamRefs,
  onDuplicate,
  onSaveToLib,
  modelRef,
}) => {
  //keep a seperate state for changeable the component values untill time to save the updates
  const [componentParameters, setcomponentParameters] = useState<ComponentParamType[]>(
    comp.parameters
  );
  const [componentVariables, setcomponentVariables] = useState<InputVar[]>(
    comp.inputVariables
  );
  const [displayName, setDisplayName] = useState(comp.displayName);
  const [itemClass, setItemClass] = useState(comp.item_class);
  const [componentType, setcomponentType] = useState(comp.type);
  const [compName, setCompName] = useState(comp.name);
  const [instantiationRules, setInstantiationRules] = useState(
    comp.instantiationRules || { isMain: true, allowDisabling: false }
  );
  const [subCompRules, setSubCompRules] = useState(comp.subCompRules || []);
  const attachedSubCompRules = useMemo(() => {
    return subCompRules.map((rule) => {
      const displayName =
        subCompOptions.find((option) => option.subCompRules.id === rule.id)?.displayName ||
        "Error..";
      return {
        subCompRules: rule,
        displayName,
      };
    });
  }, [subCompRules, subCompOptions]);

  const [workerType, setWorkerType] = useState(comp.workerType);

  const [changed, setchanged] = useState(false);
  const [loading, setLoading] = useState(false);

  //Commit changed values to FS
  const onSave = () => {
    setLoading(true);

    const updatedComponent = {
      ...comp,
      name: compName,
      displayName: displayName,
      inputVariables: componentVariables,
      parameters: componentParameters,
      type: componentType,
      subCompRules,
      item_class: itemClass,
      workerType,
      instantiationRules,
    };
    updateComponent(comp.id, updatedComponent)
      .then(() => {
        setchanged(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    //update state on external component changes.
    setcomponentParameters(comp.parameters);
    setcomponentVariables(comp.inputVariables);
    setDisplayName(comp.displayName);
    setItemClass(comp.item_class);
    setcomponentType(comp.type);
    setSubCompRules(comp.subCompRules || []);
    setchanged(false);
  }, [comp]);

  //load original values:
  const onCancel = () => {
    setcomponentVariables(comp.inputVariables);
    setcomponentParameters(comp.parameters || []);
    setDisplayName(comp.displayName);
    setcomponentType(comp.type);
    setSubCompRules(comp.subCompRules || []);
    setItemClass(comp.item_class);
    setCompName(comp.name);
    setInstantiationRules(comp.instantiationRules || { isMain: true, allowDisabling: false });
    setchanged(false);
  };

  const renderOptions = () => {
    return (
      <div className="flex items-center">
        <div className="mr-1 text-xs font-medium">{comp.order}</div>
        <Popup
          pos={"right"}
          useHover
          className="text-xs"
          content={(closeMe) => {
            return (
              <>
                {onMove && typeof comp.order === "number" && comp.order > 0 && (
                  <button
                    className={`${tw.popupBtn} border-b border-gray-400`}
                    onClick={() => {
                      onMove("up");
                      closeMe();
                    }}
                  >
                    Move up
                  </button>
                )}
                {onMove &&
                  typeof comp.order === "number" &&
                  maxOrder &&
                  comp.order < maxOrder && (
                    <button
                      className={`${tw.popupBtn} border-b border-gray-400`}
                      onClick={() => {
                        onMove("down");
                        closeMe();
                      }}
                    >
                      Move down
                    </button>
                  )}
                {onDuplicate && (
                  <button
                    className={`${tw.popupBtn} border-b border-gray-400`}
                    onClick={() => {
                      onDuplicate();
                    }}
                  >
                    Duplicate component
                  </button>
                )}
                {onSaveToLib && (
                  <button
                    className={`${tw.popupBtn} border-b border-gray-400 ${
                      changed ? "opacity-50" : ""
                    }`}
                    onClick={() => {
                      !changed && onSaveToLib();
                    }}
                  >
                    Save to library
                  </button>
                )}
                {removeComponent && (
                  <RemoveComponentButton ID={comp.id} onConfirm={removeComponent} />
                )}
              </>
            );
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24 24" width="14">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
        </Popup>
      </div>
    );
  };

  const renderComponentSettings = () => {
    return (
      <>
        <div className="flex pt-2">
          <div className="mr-4 flex flex-col">
            {comp.fixed || (
              <>
                <div className="text-xs font-medium">Name</div>
                <input
                  className={`${tw.input}`}
                  type="string"
                  value={compName}
                  onChange={(e) => {
                    setCompName(e.target.value);
                    setcomponentType(e.target.value);
                    setchanged(true);
                  }}
                />
              </>
            )}
            <div className={`${tw.label} mt-2`}>Item class</div>
            {comp.modelID ? (
              <div className="text-xs">{itemClass}</div>
            ) : (
              <input
                type="text"
                className={tw.input}
                value={itemClass}
                onChange={(e) => {
                  setItemClass(e.target.value);
                  setchanged(true);
                }}
              />
            )}
          </div>
          <div className="mr-4 w-40 flex flex-col">
            {comp.fixed || (
              <>
                <label className={tw.label}>Allow disabling</label>
                <BooleanDropdownSelecter
                  className="w-40 text-xs mb-2"
                  value={instantiationRules.allowDisabling}
                  onChange={(newVal) => {
                    setInstantiationRules({ ...instantiationRules, allowDisabling: newVal });
                    setchanged(true);
                  }}
                />
                {instantiationRules.allowDisabling && (
                  <>
                    <label className={tw.label}>Enabled by default</label>
                    <BooleanDropdownSelecter
                      className="w-40 text-xs mb-2"
                      value={!!instantiationRules.defaultEnabled}
                      onChange={(newVal) => {
                        setInstantiationRules({
                          ...instantiationRules,
                          defaultEnabled: newVal,
                        });
                        setchanged(true);
                      }}
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className=" flex flex-col">
            <div className={`${tw.label} flex items-center`}>
              <span className="mr-1">Instantiate on start</span>
              <HoverTooltip
                mt={-35}
                text="If enabled component will be added to top level of new scenarios."
              >
                <InfoIcon className="w-4 h-4" />
              </HoverTooltip>
            </div>
            <BooleanDropdownSelecter
              className="w-40 text-xs mb-2"
              value={instantiationRules.isMain}
              onChange={(newVal) => {
                setInstantiationRules((prev) => ({ ...prev, isMain: newVal }));
                setchanged(true);
              }}
            />
            <div className="opacity-50 ">
              <div className={tw.label}>Attached worker</div>
              <WorkerSelecter
                selectedWorker={workerType}
                onSelect={(selected) => {
                  setWorkerType(selected);
                  setchanged(true);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={`${tw.card} flex flex-col`}>
      <div className="pb-4">
        {comp.fixed ? (
          <div className="font-bold flex-none w-26">{comp.displayName}</div>
        ) : (
          <div className="flex justify-between">
            <EditableName
              className="w-26"
              loading={loading}
              name={comp.displayName}
              onChange={(newName) => {
                updateComponent(comp.id, {
                  ...comp,
                  displayName: newName,
                });
                setDisplayName(newName);
              }}
            />
            {renderOptions()}
          </div>
        )}
        {renderComponentSettings()}
      </div>

      <div className="flex-grow flex flex-col">
        <ParametersEditTable
          componentParameters={componentParameters}
          modelRef={modelRef}
          onUpdateParam={(updated) => {
            setcomponentParameters(updateArrayValUUID(componentParameters, updated));
            setchanged(true);
          }}
          onUpdateAllParms={(updated) => {
            setcomponentParameters(updated);
            setchanged(true);
          }}
          onDelete={(param) => {
            setcomponentParameters(componentParameters.filter((cp) => cp.uuid !== param.uuid));
            setchanged(true);
          }}
          allowedParamRefs={allowedParamRefs}
        />

        <InputEditor
          componentVariables={componentVariables}
          onDelete={(inputVar) => {
            setcomponentVariables(
              componentVariables.filter((cv) => cv.uuid !== inputVar.uuid)
            );
            setchanged(true);
          }}
          onUpdate={(updatedInputVar) => {
            setcomponentVariables(updateArrayValUUID(componentVariables, updatedInputVar));
            setchanged(true);
          }}
          onUpdateAll={(updatedVars) => {
            setcomponentVariables(updatedVars);
            setchanged(true);
          }}
        />

        <SubComponentsRules
          attachedSubCompRules={attachedSubCompRules}
          subCompOptions={subCompOptions?.filter(
            (option) => option.subCompRules.id !== comp.id
          )}
          onAddSubComponent={(rule) => {
            setSubCompRules([...subCompRules, rule]);
            setchanged(true);
          }}
          onRemoveSubComponent={(rule) => {
            setSubCompRules(subCompRules.filter((c) => c.id !== rule.id));
            setchanged(true);
          }}
          onEditRules={(editedRule) => {
            console.log({ editedRule });
            setSubCompRules(updateArrayVal(subCompRules, editedRule));
            setchanged(true);
          }}
        />
      </div>

      <div className="flex-none flex h-10 pt-2">
        {changed && (
          <>
            <button onClick={() => onSave()} className={`mr-4 flex-1 ${tw.smallBtn}`}>
              Save changes
            </button>
            <button onClick={() => onCancel()} className={`ml-4 flex-1 ${tw.smallBtn}`}>
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const tw = {
  card: "border border-gray-300 rounded p-4 bg-white shadow w-full",
  label: "text-xs font-medium",
  smallBtn:
    "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
  popupBtn: "py-1 w-full font-medium text-gray-700 focus:outline-none hover:bg-gray-200",
  input: "px-2 py-1 focus:outline-none border rounded text-xs",
};
