import { useAllUserInvitations, useAllUsers } from "api/useFirebase";
import gtw from "gtw";
import React, { useMemo, useState } from "react";
import SearchBar from "./basic/SearchBar";
import InvitationModal from "./userAdministration/InvitationModal";
import InvitationRow from "./userAdministration/InvitationRow";
import UserCard from "./userAdministration/UserCard";

export interface UserAdministrationPageProps {}

const UserAdministrationPage: React.FC<UserAdministrationPageProps> = () => {
  const [searchValue, setSearchValue] = useState("");
  const users = useAllUsers();
  const invitations = useAllUserInvitations();
  const [isOpen, setIsOpen] = useState(false);

  // const [loading, setLoading] = useState(false);

  const filteredUsers = useMemo(
    () =>
      users
        .filter((user) => {
          return user.fullName.toLowerCase().includes(searchValue.toLowerCase());
        })
        .sort((a, b) => {
          let a_value =
            a.userRole === "owner"
              ? 3
              : a.userRole === "developer"
              ? 2
              : a.userRole === "simulator"
              ? 1
              : 0;
          let b_value =
            b.userRole === "owner"
              ? 3
              : b.userRole === "developer"
              ? 2
              : b.userRole === "simulator"
              ? 1
              : 0;

          return b_value - a_value;
        }),
    [users, searchValue]
  );

  return (
    <div className={`${gtw.innerContainer} `}>
      <div className="flex mb-3 items-center">
        <div className={`font-bold text-gray-700 text-xl pl-4 flex-grow`}>
          User administration
        </div>

        <div className="mr-4">
          <SearchBar value={searchValue} onUpdate={(updated) => setSearchValue(updated)} />
        </div>

        <div>
          <button
            onClick={() => setIsOpen(true)}
            className="bg-white px-2 py-1 shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium"
          >
            Invite user
          </button>
        </div>
      </div>

      <div className="bg-white shadow-md border border-gray-300 rounded-lg flex-none px-4 h-128 overflow-auto pb-2">
        <div className="flex text-xs text-gray-600 flex-1 px-2 py-3 sticky top-0 bg-white font-bold z-20">
          <div className="w-1/5">Name</div>
          <div className="w-2/5">Mail</div>
          <div className="w-1/5 place-self-center">Change role</div>
          <div className="w-1/5"></div>
        </div>
        {users ? (
          filteredUsers.map((user) => {
            return <UserCard key={user.id} user={user} />;
          })
        ) : (
          <h1>No users found!</h1>
        )}
      </div>
      {invitations.length >= 1 && (
        <>
          <div className={`font-bold text-gray-700 text-xl pl-4 flex-grow mt-6 mb-4`}>
            Pending invitations
          </div>
          <div className="bg-white shadow-md border border-gray-300 rounded-lg flex-none px-4 max-h-50vh overflow-auto pb-2">
            <div className="flex text-xs text-gray-600 flex-1 px-2 py-3 sticky top-0 bg-white font-bold z-20">
              <div className="w-1/5">Invitation date</div>
              <div className="w-2/5">Mail</div>
              <div className="w-1/5">User role</div>
              <div className="w-1/5"></div>
            </div>
            {invitations.map((invitation) => {
              return <InvitationRow key={invitation.id} invitation={invitation} />;
            })}
          </div>
        </>
      )}
      {isOpen && <InvitationModal onFinish={() => setIsOpen(false)} />}
    </div>
  );
};

export default UserAdministrationPage;
