import {
  useGroupComments,
  useFirestore,
  useProjectComments,
  useSystemComments,
  useUserNotifications,
} from "api/useFirebase";
import CommentIcon from "components/basic/icons/CommentIcon";
import CrossIcon from "components/basic/icons/CrossIcon";
import Toast from "components/basic/Toast";
import { motion, useAnimation } from "framer-motion";
import { FullNotification, Comment } from "model/datatypes";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGlobalDispatch, useGlobalState } from "store";
import CommentComp from "./Comment";
import NotificationCard from "./NotificationCard";

export interface CommentsProps {}

const Collaboration: React.FC<CommentsProps> = () => {
  const { comments, projectID, selectedGroups, isAddingComment } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [showComments, setShowComments] = useState(false);
  const [position, setPosition] = useState<"left" | "right">("right");
  const [selected, setSelected] = useState<"Notifications" | "Comments">("Comments");
  const [repositioning, setRepositioning] = useState(false);
  const [collaboartionActive, setCollaboartionActive] = useState(false);

  const projectComments = useProjectComments(); // load all commments in scope
  const groupComments = useGroupComments(selectedGroups, projectID || undefined); // load project/group commments
  const systemComments = useSystemComments();

  const location = useLocation();
  useEffect(() => {
    const currentPage = location.pathname.split("/")[1];
    const pagesWithComments = ["", "systems", "overview", "simulations"];
    if (pagesWithComments.includes(currentPage)) setCollaboartionActive(true);
    else setCollaboartionActive(false);
  }, [location]);

  const fs = useFirestore();
  const state = useGlobalState();
  const { user } = state;
  const [loading, setLoading] = useState(false);

  const markAllAsRead = (notifications: FullNotification[]) => {
    setLoading(true);
    if (!loading) {
      const batch = fs.batch();
      notifications.forEach((notification) => {
        const notificationDoc = fs
          .collection("users")
          .doc(user?.fbUser.uid)
          .collection("notifications")
          .doc(notification.id);
        batch.update(notificationDoc, { read: true });
      });
      batch
        .commit()
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          Toast("something went wrong");
        });
    }
  };

  useEffect(() => {
    if (showComments) {
      const allCommentsSorted = [...projectComments, ...groupComments, ...systemComments].sort(
        (a, b) => b.timestamp.valueOf() - a.timestamp.valueOf()
      );
      dispatch({
        type: "UPDATE_COMMENTS",
        payload: allCommentsSorted,
      });
    }
  }, [dispatch, projectComments, groupComments, showComments, systemComments]);

  const { fullUserNotifications } = useUserNotifications();

  const [screenHeight, setScreenHeight] = useState(0);
  useEffect(() => {
    const onResize = () => {
      setScreenHeight(window.innerHeight);
    };
    document.addEventListener("resize", onResize);
    onResize();
    return () => {
      document.removeEventListener("resize", onResize);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const [unReadNotifications, setUnReadNotifications] = useState(0);
  useEffect(() => {
    let unRead = 0;
    fullUserNotifications?.forEach((fn) => {
      if (!fn.read) {
        unRead++;
      }
    });
    setUnReadNotifications(unRead);
  }, [fullUserNotifications]);

  const renderCollaborationDrawer = () => {
    return (
      <div
        // style={{ maxHeight: `${screenHeight - 40}px` }}
        style={{ height: `${screenHeight - 60}px` }}
        className="z-30 w-96 rounded-md overflow-hidden bg-white shadow-md  flex flex-col"
      >
        <div className="flex justify-between mb-2 sticky top-0 bg-white  px-4 pt-4 pb-2">
          <div className="text-md font-bold ">Collaboration</div>
          <div className="flex">
            {!isAddingComment ? (
              <button
                onClick={() => {
                  dispatch({ type: "START_ADDING_COMMENT" });
                }}
                className="flex items-center px-2 py-1 bg-blue-300 hover:bg-blue-500 rounded shadow focus:outline-none"
              >
                <span className="text-xs text-white font-bold">Add comment</span>
              </button>
            ) : (
              <button
                onClick={() => {
                  dispatch({ type: "CANCEL_ADDING_COMMENT" });
                }}
                className="flex items-center px-2 py-1 bg-blue-300 hover:bg-blue-500 rounded shadow focus:outline-none"
              >
                <span className="text-xs text-white font-bold">Cancel</span>
              </button>
            )}
            <div
              className="cursor-pointer ml-4 place-self-center mr-2"
              onClick={() => {
                setShowComments(false);
              }}
            >
              <CrossIcon />
            </div>
          </div>
        </div>
        <hr className="mb-2" />
        {renderTab()}
        <div className="mx-3 overflow-y-auto">
          {selected === "Comments" ? renderComments() : renderNotifications()}
        </div>
      </div>
    );
  };

  const renderTab = () => {
    return (
      <div className="flex h-2 mt-1 mb-5 relative">
        <button
          className="w-full cursor-pointer relative h-4 text-xs text-gray-700 focus:outline-none"
          onClick={() => {
            setSelected("Notifications");
            dispatch({ type: "SET_HIGHLIGHTED_COMMENT", payload: "" });
            setShowAllNotifications(false);
          }}
        >
          Notifications{" "}
          <span className="px-1 text-white text-xs bg-red-400 rounded-full">
            {unReadNotifications}
          </span>
        </button>
        <button
          className="w-full cursor-pointer relative h-4 text-xs text-gray-700 focus:outline-none"
          onClick={() => {
            setSelected("Comments");
            dispatch({ type: "SET_HIGHLIGHTED_COMMENT", payload: "" });
            setShowAllNotifications(false);
          }}
        >
          Comments
        </button>
        <span
          className="absolute left-0 bottom-0 -mb-4 w-1/2 border border-blue-300"
          style={{
            marginLeft: selected === "Comments" ? "50%" : "0",
            transition: "margin-left 0.25s ease",
          }}
        />
      </div>
    );
  };

  const renderComments = () => {
    return comments.length > 0 ? (
      comments.map((comment: Comment) => {
        return <CommentComp key={comment.id} comment={comment} />;
      })
    ) : (
      <div className="italic text-xs mt-2">No comments in current section.</div>
    );
  };

  const recentNotifications = fullUserNotifications?.slice(0, 5);
  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const renderNotifications = () => {
    if (recentNotifications && fullUserNotifications) {
      return (
        <>
          <div className="justify-end flex w-full my-3">
            <button
              onClick={() => markAllAsRead(recentNotifications)}
              className="text-right text-xs pr-4 italic text-blue-500 focus:outline-none"
            >
              Mark all as read
            </button>
          </div>
          {showAllNotifications
            ? fullUserNotifications.map((notification: FullNotification) => {
                return (
                  <NotificationCard
                    switchToComments={() => setSelected("Comments")}
                    key={notification.id}
                    notification={notification}
                  />
                );
              })
            : recentNotifications.map((notification: FullNotification) => {
                return (
                  <NotificationCard
                    switchToComments={() => setSelected("Comments")}
                    key={notification.id}
                    notification={notification}
                  />
                );
              })}
          {!showAllNotifications && (
            <div className="flex my-5 justify-center w-full">
              <button
                className=" text-xs text-blue-500 focus:outline-none"
                onClick={() => setShowAllNotifications(true)}
              >
                Show all notifications
              </button>
            </div>
          )}
        </>
      );
    } else return <div className="italic text-xs mt-2">You have no notifications.</div>;
  };

  //animation controls for moving comment section:
  const controls = useAnimation();
  const updatePosition = useCallback(() => {
    if (position === "right") {
      controls.start({
        x: !showComments ? width - 300 : width - 620,
        transition: { duration: 0.5 },
      });
    } else {
      controls.start({ x: 20, transition: { duration: 0.5 } });
    }
  }, [position, showComments, width, controls]);
  useEffect(() => {
    updatePosition();
  }, [updatePosition]);

  if (!collaboartionActive) return null;
  else
    return (
      <motion.div
        className={`bottom-4 fixed z-20`}
        animate={controls}
        drag="x"
        initial={{ x: width - 300 }}
        onDragStart={() => {
          setRepositioning(true);
        }}
        onDragEnd={(e, info) => {
          setTimeout(() => {
            setRepositioning(false);
            const newPosition = info.point.x > width / 2 ? "right" : "left";
            if (newPosition !== position) {
              setPosition(newPosition);
            } else {
              updatePosition();
            }
          }, 100);
        }}
        dragMomentum={false}
        dragConstraints={{ left: 20, right: !showComments ? width - 350 : width - 620 }}
      >
        {!showComments ? (
          <div
            onClick={() => {
              if (!repositioning) {
                setShowComments(true);
              }
            }}
            className="w-12 h-12 flex items-center justify-center relative cursor-pointer rounded-full shadow-lg transition duration-500 ease-in-out bg-white  transform hover:scale-125"
          >
            <CommentIcon />
            {unReadNotifications > 0 && (
              <span className="absolute px-2 rounded-full py-1 text-xs top-0 right-0 -mt-2 -mr-2 bg-red-400 text-white">
                {unReadNotifications}
              </span>
            )}
          </div>
        ) : (
          renderCollaborationDrawer()
        )}
      </motion.div>
    );
};

export default Collaboration;

// <motion.div className="px-4 pt-1 flex justify-evenly text-xs">
// <button
//   onClick={toggleToComments}
//   className={`${
//     tabToggle ? "underline text-blue-800" : ""
//   } focus:outline-none border-opacity-0 px-2 py-1`}
// >
//   Notifications
// </button>
// <button
//   onClick={toggleToNotifications}
//   className={`${
//     !tabToggle ? "underline text-blue-800" : ""
//   } focus:outline-none border-opacity-0 px-2 py-1`}
// >
//   Comments
// </button>
// </motion.div>
