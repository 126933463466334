import { startGroupProcessor } from "api/SimulationAPI";
import { useAnalyticsLogger, useFirebase } from "api/useFirebase";
import Toast from "components/basic/Toast";
import { Group, GroupProcessor } from "model/datatypes";
import React, { useMemo, useState } from "react";
import { useGlobalState } from "store";
import { PostProcessorPipelineIcon, ResultsView } from "../scenarioPipeline/PipelineControls";
import * as Sentry from "@sentry/browser";
import Modal from "components/basic/Modal";
import ConsoleLogger from "../ConsoleLogger";
import LoadingOverlay from "components/basic/LoadingOverlay";
import ReportEditor from "../reportEditor/ReportEditor";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import gtw from "gtw";
import { Popup } from "components/basic/Popup";

interface Props {
  groupProcessor: GroupProcessor;
  group: Group;
}

const GroupProcessorCard = ({ groupProcessor, group }: Props) => {
  const { user, projectID, projectName } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [viewingReport, setViewingReport] = useState(false);
  const analyticsLogger = useAnalyticsLogger();
  const fb = useFirebase();
  const [viewingLog, setViewingLog] = useState(false);

  const postProcessorDone = useMemo(
    () =>
      (!group.report_status ||
        group.report_status?.status === "READY" ||
        group.report_status?.status === "finished") &&
      !loading,
    [group, loading]
  );

  //get all scenarios that can use this processor
  const getCompatibleScenarioIDs = async () => {
    if (projectID) {
      const matchedScenariosSnap = await fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("Scenarios")
        .where("groupID", "==", group.id)
        .where("groupProcessors", "array-contains", groupProcessor.id)
        .get();
      const scenarioIDs = matchedScenariosSnap.docs.map((doc) => doc.id);
      return scenarioIDs;
    }
    return [];
  };

  const startPostProcessor = async () => {
    if (projectID && !loading) {
      try {
        setLoading(true);

        //get all scenarios that can use this processor..?
        const matchedScenarioIds = await getCompatibleScenarioIDs();

        if (matchedScenarioIds.length === 0) {
          Toast("No matching scenarios in processor", { icon: "error" });
          setLoading(false);
          return;
        } else {
          await startGroupProcessor(projectID, group.id, matchedScenarioIds, fb);
          analyticsLogger("group_processor_requested", {
            user_name: user?.fullName || "",
            user_id: user?.fbUser.uid || "",
            project_id: projectID,
            groupID: group.id,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        Toast("error starting processor", { icon: "error" });
        Sentry.captureException(error);
        setLoading(false);
      }
    }
  };

  const removeGroupProcessor = async () => {
    //...
    Toast("Not implemented yet...");
  };

  const copyRefToClipboard = async () => {
    if (!loading) {
      try {
        setLoading(true);
        const matchedScenarioIds = await getCompatibleScenarioIDs();
        const el = document.createElement("textarea");
        el.value = JSON.stringify({
          project_id: projectID,
          group_id: group.id,
          scenario_id_list: matchedScenarioIds,
        });
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        Toast("Reference copied to clipboard", { icon: "success" });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const DeleteOption = () => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Remove processor?</div>
              <div className="italic mb-2">
                This will delete the group processor and results.
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`${gtw.smallBtn} border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={() => {
                      if (!loading) {
                        removeGroupProcessor();
                        closeDeletePopup();
                      }
                    }}
                  >
                    Remove
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`${gtw.smallBtn} w-full`}
                    onClick={() => {
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`${gtw.popupBtn} text-red-500 border-t border-gray-400`}>
          Remove processor
        </button>
      </Popup>
    );
  };

  const renderOptionsBtn = () => {
    return (
      <Popup
        useHover
        mt={15}
        pos={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button
              onClick={() => {
                copyRefToClipboard();
                closeMe();
              }}
              className={`${gtw.popupBtn}`}
            >
              Copy reference
            </button>
            <button
              onClick={() => {
                setViewingLog(true);
                closeMe();
              }}
              className={`${gtw.popupBtn} border-t border-gray-400`}
            >
              View log
            </button>
            {DeleteOption()}
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  return (
    <div className="w-full mt-4 mb-2 rounded border bg-white shadow text-xs relative">
      <div className="w-full border-b px-4 border-gray-200 py-2 font-medium flex items-center">
        <div className="flex-grow">{groupProcessor.name}</div>
        {renderOptionsBtn()}
      </div>
      <div className="py-2 px-4 italic flex">
        <PostProcessorPipelineIcon
          report_status={group.report_status}
          reportCanRun={
            group?.report_status?.status !== "PROCESSING" &&
            group?.report_status?.status !== "requested"
          }
          report_download={group.report_download}
          pipelineReady={true}
          onStart={() => {
            startPostProcessor();
          }}
        />
        {group.report_download && (
          <ResultsView
            reportLink={`/group_reports/${projectID}/${group.id}`}
            isUpdated={postProcessorDone}
            onViewReport={() => setViewingReport(true)}
            timeStamp={group.report_download.timestamp}
            onViewOutput={() => {}}
            hasDatasource={false}
          />
        )}
      </div>
      {viewingReport && group.report_download && (
        <ReportEditor
          sourcetype="group"
          sourceID={group.id}
          savedChanges={group.reportChanges}
          onClose={() => setViewingReport(false)}
          report_download={group.report_download}
          useModal={true}
          reportName={`${projectName}_${group.groupName}`}
        />
      )}
      {viewingLog && (
        <Modal zIndex={30} onClose={() => setViewingLog(false)}>
          <ConsoleLogger
            headline={group.groupName}
            traceback={group.traceback}
            traceback_report={group.traceback_report}
            logs={group.logs}
          />
        </Modal>
      )}
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default GroupProcessorCard;
