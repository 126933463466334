import { DatetimeInputBox } from "components/basic/DatePicker";
import Dropdown, { DropdownAtRoot } from "components/basic/Dropdown";
import dayjs from "dayjs";
import { ScheduledSimulationTask, SimTimeUnit } from "model/datatypes";
import React, { useCallback, useMemo } from "react";
import { convertRawTimeFromUnit } from "utils/dataTransform/timeConvert";

interface Props {
  setSchedule: (updatedSchedule: ScheduledSimulationTask) => void;
  schedule: ScheduledSimulationTask;
}

const ConfigureSchedule = ({ schedule, setSchedule }: Props) => {
  const onSelectDateTime = useCallback(
    (newTime: dayjs.Dayjs) => {
      setSchedule({ ...schedule, launchTime: newTime });
    },
    [schedule, setSchedule]
  );
  const runIntervalValid = useMemo(() => schedule?.runInterval?.secconds !== 0, [schedule]);

  return (
    <div>
      <div className="text-xs font-medium">
        {schedule.runInterval !== undefined ? "First start time" : "Start time"}
      </div>
      <div className="w-1/2">
        <DatetimeInputBox onSelectDate={onSelectDateTime} selectedDate={schedule.launchTime} />
      </div>
      <div className="text-xs font-medium mt-4">Interval</div>

      <DropdownAtRoot
        className="bg-white w-1/2 mb-4"
        selectedID={schedule.runInterval !== undefined ? "interval" : "once"}
        onSelect={(option) => {
          if (option.id === "interval")
            setSchedule({
              ...schedule,
              runInterval: { secconds: 86400, displayValue: "1", displayUnit: "day" },
            });
          else setSchedule({ ...schedule, runInterval: undefined });
        }}
        options={[
          { id: "once", display: "Run once" },
          { id: "interval", display: "Run on interval" },
        ]}
      />
      {schedule.runInterval !== undefined && (
        <>
          <div className="font-medium text-xs">Run every</div>
          <div
            className={`w-full xl:w-1/2 flex bg-white border rounded mb-4 ${
              runIntervalValid ? "" : "border-red-400"
            }`}
          >
            <input
              className={`text-sm w-1/2 px-2 py-1 bg-transparent focus:outline-none`}
              type="number"
              value={schedule.runInterval.displayValue}
              min="0"
              pattern="^-?[0-9]\d*\.?\d*$"
              onChange={(e) => {
                const updatedInterval = {
                  ...schedule.runInterval,
                  displayValue: e.target.value,
                } as {
                  secconds: number;
                  displayUnit: SimTimeUnit;
                  displayValue: string;
                };

                const newVal = parseFloat(e.target.value);
                if (!isNaN(newVal))
                  updatedInterval.secconds = convertRawTimeFromUnit(
                    newVal,
                    updatedInterval.displayUnit
                  );
                else updatedInterval.secconds = 0;
                setSchedule({
                  ...schedule,
                  runInterval: updatedInterval,
                });
              }}
            />
            <Dropdown
              flipped
              headlessStyle
              className="bg-white text-xs w-1/2 border-l"
              selectedID={schedule.runInterval.displayUnit}
              options={[
                { id: "month", display: "Months" },
                { id: "day", display: "Days" },
                { id: "hour", display: "Hours" },
                { id: "minute", display: "Minutes" },
              ]}
              onSelect={(option) => {
                const newUnit = option.id as SimTimeUnit;
                const newDurationVal = convertRawTimeFromUnit(
                  parseInt(schedule.runInterval!.displayValue),
                  newUnit
                );
                setSchedule({
                  ...schedule,
                  runInterval: {
                    ...schedule.runInterval!,
                    displayUnit: newUnit,
                    secconds: newDurationVal,
                  },
                });
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ConfigureSchedule;
