import React, { useState } from "react";
import { ComponentParamType, FileQuery, ComponentTypeReference } from "model/datatypes";
import { DropdownAtRoot } from "components/basic/Dropdown";
import { TagSearcher } from "components/files/FileFilter";
import { Popup } from "components/basic/Popup";
import { useFileTags } from "api/useFirebase";
import gtw from "gtw";
import ToggleButton from "components/basic/ToggleButton";
import Modal from "components/basic/Modal";
import getUUID from "utils/jsUtils/getUUID";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import InputNumber from "components/basic/headless/InputNumber";
import RadioButton from "components/basic/RadioButton";

export const ReferenceSelecter: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParamType) => void;
  allowedParamRefs: ComponentTypeReference[];
}> = ({ parameter, onUpdate, allowedParamRefs }) => {
  const options = allowedParamRefs.map((ref) => ({
    id: ref.id,
    display: ref.displayName,
  }));
  return (
    <DropdownAtRoot
      className={`bg-white`}
      options={options}
      onSelect={(dropdownOption) => {
        onUpdate({ ...parameter, value: dropdownOption.id });
      }}
      selectedID={parameter.value === null ? "none" : (parameter.value as string)}
      placeholder={
        options.length > 0 ? "Select component to reference" : "No allowed references"
      }
      emptyMsg="No allowed referenes found"
    />
  );
};

export const FileQuerySelecter: React.FC<{
  parameter: ComponentParamType;
  modelRef: FileQuery["modelRef"];
  onUpdate: (newParam: ComponentParamType) => void;
}> = ({ parameter, onUpdate, modelRef }) => {
  return (
    <Popup
      className={"w-64"}
      mt={35}
      pos={"right"}
      allowOverflow
      content={(closeMe) => {
        return (
          <FileTagSelecter
            modelRef={modelRef}
            query={parameter.fileQuery || { tags: [] }}
            updateQuery={(updatedQuery) =>
              onUpdate({ ...parameter, fileQuery: updatedQuery, value: null })
            }
            onClose={closeMe}
          />
        );
      }}
    >
      <div
        style={{ minHeight: "1.75rem" }}
        className="flex flex-wrap items-center text-xs relative rounded bg-white border border-gray-200 shadow cursor-pointer"
      >
        {parameter.fileQuery?.projectRef && (
          <div className={`${tw.tag} bg-yellow-600`}>
            {parameter.fileQuery.projectRef.displayName}
          </div>
        )}
        {parameter.fileQuery?.type && (
          <div className={`${tw.tag} bg-blue-500`}>{parameter.fileQuery.type}</div>
        )}
        {parameter.fileQuery?.modelRef && (
          <div className={`${tw.tag} bg-purple-500`}>
            {parameter.fileQuery.modelRef.displayName}
          </div>
        )}
        {parameter.fileQuery?.tags.map((tag) => (
          <div key={tag} className={`${tw.tag} bg-green-500`}>
            {tag}
          </div>
        ))}
      </div>
    </Popup>
  );
};

const FileTagSelecter: React.FC<{
  query: FileQuery;
  updateQuery: (newQuery: FileQuery) => void;
  modelRef: FileQuery["modelRef"];
  onClose: () => void;
}> = ({ query, updateQuery, onClose, modelRef }) => {
  const { tags, types } = useFileTags();

  const renderType = (type: string) => {
    const isSelected = type === query.type;
    return (
      <div
        key={type}
        className={`px-3 py-1 mx-1 my-1 text-xs cursor-pointer rounded border-2 border-blue-600 shadow ${
          isSelected ? "text-white bg-blue-600" : "text-gray-600"
        }`}
        onClick={() => {
          const newQuery = { ...query };
          isSelected ? delete newQuery.type : (newQuery.type = type);
          updateQuery(newQuery);
        }}
      >
        {type}
      </div>
    );
  };

  return (
    <div className="px-4 py-4 text-xs">
      <div className="font-medium">Search for tags (OR)</div>
      <div className="w-48 mb-2">
        <TagSearcher
          tags={tags}
          selectedTags={query.tags}
          selectTag={(tag) => updateQuery({ ...query, tags: [...query.tags, tag] })}
          removeTag={(tag) =>
            updateQuery({ ...query, tags: query.tags.filter((t) => t !== tag) })
          }
        />
      </div>
      <div className="font-medium">Filter by type</div>
      <div className="flex mb-2 flex-wrap">{types.map(renderType)}</div>
      {modelRef && (
        <>
          <div className="font-medium">Only System files</div>
          <ToggleButton
            active={!!query.modelRef}
            onChange={() => {
              const newQuery = { ...query };
              !!query.modelRef ? delete newQuery.modelRef : (newQuery.modelRef = modelRef);
              updateQuery(newQuery);
            }}
          />
        </>
      )}
      <button className={`${gtw.smallBtn} w-full mt-2`} onClick={onClose}>
        Close
      </button>
    </div>
  );
};

export const ParamEditMode: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParamType) => void;
}> = ({ parameter, onUpdate }) => {
  let options = ["normal", "fixed", "advanced", "hidden"].map((o) => ({ id: o, display: o }));
  if (parameter.type === "reference") {
    //only allow selecting hidden for reference parameters
    options = options.filter((o) => o.id === "hidden");
  }
  const selectedID = parameter.displayMode ? parameter.displayMode : "normal";
  return (
    <DropdownAtRoot
      className={`bg-white`}
      options={options}
      onSelect={(option) => {
        onUpdate({
          ...parameter,
          displayMode: option.id as "normal" | "fixed" | "hidden" | "advanced",
        });
      }}
      selectedID={selectedID}
    />
  );
};

const tw = {
  tag: "px-1 text-white rounded-lg mx-1 flex items-center my-1",
};

export const SelecterParamSetup: React.FC<{
  parameter: ComponentParamType;
  onUpdate: (newParam: ComponentParamType) => void;
}> = ({ onUpdate, parameter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editedSelectedID, setEditedSelectedID] = useState(parameter.tag);
  const [editedOptions, setEditedOptions] = useState(parameter.selecterOptions || []);
  const options = parameter.selecterOptions;
  const [type, setType] = useState<"number" | "string">(
    options && options.length > 0 && typeof options[0].value === "string" ? "string" : "number"
  );

  const updateDataType = (newType: "number" | "string") => {
    //transform the data if paramters
    setEditedOptions(
      editedOptions.map((option) => {
        let val = option.value;
        if (newType === "number" && typeof val !== "number") val = parseFloat(val);
        else if (typeof val !== "string") val = val.toString();
        return {
          ...option,
          value: val,
        };
      })
    );
    //update type
    setType(newType);
  };

  return (
    <>
      <button
        onClick={() => {
          setEditedSelectedID(parameter.tag);
          setEditedOptions(parameter.selecterOptions || []);
          setIsOpen(true);
        }}
        className={`${gtw.smallBtn} w-full`}
      >
        Edit options
      </button>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <div className="modal-content z-30 w-2/3 lg:1/2">
            <div className="font-medium">Edit options</div>
            <div className="text-xs font-medium">Data type</div>
            <DropdownAtRoot
              className="text-xs"
              selectedID={type}
              options={[
                { id: "string", display: "string" },
                { id: "number", display: "number" },
              ]}
              onSelect={(option) => {
                if (option.id !== type) updateDataType(option.id as "string" | "number");
              }}
            />
            <div className="flex text-xs font-bold mt-4 border-b border-gray-200">
              <div className="w-1/3 pr-2">Display</div>
              <div className="w-1/3 px-2">Value</div>
              <div className="w-1/6 px-2">Default</div>
              <div className="w-1/6 text-right pl-2">Remove</div>
            </div>
            {editedOptions.map((option, i) => {
              return (
                <div className="flex text-xs mt-2">
                  <div className="w-1/3 pr-2">
                    <input
                      className="input-box w-full"
                      value={option.display}
                      onChange={(e) => {
                        const editedOption = { ...option, display: e.target.value };
                        setEditedOptions(updateArrayVal(editedOptions, editedOption));
                      }}
                    />
                  </div>
                  <div className="w-1/3 px-2">
                    {type === "number" ? (
                      <InputNumber
                        className="input-box w-full"
                        value={option.value as number}
                        onChange={(updated) => {
                          const editedOption = { ...option, value: updated };
                          setEditedOptions(updateArrayVal(editedOptions, editedOption));
                        }}
                      />
                    ) : (
                      <input
                        className="input-box w-full"
                        value={option.value}
                        onChange={(e) => {
                          const editedOption = { ...option, value: e.target.value };
                          setEditedOptions(updateArrayVal(editedOptions, editedOption));
                        }}
                      />
                    )}
                  </div>
                  <div className="flex  w-1/3 justify-between pl-2">
                    <RadioButton
                      active={editedSelectedID === option.id}
                      onClick={() => {
                        setEditedSelectedID(option.id);
                      }}
                    />
                    <button
                      className="button-small"
                      onClick={() => {
                        setEditedOptions(editedOptions.filter((o) => o.id !== option.id));
                        if (editedSelectedID === option.id) setEditedSelectedID(undefined);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              );
            })}
            <div className="mt-2">
              <button
                className="button-small"
                onClick={() => {
                  setEditedOptions([
                    ...editedOptions,
                    { id: getUUID(), display: "", value: "" },
                  ]);
                }}
              >
                + Add option
              </button>
            </div>
            <div className="flex mt-4 ">
              <button
                onClick={() => {
                  const selectedOption = editedOptions.find((o) => o.id === editedSelectedID);
                  onUpdate({
                    ...parameter,
                    tag: editedSelectedID,
                    selecterOptions: editedOptions,
                    value: selectedOption?.value || null,
                    displayValue: selectedOption?.display,
                  });

                  setIsOpen(false);
                }}
                className={`button-small flex-1 mr-2`}
              >
                Save updated
              </button>
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className={`button-small flex-1 ml-2`}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
