import React, { useEffect, useRef, useState } from "react";
import { Setting } from "../ScheduleInput";
import { InputNum, InputText } from "components/basic/Input";
import { v4 as uuid } from "uuid";

interface Props {
  settings: Setting[];
  updateSettings: (newSettings: Setting[]) => void;
  unit: string;
}

const SettingsInput: React.FC<Props> = ({ settings, updateSettings, unit }) => {
  return (
    <div className="px-4 py-2">
      <label className="text-xs font-bold">Setting</label>
      {settings.map((setting, i) => {
        return (
          <div
            key={setting.id}
            className="border border-gray-300 px-2 flex justify-between items-center my-2 text-xs"
            style={{ borderColor: setting.color }}
          >
            <div>{setting.name}</div>
            <InputNum
              className="border-l border-gray-300 w-20"
              value={setting.value}
              unit={unit}
              onChange={(newVal) => {
                updateSettings([
                  ...settings.slice(0, i),
                  {
                    ...setting,
                    value: newVal,
                  },
                  ...settings.slice(i + 1),
                ]);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export const AddSettingButton: React.FC<{
  settings: Setting[];
  updateSettings: (newSettings: Setting[]) => void;
  unit: string;
  index: number;
}> = ({ updateSettings, settings, unit, index }) => {
  const [isOpen, setisOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClickoutside = (ev: MouseEvent) => {
    if (
      ref.current &&
      ev.target &&
      ev.target instanceof Node &&
      !ref.current.contains(ev.target)
    )
      setisOpen(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickoutside);
    return () => {
      document.removeEventListener("mousedown", handleClickoutside);
    };
  }, []);
  return (
    <div className="relative">
      <button className={`w-full ${tw.timeslotBtn} font-bold`} onClick={() => setisOpen(true)}>
        Add new setting
      </button>
      {isOpen && (
        <div ref={ref} className="absolute z-10 left-0 mt-2 w-full">
          <AddSetting
            index={index}
            onAdd={(newSetting) => {
              updateSettings([...settings, newSetting]);
              setisOpen(false);
            }}
            unit={unit}
          />
        </div>
      )}
    </div>
  );
};

const AddSetting: React.FC<{
  onAdd: (setting: Setting) => void;
  unit: string;
  index: number;
}> = ({ onAdd, unit, index }) => {
  const [value, setValue] = useState(0);
  const [name, setname] = useState("");
  const isValid = name.length > 0;
  const save = () => {
    if (isValid)
      onAdd({
        value,
        name,
        id: uuid().replace(/-/gi, "_"),
        color: colors[(index % colors.length) - 1],
      });
  };
  return (
    <div className="bg-white text-xs rounded-lg shadow-lg px-4 py-2 w-full border border-gray-400">
      <div className=" font-bold my-2">Add flow setting</div>
      <div>
        <div className={tw.inputDiv}>
          <label className={tw.label}>Setting name:</label>
          <InputText
            className={tw.inputBox}
            value={name}
            onChange={(newName) => {
              setname(newName);
            }}
            onEnter={save}
          />
        </div>
        <div className={tw.inputDiv}>
          <label className={tw.label}>Value:</label>
          <InputNum
            className={tw.inputBox}
            unit={unit}
            value={value}
            onChange={(newVal) => setValue(newVal)}
            onEnter={save}
          />
        </div>
      </div>
      <button
        onClick={() => save()}
        className={`w-full border rounded shadow py-1 my-2 ${
          isValid ? "" : "opacity-50 cursor-default"
        }`}
      >
        ADD
      </button>
    </div>
  );
};

const colors = ["#636EFA", "#FF6692", "#FECB52"];

export default SettingsInput;

const tw = {
  timeslotBtn:
    "w-1/2 border border-gray-300 bg-white px-2 py-1 text-center text-xs focus:outline-none",
  inputBox: "border border-gray-200 w-2/3",
  inputDiv: "flex my-2 items-center",
  label: "w-1/3 text-right pr-4",
};
