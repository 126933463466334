import React, { useState, useMemo, useContext } from "react";
import { Scenario, ScheduledSimulationTask } from "model/datatypes";
import { useAnalyticsLogger, useFirebase } from "api/useFirebase";
import { startReport } from "api/SimulationAPI";
import * as Sentry from "@sentry/browser";
import Modal from "components/basic/Modal";
import LoadingOverlay from "components/basic/LoadingOverlay";
import gtw from "gtw";
import Dropdown from "components/basic/Dropdown";
import Toast from "components/basic/Toast";
import { store } from "store";
import ToggleButton from "components/basic/ToggleButton";
import dayjs from "dayjs";
import ConfigureSchedule from "./ConfigureSchedule";
import { useMaxHeightTransition } from "utils/hooks/useMaxHeightTransition";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";

const StartPostProcessorModal: React.FC<{
  onFinish: () => void;
  scenario: Scenario;
  projectID: string;
}> = ({ onFinish, scenario, projectID }) => {
  const [loading, setLoading] = useState(false);

  const { state } = useContext(store);
  const { projectName, user } = state;

  const fb = useFirebase();
  const analyticsLogger = useAnalyticsLogger();

  const [selectedPostProcessor, setSelectedPostProcessor] = useState(
    scenario.selectedPostProcessor
  );

  const updateScenario = async (updated: Partial<Scenario>) => {
    fb.firestore()
      .collection("Projects")
      .doc(projectID)
      .collection("Scenarios")
      .doc(scenario.id)
      .update({
        ...updated,
      });
  };
  const requestSchedule = async (schedule: ScheduledSimulationTask) => {
    const newScheduleDoc = fb.firestore().collection("ScheduledSimulationTasks").doc();
    const updatedFields: Partial<Scenario> = { processorScheduleID: newScheduleDoc.id };
    if (selectedPostProcessor?.id !== scenario.selectedPostProcessor?.id)
      updatedFields.selectedPostProcessor = selectedPostProcessor;
    await updateScenario(updatedFields);
    schedule.id = newScheduleDoc.id;
    await newScheduleDoc.set(convertToFirestoreFormat(schedule));
  };
  const requestProcessor = async () => {
    if (selectedPostProcessor?.id !== scenario.selectedPostProcessor?.id)
      await updateScenario({
        selectedPostProcessor,
      });
    startReport(projectID, scenario.id, fb);
  };

  const startPostProcessor = async () => {
    if (projectID && scenario.id && !loading && selectedPostProcessor) {
      try {
        setLoading(true);

        if (schedule) await requestSchedule(schedule);
        else await requestProcessor();

        analyticsLogger(schedule ? "processor_scheduled" : "processor_requested", {
          user_name: user?.fullName || "",
          user_id: user?.fbUser.uid || "",
          project_id: projectID,
          project_name: projectName,
          scenario_id: scenario.id,
          scenario_name: scenario.scenarioName,
        });

        setLoading(false);
        onFinish();
      } catch (error) {
        console.log(error);
        Toast("error starting post processor", { icon: "error" });
        Sentry.captureException(error);
        setLoading(false);
      }
    }
  };

  const postProcessorOptions = useMemo(
    () =>
      scenario.model.postProcessors
        ? scenario.model.postProcessors.map((postProcessor) => ({
            id: postProcessor.id,
            display: postProcessor.name,
            val: postProcessor,
          }))
        : [],
    [scenario]
  );

  const [schedule, setSchedule] = useState<ScheduledSimulationTask | null>(null);

  const { open: showAdvanced, setOpen: setShowAdvanced, style } = useMaxHeightTransition(
    "0",
    "2000px",
    false
  );

  const renderAdvancedSettings = () => {
    return (
      <div className="px-4 py-2 bg-gray-50 border border-gray-100 rounded my-4 ">
        <div
          className="flex py-2 justify-between cursor-pointer"
          onClick={() => setShowAdvanced(!showAdvanced)}
        >
          <span className="text-xs font-bold ">Advanced settings</span>
          <OpenCloseArrow isOpen={showAdvanced} />
        </div>
        <div className="flex flex-col text-sm overflow-hidden" style={style}>
          <div className={` ${scenario.processorScheduleID ? "opacity-50" : ""}`}>
            <label className={`text-xs font-medium mt-4`}>Scheduled run</label>
            <div className={`p-px mb-4`}>
              <ToggleButton
                active={!!schedule}
                onChange={() => {
                  if (!schedule && !scenario.processorScheduleID) {
                    let now = dayjs();
                    const roundedMin = Math.round(now.get("minute") / 5) * 5;
                    setSchedule({
                      id: "",
                      launchTime: now.set("minute", roundedMin),
                      projectID,
                      scenarioID: scenario.id,
                      type: "start_processor",
                    });
                  } else setSchedule(null);
                }}
              />
            </div>
            {schedule && <ConfigureSchedule schedule={schedule} setSchedule={setSchedule} />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal zIndex={30} onClose={() => onFinish()}>
      <div className={`px-8 py-4 bg-white z-50 shadow-lg rounded w-1/2 relative`}>
        {loading && <LoadingOverlay />}
        <div className="text-xl font-medium mb-2">Start post processor</div>
        <label className={`${gtw.label}`}>Report type</label>
        <Dropdown
          className="text-xs"
          selectedID={selectedPostProcessor?.id}
          options={postProcessorOptions}
          placeholder="Select report template"
          onSelect={(option) => {
            setSelectedPostProcessor(option.val);
          }}
        />
        {renderAdvancedSettings()}
        <div className="flex items-center mt-4">
          <button
            className={`${gtw.smallBtn} flex-1 mr-2`}
            onClick={() => startPostProcessor()}
          >
            Run
          </button>
          <button className={`${gtw.smallBtn} flex-1 ml-2`} onClick={() => onFinish()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StartPostProcessorModal;
