import React, { useState, useContext } from "react";
import { store } from "store";
import { useFirebase, useDataSources } from "api/useFirebase";
import Modal from "./basic/Modal";
import Dataset from "components/input/Dataset";
import gtw from "gtw";
interface Props {}

const InputPage: React.FC<Props> = () => {
  const { dataSources } = useDataSources();

  return (
    <div className={gtw.innerContainer}>
      <div className={`${tw.headline} mt-4`}>Input datasets</div>
      {dataSources.length > 0 &&
        dataSources.map((inputData) => {
          return <Dataset key={inputData.id} dataSource={inputData} />;
        })}
      <NewDataset />
    </div>
  );
};

const NewDataset: React.FC<{}> = () => {
  const [isOpen, setisOpen] = useState(false);
  const [dataSetName, setdataSetName] = useState<string>();
  const { state } = useContext(store);
  const { projectID } = state;
  const fb = useFirebase();
  const saveNewDataset = () => {
    if (dataSetName && dataSetName.length > 0 && projectID) {
      fb.firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .add({
          dataSetName: dataSetName,
          simulationVariables: [],
          origin: { type: "manual" },
        })
        .then(() => {
          setdataSetName(undefined);
          setisOpen(false);
        });
    } else {
      //set error here....
    }
  };
  return isOpen ? (
    <Modal onClose={() => setisOpen(false)}>
      <div className={`${tw.card} z-50 flex flex-col`}>
        <label className={tw.label}>Data set name</label>
        <input
          type="text"
          className={`${tw.input} w-40 text-sm`}
          value={dataSetName}
          onChange={(e) => setdataSetName(e.target.value)}
        />
        <div className="flex mt-4">
          <button className={`${tw.saveBtn} mr-2`} onClick={() => saveNewDataset()}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  ) : (
    <button
      className={`py-2 bg-white w-40 focus:outline-none font-medium text-gray-700 hover:text-gray-800 rounded shadow`}
      onClick={() => setisOpen(true)}
    >
      Add new data set
    </button>
  );
};

export default InputPage;

const tw = {
  container: "w-screen min-h-screen relative pl-40 bg-gray-bg text-gray-800",
  headline: "font-medium mb-4 text-xl",
  card: "border border-gray-300 rounded px-8 py-4 bg-white shadow",
  label: "font-bold text-xs",
  dropdown: "w-40",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
  input: "px-2 py-2 focus:outline-none border rounded",
  saveBtn:
    "py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-sm hover:font-medium",
  closeBtn:
    "w-6 h-6 rounded-full text-xs text-gray-700 flex items-center justify-center bg-white shadow focus:outline-none border border-gray-200",
  variable: "text-left px-2 py-1 mr-4 mb-2 text-xs font-medium focus:outline-none",
  selectedVar: "bg-blue-600 text-white rounded shadow",
  popupBtn: "py-2 w-full font-medium text-gray-700 focus:outline-none hover:bg-gray-200",
};
