import React, { useState, useContext, useMemo, useEffect } from "react";
import { newProject } from "utils/firebase/firebaseUtils";
import {
  useProjects,
  useFirestore,
  useTeams,
  useAnalyticsLogger,
  useProjectStatistics,
} from "api/useFirebase";
import { store } from "store";
import Modal from "./basic/Modal";
import { Popup } from "./basic/Popup";
import { Project } from "model/datatypes";
import EditProject from "./projects/EditProject";
import { useHistory } from "react-router-dom";
import gtw from "gtw";
import Toast from "./basic/Toast";
import * as Sentry from "@sentry/browser";
import moment from "moment";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import LoadingOverlay from "./basic/LoadingOverlay";
import { useUserRole } from "api/useAuth";
import AddIcon from "./basic/icons/AddIcon";
import TeamCard from "./teams/TeamCard";
import DotDotDotIcon from "./basic/icons/DotDotDotIcon";
import SearchBar from "./basic/SearchBar";
import dayjs from "dayjs";
import NewTeam from "./teams/NewTeam";
import CommentableComponent from "components/comments/CommentableComponent";

interface Props {}

const HomePage: React.FC<Props> = () => {
  const { dispatch } = useContext(store);
  const history = useHistory();
  const teams = useTeams();
  const { state } = useContext(store);
  const { user, teamIds } = state;
  const { hasDeveloperAccess, hasProjectEditAccess, hasSimulatorAccess } = useUserRole();

  const { myProjects, allProjects, projectsLoading } = useProjects(
    hasDeveloperAccess,
    teamIds,
    user?.fbUser.uid
  );

  const setProject = (projectID: string, projectName: string) => {
    //save selected project locally...
    localStorage.setItem("selectedProjectID", projectID);
    localStorage.setItem("selectedProjectName", projectName);
    dispatch({
      type: "SET_CURRENT_PROJECT",
      payload: { projectID, projectName },
    });
    history.push("/overview");
  };

  const [addingProject, setAddingProject] = useState(false);
  const [addingTeam, setAddingTeam] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [shownProjects, setshownProjects] = useState<"my_projects" | "all">("all");

  //Set the project shown based on whether my_projects first time laoading is done:
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (allProjects.length > 0 && !firstLoad) {
      setFirstLoad(true);
      if (myProjects.length > 0 && hasDeveloperAccess) setshownProjects("my_projects");
      else setshownProjects("all");
    }
  }, [allProjects, myProjects, hasDeveloperAccess, firstLoad]);

  const currentProjects = useMemo(() => {
    let projects = shownProjects === "my_projects" ? myProjects : allProjects;
    if (searchWord.length > 0)
      projects = projects.filter((p) => p.projectName.match(new RegExp(searchWord, "i")));
    return projects.sort((a, b) => a.created.diff(b.created));
  }, [myProjects, allProjects, shownProjects, searchWord]);

  const showProjectTypeSelecter =
    hasDeveloperAccess && myProjects.length > 0 && myProjects.length < allProjects.length;

  const renderTypeOfProjectSelecter = () => {
    return (
      <>
        <button
          onClick={() => setshownProjects("my_projects")}
          className={`${gtw.smallBtn} mr-4
            ${shownProjects === "my_projects" ? gtw.activeBtn : ""}
          `}
        >
          My Projects
        </button>
        <button
          onClick={() => setshownProjects("all")}
          className={`${gtw.smallBtn} mr-4
            ${shownProjects === "all" ? gtw.activeBtn : ""}
          `}
        >
          All Projects
        </button>
      </>
    );
  };

  return (
    <div className={gtw.innerContainer}>
      <div className={`${tw.headline} mb-4 pl-4`}>Projects</div>

      <div className="flex items-center w-full px-4 mb-6">
        {showProjectTypeSelecter && renderTypeOfProjectSelecter()}
        <div className="flex-grow"></div>
        <SearchBar value={searchWord} onUpdate={(updated) => setSearchWord(updated)} />
        <button
          onClick={() => setAddingProject(true)}
          className={`flex items-center justify-center px-4 py-1 border-2 border-gray-700 text-gray-700 border-dashed rounded focus:outline-none`}
        >
          <AddIcon className="w-4 h-4 mr-4" />
          <span className="font-medium text-xs">Add new project</span>
        </button>
      </div>

      <div className="flex items-center w-full px-4">
        <div className="w-1/4 font-medium text-sm px-2">Project name</div>
        <div className="w-1/6 font-medium text-sm px-2">Most recent</div>
        <div className="w-1/6 font-medium text-sm px-2">Created</div>
        <div className="w-1/6 font-medium text-sm px-2">Scenarios</div>
        <div className="w-1/6 font-medium text-sm px-2">Simulations</div>
      </div>

      <div style={{ minHeight: "24rem" }} className="mb-10 relative">
        {currentProjects.map((project, i) => {
          return (
            <CommentableComponent
              key={project.id}
              commentTaget={{
                projectID: project.id,
                projectName: project.projectName,
              }}
              className={`relative my-1`}
            >
              <ProjectRow
                project={project}
                onSelect={() => setProject(project.id, project.projectName)}
                i={i}
                key={project.id}
              />
            </CommentableComponent>
          );
        })}
        {currentProjects.length === 0 && !projectsLoading && (
          <div className="italic text-sm px-6 mt-4">
            No projects yet - create your first project for it to appear here
          </div>
        )}
        {projectsLoading && <LoadingOverlay />}
      </div>

      {hasSimulatorAccess && (
        <>
          <div className={`${tw.headline} mb-4 pl-4`}>Teams</div>
          {teams.length > 0 && (
            <div className="flex flex-wrap mb-4">
              {teams.map((team, i) => {
                return <TeamCard team={team} key={team.id} i={i}></TeamCard>;
              })}
            </div>
          )}
          {teams.length === 0 && (
            <div className="italic text-sm px-4 mb-4">
              When teams are created they will appear here.
            </div>
          )}
          {hasProjectEditAccess && (
            <div className="px-4 pb-8 w-1/3">
              <button
                onClick={() => setAddingTeam(true)}
                className={`flex items-center justify-center border-2 px-4 py-1 border-gray-700 text-gray-700 border-dashed rounded focus:outline-none`}
              >
                <AddIcon className="w-4 h-4" />
                <span className="pl-4 font-medium text-xs">Add new team</span>
              </button>
            </div>
          )}
        </>
      )}
      {addingTeam && <NewTeam onFinish={() => setAddingTeam(false)} />}
      {addingProject && <NewProject onFinish={() => setAddingProject(false)} />}
    </div>
  );
};

const ProjectRow: React.FC<{
  project: Project;
  onSelect: () => void;
  i: number;
}> = ({ project, onSelect, i }) => {
  const description =
    project.description && project.description.length > 0
      ? project.description
      : "No project description..";
  const [editingProject, setEditingProject] = useState(false);
  const [loading, setLoading] = useState(false);
  const fs = useFirestore();
  const { state } = useContext(store);
  const { user } = state;
  // const { isAddingComment } = useGlobalState();
  // const [highlighted] = useState(false);

  const { statistics } = useProjectStatistics(project.id);

  const copyProjectLinkToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = encodeURI(
      `${window.location.protocol}//${window.location.host}/simulations/${project.projectName}/${project.id}`
    );
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Direct link to project copied to clipboard", { icon: "success" });
  };

  const deleteProject = async () => {
    if (user?.fbUser.uid === project.ownerId)
      try {
        setLoading(true);
        const updateFields = { deleted: moment() };
        await fs
          .collection("Projects")
          .doc(project.id)
          .update(convertToFirestoreFormat(updateFields));
        setLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        Toast("Error deleting project", { icon: "error" });
        setLoading(false);
      }
  };

  const renderProjectOptions = () => {
    return (
      <Popup
        useHover
        mt={15}
        pos={"right"}
        content={(closeMe) => (
          <div className="text-xs">
            <button
              className={`${gtw.popupBtn}`}
              onClick={() => {
                setEditingProject(true);
                closeMe();
              }}
            >
              Edit project
            </button>
            <button
              className={`${gtw.popupBtn} border-t`}
              onClick={() => {
                copyProjectLinkToClipboard();
                closeMe();
              }}
            >
              Copy project link
            </button>
            {user?.fbUser.uid === project.ownerId ? renderDeleteOption(closeMe) : null}
          </div>
        )}
      >
        <button className="relative focus:outline-none flex justify-center items-center">
          <DotDotDotIcon />
        </button>
      </Popup>
    );
  };

  const renderDeleteOption = (onFinish: () => void) => {
    return (
      <Popup
        mt={-60}
        content={(closeDeletePopup) => {
          return (
            <div className="text-xs px-2 py-2 border border-gray-200 rounded">
              <div className="font-medium">Delete project?</div>
              <div className="italic mb-2">
                Are you sure you would like to delete this project?
              </div>
              <div className="flex">
                <div className="w-1/2 pr-1">
                  <button
                    className={`${tw.smallBtn} border-red-400 bg-red-400 text-white w-full ${
                      loading ? "opacity-50" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!loading) {
                        deleteProject();
                        closeDeletePopup();
                        onFinish();
                      }
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="w-1/2 pl-1">
                  <button
                    className={`${gtw.smallBtn} w-full`}
                    onClick={(e) => {
                      e.preventDefault();
                      closeDeletePopup();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      >
        <button className={`${gtw.popupBtn} text-red-500 border-t border-gray-400`}>
          Delete Project
        </button>
      </Popup>
    );
  };
  const mostRecent = statistics.mostRecent?.timeStamp;
  const recentSimFormat = mostRecent?.isSame(dayjs(), "year")
    ? "DD/MM HH:mm"
    : "DD/MM YY HH:mm";

  return (
    <>
      <div
        onClick={() => onSelect()}
        className={`flex items-center z-10 w-full px-6 py-4 shadow-sm bg-white cursor-pointer relative rounded border border-gray-200 hover:border-gray-300 `}
      >
        <div className="w-1/4 px-2">
          <div className="font-medium">{project.projectName}</div>{" "}
          <div className="text-xs">{description}</div>
        </div>
        <div className="w-1/6 px-2 text-xs">
          {mostRecent?.format(recentSimFormat) || "No simulations on log"}
        </div>
        <div className="w-1/6 px-2 text-xs">{project.created.format("MM/DD YYYY")}</div>
        <div className="w-1/6 px-2 text-xs">{project.amountOfScenarios} Scenarios</div>
        <div className="w-1/6 px-2 text-xs">
          <div>{statistics.totalStarted} Total runs</div>
          <div>{statistics.totalSimStarted} Simulations</div>
          <div>{statistics.totalProcessorStarted} Processors</div>
        </div>
        <div className="w-1/12 px-2 flex justify-end">{renderProjectOptions()}</div>
        {loading && <LoadingOverlay />}
      </div>
      {editingProject && (
        <EditProject project={project} onFinish={() => setEditingProject(false)} />
      )}
    </>
  );
};

const NewProject: React.FC<{ onFinish: () => void }> = ({ onFinish }) => {
  const [loading, setloading] = useState(false);
  const [projectName, setprojectName] = useState("Example Project");
  const [description, setDescription] = useState("");
  const { hasSimulatorAccess } = useUserRole();
  const { state } = useContext(store);
  const { user } = state;
  const fs = useFirestore();
  const analyticsLogger = useAnalyticsLogger();
  const addProject = () => {
    if (hasSimulatorAccess && user?.fbUser.uid) {
      setloading(true);
      newProject(fs, projectName, description, user?.fbUser.uid).then((projectID) => {
        setloading(false);
        onFinish();
        analyticsLogger("scenario_created", {
          project_name: projectName,
          project_id: projectID,
          user_name: user?.fullName || "",
          user_id: user?.fbUser.uid || "",
        });
      });
    }
  };
  return (
    <Modal onClose={onFinish}>
      <div className={`${tw.card} p-4 z-50 bg-white`}>
        <div className="font-medium">New project</div>
        <label className="font-bold text-xs">Project name</label>
        <input
          type="text"
          className={`${tw.input} mb-2`}
          value={projectName}
          onChange={(e) => setprojectName(e.target.value)}
        />
        <label className="font-bold text-xs">Project description</label>
        <textarea
          className={`${tw.input} h-20 mb-2`}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <button
          className={`${tw.saveBtn} mb-2 ${loading ? "opacity-50" : ""}`}
          onClick={() => {
            !loading && addProject();
          }}
        >
          Add project
        </button>
      </div>
    </Modal>
  );
};
export default HomePage;

const tw = {
  headline: "font-bold text-gray-700 text-xl",
  card: "border border-gray-300 rounded bg-white shadow",
  label: "font-bold text-xs",
  dropdown: "w-40",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
};
