import React, { useState, useContext } from "react";
import { useProject } from "api/useFirebase";
import { store } from "store";
import { Popup } from "components/basic/Popup";
import EditProject from "components/projects/EditProject";

import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import gtw from "gtw";

interface Props {}

const ProjectInfo = (props: Props) => {
  const { state } = useContext(store);
  const [editingProject, setEditingProject] = useState(false);
  const project = useProject(state.projectID);

  return (
    <>
      <div className="flex items-center">
        <div className={`font-bold text-xl mr-2 text-gray-700`}>{project?.projectName}</div>
        <Popup
          useHover
          mt={15}
          content={(closeMe) => (
            <div className="text-xs">
              <button
                className={`${gtw.popupBtn}`}
                onClick={() => {
                  setEditingProject(true);
                  closeMe();
                }}
              >
                Edit project
              </button>
            </div>
          )}
        >
          <button className="relative focus:outline-none flex justify-center items-center">
            <DotDotDotIcon />
          </button>
        </Popup>
      </div>

      <div className="text-sm mb-4">{project?.description}</div>
      {editingProject && project && (
        <EditProject project={project} onFinish={() => setEditingProject(false)} />
      )}
    </>
  );
};

export default ProjectInfo;
