import React, { useRef, useState } from "react";
import { useFirebase } from "api/useFirebase";
import Dropzone from "components/basic/upload/Dropzone";
import { v4 as uuid } from "uuid";
import { FileQuery, SimFile, FileRef } from "model/datatypes";
import UploadIcon from "components/basic/icons/UploadIcon";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useGlobalState } from "store";
import CheckmarkIcon from "components/basic/icons/CheckmarkIcon";
import gtw from "gtw";
import Toast from "components/basic/Toast";
import Modal from "components/basic/Modal";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import { ErrorIcon } from "components/basic/icons/ErrorIcon";
import app from "firebase/app";

interface Props {
  fileQuery: FileQuery;
  onFinish: (uploadedFile?: FileRef) => void;
}

type UploadFile = {
  id: string;
  file: File;
  name: string;
  type: string;
};

const FileParameterUploader: React.FC<Props> = ({ fileQuery, onFinish }) => {
  const { projectID } = useGlobalState();

  const [newFile, setNewFile] = useState<UploadFile | null>(null);
  const [uploadedFileRef, setUploadedFileRef] = useState<null | FileRef>(null);
  const [progress, setProgress] = useState<number | null>(null);
  const [uploading, setUploading] = useState(false);
  const fb = useFirebase();

  const uploadTask = useRef<app.storage.UploadTask | null>(null);

  const saveFile = async (nf: UploadFile) => {
    if (!projectID) return;

    setProgress(0);
    setUploading(true);

    const allTags = [...fileQuery.tags, `project_${projectID}`];
    const type = nf.type.toLowerCase();

    //UPLOAD To buckett with metadata:
    let path = `simulation_files/project/${projectID}`;
    if (fileQuery.modelRef?.id) path = `${path}/system/${fileQuery.modelRef.id}`;

    path = `${path}/${nf.id}`;
    const uploadRef = fb.storage().ref(path);
    const customMetadata: { [key: string]: any } = { name: nf.name, tags: allTags.toString() };
    customMetadata.projectID = projectID;
    if (fileQuery.modelRef?.id) customMetadata.modelID = fileQuery.modelRef.id;
    uploadTask.current = uploadRef.put(nf.file, { customMetadata });
    uploadTask.current.on(
      "state_changed",
      (snap) => {
        let prog = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(prog);
      },
      (error) => {
        console.log(error);
        uploadTask.current = null;
        setProgress(null);
        setUploading(false);
      },
      async () => {
        const fs = fb.firestore();
        const uploadedSimFile: SimFile = {
          id: nf.id,
          path,
          name: nf.name,
          tags: allTags,
          type,
          projectID,
        };
        if (fileQuery.modelRef?.id) uploadedSimFile.modelID = fileQuery.modelRef.id;
        await fs.collection("FileLibrary").doc(uploadedSimFile.id).set(uploadedSimFile);
        setProgress(100);
        setUploadedFileRef({
          id: uploadedSimFile.id,
          name: uploadedSimFile.name,
          path: uploadedSimFile.path,
        });
        setUploading(false);
        uploadTask.current = null;
      }
    );
  };

  const renderUploadBtn = (
    dropHovered: boolean,
    error: string | null,
    manualOpen: () => void
  ) => {
    return (
      <div
        className={`cursor-pointer flex flex-col items-center justify-center border-4 border-gray-400 border-dashed rounded-lg h-32 ${
          dropHovered ? "bg-green-200 shadow-lg" : ""
        }`}
        onClick={() => manualOpen()}
      >
        <div className="w-12 h-12 text-gray-700">
          <UploadIcon />
        </div>
        <div className="font-medium text-gray-700">Drop file or click to upload</div>
        {fileQuery.type && (
          <div className="text-xs italic">File must have extension: {fileQuery.type}</div>
        )}
      </div>
    );
  };
  const renderAddingFile = () => {
    if (newFile)
      return (
        <div className="h-32 flex flex-col">
          <div className="flex-grow flex flex-col items-center w-full justify-center">
            <div className="w-16 h-16">
              <CircularProgressbarWithChildren
                value={progress || 0}
                strokeWidth={12}
                styles={{ path: { stroke: progress === 100 ? "#48BB78" : "#e4ebf2" } }}
              >
                {progress === 100 ? (
                  <CheckmarkIcon />
                ) : uploading ? (
                  <LoadingIcon className="text-green-400" />
                ) : (
                  <ErrorIcon className="text-red-500" />
                )}
              </CircularProgressbarWithChildren>
            </div>
            <div className="font-medium text-xs">{newFile.name}</div>
          </div>
          {uploadedFileRef && (
            <button
              onClick={() => onFinish(uploadedFileRef)}
              className={`${gtw.smallBtn} w-full`}
            >
              Ok
            </button>
          )}
          {uploading && (
            <button
              onClick={() => {
                if (uploadTask.current?.cancel()) {
                  setNewFile(null);
                  setUploading(false);
                  setProgress(null);
                }
              }}
              className={`${gtw.smallBtn} w-full`}
            >
              Cancel
            </button>
          )}
        </div>
      );
  };

  return (
    <>
      <Modal
        onClose={() => {
          if (!uploading) {
            if (uploadedFileRef) onFinish(uploadedFileRef);
            else onFinish();
          }
        }}
      >
        <div className="z-30 bg-white rounded shadow-lg p-4 w-1/2">
          <Dropzone
            allowedTypes={fileQuery.type || "all"}
            className=""
            onFilesAdded={(files) => {
              const file = files[0];
              const fileID = uuid().replace(/-/gi, "_");
              const extension = file.name.substring(file.name.lastIndexOf(".") + 1);
              const uploadFile: UploadFile = {
                id: fileID,
                file,
                name: file.name,
                type: extension,
              };
              setNewFile(uploadFile);
              saveFile(uploadFile);
            }}
            onError={(msg) => {
              Toast(msg, { icon: "error" });
            }}
          >
            {(dropHovered, error, manualOpen) => {
              if (!newFile) return renderUploadBtn(dropHovered, error, manualOpen);
              else return renderAddingFile();
            }}
          </Dropzone>
        </div>
      </Modal>
    </>
  );
};

export default FileParameterUploader;
