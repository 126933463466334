import React, { useState, useMemo } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Scenario } from "model/datatypes";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import { ErrorIcon } from "components/basic/icons/ErrorIcon";
import RetryIcon from "components/basic/icons/RetryIcon";
import CheckmarkIcon from "components/basic/icons/CheckmarkIcon";
import StopIcon from "components/basic/icons/StopIcon";
import PlayIcon from "components/basic/icons/PlayIcon";
import { useUserRole } from "api/useAuth";
import NewTabIcon from "components/basic/icons/NewTabIcon";
import InputDataIcon from "components/basic/icons/InputDataIcon";
import ReportIcon from "components/basic/icons/ReportIcon";

type SimulationPipelineIconProps = {
  pipelineReady: boolean;
  status: Scenario["status"];
  onStart: () => void;
  onStop: () => void;
};

export const SmulationPipelineIcon = ({
  status,
  onStart,
  onStop,
  pipelineReady,
}: SimulationPipelineIconProps) => {
  const { hasDeveloperAccess } = useUserRole();
  let statusColor = "#CBD5E0"; //grey
  let done = false;
  let running = false;
  switch (status?.status) {
    case "finished":
      statusColor = "#48BB78"; //dark green
      done = true;
      break;
    case "failed":
      statusColor = "#E53E3E"; //red
      break;
    case "Components Incomplete":
    case "incomplete":
      statusColor = "#e4ebf2"; //light grey...
      break;
    case "requested":
    case "running":
    case "initializing":
    case "Model Initializing":
    case "Environment Initializing":
    case "Equations Assembly":
      statusColor = "#9AE6B4"; //Light green
      running = true;
      break;
  }

  const [hovered, setHovered] = useState(false);

  const simCanReRun = status?.status === "finished" && hasDeveloperAccess;
  const simCanRun =
    pipelineReady &&
    !done &&
    !running &&
    status?.status !== "incomplete" &&
    status?.status !== "Components Incomplete";

  const message = status ? (status.message ? status.message : status.status) : "Simulation";

  const renderIcon = () => {
    if (done) return hovered && simCanReRun ? <RetryIcon /> : <CheckmarkIcon className="" />;
    if (running)
      return hovered ? (
        <StopIcon />
      ) : status?.progress ? (
        <span className="text-xs font-medium">{status?.progress.toFixed(0)}%</span>
      ) : (
        <LoadingIcon />
      );
    if (simCanRun) return <PlayIcon />;
  };
  return (
    <div className={`${pipelineReady ? "" : "opacity-50"}`}>
      <div className="flex items-center justify-center relative">
        <div
          className={`h-8 w-8 ${
            simCanRun || simCanReRun || pipelineReady ? "cursor-pointer" : ""
          }`}
          style={{ color: statusColor }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => {
            (simCanRun || simCanReRun) && onStart();
            running && onStop();
          }}
        >
          <CircularProgressbarWithChildren
            value={status?.progress ? status.progress : 100}
            strokeWidth={8}
            styles={{ path: { stroke: statusColor } }}
          >
            {renderIcon()}
          </CircularProgressbarWithChildren>
        </div>
        <hr
          className={`w-24 border-b ${done ? "" : "border-dashed"}`}
          style={{ borderColor: done ? statusColor : "#CBD5E0" }}
        />
      </div>
      <div className="text-xs font-bold">Simulation</div>
      <div className="text-xs italic pr-2 w-24">{message}</div>
    </div>
  );
};

type ReportPipelineIconProps = {
  report_status: Scenario["report_status"];
  report_download: Scenario["report_download"];
  reportCanRun: boolean;
  pipelineReady: boolean;
  onStart: () => void;
};

export const PostProcessorPipelineIcon: React.FC<ReportPipelineIconProps> = ({
  report_status,
  onStart,
  report_download,
  reportCanRun,
  pipelineReady,
}) => {
  let statusColor = "#CBD5E0"; //grey
  switch (report_status?.status) {
    case "FAILED":
      statusColor = statusColor = "#E53E3E"; //red
      break;
    case "PROCESSING":
    case "TERMINATING":
      statusColor = "#9AE6B4"; //Light green
      break;
  }
  const [hovered, setHovered] = useState(false);

  const reportFinished =
    pipelineReady &&
    !!report_download &&
    (!report_status ||
      report_status?.status === "READY" ||
      report_status?.status === "finished");
  if (reportFinished) statusColor = statusColor = "#48BB78"; //dark green

  const displayMessage = useMemo(() => {
    if (report_status?.custom_message) return report_status.custom_message;
    if (report_status) return report_status.status;
    return "Ready";
  }, [report_status]);

  const renderIcon = () => {
    if (reportFinished) return hovered ? <RetryIcon /> : <CheckmarkIcon />;
    if (report_status?.status === "FAILED") return hovered ? <RetryIcon /> : <ErrorIcon />;
    if (report_status?.status === "requested" || report_status?.status === "PROCESSING")
      return <LoadingIcon />;
    if (reportCanRun) return <PlayIcon />;
    return null;
  };
  return (
    <div className={`${pipelineReady ? "" : "opacity-50"}`}>
      <div className="flex items-center relative">
        <div
          className="h-8 w-8 relative cursor-pointer"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          style={{ color: statusColor }}
          onClick={() => {
            if (reportCanRun) onStart();
          }}
        >
          <CircularProgressbarWithChildren
            value={report_status?.progress ? report_status.progress : 100}
            strokeWidth={8}
            styles={{ path: { stroke: statusColor } }}
          >
            {renderIcon()}
          </CircularProgressbarWithChildren>
        </div>
        {report_download && (
          <hr
            className={`w-24 border-b ${reportFinished ? "" : "border-dashed"}`}
            style={{ borderColor: reportFinished ? statusColor : "#CBD5E0" }}
          />
        )}
      </div>
      <div className="text-xs font-bold">Post processor</div>
      <div className="text-xs italic"> {displayMessage}</div>
    </div>
  );
};

export const ResultsView: React.FC<{
  isUpdated: boolean;
  timeStamp: string;
  reportLink: string;
  onViewReport: () => void;
  hasDatasource: boolean;
  onViewOutput: () => void;
}> = ({ isUpdated, timeStamp, onViewReport, hasDatasource, onViewOutput, reportLink }) => {
  const baseURL = window.location.protocol + "//" + window.location.host;

  const borderColorStyle = useMemo(
    () => (isUpdated ? "border-green-500 text-green-500" : "border-gray-300 text-gray-300"),
    [isUpdated]
  );

  const renderViewOuput = () => {
    return (
      <div>
        <div
          className={`mt-1 h-8 w-8 flex items-center justify-center rounded-full border-2 cursor-pointer ${borderColorStyle} `}
          onClick={() => onViewOutput()}
        >
          <InputDataIcon />
        </div>
        <div className="font-medium">Dataset</div>
        <div
          className={`absolute top-0 mt-4 left-0 ${borderColorStyle}`}
          style={{ height: "4.5rem", width: "3.375rem", marginLeft: "-3.375rem" }}
        >
          <CurvedConnection />
        </div>
      </div>
    );
  };

  const renderReportOptions = () => {
    return (
      <a
        href={`${baseURL}${reportLink}`}
        target="_blank"
        rel="noopener noreferrer"
        className="h-4 w-4"
      >
        <NewTabIcon />
      </a>
    );
  };

  return (
    <div className="text-xs relative">
      <div
        onClick={() => {
          onViewReport();
        }}
        style={{ borderWidth: "3px" }}
        className={`p-1 h-8 w-8 flex items-center justify-center rounded-full   cursor-pointer ${borderColorStyle} `}
      >
        <ReportIcon className="w-full" />
      </div>
      <div className="flex items-center">
        <span className="font-medium mr-1">Report</span>
        {renderReportOptions()}
      </div>
      <div className="italic">Generated: {timeStamp}</div>
      {hasDatasource && renderViewOuput()}
    </div>
  );
};

const CurvedConnection: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={"h-full w-full"}
    stroke="currentColor"
    width="75"
    height="100"
    viewBox="0 0 75 100"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path stroke="currentColor" strokeWidth="4" d="M0 0c51.198 0 24.05 99 75 99"></path>
    </g>
  </svg>
);
