import React, { useState, useMemo, useContext } from "react";
import {
  useComponentTypes,
  useContainers,
  useFirebase,
  useSimulationModel,
} from "api/useFirebase";
import { ComponentEditer } from "./componentEditor/ComponentEditor";
import { useParams } from "react-router-dom";
import {
  ComponentType,
  SubCompRules,
  Container,
  DiscoveredComponent,
  DiscoveredGitModel,
  SimulationModel,
} from "model/datatypes";
import AddComponentType from "./AddComponentType";
import SaveLibraryComponent from "./SaveLibraryComponent";
import EditableName from "components/basic/EditableName";
import gtw from "gtw";
import ContainerEditor from "./componentEditor/ContainerEditor";
import LocalFiles from "./files/LocalFiles";
import ManualGitModels from "./GitModels";
import PostProcessorSetup from "./PostProcessorSetup";
import { DiscoveredGitModels } from "./DiscoveredGitModels";
import { compTypeFromDiscovered } from "utils/ComponentTypeHelpers";
import * as Sentry from "@sentry/browser";
import { store } from "store";
import AddIcon from "components/basic/icons/AddIcon";
import GroupProcessorSelecter from "./GroupProcessorSelecter";
import LoadingOverlay from "components/basic/LoadingOverlay";
import {
  EndpointSettings,
  SimulationRunSettings,
} from "components/simulations/newSimulation/simSetup/RunSimulation";
import Dropdown from "components/basic/Dropdown";
import EditCollaborators from "components/collaboratos/EditCollaborators";
import dayjs from "dayjs";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { fsFieldvalue } from "utils/firebase/helpers";

interface Props {}

type EntityListItem =
  | { type: "container"; entity: Container }
  | { type: "componentType"; entity: ComponentType };

const EditSystemPage: React.FC<Props> = () => {
  const { state } = useContext(store);
  const { user } = state;
  const [loading, setLoading] = useState(false);
  const [addComponent, setAddComponent] = useState<boolean | ComponentType>(false);
  const [newLibraryComponent, setNewLibraryComponent] = useState<null | ComponentType>(null);
  const { simModelID } = useParams<{ simModelID: string | undefined }>();
  const { componentTypes, updateComponent, addComponentType } = useComponentTypes(simModelID);
  const { containers, updateContainer } = useContainers(simModelID);
  const simulationModel = useSimulationModel(simModelID);
  const isSystemOwner = user && user.fbUser.uid === simulationModel?.ownerId;

  const fb = useFirebase();

  //Create list of all containers and components:
  const entities = useMemo(() => {
    const entitityHolder: EntityListItem[] = containers.map((container) => ({
      type: "container",
      entity: container,
    }));
    componentTypes
      .filter((c) => !c.fixed)
      .forEach((c) => {
        entitityHolder.push({ type: "componentType", entity: c });
      });
    return entitityHolder.sort((a, b) => {
      if (!a.entity.order) return -1;
      else if (!b.entity.order) return 1;
      else return a.entity.order - b.entity.order;
    });
  }, [containers, componentTypes]);

  const fixedComponents = useMemo(() => componentTypes.filter((c) => c.fixed), [
    componentTypes,
  ]);

  const subComponentRulesOptions = useMemo(
    () =>
      componentTypes
        .filter((c) => !c.fixed)
        .map((c) => {
          const subCompRules: SubCompRules = {
            id: c.id,
            min: 1,
            max: 1,
            default: 1,
          };
          return { displayName: c.displayName, subCompRules };
        }),
    [componentTypes]
  );

  const addDiscoveredComp = (comp: DiscoveredComponent, model: DiscoveredGitModel) => {
    return new Promise<void>((resolve, reject) => {
      const compType = compTypeFromDiscovered(comp, model.id, entities.length);
      addComponentType(compType)
        .then((id) => {
          const element = document.getElementById(id);
          element && element.scrollIntoView({ behavior: "smooth" });
          resolve();
        })
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        });
    });
  };

  const onRemoveContainer = (container: Container) => {
    const batch = fb.firestore().batch();
    const modelDoc = fb.firestore().collection("SimulationModels").doc(simModelID);

    //delete the container
    batch.delete(modelDoc.collection("Containers").doc(container.id));

    //Move entities below up:
    moveEntitiesBelowUp(batch, container.order);

    const latestEdited: SimulationModel["latestEdited"] = {
      userId: user!.fbUser.uid,
      time: dayjs(),
    };
    batch.update(modelDoc, {
      version: fsFieldvalue.increment(1),
      latestEdited: convertToFirestoreFormat(latestEdited),
    });
    return batch.commit();
  };

  const updateSimulationModel = (changed: Partial<SimulationModel>) => {
    const latestEdited: SimulationModel["latestEdited"] = {
      userId: user!.id,
      time: dayjs(),
    };

    return fb
      .firestore()
      .collection("SimulationModels")
      .doc(simModelID)
      .update(convertToFirestoreFormat({ ...changed, latestEdited }));
  };

  const updateStatus = (newStatus: SimulationModel["status"]) => {
    if (simModelID && !loading) {
      setLoading(true);
      updateSimulationModel({ status: newStatus })
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          Sentry.captureException(error);
        });
    }
  };

  const onMoveComponentType = (comp: ComponentType, i: number, dir: "up" | "down") => {
    if (dir === "up") {
      updateComponent(comp.id, { order: comp.order - 1 });
      const itemAbove = entities[i - 1];
      if (itemAbove) {
        if (itemAbove.type === "componentType")
          updateComponent(itemAbove.entity.id, {
            order: itemAbove.entity.order + 1,
          });
        else updateContainer(itemAbove.entity.id, { order: itemAbove.entity.order + 1 });
      }
    } else {
      const itemBelow = entities[i + 1];
      if (itemBelow) {
        updateComponent(comp.id, { ...comp, order: comp.order + 1 });
        if (itemBelow.type === "componentType")
          updateComponent(itemBelow.entity.id, {
            order: itemBelow.entity.order - 1,
          });
        else {
          updateContainer(itemBelow.entity.id, { order: itemBelow.entity.order - 1 });
        }
      }
    }
  };

  const onMoveContainer = (cont: Container, i: number, dir: "up" | "down") => {
    if (dir === "up") {
      updateContainer(cont.id, { order: cont.order - 1 });
      const itemAbove = entities[i - 1];
      if (itemAbove) {
        if (itemAbove.type === "componentType")
          updateComponent(itemAbove.entity.id, {
            order: itemAbove.entity.order + 1,
          });
        else updateContainer(itemAbove.entity.id, { order: itemAbove.entity.order + 1 });
      }
    } else {
      const itemBelow = entities[i + 1];
      if (itemBelow) {
        updateContainer(cont.id, { ...cont, order: cont.order + 1 });
        if (itemBelow.type === "componentType")
          updateComponent(itemBelow.entity.id, {
            order: itemBelow.entity.order - 1,
          });
        else {
          updateContainer(itemBelow.entity.id, { order: itemBelow.entity.order - 1 });
        }
      }
    }
  };

  const onRemoveComponent = (componentID: string, i: number) => {
    const batch = fb.firestore().batch();
    const modelDoc = fb.firestore().collection("SimulationModels").doc(simModelID);

    //delete the component
    batch.delete(modelDoc.collection("Components").doc(componentID));

    //move all entities below up:
    moveEntitiesBelowUp(batch, i);

    //remove from all conatiners where it is referenced:
    containers
      .filter((container) => container.componentTypeIDs.some((id) => id === componentID))
      .forEach((container) => {
        const contDoc = modelDoc.collection("Containers").doc(container.id);
        const isDefaultComponent = container.defaultComponentType === componentID;

        batch.update(contDoc, {
          componentTypeIDs: fsFieldvalue.arrayRemove(componentID),
          ...(isDefaultComponent && { defaultComponentType: fsFieldvalue.delete() }),
        });
      });

    const latestEdited: SimulationModel["latestEdited"] = {
      userId: user!.id,
      time: dayjs(),
    };

    //update the model
    batch.update(
      modelDoc,
      convertToFirestoreFormat({ version: fsFieldvalue.increment(1), latestEdited })
    );

    return batch.commit();
  };

  const moveEntitiesBelowUp = (batch: any, i: number) => {
    const modelDoc = fb.firestore().collection("SimulationModels").doc(simModelID);

    //move all comp below one order up...
    let iterator = i + 1;
    while (iterator < entities.length) {
      const itemBelow = entities[iterator];
      if (itemBelow.type === "componentType") {
        const compDoc = modelDoc.collection("Components").doc(itemBelow.entity.id);
        batch.update(compDoc, { order: itemBelow.entity.order - 1 });
      } else {
        const contDoc = modelDoc.collection("Containers").doc(itemBelow.entity.id);
        batch.update(contDoc, { order: itemBelow.entity.order - 1 });
      }
      iterator = iterator + 1;
    }
  };

  const renderComponentType = (c: ComponentType, i: number) => {
    const modelRef = simulationModel
      ? { id: simulationModel.id, displayName: simulationModel.displayName }
      : undefined;

    return (
      <div id={c.id} key={c.id} className="w-full py-4">
        <ComponentEditer
          modelRef={modelRef}
          comp={c}
          onMove={(dir) => onMoveComponentType(c, i, dir)}
          maxOrder={entities.length - 1}
          updateComponent={updateComponent}
          removeComponent={(componentID) => onRemoveComponent(componentID, i)}
          onDuplicate={() => {
            setAddComponent(c);
          }}
          onSaveToLib={() => {
            setNewLibraryComponent(c);
          }}
          subCompOptions={subComponentRulesOptions}
          allowedParamRefs={entities
            .filter((item) => item.entity.order < c.order)
            .map((item) => ({ id: item.entity.id, displayName: item.entity.displayName }))}
        />
      </div>
    );
  };

  const renderContainer = (container: Container, i: number) => {
    const modelRef = simulationModel
      ? { id: simulationModel.id, displayName: simulationModel.displayName }
      : undefined;

    return (
      <div key={container.id} className="w-full py-4">
        <ContainerEditor
          container={container}
          allComponentTypes={componentTypes}
          updateContainer={updateContainer}
          modelRef={modelRef}
          removeContainer={() => onRemoveContainer(container)}
          allowedParamRefs={entities
            .filter((item) => item.entity.order < container.order)
            .map((item) => ({ id: item.entity.id, displayName: item.entity.displayName }))}
          subCompOptions={subComponentRulesOptions}
          onMove={(dir) => onMoveContainer(container, i, dir)}
          maxOrder={entities.length - 1}
        />
      </div>
    );
  };

  if (!simulationModel)
    return (
      <div className="w-screen h-screen relative">
        <LoadingOverlay />
      </div>
    );

  return (
    <div className={gtw.innerContainer}>
      <div className={`${tw.headline} mb-2 flex items-center`}>
        <div className="flex-grow">
          <EditableName
            name={simulationModel.displayName}
            onChange={(displayName) => {
              if (simModelID && !loading) {
                setLoading(true);
                updateSimulationModel({ displayName })
                  .then(() => {
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);
                  });
              }
            }}
            loading={loading}
          />
        </div>
      </div>
      <DiscoveredGitModels
        addedComponentTypes={componentTypes.filter((ct) => !!ct.modelID)}
        simulationModel={simulationModel}
        onAddcomponent={addDiscoveredComp}
      />
      <ManualGitModels simulationModel={simulationModel} />
      <PostProcessorSetup simulationModel={simulationModel} />
      <GroupProcessorSelecter simulationModel={simulationModel} />
      {fixedComponents.length > 0 && (
        <>
          <div className={`${tw.headline} mt-4 mb-2`}>Fixed components</div>
          {fixedComponents.map((c) => {
            return (
              <div key={c.id} className="w-full py-4">
                <ComponentEditer
                  modelRef={
                    simulationModel
                      ? { id: simulationModel.id, displayName: simulationModel.displayName }
                      : undefined
                  }
                  comp={c}
                  updateComponent={updateComponent}
                  subCompOptions={subComponentRulesOptions}
                  allowedParamRefs={[]}
                />
              </div>
            );
          })}
        </>
      )}
      <div className={`${tw.headline} mt-4 mb-2`}>Configure system components</div>
      <div>
        {entities.map((item, i) => {
          if (item.type === "componentType") {
            return renderComponentType(item.entity, i);
          } else {
            return renderContainer(item.entity, i);
          }
        })}
      </div>
      <div></div>
      {newLibraryComponent && (
        <SaveLibraryComponent
          onFinish={() => setNewLibraryComponent(null)}
          compType={newLibraryComponent}
        />
      )}
      {addComponent && simModelID && (
        <AddComponentType
          simModelID={simModelID}
          startComp={typeof addComponent === "object" ? addComponent : undefined}
          order={entities.length}
          onFinish={() => setAddComponent(false)}
          onAdd={addComponentType}
        />
      )}
      <button
        onClick={() => setAddComponent(true)}
        className="mt-1 mb-6 flex items-center px-3 py-2 bg-white rounded shadow focus:outline-none text-gray-700 hover:text-gray-800"
      >
        <AddIcon className="h-6 w-6 mr-3" />
        <span className="text-xs font-bold">Add component type</span>
      </button>
      {simModelID && (
        <LocalFiles modelID={simModelID} modelName={simulationModel.displayName} />
      )}
      <div className="flex">
        <div className="mt-6 mb-6 w-1/2">
          <DefaultRunSetting
            runSettings={simulationModel.default_run_settings}
            endpoint={simulationModel.default_endpoint}
            updateRunSettings={(newSettings, updatedEndpoint) =>
              updateSimulationModel({
                default_run_settings: newSettings,
                default_endpoint: updatedEndpoint,
              })
            }
          />
        </div>
      </div>
      <div>
        <div className="text-lg">Share system</div>
        {isSystemOwner && (
          <>
            <span className="text-xs font-medium">Status</span>
            <Dropdown
              className={"bg-white w-64 text-xs"}
              flipped={simulationModel.status !== "published" || undefined}
              options={[
                { id: "draft", display: "Draft" },
                { id: "published", display: "Published" },
              ]}
              selectedID={simulationModel.status || "draft"}
              onSelect={(option) => {
                const newStatus = option.id as SimulationModel["status"];
                updateStatus(newStatus);
              }}
            />
            <span className="text-xs italic">
              {simulationModel.status === "published"
                ? "System will be availalble to other developers and selected colaborators"
                : "Draft systems are only available to the author"}
            </span>
          </>
        )}
        {simulationModel.status === "published" && (
          <div>
            <EditCollaborators
              allowEditing
              collaborators={simulationModel.collaborators || []}
              teamIds={simulationModel.teams || []}
              updateCollaborators={(updatedCollaborators) =>
                updateSimulationModel({ collaborators: updatedCollaborators })
              }
              updateTeams={(updatedTeams) => updateSimulationModel({ teams: updatedTeams })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditSystemPage;

const tw = {
  headline: "font-medium text-xl",
  card: "border border-gray-300 rounded p-4 bg-white shadow w-full",
  label: "text-xs font-medium",
  dropdown: "w-40",
  smallBtn:
    "bg-white py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
  activeBtn: "bg-gray-700 border-gray-700 text-white font-medium",
  input: "px-2 py-1 focus:outline-none border rounded",
  popupBtn: "py-1 w-full font-medium text-gray-700 focus:outline-none hover:bg-gray-200",
};

const DefaultRunSetting: React.FC<{
  runSettings: SimulationModel["default_run_settings"];
  updateRunSettings: (
    newSettings: SimulationModel["default_run_settings"],
    updatedendpoint: SimulationModel["default_endpoint"]
  ) => Promise<void>;
  endpoint: SimulationModel["default_endpoint"];
}> = ({ runSettings, updateRunSettings, endpoint }) => {
  const [changed, setChanged] = useState(false);
  const [editedSettings, setEditedSettings] = useState({
    settings: runSettings || {
      duration: 31622400,
      resolution: 30,
      result_resolution: 3600,
      result_unit: "minute",
      duration_unit: "month",
    },
    forceUpdate: false,
  });
  const [editedEndpoint, setEditedEndpoint] = useState<SimulationModel["default_endpoint"]>(
    endpoint || "kubernetes"
  );
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div className="font-bold">Default system run settings</div>
      <div className="p-4 bg-white shadow-md rounded">
        <SimulationRunSettings
          runSettings={editedSettings}
          updateRunSettings={(updatedSettings) => {
            setEditedSettings({ settings: updatedSettings, forceUpdate: false });
            setChanged(true);
          }}
        />
        <EndpointSettings
          endpoint={editedEndpoint}
          setEndpoint={(updated) => {
            setEditedEndpoint(updated);
            setChanged(true);
          }}
        />

        {changed && (
          <div className="flex w-full mt-4">
            <button
              className={`${gtw.smallBtn} flex-1 mr-2`}
              onClick={() => {
                if (changed && !loading) {
                  setLoading(true);
                  updateRunSettings(editedSettings.settings, editedEndpoint)
                    .then(() => {
                      setLoading(false);
                      setChanged(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      setLoading(false);
                    });
                }
              }}
            >
              Save
            </button>
            <button
              className={`${gtw.smallBtn} flex-1 mr-2`}
              onClick={() => {
                setEditedSettings({ settings: runSettings, forceUpdate: true });
                setChanged(false);
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </>
  );
};
