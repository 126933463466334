import React, { useState } from "react";
import LocationInput from "./LocationInput";
import { getClosestWeatherStations, LocationSearchResponse } from "api/WeatherAPI";
import AvgWeatherDisplay from "./AvgWeatherDisplay";

const WeatherInput: React.FC<{}> = () => {
  const [locationResponse, setlocationResponse] = useState<LocationSearchResponse | null>(
    null
  );
  return (
    <div className={`${tw.card} z-50 w-2/3`}>
      <div className="font-bold">Add weather data</div>
      <div className="">
        <label className={`text-xs font-medium`}>Search Location</label>
        <LocationInput
          onSelect={(location) => {
            if (location) {
              getClosestWeatherStations(location).then((res) => {
                setlocationResponse(res[0]);
                console.log(res[0]);
              });
            } else {
              setlocationResponse(null);
            }
          }}
        />
      </div>
      <div>
        {locationResponse && (
          <div className="my-1">
            Closest weather station: {locationResponse.name},{" "}
            {locationResponse.distance.toFixed(1)}km away.
          </div>
        )}
        {locationResponse && <AvgWeatherDisplay location={locationResponse} />}
        {locationResponse && (
          <button className={`${tw.saveBtn} mt-2`}>
            Load weather for {locationResponse.name}
          </button>
        )}
      </div>
    </div>
  );
};

export default WeatherInput;

const tw = {
  card: "border border-gray-300 rounded px-8 py-4 bg-white shadow",
  saveBtn:
    "py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-sm hover:font-medium",
};
