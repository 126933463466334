import React, { useState, useContext, useEffect } from "react";
import moment, { Moment } from "moment";
import { TimeSlotButton, AddTimeslot } from "./components/TimeSlotComponents";
import { v4 as uuid } from "uuid";
import SettingsInput, { AddSettingButton } from "./components/SettingsInput";
import DayViewer from "./components/DayViewer";
import { useFirebase } from "api/useFirebase";
import { store } from "store";
import { VariableReference } from "model/datatypes";
import * as Sentry from "@sentry/browser";
import {
  convertToFirestoreFormat,
  convertFromFirestoreFormat,
} from "utils/firebase/firestoreFormatter";
import LoadingOverlay from "components/basic/LoadingOverlay";
import app from "firebase/app";

export type Schedule = {
  name: string;
  id: string;
  periodSettings: PeriodSetting[];
};

type InputData = {
  variableName: string;
  variableType: "string" | "number";
  values: (string | number)[];
};

export type PeriodSetting = {
  id: string;
  calendar?: { label: string; id: string };
  type: PeriodType;
  timeslots: TimeSlot[];
};

export type PeriodType = "Weekends" | "Weekdays" | "Holidays" | "Custom" | "Other";

export type TimeSlot = {
  id: string;
  startTime: Moment;
  endTime: Moment;
  settingID: string;
};

export type Setting = {
  value: number;
  id: string;
  name: string;
  color: string;
};

interface Props {
  onFinished: (data?: number[]) => void;
  inputDataID: string;
  savedScheduleID?: string;
}

const ScheduleInput: React.FC<Props> = ({ onFinished, inputDataID, savedScheduleID }) => {
  const [schedule, setschedule] = useState<Schedule | null>(null);
  const [activePeriodID, setActivePeriodID] = useState<string>("weekday");
  const [settings, setsettings] = useState<Setting[]>(intialSettings);
  const [unit, setUnit] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);
  const fb = useFirebase();
  const { state } = useContext(store);
  const { projectID } = state;

  //load previous schedule....
  useEffect(() => {
    if (savedScheduleID && projectID) {
      setLoading(true);
      fb.firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .doc(inputDataID)
        .collection("Schedules")
        .doc(savedScheduleID)
        .get()
        .then((doc) => {
          const data = doc.data();
          if (!!data) {
            const savedSchedule = convertFromFirestoreFormat(data) as {
              unit: string | null;
              schedule: Schedule;
              settings: Setting[];
            };
            setUnit(savedSchedule.unit);
            setschedule(savedSchedule.schedule);
            setsettings(savedSchedule.settings);
            setLoading(false);
          }
        })
        .catch((e) => {
          Sentry.captureException(e);
          setLoading(false);
        });
    }
  }, [savedScheduleID, fb, inputDataID, projectID]);

  const saveNewInput = (data: number[]) => {
    if (schedule && !loading && projectID) {
      const batch = fb.firestore().batch();
      const inputDataSetRef = fb
        .firestore()
        .collection("Projects")
        .doc(projectID)
        .collection("InputData")
        .doc(inputDataID);
      //save the actual data in a subcollection
      const newDataRef = savedScheduleID
        ? inputDataSetRef.collection("Data").doc(savedScheduleID)
        : inputDataSetRef.collection("Data").doc();
      const inputToSave: InputData = {
        variableName: schedule.name,
        values: data,
        variableType: "number",
      };
      batch.set(newDataRef, inputToSave);

      //save a reference to the variable in the input set
      const varRef: VariableReference = {
        id: newDataRef.id,
        variableName: schedule.name,
        origin: "schedule",
      };
      batch.update(inputDataSetRef, {
        simulationVariables: app.firestore.FieldValue.arrayUnion(varRef),
      });

      //save the schedule settings for editing later...
      const scheduleSettingRef = inputDataSetRef.collection("Schedules").doc(newDataRef.id);
      const savedScheduleState = convertToFirestoreFormat({ unit, schedule, settings });
      batch.set(scheduleSettingRef, savedScheduleState);

      return batch.commit();
    }
  };

  ////Whenever new time period is input / updated
  const newPeriodTimeSetting = (newTimeslot: TimeSlot) => {
    if (schedule) {
      //make sure the settings fit full day, the update the settings:
      const curPeriodSetting = schedule.periodSettings.find((ps) => ps.id === activePeriodID);
      const curPeriodSettingIndex = schedule.periodSettings.findIndex(
        (ps) => ps.id === activePeriodID
      );
      if (curPeriodSetting) {
        let timeslots = curPeriodSetting.timeslots;
        timeslots = getCleanTimeslots(timeslots, newTimeslot);
        const newPeriodSetting = {
          ...curPeriodSetting,
          timeslots,
        };
        if (curPeriodSetting)
          setschedule({
            ...schedule,
            periodSettings: [
              ...schedule.periodSettings.slice(0, curPeriodSettingIndex),
              newPeriodSetting,
              ...schedule.periodSettings.slice(curPeriodSettingIndex + 1),
            ],
          });
      }
    }
  };

  if (!schedule || unit === null)
    return (
      <div className="relative">
        <ScheduleStartOptions
          onStart={(unit: string, schedule: Schedule) => {
            setUnit(unit);
            setschedule(schedule);
          }}
        />
        {loading && <LoadingOverlay />}
      </div>
    );
  else {
    const activePeriod = schedule.periodSettings.find((p) => p.id === activePeriodID);
    return (
      <div className="px-4 py-4 relative">
        <div className="font-medium text-xl">Schedule input</div>
        <div className="flex mt-4">
          <div className="w-1/2 pr-4 min-h-64 flex flex-col">
            <label className="text-xs font-bold mb-2">Schedule</label>
            <div className="bg-gray-100 flex-grow relative pb-10">
              <div className="bg-gray-300 flex">
                {schedule.periodSettings.map((daySetting) => {
                  const isActive = activePeriodID === daySetting.id;

                  return (
                    <button
                      key={daySetting.id}
                      className={`${tw.dayBtn} ${
                        isActive ? tw.activeDayBtn : tw.inactiveDayBtn
                      }`}
                      onClick={() => setActivePeriodID(daySetting.id)}
                    >
                      {daySetting.type}
                    </button>
                  );
                })}
              </div>
              {activePeriod && (
                <div className="pt-4">
                  <DayViewer timeslots={activePeriod.timeslots} settings={settings} />
                </div>
              )}
              <div className="px-4 pb-4">
                {activePeriod &&
                  activePeriod.timeslots
                    .sort((a, b) => {
                      return a.endTime.valueOf() - b.endTime.valueOf();
                    })
                    .map((slot) => {
                      const setting = settings.find((s) => s.id === slot.settingID);
                      if (setting)
                        return (
                          <TimeSlotButton
                            key={slot.id}
                            slot={slot}
                            setting={setting}
                            onAdd={(newtimeslot) => {
                              newPeriodTimeSetting(newtimeslot);
                            }}
                            settings={settings}
                          />
                        );
                      else return null;
                    })}
              </div>
              <div className="absolute bottom-0 left-0 mb-4 w-full px-4">
                <AddTimeslot
                  onAdd={(newtimeslot) => {
                    newPeriodTimeSetting(newtimeslot);
                  }}
                  settings={settings}
                />
              </div>
            </div>
          </div>

          <div className="w-1/2 min-h-64 pl-4 flex flex-col">
            <label className="text-xs font-bold mb-2">Settings</label>
            <div className="pb-10 bg-gray-100 relative flex-grow">
              <SettingsInput
                unit={unit}
                settings={settings}
                updateSettings={(newSettings) => setsettings(newSettings)}
              />
              <div className="absolute bottom-0 mb-4 left-0 w-full px-4">
                <AddSettingButton
                  index={settings.length}
                  unit={unit}
                  settings={settings}
                  updateSettings={(newSettings) => setsettings(newSettings)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex my-2">
          <button
            className={`${tw.smallBtn} mr-2 flex-1`}
            onClick={() => {
              if (!loading) {
                setLoading(true);
                const data = getFinalYearData(schedule, settings);
                saveNewInput(data)
                  ?.then(() => {
                    setLoading(false);
                    onFinished(data);
                  })
                  .catch((error) => {
                    Sentry.captureException(error);
                    setLoading(false);
                    console.log(error);
                  });
              }
            }}
          >
            Save
          </button>
          <button className={`${tw.smallBtn} ml-2 flex-1`} onClick={() => onFinished()}>
            Cancel
          </button>
        </div>
        {loading && <LoadingOverlay />}
      </div>
    );
  }
};

const tw = {
  dayBtn: "px-4 py-2 text-xs font-bold focus:outline-none",
  activeDayBtn: "border-b-2 border-cm-blue",
  inactiveDayBtn: "opacity-75",
  timeslotBtn: "w-1/2 border border-gray-300 bg-white px-2 py-1 text-center text-xs",
  input: "px-2 py-1 focus:outline-none border rounded",
  smallBtn:
    "bg-white py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
};

const ScheduleStartOptions: React.FC<{
  onStart: (unit: string, schedule: Schedule) => void;
}> = ({ onStart }) => {
  const [unit, setUnit] = useState("");
  const [variableID, setVariableID] = useState("");
  const [includeWeekends, setIncludeWeekends] = useState(true);
  const [includeHolidays, setIncludeHolidays] = useState(true);
  const startSchedule = () => {
    const periodSettings: PeriodSetting[] = [];
    if (includeWeekends) periodSettings.push(DefaultWeekdaySettings);
    else periodSettings.push(DefaultOtherSettings);
    includeWeekends && periodSettings.push(DefaultWeekendSettings);
    includeHolidays && periodSettings.push(DefaultHolidaySettings);

    const newSchedule: Schedule = {
      name: variableID,
      id: variableID,
      periodSettings,
    };

    onStart(unit, newSchedule);
  };

  return (
    <div className="text-xs flex flex-col px-4 py-4">
      <div className="font-bold text-lg">Start new schedule</div>
      <div className="mt-2">Data ID</div>
      <input
        type="text"
        className={`${tw.input} w-40`}
        value={variableID}
        onChange={(e) => {
          setVariableID(e.target.value);
        }}
        onKeyDown={(e) => e.key === "Enter" && startSchedule()}
      />
      <div className="mt-2">Unit</div>
      <input
        type="text"
        className={`${tw.input} w-40`}
        value={unit}
        onChange={(e) => {
          setUnit(e.target.value);
        }}
        onKeyDown={(e) => e.key === "Enter" && startSchedule()}
      />
      <div className="mt-4 font-medium">Included days</div>
      <div>
        <input
          type="checkbox"
          checked={includeWeekends}
          onChange={(e) => setIncludeWeekends(e.target.checked)}
        />
        <label
          className="ml-2 cursor-pointer"
          onClick={() => {
            setIncludeWeekends(!includeWeekends);
          }}
        >
          Weekends
        </label>
      </div>
      <div>
        <input
          name="holidays"
          type="checkbox"
          checked={includeHolidays}
          onChange={(e) => setIncludeHolidays(e.target.checked)}
        />
        <label
          className="ml-2 cursor-pointer"
          onClick={() => {
            setIncludeHolidays(!includeHolidays);
          }}
        >
          Holidays
        </label>
      </div>
      <button className={`${tw.smallBtn} mt-2`} onClick={() => startSchedule()}>
        Ok
      </button>
    </div>
  );
};

export default ScheduleInput;

const intialSettings: Setting[] = [
  {
    value: 1,
    id: "default",
    name: "Default",
    color: "#555555",
  },
];

const DefaultWeekendSettings: PeriodSetting = {
  id: "weekends",
  type: "Weekends",
  timeslots: [],
};
const DefaultWeekdaySettings: PeriodSetting = {
  id: "weekday",
  type: "Weekdays",
  timeslots: [],
};
const DefaultOtherSettings: PeriodSetting = {
  id: "other",
  type: "Other",
  timeslots: [],
};
const DefaultHolidaySettings: PeriodSetting = {
  id: "holiday",
  type: "Holidays",
  calendar: {
    label: "Weekends and Swedish National Holidays",
    id: "weekend-sweholiday",
  },
  timeslots: [],
};

//Inserts new timeslot into previous and makes sure no previous values overlap.
const getCleanTimeslots = (timeslots: TimeSlot[], newTimeslot: TimeSlot) => {
  const cleanTSStart: TimeSlot[] = [];
  const cleanTS = timeslots.reduce((prev, cur) => {
    //if cur is the one being updated:
    if (cur.id === newTimeslot.id) return prev;
    //if cur is between newTS completely, remove it
    if (
      cur.startTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]") &&
      cur.endTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]")
    )
      return prev;
    //if cur overlaps newTS completely, split it into two
    if (
      cur.startTime.isBefore(newTimeslot.startTime) &&
      cur.endTime.isAfter(newTimeslot.endTime)
    )
      return [
        ...prev,
        { ...cur, id: uuid().replace(/-/gi, "_"), endTime: newTimeslot.startTime },
        { ...cur, startTime: newTimeslot.endTime },
      ];

    //If overlap only one time with new TS:
    if (cur.startTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]"))
      return [...prev, { ...cur, startTime: newTimeslot.endTime.clone() }];
    if (cur.endTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]"))
      return [...prev, { ...cur, endTime: newTimeslot.startTime.clone() }];
    //no overlap..
    return [...prev, cur];
  }, cleanTSStart);

  return newTimeslot.settingID === "default" ? cleanTS : [...cleanTS, newTimeslot];
};

const getFinalYearData = (schedule: Schedule, settings: Setting[]) => {
  const momentIterater = moment().startOf("year");
  const endMoment = momentIterater.clone().add(1, "year");
  let data: number[] = [];
  const includeWeekends = schedule.periodSettings.some((p) => p.type === "Weekends");
  const includeHolidays = schedule.periodSettings.some((p) => p.type === "Holidays");
  const includeWeekdays = schedule.periodSettings.some((p) => p.type === "Weekdays");

  //Run once for each day during the year:
  while (momentIterater.isBefore(endMoment)) {
    //Check what type of day it is and get data for the type of day.
    let nextDataPoints: number[] = [];
    if (includeHolidays && isHoliday(momentIterater, swedishHoliday)) {
      const period = schedule.periodSettings.find((p) => p.type === "Holidays");
      if (period) nextDataPoints = getDayData(period.timeslots, settings);
    } else if (includeWeekends && isWeekend(momentIterater)) {
      const period = schedule.periodSettings.find((p) => p.type === "Weekends");
      if (period) nextDataPoints = getDayData(period.timeslots, settings);
    } else if (includeWeekdays && isWeekday(momentIterater)) {
      const period = schedule.periodSettings.find((p) => p.type === "Weekdays");
      if (period) nextDataPoints = getDayData(period.timeslots, settings);
    } else if (nextDataPoints.length === 0) {
      const period = schedule.periodSettings.find((p) => p.type === "Other");
      if (period) nextDataPoints = getDayData(period.timeslots, settings);
    }
    console.log(nextDataPoints.length);
    data = [...data, ...nextDataPoints];
    momentIterater.add(1, "day");
  }
  return data;
};

const getDayData = (timeslots: TimeSlot[], settings: Setting[]) => {
  //Get data for all hours during a single day
  const data: number[] = [];
  const time = moment("00:00", "HH:mm");
  const endTime = time.clone().add(1, "day");
  while (time.isBefore(endTime)) {
    const settingID = getSettingID(time, timeslots);
    const setting = settings.find((s) => s.id === settingID);

    data.push(setting ? setting.value : 0);
    time.add(1, "hour");
  }
  return data;
};

//get the setting ID for a given time, based on timeslots
const getSettingID = (time: Moment, slots: TimeSlot[]) => {
  const slot = slots.find((ts) => time.isBetween(ts.startTime, ts.endTime, undefined, "[)"));
  return slot ? slot.settingID : "default";
};

const isWeekend = (date: Moment) => {
  const day = date.isoWeekday();
  return day > 5;
};
const isWeekday = (date: Moment) => {
  const day = date.isoWeekday();
  return day <= 5;
};

const isHoliday = (date: Moment, holidays: Moment[]) => {
  let holiday = false;
  swedishHoliday.forEach((day) => {
    if (day.isSame(date, "day")) holiday = true;
  });
  return holiday;
};

const swedishHoliday = [
  moment("6/1", "DD/MM"), //Epiphany
  moment("1/1", "DD/MM"), //new years day
  moment("31/12", "DD/MM"), //New years eve
  moment("24/12", "DD/MM"), //Christmas eve
  moment("25/12", "DD/MM"), //Christmas day
  moment("26/12", "DD/MM"), //Seccond christmas day
  moment("6/6", "DD/MM"), //National day
  // easter stuff......
];
