import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useScenario, useProject } from "api/useFirebase";
import LoadingOverlay from "./basic/LoadingOverlay";
import ReportEditor from "./simulations/reportEditor/ReportEditor";
import { store } from "store";

interface Props {}

export const ReportViewPage: React.FC<Props> = () => {
  const { projectID, scenarioID } = useParams<{
    projectID: string | undefined;
    scenarioID: string | undefined;
  }>();
  const scenario = useScenario(projectID, scenarioID);
  const project = useProject(projectID);
  const { dispatch } = useContext(store);
  useEffect(() => {
    if (scenario) document.title = scenario.scenarioName;
  }, [scenario]);

  useEffect(() => {
    if (project)
      dispatch({
        type: "SET_CURRENT_PROJECT",
        payload: { projectID: project.id, projectName: project.projectName },
      });
  }, [project, dispatch]);

  if (!projectID || !scenarioID || (scenario && !scenario.report_download))
    return <div className="px-4 py-4 italic text-red-600">Error loading reports</div>;
  else
    return (
      <div className="fixed top-0 left-0 h-screen w-screen bg-white">
        {!scenario && <LoadingOverlay />}
        {scenario && (
          <ReportEditor
            //@ts-ignore
            report_download={scenario.report_download}
            sourcetype="scenario"
            savedChanges={scenario.reportChanges}
            sourceID={scenario.id}
            useModal={false}
            reportName={`${project?.projectName}_${scenario.scenarioName}`}
          />
        )}
      </div>
    );
};
