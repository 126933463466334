import React, { useEffect, useMemo, useState } from "react";
import {
  Scenario,
  PostProcessor,
  SimTimeUnit,
  ScheduledSimulationTask,
} from "model/datatypes";
import Dropdown, { DropdownAtRoot } from "components/basic/Dropdown";
import { useUserRole } from "api/useAuth";
import OpenCloseArrow from "components/basic/icons/OpenCloseArrow";
import { useMaxHeightTransition } from "utils/hooks/useMaxHeightTransition";
import { ModelEditor } from "components/systems/editSystem/GitModels";
import ToggleButton from "components/basic/ToggleButton";
import dayjs from "dayjs";
import { useAnalyticsLogger, useFirebase, useFirestore } from "api/useFirebase";
import { convertToFirestoreFormat } from "utils/firebase/firestoreFormatter";
import { useGlobalState } from "store";
import { startSimulation } from "api/SimulationAPI";
import * as Sentry from "@sentry/browser";
import Toast from "components/basic/Toast";
import LoadingOverlay from "components/basic/LoadingOverlay";
import { convertRawTimeFromUnit, convertRawTimeToUnit } from "utils/dataTransform/timeConvert";
import ConfigureSchedule from "components/simulations/scenarioPipeline/ConfigureSchedule";
import Modal from "components/basic/Modal";
import gtw from "gtw";

interface Props {
  onFinish: () => void;
  scenario: Scenario;
  projectID: string;
}

export const RunSimulation: React.FC<Props> = ({ onFinish, scenario, projectID }) => {
  const { hasDeveloperAccess } = useUserRole();

  const { open: showAdvanced, setOpen: setShowAdvanced, style } = useMaxHeightTransition(
    "0",
    "2000px",
    false
  );

  const { projectName, user } = useGlobalState();
  const analyticsLogger = useAnalyticsLogger();

  const [endpoint, setEndpoint] = useState<Scenario["endpoint"]>(
    scenario.endpoint || "kubernetes"
  );
  const [edtiedRunSettings, setEdtiedRunSettings] = useState({
    settings: scenario.run_settings,
    forceUpdate: false, //used to update UI for selectercomponent
  });
  const [changedSetting, setChangedSetting] = useState(false);

  const [editedGitModel, setEditedGitModel] = useState(scenario.model.model_code);
  const [gitModelChanged, setGitModelChanged] = useState(false);

  const fs = useFirestore();
  const fb = useFirebase();

  const [loading, setLoading] = useState(false);

  const updateScenario = async (updated: Partial<Scenario>) => {
    fb.firestore()
      .collection("Projects")
      .doc(projectID)
      .collection("Scenarios")
      .doc(scenario.id)
      .update({
        ...updated,
      });
  };
  const requestSimulation = async (updatedFields: Partial<Scenario>) => {
    await updateScenario(updatedFields);
    await startSimulation(projectID, scenario.id, fb, endpoint, scenario.groupID); //call start simulation api
  };

  const requestSchedule = async (
    updatedFields: Partial<Scenario>,
    schedule: ScheduledSimulationTask
  ) => {
    const newScheduleDoc = fs.collection("ScheduledSimulationTasks").doc();
    updatedFields.simulationScheduleID = newScheduleDoc.id;
    await updateScenario(updatedFields);
    schedule.id = newScheduleDoc.id;
    await newScheduleDoc.set(convertToFirestoreFormat(schedule));
  };

  const startRequesting = async () => {
    if (!loading) {
      try {
        setLoading(true);
        const updatedFields: Partial<Scenario> = {
          run_settings: edtiedRunSettings.settings,
          endpoint,
        };
        if (gitModelChanged)
          updatedFields.model = { ...scenario.model, model_code: editedGitModel };
        if (schedule) await requestSchedule(updatedFields, schedule);
        else await requestSimulation(updatedFields);

        analyticsLogger(schedule ? "scheduled_simulation_set" : "simulation_started", {
          project_name: projectName,
          project_id: projectID,
          scenarioName: scenario.scenarioName,
          endpoint: endpoint || "kubernetes",
          user_name: user?.fullName || "",
          user_id: user?.fbUser.uid || "",
        });
        setLoading(false);
        onFinish();
      } catch (error) {
        Sentry.captureException(error);
        Toast("Something went wrong");
        setLoading(false);
      }
    }
  };

  const [schedule, setSchedule] = useState<ScheduledSimulationTask | null>(null);
  const renderScheduleSetup = () => {
    return (
      <>
        <label className={`${tw.label} mt-4`}>Scheduled run</label>
        <div className="p-px mb-4">
          <ToggleButton
            active={!!schedule}
            onChange={() => {
              if (!schedule) {
                let now = dayjs();
                const roundedMin = Math.round(now.get("minute") / 5) * 5;
                setSchedule({
                  id: "",
                  launchTime: now.set("minute", roundedMin),
                  projectID,
                  scenarioID: scenario.id,
                  userID: user?.fbUser.uid,
                  type: "start_simulation",
                });
              } else setSchedule(null);
            }}
          />
        </div>
        {schedule && <ConfigureSchedule schedule={schedule} setSchedule={setSchedule} />}
      </>
    );
  };

  const renderAdvancedSettings = () => {
    return (
      <div className="px-4 py-2 bg-gray-50 border border-gray-100 rounded my-4 ">
        <div
          className="flex py-2 justify-between cursor-pointer"
          onClick={() => setShowAdvanced(!showAdvanced)}
        >
          <span className="text-xs font-bold ">Advanced settings</span>
          <OpenCloseArrow isOpen={showAdvanced} />
        </div>
        <div className="flex flex-col text-sm overflow-hidden" style={style}>
          <SimulationRunIntervalSetting
            runSettings={edtiedRunSettings}
            updateRunSettings={(newSetting) =>
              setEdtiedRunSettings({ settings: newSetting, forceUpdate: false })
            }
          />
          {hasDeveloperAccess && (
            <>
              <label className={`${tw.label}`}>
                Manual models {gitModelChanged ? "*" : ""}
              </label>
              <ModelEditor
                editedGitRefs={editedGitModel}
                setEditedGitRefs={(updated) => {
                  setEditedGitModel(updated);
                  setGitModelChanged(true);
                  setChangedSetting(true);
                }}
              />
            </>
          )}
          {hasDeveloperAccess && (
            <EndpointSettings
              endpoint={endpoint}
              setEndpoint={(updated) => {
                setEndpoint(updated);
                setChangedSetting(true);
              }}
            />
          )}
          {renderScheduleSetup()}
        </div>
      </div>
    );
  };

  const [saveChangeModal, setSaveChangeModal] = useState(false);
  const renderSaveChangeOption = () => {
    return (
      <Modal zIndex={40} onClose={() => setSaveChangeModal(false)}>
        <div className="modal-content z-50 w-64">
          <div className="font-medium">Save updated runner settings?</div>
          <div className=" mt-2 w-full">
            <button
              className="button-small  w-full mb-2"
              onClick={() => {
                //save
                const updatedFields: Partial<Scenario> = {
                  run_settings: edtiedRunSettings.settings,
                  endpoint,
                };
                if (gitModelChanged)
                  updatedFields.model = { ...scenario.model, model_code: editedGitModel };
                setLoading(true);
                updateScenario(updatedFields)
                  .then(() => {
                    setLoading(false);
                    onFinish();
                  })
                  .catch(() => {
                    setLoading(false);
                    Toast("Error saving changes", { icon: "error" });
                  });
              }}
            >
              Save
            </button>
            <button
              className="button-small  w-full"
              onClick={() => {
                onFinish();
              }}
            >
              Close without saving
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Modal
        canOverflow
        zIndex={30}
        onClose={() => {
          if (loading) return;
          else if (changedSetting) setSaveChangeModal(true);
          else onFinish();
        }}
      >
        <div className={`px-8 py-4 bg-white z-40 shadow-lg rounded w-1/2 relative`}>
          {loading && <LoadingOverlay />}
          <div className="text-xl font-medium ">Start simulation</div>
          <div className="text-xs italic mb-2">{scenario.scenarioName}</div>
          {scenario.model.postProcessors && scenario.model.postProcessors.length > 0 && (
            <div className="mb-2">
              <PostProcessingSettings
                postProcessors={scenario.model.postProcessors}
                selectedID={scenario.selectedPostProcessor?.id}
                onSelect={(selected) => {
                  updateScenario({ selectedPostProcessor: selected });
                }}
              />
            </div>
          )}
          <SimulationRunSettings
            runSettings={edtiedRunSettings}
            updateRunSettings={(newSetting) => {
              setEdtiedRunSettings({ settings: newSetting, forceUpdate: false });
              setChangedSetting(true);
            }}
          />
          {renderAdvancedSettings()}

          <button
            onClick={() => {
              startRequesting();
            }}
            className={`${tw.runBtn} w-full mt-4`}
          >
            {schedule ? "Setup scheduled simulation" : "Start simulation now"}
          </button>
        </div>
      </Modal>
      {saveChangeModal && renderSaveChangeOption()}
    </>
  );
};

//////////////////////
//////////////////////
//////////////////////
//////////////////////
//////////////////////
//////////////////////
//HELPERS

export const PostProcessingSettings: React.FC<{
  postProcessors: PostProcessor[];
  selectedID?: string;
  onSelect: (selected: PostProcessor) => void;
}> = ({ postProcessors, selectedID, onSelect }) => {
  return (
    <>
      <label className={`${tw.label}`}>Post processor</label>
      <Dropdown
        className={"text-xs"}
        selectedID={selectedID}
        options={postProcessors.map((postProcessor) => ({
          id: postProcessor.id,
          display: postProcessor.name,
          val: postProcessor,
        }))}
        placeholder="Select post processor"
        onSelect={(option) => {
          onSelect(option.val);
        }}
      />
    </>
  );
};

const tw = {
  label: "text-xs font-bold",
  runBtn: "py-1 px-2 shadow rounded border focus:outline-none text-xs font-medium w-full",
  input: "px-2 py-1 focus:outline-none border rounded",
};

export default RunSimulation;

export const SimulationRunSettings: React.FC<{
  runSettings: { settings: Scenario["run_settings"]; forceUpdate: boolean };
  updateRunSettings: (newSetting: Scenario["run_settings"]) => void;
}> = ({ runSettings, updateRunSettings }) => {
  const [displayDuration, setDisplayDuration] = useState(
    convertRawTimeToUnit(
      runSettings.settings.duration,
      runSettings.settings.duration_unit || "months"
    ).toString()
  );

  const runTimeMsg = useMemo(() => {
    return `Will simulate ${runSettings.settings.duration} seconds`;
  }, [runSettings]);

  const [displayResultRes, setDisplayResultRes] = useState(
    convertRawTimeToUnit(
      runSettings.settings.result_resolution,
      runSettings.settings.result_unit || "months"
    ).toString()
  );
  const resultResMsg = useMemo(() => {
    return `Will generate result for every ${runSettings.settings.result_resolution} seconds`;
  }, [runSettings]);

  useEffect(() => {
    if (runSettings.settings && runSettings.forceUpdate) {
      setDisplayResultRes(
        convertRawTimeToUnit(
          runSettings.settings.result_resolution,
          runSettings.settings.result_unit || "month"
        ).toString()
      );
      setDisplayDuration(
        convertRawTimeToUnit(
          runSettings.settings.duration,
          runSettings.settings.duration_unit || "month"
        ).toString()
      );
    }
  }, [runSettings]);

  return (
    <>
      <label className={`${tw.label} mb-1`}>Simulation Run Time</label>
      <div className="flex items-start">
        <div className={`w-full xl:w-1/2 flex border rounded overflow-hidden`}>
          <input
            className={`text-sm w-1/2 px-2 py-1 focus:outline-none `}
            type="number"
            value={displayDuration}
            min="0"
            pattern="^-?[0-9]\d*\.?\d*$"
            onChange={(e) => {
              setDisplayDuration(e.target.value);
              const newVal = parseFloat(e.target.value);
              if (!isNaN(newVal)) {
                updateRunSettings({
                  ...runSettings.settings,
                  duration: convertRawTimeFromUnit(newVal, runSettings.settings.duration_unit),
                });
              } else updateRunSettings({ ...runSettings.settings, duration: 0 });
            }}
          />
          <DropdownAtRoot
            headlessStyle
            className="bg-white text-xs w-1/2 border-l"
            selectedID={runSettings.settings.duration_unit}
            options={[
              { id: "month", display: "Months" },
              { id: "day", display: "Days" },
              { id: "hour", display: "Hours" },
              { id: "minute", display: "Minutes" },
              { id: "second", display: "Seconds" },
            ]}
            onSelect={(option) => {
              const newUnit = option.id as SimTimeUnit;
              const newDurationVal = convertRawTimeFromUnit(
                parseInt(displayDuration),
                newUnit
              );
              updateRunSettings({
                ...runSettings.settings,
                duration_unit: newUnit,
                duration: newDurationVal,
              });
            }}
          />
        </div>
      </div>
      <div className="italic text-xs mb-2">{runTimeMsg}</div>

      <label className={`${tw.label}`}>Result resolution</label>
      <div className="flex items-start">
        <div className={`w-full xl:w-1/2 flex border rounded overflow-hidden`}>
          <input
            className={`text-sm w-1/2 px-2 py-1 focus:outline-none `}
            type="number"
            value={displayResultRes}
            min="0"
            pattern="^-?[0-9]\d*\.?\d*$"
            onChange={(e) => {
              setDisplayResultRes(e.target.value);
              const newVal = parseFloat(e.target.value);
              if (!isNaN(newVal)) {
                updateRunSettings({
                  ...runSettings.settings,
                  result_resolution: convertRawTimeFromUnit(
                    newVal,
                    runSettings.settings.result_unit
                  ),
                });
              } else updateRunSettings({ ...runSettings.settings, result_resolution: 0 });
            }}
          />
          <DropdownAtRoot
            headlessStyle
            className="bg-white text-xs w-1/2 border-l"
            selectedID={runSettings.settings.result_unit}
            options={[
              { id: "day", display: "Days" },
              { id: "hour", display: "Hours" },
              { id: "minute", display: "Minutes" },
              { id: "second", display: "Seconds" },
            ]}
            onSelect={(option) => {
              const newUnit = option.id as SimTimeUnit;
              const newResResVal = convertRawTimeFromUnit(parseInt(displayResultRes), newUnit);
              updateRunSettings({
                ...runSettings.settings,
                result_unit: newUnit,
                result_resolution: newResResVal,
              });
            }}
          />
        </div>
      </div>
      <div className="italic text-xs mb-2">{resultResMsg}</div>
    </>
  );
};

const SimulationRunIntervalSetting: React.FC<{
  runSettings: { settings: Scenario["run_settings"]; forceUpdate: boolean };
  updateRunSettings: (newSetting: Scenario["run_settings"]) => void;
}> = ({ runSettings, updateRunSettings }) => {
  const stepsPrDay = useMemo(() => 86400 / runSettings.settings.resolution, [runSettings]);

  const [simResDisplay, setSimResDisplay] = useState(
    runSettings.settings.resolution?.toString() || "1"
  );
  return (
    <>
      <label className={`${tw.label}`}>Simulation calculation interval (s)</label>
      <input
        className={`${tw.input} text-sm w-1/2`}
        type="number"
        value={simResDisplay}
        min="0"
        step="0.1"
        onChange={(e) => {
          const newVal = parseFloat(e.target.value);
          setSimResDisplay(e.target.value);
          if (!isNaN(newVal))
            updateRunSettings({ ...runSettings.settings, resolution: newVal });
          else updateRunSettings({ ...runSettings.settings, resolution: 0 });
        }}
      />
      <div className="italic text-xs mb-2">{stepsPrDay} timesteps pr. day</div>
    </>
  );
};

export const EndpointSettings: React.FC<{
  endpoint: Scenario["endpoint"];
  setEndpoint: (updated: Scenario["endpoint"]) => void;
}> = ({ endpoint, setEndpoint }) => {
  return (
    <>
      <label className={`${tw.label} mt-4`}>Deployment system</label>
      <DropdownAtRoot
        className="bg-white mb-4 text-xs"
        options={[
          { id: "kubernetes", display: "Kubernetes", val: "kubernetes" },
          { id: "pipelines", display: "Pipelines", val: "pipelines" },
          {
            id: "custom_url",
            display: "Custom URL",
            val: { startEndpoint: "", stopEndpoint: "" },
          },
        ]}
        selectedID={typeof endpoint === "object" ? "custom_url" : endpoint}
        onSelect={(option) => {
          setEndpoint(option.val as Scenario["endpoint"]);
        }}
      />
      {typeof endpoint === "object" && (
        <div>
          <div className="text-xs font-medium">Start simulation endpoint</div>
          <input
            className={`${gtw.input} w-full text-xs`}
            value={endpoint.startEndpoint}
            onChange={(e) => setEndpoint({ ...endpoint, startEndpoint: e.target.value })}
          />
          <div className="text-xs font-medium">Stop simulation endpoint</div>
          <input
            className={`${gtw.input} w-full text-xs`}
            value={endpoint.stopEndpoint}
            onChange={(e) => setEndpoint({ ...endpoint, stopEndpoint: e.target.value })}
          />
        </div>
      )}
    </>
  );
};
