import { Comment } from "model/datatypes";
import React, { useContext, useState } from "react";
import { store } from "store";
import AddComment from "./AddComment";

interface Props {
  commentTaget: Partial<Comment>;
}

const CommentableComponent: React.FC<Props & React.HTMLProps<HTMLDivElement>> = ({
  onClick,
  commentTaget,
  className,
  children,
  ...props
}) => {
  const { state, dispatch } = useContext(store);
  const { isAddingComment } = state;
  const [target, setTarget] = useState<null | Partial<Comment>>(null);
  // console.log(commentTaget);

  return (
    <>
      <div
        {...props}
        className={`
        relative 
        ${className || ""}
      `}
        // ${isAddingComment ? "highlighted-card-active cursor-pointer" : ""}
        // onClick={(e) => {
        //   e.stopPropagation();
        //   if (isAddingComment) {
        //     setTarget(commentTaget);
        //   }
        //   if (onClick) onClick(e);
        // }}
      >
        {isAddingComment && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (isAddingComment) {
                setTarget(commentTaget);
              }
              if (onClick) onClick(e);
            }}
            className="absolute z-20 w-full h-full flex items-center justify-center cursor-pointer"
          >
            <span className="text-white font-medium z-20 opacity-100 px-4 py-1 rounded-full bg-gray-400">
              Click to add comment
            </span>
            <div className="bg-gray-400 opacity-75 absolute h-full w-full z-10"></div>
          </div>
        )}
        {children}
      </div>
      {target && (
        <AddComment
          commentTarget={target}
          onFinish={() => {
            dispatch({ type: "FINISHED_ADDING_COMMENT" });
            setTarget(null);
            console.log("On finish");
          }}
          onClose={() => {
            setTarget(null);
            console.log("On close");
          }}
        />
      )}
    </>
  );
};

export default CommentableComponent;
