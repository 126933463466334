import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useFirebase } from "api/useFirebase";
import * as Sentry from "@sentry/browser";
import Modal from "components/basic/Modal";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import { getReportURL } from "utils/firebase/firebaseUtils";
import gtw from "gtw";
import { updateArrayVal } from "utils/jsUtils/imutableArray";
import { Scenario, ReportChange } from "model/datatypes";
import { store } from "store";
import Toast from "components/basic/Toast";
import getUUID from "utils/jsUtils/getUUID";
import { useUserRole } from "api/useAuth";

const ReportEditor: React.FC<{
  onClose?: () => void;
  report_download: {
    bucket_id: string;
    report_path: string;
    timestamp: string;
  };
  savedChanges: Scenario["reportChanges"];
  sourcetype: "group" | "scenario";
  sourceID: string;
  useModal: boolean;
  reportName: string;
}> = ({
  report_download,
  onClose,
  savedChanges,
  sourceID,
  sourcetype,
  useModal,
  reportName,
}) => {
  const [changeLog, setChangeLog] = useState<ReportChange[][]>([savedChanges || []]); //list of changes in session
  const [changes, setChanges] = useState(savedChanges); //the changes actually send to the report
  const [selectedChange, setSelectedChange] = useState<ReportChange | null>(null); //change being edited
  const [savingChanges, setSavingChanges] = useState(false);
  const [loadingReportUrl, setLoadingReportUrl] = useState(false);
  const fb = useFirebase();
  const [reportUrl, setReportUrl] = useState<string>();
  const [error, setError] = useState<string>();
  const [iframeLoading, setIframeLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { state } = useContext(store);
  const { projectID } = state;
  const { hasSimulatorAccess } = useUserRole();

  //Load URL for Iframe:
  useEffect(() => {
    setLoadingReportUrl(true);
    getReportURL(
      { report_path: report_download.report_path, bucket_id: report_download.bucket_id },
      fb
    )
      .then((url) => {
        //open URL in new tab....
        setLoadingReportUrl(false);
        setReportUrl(url);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setLoadingReportUrl(false);
        setError("error loading report...");
      });
  }, [report_download.report_path, report_download.bucket_id, report_download.timestamp, fb]);

  const retryLoadReport = () => {
    setLoadingReportUrl(true);
    setError(undefined);
    getReportURL(
      { report_path: report_download.report_path, bucket_id: report_download.bucket_id },
      fb
    )
      .then((url) => {
        setLoadingReportUrl(false);
        setReportUrl(url);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setLoadingReportUrl(false);
        setError("error loading report...");
      });
  };

  //sync changes to report iframe:
  useEffect(() => {
    const reportWindow = iframeRef.current?.contentWindow;
    if (changes && changes.length > 0 && reportWindow && !iframeLoading) {
      reportWindow.postMessage({ type: "UPDATE_CONTENT", payload: changes }, "*");
    }
  }, [changes, iframeLoading]);

  const onUpdateChanges = async (updatedChanges: ReportChange[]) => {
    try {
      if (projectID && !savingChanges) {
        setSavingChanges(true);
        await fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection(sourcetype === "group" ? "Groups" : "Scenarios")
          .doc(sourceID)
          .update({
            reportChanges: updatedChanges,
          });
        setChanges(updatedChanges);
        setChangeLog([...changeLog, updatedChanges]);
        setSavingChanges(false);
      }
    } catch (error) {
      setSavingChanges(false);
      Sentry.captureException(error);
      setError("Error saving changes...");
    }
  };

  const goBack = async () => {
    try {
      if (projectID && !savingChanges && changeLog.length > 1) {
        const prevChanges = changeLog[changeLog.length - 2];
        setSavingChanges(true);
        await fb
          .firestore()
          .collection("Projects")
          .doc(projectID)
          .collection(sourcetype === "group" ? "Groups" : "Scenarios")
          .doc(sourceID)
          .update({
            reportChanges: prevChanges,
          });
        //set changes to set removed changes to === ""
        setChanges(
          changeLog[changeLog.length - 1].map(
            (change) =>
              prevChanges.find((c) => c.id === change.id) || {
                ...change,
                innerHTML: change.original,
              }
          )
        );
        setSavingChanges(false);
        setChangeLog(changeLog.slice(0, changeLog.length - 1));
      }
    } catch (error) {
      setSavingChanges(false);
      Sentry.captureException(error);
      setError("Error saving changes...");
    }
  };

  const [iframeScrollTop, setIframeScrollTop] = useState(0);
  const handleIframeScroll = (scrollData: { top: number }) => {
    setIframeScrollTop(scrollData.top);
  };

  const latestClickedRef = useRef<{ clickedId: string; uuid: string } | null>(null);

  const onReportClick = useCallback(
    (clicked: ReportChange) => {
      if (hasSimulatorAccess) {
        const isDoubleClick = latestClickedRef.current?.clickedId === clicked.id;
        if (isDoubleClick && clicked.id.length > 0) {
          const existingChange = changes?.find((change) => change.id === clicked.id);
          setSelectedChange(existingChange || clicked);
        } else setSelectedChange(null);

        const clickUUID = getUUID();
        latestClickedRef.current = { clickedId: clicked.id, uuid: clickUUID };
        setTimeout(() => {
          if (latestClickedRef.current?.uuid === clickUUID) latestClickedRef.current = null;
        }, 1000); //double click reset
      }
    },
    [changes, hasSimulatorAccess]
  );

  const copyLinkToClipboard = (url: string) => {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Toast("Link copied to clipboard", { icon: "success" });
  };

  //on recieve iframe communication
  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      const action = event.data;
      switch (action.type) {
        case "CLICK":
          onReportClick(action.payload);
          break;
        case "SCROLL":
          handleIframeScroll(action.payload);
          break;
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onReportClick]);

  const renderChangeEditor = () => {
    if (selectedChange)
      return (
        <div
          className="px-4 py-2 bg-white rounded shadow-md absolute top-0 left-0 flex flex-col border border-gray-300"
          style={{
            height: "250px",
            width: "450px",
            marginTop: selectedChange.pos.top - iframeScrollTop - 250,
            marginLeft: selectedChange.pos.left - 225,
          }}
        >
          <div>{selectedChange.id}</div>
          <textarea
            value={selectedChange.innerHTML}
            className="border w-full flex-grow focus:outline-none focus:shadow-md px-2 py-1 mb-2"
            onChange={(e) =>
              setSelectedChange({ ...selectedChange, innerHTML: e.target.value })
            }
          />
          <div className="flex items-center">
            <button
              className={`${gtw.smallBtn} flex-1 mr-1`}
              onClick={() => {
                onUpdateChanges(updateArrayVal(changes || [], selectedChange)).then(() => {
                  setSelectedChange(null);
                });
              }}
            >
              Save
            </button>
            <button
              className={`${gtw.smallBtn} flex-1 mx-1`}
              onClick={() => {
                setSelectedChange(null);
              }}
            >
              Cancel
            </button>
            <button
              className={`${gtw.smallBtn} flex-1 bg-red-300 border-red-300 ml-1`}
              onClick={() => {
                const resatChange = {
                  ...selectedChange,
                  innerHTML: selectedChange.original,
                };
                onUpdateChanges(updateArrayVal(changes || [], resatChange)).then(() => {
                  setSelectedChange(null);
                });
              }}
            >
              Reset
            </button>
          </div>
        </div>
      );
    else return null;
  };

  const [overviewOpen, setOverviewOpen] = useState(false);
  const renderChangeOverview = () => {
    const activeChanges = changes?.filter((c) => c.innerHTML !== c.original);
    return (
      <div className="absolute bottom-0 right-0 mb-4 mr-4">
        <div
          className={`bg-white shadow-lg border border-gray-400 ${
            overviewOpen ? "rounded h-128 w-64" : "rounded-full h-8 w-8"
          }`}
          style={{ transition: "width 0.2s, height 0.2s" }}
        >
          {overviewOpen && (
            <div className="px-2 py-2 h-full w-full flex flex-col">
              <div className="font-medium text-sm px-2">Report changes</div>
              <div className="flex-grow overflow-auto text-xs">
                {activeChanges &&
                  activeChanges.map((change) => {
                    const isSelected = change.id === selectedChange?.id;
                    return (
                      <div
                        key={change.id}
                        className={`cursor-pointer my-2 py-1 px-2 border rounded-lg ${
                          isSelected ? "border-green-200 bg-green-200" : "border-white"
                        }`}
                        onClick={() => setSelectedChange(change)}
                      >
                        <div>{change.id}</div>
                        <div className="truncate font-medium">{change.innerHTML}</div>
                      </div>
                    );
                  })}
              </div>
              <button
                className="italic text-blue-700 focus:outline-none text-xs"
                onClick={() => setOverviewOpen(false)}
              >
                Hide
              </button>
            </div>
          )}
          {!overviewOpen && (
            <div
              className="w-full h-full flex items-center justify-center cursor-pointer"
              onClick={() => setOverviewOpen(true)}
            >
              {activeChanges?.length || 0}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderTheReport = () => {
    if (loadingReportUrl)
      return (
        <div className="w-full h-full flex items-center justify-center text-xl">
          <LoadingIcon />
        </div>
      );
    else if (error)
      return (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <div className="italic text-red-500">{error}</div>
          <button className={gtw.smallBtn} onClick={() => retryLoadReport()}>
            Retry
          </button>
        </div>
      );
    else if (reportUrl)
      return (
        <>
          <iframe
            ref={iframeRef}
            className="h-full w-full"
            src={reportUrl}
            title="Report"
            onLoad={(reportLoadedEvent) => setIframeLoading(false)}
          />

          {selectedChange && renderChangeEditor()}
          {hasSimulatorAccess && renderChangeOverview()}

          <div className="absolute top-0 right-0 mt-4 mr-4 flex">
            {changeLog.length > 1 && (
              <button
                className={`${gtw.smallBtn} mr-2 hover:text-black hover:shadow-md w-8`}
                onClick={() => {
                  goBack();
                }}
              >
                {savingChanges ? <LoadingIcon /> : <UndoIcon />}
              </button>
            )}
            <button
              className={`${gtw.smallBtn} hover:text-black hover:shadow-md w-8 mr-2`}
              onClick={() => {
                copyLinkToClipboard(reportUrl);
              }}
            >
              <LinkIcon />
            </button>
            <button
              className={`${gtw.smallBtn} hover:text-black hover:shadow-md w-8`}
              onClick={() => {
                const reportWindow = iframeRef.current?.contentWindow;
                if (reportWindow)
                  reportWindow.postMessage(
                    {
                      type: "DOWNLOAD_REPORT",
                      payload: reportName,
                    },
                    "*"
                  );
              }}
            >
              <DownloadIcon />
            </button>
          </div>
        </>
      );
    else return null;
  };

  if (useModal)
    return (
      <Modal
        className="px-8 py-4"
        onClose={() => {
          if (onClose) onClose();
        }}
      >
        <div className="z-50 w-full h-full bg-white rounded shadow-xl relative">
          {renderTheReport()}
        </div>
      </Modal>
    );
  else return <div className="h-full w-full  bg-white">{renderTheReport()}</div>;
};

export default ReportEditor;

const DownloadIcon = () => {
  return (
    <svg
      className="w-full h-full"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
    </svg>
  );
};

const LinkIcon = () => {
  return (
    <svg
      className="w-full h-full"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"></path>
    </svg>
  );
};

const UndoIcon = () => (
  <svg
    className="w-full h-full"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0V0z"></path>
    <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L3.71 8.71C3.08 8.08 2 8.52 2 9.41V15c0 .55.45 1 1 1h5.59c.89 0 1.34-1.08.71-1.71l-1.91-1.91c1.39-1.16 3.16-1.88 5.12-1.88 3.16 0 5.89 1.84 7.19 4.5.27.56.91.84 1.5.64.71-.23 1.07-1.04.75-1.72C20.23 10.42 16.65 8 12.5 8z"></path>
  </svg>
);
