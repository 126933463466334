import { useFirebase } from "api/useFirebase";
import { DropdownAtRoot } from "components/basic/Dropdown";
import DotDotDotIcon from "components/basic/icons/DotDotDotIcon";
import LoadingOverlay from "components/basic/LoadingOverlay";
import Modal from "components/basic/Modal";
import { Popup } from "components/basic/Popup";
import gtw from "gtw";
import { RawUser } from "model/datatypes";
import React, { useState } from "react";
import { deleteUser } from "utils/firebase/firebaseUtils";

const UserCard: React.FC<{ user: RawUser }> = ({ user }) => {
  const fb = useFirebase();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const roleOptions = [
    { id: "developer", display: "Developer" },
    { id: "simulator", display: "Simulator" },
    { id: "guest", display: "Guest" },
    // vil vi tilbyde at man kan ændre nogle til owner? De kan ikke ændres tilbage til sim/dev efter den ændring
  ];

  // Skal der lige en setLoading(true) på her? Eller er det ligegyldigt.
  const updateUserRole = (updatedRole: string) => {
    if (user && updatedRole)
      fb.firestore().collection("users").doc(user.id).update({ userRole: updatedRole });
  };

  const removeUser = () => {
    if (user.id && !loading) {
      setLoading(true);
      deleteUser(user.id, fb)
        .then(() => setLoading(false))
        .catch((err) => {
          console.log("Issue deleting user", err);
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex items-center  justify-evenly p-2 text-xs text-gray-700">
      <div className="w-1/5">{user.fullName}</div>
      <div className="w-2/5">{user.mail}</div>

      <div className="w-1/5 pr-3">
        {user.userRole !== "owner" ? (
          <DropdownAtRoot
            selectedID={user.userRole || undefined}
            options={roleOptions}
            onSelect={(option) => updateUserRole(option.id)}
          />
        ) : (
          <p>Owner</p>
        )}
      </div>
      <div className="w-1/5 flex justify-end">
        {user.userRole !== "owner" ? (
          <Popup
            useHover
            mt={15}
            pos={"right"}
            content={(closeMe) => (
              <div className="text-xs">
                <button
                  className={`${gtw.popupBtn} text-red-400`}
                  onClick={() => {
                    setIsOpen(true);
                    closeMe();
                  }}
                >
                  Remove user
                </button>
              </div>
            )}
          >
            <button className="relative focus:outline-none flex justify-center items-center">
              <DotDotDotIcon />
            </button>
          </Popup>
        ) : null}
      </div>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <div className={`${tw.card} z-50 p-6 relative`}>
            {loading && <LoadingOverlay />}
            <div className="text-xl text-center mb-2">
              Remove {user.fullName} from the organisation?
            </div>
            <div className="text-center">This action is permanent and cannot be undone</div>
            <div className="mt-4 flex justify-center">
              <button
                onClick={removeUser}
                className="bg-red-400 p-2 border border-red-400 text-white font-bold rounded px-20 mx-1"
              >
                Delete
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className="bg-transparent border border-gray-300 p-2 rounded px-20 mx-1"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UserCard;

const tw = {
  headline: "font-bold text-gray-700 text-xl",
  card: "border border-gray-300 rounded bg-white shadow",
  label: "font-bold text-xs",
  dropdown: "w-40",
  input: "px-2 py-2 focus:outline-none border w-full text-sm",
  saveBtn:
    "bg-white py-2 w-full shadow rounded border border-gray-200 focus:outline-none text-xs hover:font-medium",
  smallBtn: "py-1 px-2 shadow rounded border border-gray-200 focus:outline-none text-xs",
};
