import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import gtw from "gtw";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useClickOutsideEffect } from "utils/hooks/useClickOutside";
import ArrowIcon from "./icons/ArrowIcon";

dayjs.extend(customParseFormat);

interface Props {
  onSelect?: (date: dayjs.Dayjs) => void;
  onCancel?: () => void;
  startSelected?: dayjs.Dayjs;
}

const DatePicker = ({ onCancel, onSelect, startSelected }: Props) => {
  const [selectedDay, setSelectedDay] = useState<null | dayjs.Dayjs>(startSelected || null);
  const [monthSelected, setMonthSelected] = useState(dayjs().date(1));
  const monthDays = useMemo(() => {
    const days = [];
    const startday = monthSelected.day(1);
    for (let i = 0; i < 35; i++) {
      const d = startday.add(i, "day");
      days.push(d);
    }
    return days;
  }, [monthSelected]);

  const renderDayNameCols = () => {
    const monday = dayjs().day(1);
    return (
      <>
        {new Array(7).fill(0).map((v, i) => (
          <div className="w-8 h-8 flex items-center justify-center -mb-1">
            {monday.add(i, "day").format("ddd")}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="bg-white rounded p-4 border border-gray-200 inline-block">
      <div className="w-full mb-2 flex justify-center items-center">
        <ArrowIcon
          direction="left"
          className="cursor-pointer"
          onClick={() => setMonthSelected(monthSelected.subtract(1, "month"))}
        />
        <div className="w-32 font-medium text-center">{monthSelected.format("MMMM YY")}</div>
        <ArrowIcon
          direction="right"
          className="cursor-pointer"
          onClick={() => setMonthSelected(monthSelected.add(1, "month"))}
        />
      </div>
      <div className="grid grid-cols-7 gap-4 text-xs">
        {renderDayNameCols()}
        {monthDays.map((d) => {
          const isThisMonth = d.isSame(monthSelected, "month");
          const isThePast = d.isBefore(dayjs(), "day");
          const isToday = d.isSame(dayjs(), "day");
          const isSelected = selectedDay && d.isSame(selectedDay, "day");
          return (
            <div key={d.valueOf()} className="w-full flex justify-center">
              <div
                onClick={() => {
                  if (isThePast) return;
                  setSelectedDay(d);
                  if (d.isAfter(monthSelected, "month"))
                    setMonthSelected(monthSelected.add(1, "month"));
                  if (d.isBefore(monthSelected, "month"))
                    setMonthSelected(monthSelected.subtract(1, "month"));
                }}
                className={`rounded-full w-6 h-6 flex items-center justify-center ${
                  isSelected
                    ? "bg-blue-700 text-white cursor-pointer font-medium"
                    : isThePast
                    ? "opacity-25 bg-gray-300"
                    : isToday
                    ? "bg-gray-300 font-medium cursor-pointer"
                    : !isThisMonth
                    ? "bg-gray-300 opacity-50 cursor-pointer"
                    : "bg-gray-300 cursor-pointer"
                }`}
              >
                {d.format("DD")}
              </div>
            </div>
          );
        })}
      </div>
      {onSelect && (
        <div className="flex mt-4">
          <button
            onClick={() => selectedDay && onSelect(selectedDay)}
            className={`${gtw.smallBtn} flex-1 
              ${selectedDay ? "" : "opacity-50"}  
              ${onCancel ? "mr-2" : ""}
          `}
          >
            Ok
          </button>
          {onCancel && (
            <button onClick={() => onCancel()} className={`${gtw.smallBtn} ml-2 flex-1`}>
              Cancel
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DatePicker;

export const DatetimeInputBox: React.FC<{
  selectedDate: dayjs.Dayjs;
  onSelectDate: (newDay: dayjs.Dayjs) => void;
}> = ({ selectedDate, onSelectDate }) => {
  const [value, setValue] = useState(selectedDate.format("HH:mm DD/MM YYYY"));
  const [error, setError] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const isFocused = useRef(false);
  const onClickOutside = useCallback(() => {
    if (isFocused) {
      let newDate = dayjs(value, "HH:mm DD/MM YYYY");
      const now = dayjs();
      if (newDate.isBefore(now)) newDate = now;
      const roundedMin = Math.round(newDate.get("minute") / 5) * 5;
      newDate = newDate.set("minute", roundedMin);
      if (newDate.isValid()) {
        onSelectDate(newDate);
        setValue(newDate.format("HH:mm DD/MM YYYY"));
      } else {
        setError("Wrong format");
      }
      isFocused.current = false;
    }
  }, [value, onSelectDate]);
  useClickOutsideEffect(ref, onClickOutside);
  return (
    <div
      ref={ref}
      className={`w-full relative px-2 py-1 border rounded bg-white overflow-x-hidden ${
        error ? "border-red-400" : ""
      }`}
    >
      <input
        className={`absolute top-0 left-0 px-2 py-1 focus:outline-none w-full z-10 block font-mono bg-transparent`}
        placeholder={"HH:mm DD/MM YYYY"}
        onFocus={() => {
          isFocused.current = true;
        }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          if (error && dayjs(e.target.value, "HH:mm DD/MM YYYY").isValid()) {
            setError(null);
          }
        }}
      />
      <div className="absolute top-0 left-0 h-full px-2 py-1 ">
        <div style={{ width: `${value.length * 8.4}px` }} className="bg-white h-full"></div>
      </div>
      <div className=" text-gray-400 font-mono">HH:mm DD/MM YYYY</div>
    </div>
  );
};
